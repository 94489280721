import {
  faChevronRight,
  faClock,
  faCreditCard,
} from "@fortawesome/pro-regular-svg-icons";
import { AnimatePresence, m } from "framer-motion";
import { times } from "lodash";
import { twJoin } from "tailwind-merge";
import { useBoolean } from "usehooks-ts";

import { DataValue } from "src/base-components/DataList";
import { Icon } from "src/base-components/Icon";
import { Pill } from "src/base-components/Pill";
import { SkeletonPlaceholder } from "src/base-components/SkeletonPlaceholder";
import { formatDate } from "src/utils/datetime";

const LeafNode: React.FC<{
  node?: TreeData;
  isFirst: boolean;
  isLast: boolean;
}> = ({ node, isFirst, isLast }) => {
  return (
    <li className="ml-2 flex border-l border-gray-100 pl-3 text-gray-800 font-inter-medium-13px">
      <div
        className={twJoin(
          "w-36 shrink-0 border-x border-gray-100 p-1.5",
          !isFirst && "border-t",
          isLast && "border-b",
        )}
      >
        {node ? node.key : <SkeletonPlaceholder height="h-5" width="w-24" />}
      </div>
      <div
        className={twJoin(
          "w-full flex-grow overflow-hidden text-ellipsis whitespace-nowrap border-r border-gray-100 p-1.5",
          !isFirst && "w-3 border-t",
          isLast && "border-b",
        )}
      >
        {node ? (
          <DataValue field={node.key} value={node.value} />
        ) : (
          <SkeletonPlaceholder height="h-5" width="w-36" />
        )}
      </div>
    </li>
  );
};

const ChildrenNode: React.FC<{
  node: TreeData;
  isLast: boolean;
  isRoot?: boolean;
  loading?: boolean;
}> = ({ node, isLast, isRoot, loading }) => {
  const { value: isOpen, toggle } = useBoolean(!!isRoot);

  return (
    <li
      className={twJoin(
        "relative",
        !isRoot && "ml-2 border-l pl-3",
        isLast ? "border-transparent" : "border-gray-100",
      )}
    >
      {!isRoot && (
        <div className="absolute -left-px h-4 w-3 border-b border-l border-gray-100" />
      )}
      <div
        className="flex cursor-pointer items-center gap-x-1 whitespace-nowrap border border-gray-100 bg-gray-50 p-1.5"
        onClick={toggle}
      >
        <Icon
          color={twJoin("text-gray-500", isOpen && "rotate-90")}
          icon={faChevronRight}
          size="2xs"
          animate
        />
        <span className="flex-grow truncate text-gray-800 font-inter-medium-13px">
          {loading ? (
            <SkeletonPlaceholder height="h-5" width="w-36" />
          ) : (
            String(node.value)
          )}
        </span>
        {node.type === "cards" && (
          <Pill size="sm" variant="gray">
            <Pill.Icon icon={faCreditCard} />
            <Pill.Text>Cards</Pill.Text>
          </Pill>
        )}
        {node.updatedAt && (
          <div className="ml-auto overflow-hidden">
            <Pill size="sm" variant="white" fullWidth>
              <Pill.Icon icon={faClock} />
              <Pill.Text>As on {formatDate(node.updatedAt)}</Pill.Text>
            </Pill>
          </div>
        )}
      </div>
      <AnimatePresence initial={false}>
        {isOpen && (
          <m.ul
            animate={{
              height: "auto",
              transition: { duration: 0.2 },
            }}
            exit={{ height: 0, transition: { duration: 0.08 } }}
            initial={{ height: 0 }}
            layout={!loading}
            style={{ overflow: "hidden" }}
          >
            {node.children?.map((childNode, i) =>
              childNode.children ? (
                <ChildrenNode
                  key={childNode.id}
                  isLast={i + 1 === node.children?.length}
                  loading={loading}
                  node={childNode}
                />
              ) : (
                <LeafNode
                  key={childNode.id}
                  isFirst={i === 0}
                  isLast={isLast && i + 1 === node.children?.length}
                  node={loading ? undefined : childNode}
                />
              ),
            )}
          </m.ul>
        )}
      </AnimatePresence>
    </li>
  );
};

export type TreeData = {
  id: string;
  key: string;
  value: unknown;
  type?: "cards";
  updatedAt?: string;
  children?: TreeData[];
};

type Props = {
  data: TreeData;
  loading?: boolean;
};

const loadingData: TreeData = {
  id: "loading",
  key: "loading",
  value: "loading",
  children: times(10, (i) => ({
    id: `loading-${i}`,
    key: `loading-${i}`,
    value: "loading",
  })),
};

export const Tree: React.FC<Props> = ({ data, loading }) => {
  return (
    <ul className="relative">
      <ChildrenNode
        loading={loading}
        node={loading ? loadingData : data}
        isLast
        isRoot
      />
    </ul>
  );
};
