import { capitalize, get, isNull } from "lodash";
import { Controller, useFormContext } from "react-hook-form";

import { JSONSchemaPropertyDefinition } from "src/api/connectApi/manifestTypes";
import { Select } from "src/base-components/Select";
import {
  FormPathT,
  ManifestFormType,
} from "src/connections/config/manifest/types";

type EnumFieldPropsT = {
  formPath: FormPathT;
  fieldKey: string;
  prefix: string;
  jsonSchemaDefinition: JSONSchemaPropertyDefinition;
  isRequired: boolean;
};

export const EnumField: React.FC<EnumFieldPropsT> = ({
  formPath,
  fieldKey,
  prefix,
  jsonSchemaDefinition,
  isRequired,
}) => {
  const { control, formState } = useFormContext<ManifestFormType>();

  // Satisfy lint
  if (typeof jsonSchemaDefinition === "boolean") {
    throw new Error("Boolean type is not supported for EnumField");
  }

  const name: `${FormPathT}.${string}` = `${formPath}.${fieldKey}`;
  const { enumLabels = {} } = jsonSchemaDefinition;

  const selectOptions =
    jsonSchemaDefinition.enum?.map((option, i) => {
      let value = option?.toString();

      if (typeof option === "string") {
        value = enumLabels[option] ?? option;
      }

      return {
        key: i.toString(),
        value,
      };
    }) ?? [];

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        const selectProps = {
          dataLoc: `${prefix}-form-select`,
          errored: !!get(formState.errors, fieldKey),
          options: selectOptions,
          placeholder: "Select option",
          value: field.value
            ? jsonSchemaDefinition.enum
                ?.indexOf(field.value as string | number)
                .toString()
            : null,
        };

        // Split into two separate cases, otherwise typescript will complain about the onChange function
        return !isRequired ? (
          <Select
            {...selectProps}
            showResetButton
            onChange={(key) => {
              return field.onChange(
                isNull(key) ? null : jsonSchemaDefinition.enum?.[parseInt(key)],
              );
            }}
          />
        ) : (
          <Select
            {...selectProps}
            onChange={(key) => {
              return field.onChange(jsonSchemaDefinition.enum?.[parseInt(key)]);
            }}
          />
        );
      }}
      rules={{
        required: isRequired && `${capitalize(fieldKey)} is required`,
      }}
    />
  );
};
