import { faSearch } from "@fortawesome/pro-regular-svg-icons";

import { Icon } from "src/base-components/Icon";

const Suggestion: React.FC<{
  prompt: string;
  onClick: (prompt: string) => void;
}> = ({ prompt, onClick }) => {
  return (
    <button
      className="flex h-28 w-36 appearance-none flex-col items-start gap-y-2 rounded-lg border border-gray-200 bg-gray-50 p-3 text-left font-inter-normal-12px"
      onClick={() => onClick(prompt)}
    >
      <Icon color="text-gray-500" icon={faSearch} size="xs" />
      <p className="text-gray-800">{prompt}</p>
    </button>
  );
};

export const EmptyState: React.FC<{
  onPromptClick: (prompt: string) => void;
}> = ({ onPromptClick }) => (
  <div className="flex h-full flex-grow flex-col items-center justify-center gap-x-2 gap-y-3 p-2">
    <p className="text-gray-500 font-inter-normal-12px">Some ideas...</p>
    <div className="flex items-center gap-x-2">
      <Suggestion
        prompt="Calculate the applicant's age from their birth date"
        onClick={onPromptClick}
      />
      <Suggestion
        prompt="Explain what this node does"
        onClick={onPromptClick}
      />
      <Suggestion
        prompt="Refactor this node to simplify it's logic"
        onClick={onPromptClick}
      />
    </div>
  </div>
);
