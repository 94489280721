import { faXmark } from "@fortawesome/pro-light-svg-icons";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React from "react";

import { useWorkspaces } from "src/api/queries";
import { ExternalLink } from "src/base-components/ExternalLink";
import { Icon } from "src/base-components/Icon";
import { LoadingView } from "src/base-components/LoadingView";
import { DashboardPageParamsT } from "src/router/urls";
import { useParamsDecode } from "src/utils/useParamsDecode";

type PropsT = {
  onRequestClose?: () => void;
};

type WsInfoT = {
  region: string;
  flow_services_version: string;
  function_name: string;
  log_group_name: string;
  stack_name: string;
};

const wsInfoKey = (id: string) => ["wsinfo", id] as const;

const useWsInfo = (_infoUrl: string) => {
  return useQuery<WsInfoT, Error>(
    wsInfoKey(_infoUrl),
    async () => (await axios.get(_infoUrl)).data as WsInfoT,
    {},
  );
};

type DebugToolsPropsT = {
  workspace: any;
};

const DebugTools: React.FC<DebugToolsPropsT> = ({ workspace }) => {
  const infoEndpoint = `https://${workspace.base_url}/_info`;

  const getLambdaLink = (lambdaName: string) => {
    return `https://console.aws.amazon.com/lambda/home?region=${workspace.aws_region}#/functions/${lambdaName}?tab=configuration`;
  };

  const getCloudFormationStackLink = (stackName: string, region: string) => {
    return `https://${
      region ? region : ""
    }.console.aws.amazon.com/cloudformation/home?region=${region}#/stacks?filteringText=${stackName}&filteringStatus=active&viewNested=true`;
  };

  const wsInfo = useWsInfo(infoEndpoint);
  const cfnStackName = wsInfo.data?.stack_name || "";
  const region = wsInfo.data?.region || "";
  const lambdas = wsInfo.data?.function_name
    ? {
        router: wsInfo.data!.function_name!,
        runner: wsInfo.data!.function_name!.replace("router", "runner"),
        copilot: wsInfo.data!.function_name!.replace("router", "copilot"),
        connect: wsInfo.data!.function_name!.replace("flow-router", "connect"),
        exporter: wsInfo.data!.function_name!.replace(
          "flow-router",
          "exporter",
        ),
        archiver: wsInfo.data!.function_name!.replace(
          "flow-router",
          "archiver",
        ),
        stitcher: wsInfo.data!.function_name!.replace(
          "flow-router",
          "stitcher",
        ),
      }
    : {
        router: "",
        runner: "",
        copilot: "",
        connect: "",
        exporter: "",
        archiver: "",
        migrator: "",
      };
  return (
    <div className="justify-around px-10">
      <h1>Summary</h1>
      <div className="mb-5 text-gray-500 font-inter-normal-12px">
        <span className="font-bold">AWS Region: </span> {workspace.aws_region}
        <br />
        <span className="font-bold">Lambda version: </span>{" "}
        {workspace.flow_services_version}
        <br />
        <span className="font-bold">STATUS: </span> {workspace.status}
        <br />
        <span className="font-bold">Info endpoint: </span>{" "}
        <ExternalLink href={infoEndpoint}>here</ExternalLink>
      </div>
      <h1>On call tools</h1>
      <div className="text-gray-500 font-inter-normal-12px">
        <p>
          Click{" "}
          <ExternalLink href={getCloudFormationStackLink(cfnStackName, region)}>
            here
          </ExternalLink>{" "}
          to access the CloudFormation stack.
        </p>
        {Object.entries(lambdas).map(([key, lambda]) => (
          <p>
            Click <ExternalLink href={getLambdaLink(lambda)}>here</ExternalLink>{" "}
            to access the {key} Lambda function on the AWS console
          </p>
        ))}
      </div>
    </div>
  );
};

export const DebugSidePane: React.FC<PropsT> = ({ onRequestClose }) => {
  const workspacesResult = useWorkspaces();
  const { wsId } = useParamsDecode<DashboardPageParamsT>();

  const currentWs = workspacesResult.data?.find((ws) => ws.id === wsId);

  const renderHeader = () => (
    <div className="mb-5 flex items-start justify-around px-10">
      <div className="grow">
        <div className="mb-3 text-gray-800 font-inter-semibold-16px">
          @taktile Debug Pane
        </div>
      </div>
      {onRequestClose && (
        <Icon
          color="text-gray-500"
          icon={faXmark}
          size="xl"
          onClick={onRequestClose}
        />
      )}
    </div>
  );

  return (
    <div className="flex h-full w-128 flex-col border-l border-gray-200 bg-white py-8">
      {renderHeader()}
      <div className="mt-4 min-h-0 flex-1">
        <LoadingView
          queryResult={workspacesResult}
          renderUpdated={() =>
            currentWs ? <DebugTools workspace={currentWs} /> : <div />
          }
        />
      </div>
    </div>
  );
};
