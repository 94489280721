import { Buffer } from "buffer";
import { encode as urlSafeEncode } from "url-safe-base64";

import { DecisionsOutcomeFilter } from "src/api/decisionHistoryV2/decisionHistoryQueries";
import {
  datasetJobsApi,
  datasetsApi,
  decisionHistoryApiRoute,
  metricsApi,
  reviewApi,
} from "src/api/exporterApi";
import {
  flowRouterApiGet,
  flowRouterApiPost,
  flowRouterApiPut,
} from "src/api/flowRouterApi";
import {
  CreateDatasetFromHistory,
  CreateDatasetFromScratch,
  GenericObjectT,
  SchemaTypesT,
} from "src/api/flowTypes";
import { flowApiUrl } from "src/api/hosts";
import {
  CreateDownloadDatasetJob,
  CreateDuplicateDatasetJob,
  CreateHistoryExportDatasetJob,
  Dataset,
  DatasetColumn,
  DatasetColumnGroups,
  DatasetFileFormat,
  DatasetFileUpload,
  DatasetJob,
  DatasetPurpose,
  DatasetResponse,
  DatasetRow,
  DecideDecisionSuccess,
  DecisionEnvironment,
  DecisionSummaryResponse,
  DownloadDatasetJob,
  ExportDecisionsDatasetJob,
  ExportDecisionsDatasetJobRequest,
  FlowAnalyzeResultBET,
  FlowRunnerResultV2,
  HistoryExportDatasetJob,
  ListReviewCasesResponse,
  MetricsDecisionsResponse,
  MetricsErrorsResponse,
  MetricsLatencyResponse,
  MetricsSummaryResponse,
  ParsedModelDataBET,
  ReviewCase,
  ReviewCaseStatusPending,
  ReviewCaseWithDecisionData,
  ReviewCasesAvailableFiltersResponse,
  ReviewSummaryResponse,
  UploadModelSignedUrlDataT,
} from "src/api/types";
import { DefaultApi as CopilotApi } from "src/clients/copilot";
import { DefaultApi as EntitiesApi } from "src/clients/entities";
import { FeatureQueriesApi, FeaturesApi } from "src/clients/features";
import {
  ChartsApi,
  CommentsApi,
  Configuration,
  FlowVersionsApi,
  FlowsApi,
  FoldersApi,
  OutcomeTypesApi,
  ReviewsApi,
  TrafficPoliciesApi,
  TypingApi,
  WebhooksApi,
  WorkspacesApi,
} from "src/clients/flow-api";
import {
  DecisionsApi,
  ListDecisionsDecisionsGetOriginTypesEnum,
} from "src/clients/history-v3";
import { JSONValue } from "src/datasets/DatasetTable/types";
import { DatasetPatch, DatasetRowPatch } from "src/datasets/api/queries";
import { HistoryDataResponse } from "src/datasets/api/types";
import { type LatencyFunction } from "src/performance/types";

export const BACKEND_URL = flowApiUrl();

const configuration = new Configuration({
  // We want to set the apiKey here but cannot do so for now since we would then also set both authorization headers in each call
  basePath: BACKEND_URL,
});

export const flowsApi = new FlowsApi(configuration);
export const workspacesApi = new WorkspacesApi(configuration);
export const versionsApi = new FlowVersionsApi(configuration);
export const trafficPoliciesApi = new TrafficPoliciesApi(configuration);
export const commentsApi = new CommentsApi(configuration);
export const outgoingWebhooksApi = new WebhooksApi(configuration);
export const typingsApi = new TypingApi(configuration);
export const chartsApi = new ChartsApi(configuration);
export const foldersApi = new FoldersApi(configuration);
export const flowVersionReviewsApi = new ReviewsApi(configuration);
export const historyApi = (wsBaseUrl: string) => {
  return new DecisionsApi(
    new Configuration({ basePath: "https://" + wsBaseUrl + "/history/api/v3" }),
  );
};
export const copilotApi = (wsBaseUrl: string) => {
  return new CopilotApi(
    new Configuration({ basePath: "https://" + wsBaseUrl }),
  );
};
export const entitiesApi = (baseUrl: string) => {
  return new EntitiesApi(new Configuration({ basePath: "https://" + baseUrl }));
};
export const outcomeTypesApi = new OutcomeTypesApi(configuration);
export const featuresApi = (wsBaseUrl: string) => {
  return new FeaturesApi(
    new Configuration({ basePath: "https://" + wsBaseUrl }),
  );
};
export const featureQueriesApi = (wsBaseUrl: string) => {
  return new FeatureQueriesApi(
    new Configuration({ basePath: "https://" + wsBaseUrl }),
  );
};

export enum ExecutionMode {
  SYNC = "sync",
  ASYNC = "async",
}

export class FlowRouterEndpoint {
  static analyze = (baseUrl: string, flowSlug: string, versionId: string) =>
    flowRouterApiPost<FlowAnalyzeResultBET>(
      baseUrl,
      `/flows/${flowSlug}/author/analyze`,
      {
        flow_version: versionId,
      },
    );

  static sandboxDecide = async <ResponseData extends GenericObjectT>(params: {
    baseUrl: string;
    flowSlug: string;
    versionName: string;
    executionMode: ExecutionMode;
    entityId?: string;
    data: GenericObjectT;
  }) => {
    const payload = {
      data: params.data,
      metadata: {
        version: params.versionName,
        entity_id: params.entityId,
      },
      control: {
        execution_mode: params.executionMode,
      },
    };
    return (
      await flowRouterApiPost<DecideDecisionSuccess<ResponseData>>(
        params.baseUrl,
        `/flows/${params.flowSlug}/sandbox/decide`,
        payload,
      )
    ).data;
  };
}

export class FlowRouterMlNodeEndpoint {
  static getSignedUrl = (baseUrl: string, flowSlug: string) => {
    return flowRouterApiPut<UploadModelSignedUrlDataT>(
      baseUrl,
      `/flows/${flowSlug}/ml-node/models`,
      {},
    );
  };

  static getParsedModelData = (
    baseUrl: string,
    flowSlug: string,
    modelId: string,
  ) =>
    flowRouterApiGet<ParsedModelDataBET>(
      baseUrl,
      `/flows/${flowSlug}/ml-node/models/${modelId}`,
      {},
    );
}

type MetricsCommonParams = {
  startDate: string;
  endDate: string;
  versions: string[];
};

type GetSummaryParams = MetricsCommonParams;

type GetDecisionsParams = MetricsCommonParams & {
  interval: number;
};

type GetErrorsParams = GetDecisionsParams & {
  errorCodes: string[];
};

type GetLatencyParams = MetricsCommonParams & {
  interval: number;
  func: LatencyFunction;
};

export type GroupFilter = {
  [flowId: string]: {
    statusCodes?: string;
    flowVersionIds?: string;
    originTypes?: ListDecisionsDecisionsGetOriginTypesEnum;
    parentFlowIds?: string;
    parentFlowVersionIds?: string;
    jobIds?: string;
    jobRunIds?: string;
  };
};

export type GetDecisionSummaryParams = {
  groupby: "flow.id";
  aggregate: "count";
  decision_id?: string;
  parent_decision_id?: string;
  entity_id?: string;
  environments?: DecisionEnvironment;
  groupfilter?: GroupFilter;
  start_start_time?: string;
  end_start_time?: string;
};

export class ExporterMetricsEndpoint {
  static getAvailableVersions = async (
    baseURL: string,
    { startDate, endDate, versions }: GetSummaryParams,
  ) => {
    const response = await metricsApi.get<string[]>(
      `/operational/active_flow_versions`,
      {
        baseURL,
        params: {
          versions: versions.join(","),
          start_date: startDate,
          end_date: endDate,
        },
      },
    );

    return response.data;
  };

  static getSummary = async (
    baseURL: string,
    { startDate, endDate, versions }: GetSummaryParams,
  ) => {
    const response = await metricsApi.get<MetricsSummaryResponse>(
      `/operational/summary`,
      {
        baseURL,
        params: {
          versions: versions.join(","),
          start_date: startDate,
          end_date: endDate,
        },
      },
    );

    return response.data;
  };

  static getDecisions = async (
    baseURL: string,
    { startDate, endDate, interval, versions }: GetDecisionsParams,
  ) => {
    const response = await metricsApi.get<MetricsDecisionsResponse>(
      `/operational/decisions`,
      {
        baseURL,
        params: {
          versions: versions.join(","),
          start_date: startDate,
          end_date: endDate,
          interval_in_seconds: interval,
          fix_bin_origin_bug: "true",
        },
      },
    );

    return response.data;
  };

  static getErrors = async (
    baseURL: string,
    { startDate, endDate, interval, versions, errorCodes }: GetErrorsParams,
  ) => {
    const response = await metricsApi.get<MetricsErrorsResponse>(
      `/operational/errors`,
      {
        baseURL,
        params: {
          versions: versions.join(","),
          start_date: startDate,
          end_date: endDate,
          interval_in_seconds: interval,
          error_codes: errorCodes.length ? errorCodes.join(",") : undefined,
          fix_bin_origin_bug: "true",
        },
      },
    );

    return response.data;
  };

  static getLatency = async (
    baseURL: string,
    { startDate, endDate, versions, func, interval }: GetLatencyParams,
  ) => {
    const response = await metricsApi.get<MetricsLatencyResponse>(
      `/operational/latency`,
      {
        baseURL,
        params: {
          versions: versions.join(","),
          start_date: startDate,
          end_date: endDate,
          function: func,
          interval_in_seconds: interval,
          fix_bin_origin_bug: "true",
        },
      },
    );

    return response.data;
  };

  static getDecisionSummary = async (
    baseURL: string,
    queryParams: GetDecisionSummaryParams,
    signal?: AbortSignal,
  ) => {
    const formatedGroupFilters:
      | {
          [flowId: string]: {
            status_codes?: string[];
            flow_version_ids?: string[];
            origin_types?: string;
            parent_flow_ids?: string[];
            parent_flow_version_ids?: string[];
            job_ids?: string[];
            job_run_ids?: string[];
          };
        }
      | undefined = queryParams.groupfilter
      ? Object.fromEntries(
          Object.entries(queryParams.groupfilter).map(([key, value]) => {
            const mappedValue = {
              status_codes: value.statusCodes ? [value.statusCodes] : undefined,
              flow_version_ids: value.flowVersionIds
                ? [value.flowVersionIds]
                : undefined,
              origin_types: value.originTypes ? value.originTypes : undefined,
              parent_flow_ids: value.parentFlowIds
                ? [value.parentFlowIds]
                : undefined,
              parent_flow_version_ids: value.parentFlowVersionIds
                ? [value.parentFlowVersionIds]
                : undefined,
              job_ids: value.jobIds ? [value.jobIds] : undefined,
              job_run_ids: value.jobRunIds ? [value.jobRunIds] : undefined,
            };
            return [key, mappedValue];
          }),
        )
      : undefined;
    const response = await metricsApi.get<DecisionSummaryResponse>(
      `/decision_summary`,
      {
        baseURL,
        signal,
        params: {
          ...queryParams,
          ...(formatedGroupFilters && {
            groupfilter: urlSafeEncode(
              Buffer.from(JSON.stringify(formatedGroupFilters)).toString(
                "base64",
              ),
            ),
          }),
        },
      },
    );

    return response.data;
  };
}

type GetDatasetParams = {
  from?: number;
  size?: number;
};
export class ExporterDatasetsEndpoint {
  static getDatasets = async (baseURL: string, flowId: string) => {
    const response = await datasetsApi.get<Dataset[]>(`/datasets`, {
      baseURL,
      params: { flow_id: flowId },
    });
    return response.data;
  };

  static createDataset = async (
    baseURL: string,
    body: CreateDatasetFromHistory | CreateDatasetFromScratch,
  ) => {
    const response = await datasetsApi.post<Dataset>(`/datasets`, body, {
      baseURL,
    });

    return response.data;
  };

  static getDataset = async (
    baseURL: string,
    datasetId: string,
    params: GetDatasetParams = {},
  ) => {
    const response = await datasetsApi.get<DatasetResponse>(
      `/datasets/${datasetId}`,
      {
        baseURL,
        params: {
          from_: params.from ?? 0,
          size: params.size ?? 100,
        },
      },
    );

    return response.data;
  };

  static getDatasetAsFile = async (
    baseURL: string,
    datasetId: string,
    format: DatasetFileFormat,
  ) => {
    const response = await datasetsApi.get<string>(
      `/datasets/${datasetId}/file`,
      {
        baseURL,
        transformResponse: (data) => data,
        params: {
          format,
          include_output: true,
        },
      },
    );

    return response.data;
  };

  static putColumn = async (
    baseURL: string,
    datasetId: string,
    group: DatasetColumnGroups,
    name: string,
    desired_type: SchemaTypesT,
    column_to_replace?: string,
    use_subflow_mocks?: boolean,
  ) => {
    const response = await datasetsApi.put<Dataset>(
      `/datasets/${datasetId}/columns/${group}/${encodeURIComponent(name)}`,
      { desired_type, use_subflow_mocks: use_subflow_mocks ?? false },
      {
        baseURL,
        params: column_to_replace ? { column_to_replace } : undefined,
      },
    );

    return response.data;
  };

  static deleteColumn = async (
    baseURL: string,
    datasetId: string,
    group: DatasetColumnGroups,
    name: string,
  ) => {
    const response = await datasetsApi.delete<Dataset>(
      `/datasets/${datasetId}/columns/${group}/${encodeURIComponent(name)}`,
      { baseURL },
    );

    return response.data;
  };

  static patchRow = async (
    baseURL: string,
    datasetId: string,
    rowId: string,
    patch: DatasetRowPatch,
  ) => {
    const response = await datasetsApi.patch<DatasetRow>(
      `/datasets/${datasetId}/rows/${rowId}`,
      patch,
      { baseURL },
    );

    return response.data;
  };

  static deleteDataset = async (baseURL: string, datasetId: string) => {
    const response = await datasetsApi.delete(`/datasets/${datasetId}`, {
      baseURL,
    });

    return response.data;
  };

  static createDatasetFileUpload = async (
    baseURL: string,
    flowId: string,
    fileName: string,
    inputColumnsToOverwrite: DatasetColumn[],
    purpose: DatasetPurpose,
  ) => {
    const response = await datasetsApi.post<DatasetFileUpload>(
      `/dataset_file_uploads`,
      {
        flow_id: flowId,
        file_name: fileName,
        input_columns_to_overwrite: inputColumnsToOverwrite,
        purpose,
      },
      { baseURL },
    );

    return response.data;
  };

  static getDatasetFileUpload = async (baseURL: string, id: string) => {
    const response = await datasetsApi.get<DatasetFileUpload>(
      `/dataset_file_uploads/${id}`,
      {
        baseURL,
      },
    );

    return response.data;
  };

  static postRows = async (
    baseURL: string,
    datasetId: string,
    payload:
      | { source: "blank"; new_row_ids: string[] }
      | { source: "row_id"; row_id: string; new_row_ids: string[] }
      | {
          source: "decision_id";
          decision_id: string;
          new_row_ids: string[];
        },
  ) => {
    const response = await datasetsApi.post<DatasetRow[]>(
      `/datasets/${datasetId}/rows`,
      payload,
      { baseURL },
    );

    return response.data;
  };

  static deleteRow = async (
    baseURL: string,
    datasetId: string,
    rowId: string,
  ) => {
    const response = await datasetsApi.delete<DatasetRow[]>(
      `/datasets/${datasetId}/rows/${rowId}`,
      { baseURL },
    );

    return response.data;
  };

  static patchDataset = async (
    baseURL: string,
    datasetId: string,
    patch: DatasetPatch,
    etag?: string,
  ) => {
    const response = await datasetsApi.patch<Dataset>(
      `/datasets/${datasetId}`,
      patch,
      { baseURL, headers: etag ? { "If-Match": etag } : undefined },
    );

    return response.data;
  };

  static fillColumn = async (
    baseURL: string,
    datasetId: string,
    group: DatasetColumnGroups,
    name: string,
    fillValue: JSONValue,
  ) => {
    const response = await datasetsApi.patch<Dataset>(
      `/datasets/${datasetId}/columns/${group}/${encodeURIComponent(name)}`,
      { fill_value: fillValue },
      { baseURL },
    );

    return response.data;
  };
}

export class ExporterHistoryEndpoint {
  static getDecisionHistoryPreview = ({
    baseUrl,
    flowSlug,
    flowVersionIds,
    timeWindow,
    statusCodes,
    trafficPolicyId,
    subSamplingSize,
    outcomeFilters,
  }: {
    baseUrl: string;
    flowSlug: string;
    flowVersionIds: string[];
    timeWindow: [string, string];
    statusCodes: string[];
    trafficPolicyId?: string;
    subSamplingSize?: number;
    outcomeFilters?: DecisionsOutcomeFilter[];
  }) => {
    const queryParams = new URLSearchParams({
      start_date: timeWindow[0],
      end_date: timeWindow[1],
      preview_limit: "100",
      flow_slug: flowSlug,
      versions: flowVersionIds.join(","),
      status_codes: statusCodes.join(","),
      assemble_async: "true",
    });
    if (trafficPolicyId) {
      queryParams.append("traffic_policy_id", trafficPolicyId);
    }
    if (subSamplingSize) {
      queryParams.append("sub_sampling_size", subSamplingSize.toString());
    }
    if (outcomeFilters) {
      queryParams.append(
        "outcome_filters",
        btoa(JSON.stringify(outcomeFilters)),
      );
    }

    return decisionHistoryApiRoute.get<HistoryDataResponse>(
      `/decisions/export/preview`,
      { baseURL: baseUrl, params: queryParams },
    );
  };
}

export type ListReviewCasesParams = {
  limit?: number;
  order?: "asc" | "desc";
  order_by?: "decision_start_time" | "created_at";
  after_timestamp?: string;
  before_timestamp?: string;
  flow_version_names?: string;
  assignees?: string;
  statuses?: string;
  environment?: "prod" | "sandbox";
  entity_or_decision_id?: string;
  node_names?: string;
};

export type CasePatchBody = {
  response_data?: Nullable<GenericObjectT>;
  assignee?: Nullable<string>;
  status?: ReviewCaseStatusPending;
  etag?: string;
};

export class FlowRouterReviewCasesEndpoint {
  static getCases = async (
    baseURL: string,
    flowSlug: string,
    params: ListReviewCasesParams = {},
  ) => {
    const response = await reviewApi.get<ListReviewCasesResponse>(
      `/flows/${flowSlug}/cases`,
      {
        baseURL,
        params: {
          ...params,
          order: params.order ?? "desc",
          limit: params.limit ?? 100,
        },
      },
    );
    return response.data;
  };

  static getCase = async (
    baseURL: string,
    flowSlug: string,
    caseId: string,
  ) => {
    const response = await reviewApi.get<ReviewCaseWithDecisionData>(
      `/flows/${flowSlug}/cases/${caseId}`,
      {
        baseURL,
      },
    );
    return response.data;
  };

  static patchCase = async (
    baseURL: string,
    flowSlug: string,
    caseId: string,
    data: CasePatchBody,
    etag?: string,
  ) => {
    const response = await reviewApi.patch<ReviewCase>(
      `/flows/${flowSlug}/cases/${caseId}`,
      data,
      { baseURL, headers: etag ? { "If-Match": etag } : undefined },
    );
    return response.data;
  };

  static getFilters = async (
    baseURL: string,
    flowSlug: string,
    params: Pick<
      ListReviewCasesParams,
      "after_timestamp" | "before_timestamp" | "environment" | "statuses"
    > = {},
  ) => {
    const response = await reviewApi.get<ReviewCasesAvailableFiltersResponse>(
      `/flows/${flowSlug}/cases/filter_values`,
      { baseURL, params },
    );
    return response.data;
  };

  static getSummary = async (
    baseURL: string,
    flowSlug: string,
    environment: "prod" | "sandbox",
  ) => {
    const response = await reviewApi.get<ReviewSummaryResponse>(
      `/flows/${flowSlug}/cases/summary`,
      { baseURL, params: { environment } },
    );
    return response.data;
  };

  static submit = async (
    baseURL: string,
    flowSlug: string,
    caseId: string,
    etag: string,
  ) => {
    const response = await reviewApi.post<ReviewCase>(
      `/flows/${flowSlug}/cases/${caseId}/submit`,
      undefined,
      { baseURL, headers: { "If-Match": etag } },
    );
    return response.data;
  };
}

export class ExporterDatasetJobsEndpoint {
  static createAssembleDatasetJob = async (
    baseURL: string,
    body: { flow_id: string; request: CreateDatasetFromHistory },
  ) => {
    const response = await datasetJobsApi.post<DatasetJob>(
      `/dataset_jobs`,
      { ...body, type: "assemble" },
      {
        baseURL,
      },
    );

    return response.data;
  };
  static createDuplicateDatasetJob = async (
    baseURL: string,
    body: CreateDuplicateDatasetJob,
  ) => {
    const response = await datasetJobsApi.post<DatasetJob>(
      `/dataset_jobs`,
      { ...body, type: "duplicate" },
      {
        baseURL,
      },
    );

    return response.data;
  };
  static createExportDecisionsDatasetJob = async (
    baseURL: string,
    body: { flow_id: string; request: ExportDecisionsDatasetJobRequest },
  ) => {
    const response = await datasetJobsApi.post<ExportDecisionsDatasetJob>(
      `/dataset_jobs`,
      { ...body, type: "export_decisions" },
      {
        baseURL,
      },
    );

    return response.data;
  };
  static getDatasetJobs = async ({
    baseURL,
    flowId,
    limit,
    offset,
    statuses,
  }: {
    baseURL: string;
    flowId: string;
    limit: number;
    offset: number;
    statuses: DatasetJob["status"][];
  }) => {
    const response = await datasetJobsApi.get<DatasetJob[]>(`/dataset_jobs`, {
      baseURL,
      params: {
        flow_id: flowId,
        limit,
        offset,
        statuses: statuses.join(","),
      },
    });

    return response.data;
  };
  static hideDatasetJob = async (baseURL: string, id: string) => {
    const response = await datasetJobsApi.patch<DatasetJob>(
      `/dataset_jobs/${id}/hide`,
      undefined,
      {
        baseURL,
      },
    );

    return response.data;
  };
  static createDownloadDatasetJob = async (
    baseURL: string,
    body: CreateDownloadDatasetJob,
  ) => {
    const response = await datasetJobsApi.post<DownloadDatasetJob>(
      `/dataset_jobs`,
      { ...body, type: "download" },
      {
        baseURL,
      },
    );

    return response.data;
  };

  static createCreateSourceJobFromJobRunDatasetJob = async (
    baseURL: string,
    body: {
      flow_id: string;
      request: { name: string; status_codes: string[]; job_run_id: string };
    },
  ) => {
    const response = await datasetJobsApi.post<DatasetJob>(
      `/dataset_jobs`,
      { ...body, type: "create_job_source" },
      {
        baseURL,
      },
    );

    return response.data;
  };

  static getDatasetJob = async ({
    baseURL,
    id,
  }: {
    baseURL: string;
    id: string;
  }) => {
    const response = await datasetJobsApi.get<DatasetJob>(
      `/dataset_jobs/${id}`,
      {
        baseURL,
      },
    );

    return response.data;
  };
}

export class ExportDecisionsEndpoint {
  static exportDecisionsAsync = async (
    baseURL: string,
    body: CreateHistoryExportDatasetJob,
  ) => {
    const response =
      await decisionHistoryApiRoute.post<HistoryExportDatasetJob>(
        `/decisions/export`,
        { ...body, type: "history_export" },
        {
          baseURL,
        },
      );

    return response.data;
  };

  static pollDecisionExport = async ({
    baseURL,
    id,
  }: {
    baseURL: string;
    id: string;
  }) => {
    const response = await decisionHistoryApiRoute.get<DatasetJob>(
      `/decisions/export/${id}`,
      {
        baseURL,
      },
    );

    return response.data;
  };
}

export type CreateMultiversionTestRunVersion = {
  id: string;
  etag: string;
  children?: CreateMultiversionTestRunVersion[];
};
export type CreateMultiversionTestRunParams = {
  dataset_id: string;
  infer_types: boolean;
  flow_versions: CreateMultiversionTestRunVersion[];
  flow_id: string;
  flow_slug: string;
};

export type TestRunVersionProgress = {
  total: number;
  successful: number;
  failed: number;
  ignored: number;
  output_mismatch: number;
};

export type MultiversionTestRunVersionResults =
  CreateMultiversionTestRunVersion & {
    execution_results: FlowRunnerResultV2 | null;
    progress: TestRunVersionProgress | null;
  };

export type MultiversionTestRunResults = {
  id: string;
  infer_types: boolean;
  created_by: string;
  created_at: string;
  flow_versions: MultiversionTestRunVersionResults[];
  dataset: {
    id: string;
    etag: string;
    shard_count: number;
  };
};

export class RouterMultiversionTestRun {
  static post = (baseUrl: string, params: CreateMultiversionTestRunParams) =>
    flowRouterApiPost<MultiversionTestRunResults>(
      baseUrl,
      `/test-runs`,
      params,
    );
  static get = (baseUrl: string, runId: string) =>
    flowRouterApiGet<MultiversionTestRunResults>(
      baseUrl,
      `/test-runs/${runId}`,
      {},
    );
}

export type AnalyticsPresignedUrlRequest = {
  type: "analytics";
};

export type VersionNodeResultsPresignedUrlRequest = {
  type: "node_results";
  run_id: string;
  version_id: string;
  node_id: string;
  format: "json" | "csv";
};
export type VersionNodeResultsPagePresignedUrlRequest = {
  type: "node_results_page";
  run_id: string;
  version_id: string;
  node_id: string;
  page: number;
  status:
    | "success"
    | "failure"
    | "ignored"
    | "success_mismatch"
    | "success_match"
    | "errors";
};
export type NodeAnalyticsPresignedUrlRequest = {
  type: "node_analytics";
  run_id: string;
  version_id: string;
  node_id: string;
};

export type OutcomePresignedUrlRequest = {
  type: "outcome_data";
  dataset_id: string;
  dataset_etag: string;
};

export type PresignedUrlRequest =
  | AnalyticsPresignedUrlRequest
  | VersionNodeResultsPresignedUrlRequest
  | VersionNodeResultsPagePresignedUrlRequest
  | NodeAnalyticsPresignedUrlRequest
  | OutcomePresignedUrlRequest;

export class RouterPresignedUrls {
  static post = (
    baseUrl: string,
    testRunId: string,
    params: PresignedUrlRequest,
  ) =>
    flowRouterApiPost<{ url: string }>(
      baseUrl,
      `/test-runs/${testRunId}/presigned_urls`,
      params,
    );
}
