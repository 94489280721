import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { keyBy } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { twJoin } from "tailwind-merge";
import { v4 as uuidV4 } from "uuid";

import { FlowT, FlowVersionFlowChild } from "src/api/flowTypes";
import { useEditFlowVersion } from "src/api/flowVersionQueries";
import { useWorkspaceUsers } from "src/api/taktile/queries";
import { Button } from "src/base-components/Button";
import { Icon } from "src/base-components/Icon";
import { SkeletonPlaceholder } from "src/base-components/SkeletonPlaceholder";
import { TextEditor } from "src/base-components/TextEditor/TextEditor";
import { Toolbar } from "src/base-components/TextEditor/Toolbar";
import { Textarea } from "src/base-components/Textarea";
import {
  ReviewerReviewStatus,
  ReviewRequestStatus,
  ReviewStatus,
  ReviewUpsert,
} from "src/clients/flow-api";
import { OrganizationUser } from "src/clients/taktile-api";
import { Avatar } from "src/design-system/Avatar";
import { Modal } from "src/design-system/Modal";
import {
  useFlowVersionReview,
  useFlowVersionReviewPatch,
} from "src/flowReview/api/queries";
import { useWorkspaceContext } from "src/router/routerContextHooks";
import { logger } from "src/utils/logger";
import { getUserName } from "src/utils/user";

type FlowVersionReviewModalProps = {
  flow: FlowT;
  flowVersion: FlowVersionFlowChild;
  isOpen: boolean;
  onClose: () => void;
  onCreate?: () => void;
};

const Section: React.FC<{
  title: string;
  children: React.ReactNode;
  grow?: boolean;
}> = ({ title, children, grow }) => {
  return (
    <div className={twJoin("flex flex-col", grow && "h-full flex-grow")}>
      <h3 className="mb-2 text-gray-800 font-inter-semibold-13px">{title}</h3>
      {children}
    </div>
  );
};

const ReviewerListItem: React.FC<{ user: OrganizationUser }> = ({ user }) => {
  return (
    <li className="mb-2 flex items-center gap-x-1 border-b border-b-gray-100 pb-2 last:mb-0 last:border-b-0 last:pb-0">
      <Avatar user={user} />
      <span className="text-gray-800 font-inter-medium-12px">
        {getUserName(user)}
      </span>
    </li>
  );
};

const ReviewerLoadingItem: React.FC = () => {
  return (
    <li className="mb-2 flex items-center gap-x-1 border-b border-b-gray-100 pb-2 last:mb-0 last:border-b-0 last:pb-0">
      <SkeletonPlaceholder height="h-3" width="w-50" />
    </li>
  );
};

export const FlowVersionReviewModal: React.FC<FlowVersionReviewModalProps> = ({
  flow,
  flowVersion,
  isOpen,
  onClose,
  onCreate,
}) => {
  const { orgId, workspace } = useWorkspaceContext();
  const toolbarRef = useRef<HTMLUListElement>(null);
  const { mutateAsync: updateFlowVersion } = useEditFlowVersion();

  const { data, isLoading, isError } = useFlowVersionReview(flowVersion.id);
  const { mutateAsync: patchReview, isLoading: isRequesting } =
    useFlowVersionReviewPatch();
  const isPending = isOpen ? isLoading || isError : false;

  const [isFocused, setFocused] = useState(false);
  const [reviewDescription, setReviewDescription] = useState<string>(
    data?.description ?? "",
  );
  const [versionDescription, setVersionDescription] = useState<string>(
    flowVersion?.meta.release_note ?? "",
  );

  const { data: workspaceUsers } = useWorkspaceUsers(orgId, workspace.id, {
    include_deactivated: false,
  });
  const usersById = useMemo(
    () => keyBy(workspaceUsers, "id"),
    [workspaceUsers],
  );

  const reviewersList: string[] = useMemo(() => {
    if (isError) {
      return [];
    }

    if (data?.reviewers_status) {
      return Object.values(data.reviewers_status).map(
        ({ reviewer_id }) => reviewer_id,
      );
    }

    if (flow.review_configuration) {
      return flow.review_configuration.default_reviewer_list ?? [];
    }

    return [];
  }, [isError, data, flow.review_configuration]);

  const reviewers = useMemo(
    () =>
      reviewersList
        .map((reviewerId) => usersById[reviewerId])
        .filter((reviewer) => Boolean(reviewer))
        .sort((r1, r2) =>
          (getUserName(r1) ?? "").localeCompare(getUserName(r2) ?? ""),
        ),
    [usersById, reviewersList],
  );

  const handleSubmit = async () => {
    if (!flowVersion || isRequesting) return;
    if (versionDescription !== flowVersion.meta.release_note) {
      await updateFlowVersion({
        version: flowVersion,
        description: versionDescription,
      });
    }

    if (data) {
      const isCancelled = data.review_status === ReviewStatus.CANCELLED;
      await patchReview({
        reviewId: data.id,
        data: {
          description: reviewDescription,
          flow_version_etag: flowVersion.etag ?? "",
          review_status: isCancelled ? ReviewRequestStatus.OPEN : undefined,
        },
        flowId: flow.id,
      });
      isCancelled && onCreate?.();
    } else if (data === null) {
      const reviewersStatus = reviewersList.reduce(
        (acc, reviewerId) => ({
          ...acc,
          [reviewerId]: {
            reviewer_status: ReviewerReviewStatus.PENDING,
            reviewer_id: reviewerId,
          },
        }),
        {} as ReviewUpsert["reviewers_status"],
      );

      await patchReview({
        reviewId: uuidV4(),
        data: {
          description: reviewDescription,
          flow_version_id: flowVersion.id,
          flow_version_etag: flowVersion.etag ?? "",
          reviewers_status: reviewersStatus,
        },
        flowId: flow.id,
      });
      onCreate?.();
    } else {
      logger.warn("Review data is not loaded");
    }

    onClose();
  };

  useEffect(() => {
    if (data?.description) {
      setReviewDescription(data.description);
    }
  }, [data?.description]);

  return (
    <Modal open={isOpen} size="lg" onClose={onClose}>
      <Modal.Header>Request a review</Modal.Header>
      <Modal.Content>
        <div className="flex gap-x-5">
          <div className="flex flex-shrink-0 basis-[360px] flex-col justify-start gap-y-6">
            <Section title="Decision Flow">
              <div className="flex items-center gap-x-1 rounded-lg border border-gray-200 bg-gray-50 px-3 py-1.5">
                <div className="px-0.5">
                  <Icon color="text-yellow-400" icon={faCircle} size="3xs" />
                </div>
                <span className="text-gray-500 font-inter-normal-12px">
                  {flow.name}, {flowVersion?.name}
                </span>
              </div>
            </Section>
            <Section title="Version description">
              <Textarea
                placeholder="Add description..."
                value={versionDescription}
                fullWidth
                onChange={(e) => setVersionDescription(e.target.value)}
              />
            </Section>
            <Section title="Reviewers">
              <p className="mb-3 text-gray-500 font-inter-normal-12px">
                Reviewers will be notified when you create a review request.
              </p>
              <ul className="list">
                {isPending ? (
                  <>
                    <ReviewerLoadingItem />
                    <ReviewerLoadingItem />
                  </>
                ) : (
                  reviewers.map((reviewer) => (
                    <ReviewerListItem key={reviewer.id} user={reviewer} />
                  ))
                )}
              </ul>
            </Section>
          </div>
          <div className="border-l border-l-gray-100" />
          <div className="flex-grow">
            <Section title="Review guidelines" grow>
              <div
                className={twJoin(
                  "flex h-[528px] w-full flex-grow flex-col rounded-lg",
                  isPending ? "gap-y-3.5" : "border border-gray-200 p-3",
                  isFocused && "border-indigo-400 ring-2 ring-indigo-500/25",
                )}
              >
                {isPending && (
                  <>
                    <SkeletonPlaceholder height="h-3" width="w-150" />
                    <SkeletonPlaceholder height="h-3" width="w-150" />
                    <SkeletonPlaceholder height="h-3" width="w-96" />
                  </>
                )}
                <div className="h-full flex-grow">
                  <TextEditor
                    maxHeight="max-h-[470px]"
                    placeholder={
                      isPending
                        ? ""
                        : "Provide specific instructions or steps for the reviewers to follow"
                    }
                    toolbarRef={toolbarRef}
                    value={isPending ? "" : reviewDescription}
                    fullHeight
                    onBlur={() => setFocused(false)}
                    onChange={(value) => setReviewDescription(value)}
                    onFocus={() => setFocused(true)}
                  />
                </div>
                <div
                  className={twJoin(
                    "border-t border-gray-200 pt-2",
                    isPending && "invisible",
                  )}
                >
                  <Toolbar ref={toolbarRef} exclude={["heading"]} />
                </div>
              </div>
            </Section>
          </div>
        </div>
      </Modal.Content>
      <Modal.Footer
        primaryButton={
          <Button
            dataLoc="request-review-modal-submit"
            disabled={isLoading || isError || isRequesting}
            loading={isRequesting}
            size="base"
            onClick={handleSubmit}
          >
            Request review
          </Button>
        }
        secondaryButton="Cancel"
      />
    </Modal>
  );
};
