import { makeConnectionTemplate } from "src/connections/types";

export const EquifaxCanadaTemplate = makeConnectionTemplate({
  provider: "equifax_canada",
  secrets: [
    { key: "client_id", name: "Client ID", required: true },
    { key: "client_secret", name: "Client Secret", required: true },
  ],
  resources: {
    equifax_canada_consumer_credit_file: {
      name: "Consumer Credit File",
      configKeys: [
        {
          key: "customer_number",
          required: true,
          name: "Customer number",
          hint: `The 10-character customer number provided by Equifax`,
          type: "text",
          placeholder: "999AA99999",
        },
        {
          key: "security_code",
          required: true,
          name: "Security code",
          hint: `The 2-character security code provided by Equifax`,
          type: "text",
          placeholder: "12",
        },
        {
          key: "customer_code",
          required: true,
          name: "Customer code",
          hint: `The customer code provided by Equifax, up to 4 characters`,
          type: "text",
          placeholder: "9999",
        },
        {
          key: "customer_id",
          required: false,
          name: "Customer ID",
          hint: `The customer ID provided by Equifax and used for billing identification purposes`,
          type: "text",
        },
      ],
    },
  },
});
