import { faCalculatorSimple, faCode } from "@fortawesome/pro-regular-svg-icons";
import React from "react";

import { FeatureCatalogueHeader, TabContent } from "./FeatureCatalogueHeader";
import { useTabNavigation } from "src/featureCatalogue/utils";
import { useCapabilities } from "src/hooks/useCapabilities";
import { DashboardPageParamsT } from "src/router/urls";
import { useParamsDecode } from "src/utils/useParamsDecode";

export const FeatureCatalogueWrapper: React.FC<{
  header: React.ReactNode;
  children: React.ReactNode;
}> = ({ header, children }) => {
  const { featureQueriesCatalogue } = useCapabilities();
  const { orgId, wsId } = useParamsDecode<DashboardPageParamsT>();
  const { isFeatureRoute, isQueryRoute, getTabUrl } = useTabNavigation(
    orgId,
    wsId,
  );

  return (
    <div data-loc="catalogue-wrapper">
      <FeatureCatalogueHeader>
        <FeatureCatalogueHeader.Tabs>
          <FeatureCatalogueHeader.Tab
            active={isFeatureRoute}
            to={getTabUrl("features")}
          >
            <TabContent icon={faCalculatorSimple} label="Features" />
          </FeatureCatalogueHeader.Tab>
          {featureQueriesCatalogue.canAccess && (
            <FeatureCatalogueHeader.Tab
              active={isQueryRoute}
              to={getTabUrl("queries")}
            >
              <TabContent icon={faCode} label="Queries" />
            </FeatureCatalogueHeader.Tab>
          )}
        </FeatureCatalogueHeader.Tabs>
        {header}
      </FeatureCatalogueHeader>

      <div className="flex w-full max-w-[1800px] flex-col gap-y-5.5 px-4">
        {children}
      </div>
    </div>
  );
};
