import {
  faClose,
  faSearch,
  faSliders,
} from "@fortawesome/pro-regular-svg-icons";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui2/react";
import { groupBy, uniq } from "lodash";
import { Fragment, useEffect, useMemo, useState } from "react";
import { twJoin } from "tailwind-merge";

import { FlowT } from "src/api/flowTypes";
import { Button } from "src/base-components/Button";
import { Checkbox } from "src/base-components/Checkbox";
import { Icon } from "src/base-components/Icon";
import { Input } from "src/base-components/Input";
import {
  decisionFieldItems,
  decisionFieldsPrefix,
  inputFieldsPrefix,
  outcomeFieldsPrefix,
  outputFieldsPrefix,
} from "src/decisionsOverview/useViewOptions";
import {
  OutcomeItem,
  transformOutcomeTypesToOptions,
  transformSchemaToOptions,
} from "src/decisionsOverview/utils";
import { EmptyState } from "src/design-system/EmptyState";
import { Tooltip } from "src/design-system/Tooltip";
import { useOutcomeTypes } from "src/outcomes/queries";
import { useFuseSearch } from "src/utils/useFuseSearch";

type Props = {
  flow: FlowT;
  value: string[];
  isSaving: boolean;
  canSelect: (nItems: number) => boolean;
  canDeselect: (nItems: number) => boolean;
  button?: React.ReactNode;
  onChange: (selected: string[]) => void;
  onReset: () => void;
  onSave: () => void;
};

const Divider = () => (
  <li className="my-2.5 list-none border-b border-b-gray-200" />
);

const SearchBox: React.FC<{ value: string; onChange: (v: string) => void }> = ({
  value,
  onChange,
}) => {
  // clear value on unmount
  useEffect(() => {
    return () => onChange("");
  }, [onChange]);

  const isEmpty = value === "";

  return (
    <li className="list-none pb-2 pt-5">
      <Input
        placeholder="Search fields"
        prefixIcon={{ icon: faSearch }}
        suffixIcon={
          isEmpty ? undefined : { icon: faClose, onClick: () => onChange("") }
        }
        value={value}
        fullWidth
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={(e) => e.stopPropagation()}
      />
    </li>
  );
};

const HeaderItem: React.FC<{
  selected: number;
  total: number;
  title: string;
  dataLoc?: string;
}> = ({ selected, total, title, dataLoc }) => (
  <>
    <Divider />
    <ListboxOption
      as="li"
      className="flex items-center justify-between gap-x-2 px-4 py-2.5"
      data-loc={dataLoc}
      value="header"
      disabled
    >
      <span className="text-gray-800 font-inter-semibold-13px">{title}</span>
      <span className="text-gray-400 font-inter-normal-13px">
        {selected}/{total}
      </span>
    </ListboxOption>
  </>
);

const GroupItem: React.FC<{
  outcomeKey: string;
  onChange: (selected: string[]) => void;
  value: string[];
  items: OutcomeItem[];
  canSelect: (nItems: number) => boolean;
  canDeselect: (nItems: number) => boolean;
}> = ({ outcomeKey, value, onChange, items, canSelect, canDeselect }) => {
  const outcomeName = items[0].outcomeName;
  const selectedItems = value.filter((v) => v.startsWith(outcomeKey));
  const checked = selectedItems.length === items.length;
  const indeterminate =
    selectedItems.length > 0 && selectedItems.length < items.length;

  const disabled = checked
    ? false
    : !canSelect(items.length - selectedItems.length);
  const readOnly = checked && !canDeselect(items.length);

  return (
    <ListboxOption
      as="li"
      className={twJoin(
        "list-none data-[focus]:bg-gray-50",
        disabled || readOnly ? "cursor-default" : "cursor-pointer",
      )}
      value={outcomeKey}
      onClick={(e) => {
        // Ignore default behavior
        e.preventDefault();

        onChange(
          checked
            ? value.filter((v) => !v.startsWith(outcomeKey))
            : uniq([...value, ...items.map((item) => item.value)]),
        );
      }}
    >
      <Tooltip
        disabled={!(disabled || readOnly)}
        placement="left"
        title={
          disabled
            ? "Maximum 10 fields can be selected. Please select items one by one."
            : "At least 3 fields should be selected. Please unselect items one by one."
        }
        asChild
      >
        <div className="flex items-center gap-x-2 overflow-hidden px-4 py-2.5">
          <Checkbox
            checked={checked}
            disabled={disabled}
            indeterminate={indeterminate}
            readOnly
          />
          <span
            className={twJoin(
              "overflow-hidden text-ellipsis",
              "font-inter-semibold-13px",
            )}
          >
            {outcomeName}
          </span>
        </div>
      </Tooltip>
    </ListboxOption>
  );
};

const Item: React.FC<{
  label: string;
  value: string;
  disabled: boolean;
  readOnly: boolean;
  shifted?: boolean;
}> = ({ label, value, readOnly, disabled, shifted }) => (
  <ListboxOption
    as="li"
    className={twJoin(
      "list-none data-[focus]:bg-gray-50",
      disabled || readOnly ? "cursor-default" : "cursor-pointer",
      shifted && "pl-5",
    )}
    data-loc={`column-selector-item-${value}`}
    value={value}
  >
    {({ selected }) => (
      <Tooltip
        disabled={!(disabled || readOnly)}
        placement="left"
        title={
          disabled
            ? "Maximum 10 fields can be selected."
            : "At least 3 fields should be selected."
        }
        asChild
      >
        <div className="flex items-center gap-x-2 overflow-hidden px-4 py-2.5">
          <Checkbox checked={selected} disabled={disabled} readOnly />
          <span className="overflow-hidden text-ellipsis font-inter-normal-13px">
            {label}
          </span>
        </div>
      </Tooltip>
    )}
  </ListboxOption>
);

const Footer: React.FC<{
  onSave?: () => void;
  onReset?: () => void;
  isSaving: boolean;
}> = ({ isSaving, onSave, onReset }) => (
  <li className="sticky bottom-0 mt-2.5 flex w-full list-none items-center justify-between border-t border-t-gray-200 bg-white px-4 pb-4 pt-4.5">
    <Button
      dataLoc="column-selector-reset"
      disabled={!onReset}
      size="sm"
      variant="secondary"
      onClick={onReset}
    >
      Reset
    </Button>
    <Button
      dataLoc="column-selector-save"
      disabled={!onSave || isSaving}
      loading={isSaving}
      size="sm"
      variant="secondary"
      onClick={onSave}
    >
      Save for everyone
    </Button>
  </li>
);

export const ColumnSelector: React.FC<Props> = ({
  flow,
  value,
  isSaving,
  canSelect,
  canDeselect,
  button,
  onChange,
  onReset,
  onSave,
}) => {
  const { data: outcomeTypes } = useOutcomeTypes({
    flowId: flow.id,
  });

  const outcomeItems = transformOutcomeTypesToOptions(outcomeTypes ?? []);

  const [searchQuery, setSearchQuery] = useState("");
  const { input: inputItems, output: outputItems } = useMemo(
    () => transformSchemaToOptions(flow.versions),
    [flow.versions],
  );
  const allItems = useMemo(
    () => [
      ...decisionFieldItems,
      ...inputItems,
      ...outputItems,
      ...outcomeItems,
    ],
    [inputItems, outputItems, outcomeItems],
  );

  const [inputSelectedCount, outputSelectedCount, outcomeSelectedCount] = [
    value.filter((s) => s.startsWith(inputFieldsPrefix)).length,
    value.filter((s) => s.startsWith(outputFieldsPrefix)).length,
    value.filter((s) => s.startsWith(outcomeFieldsPrefix)).length,
  ];

  const filter = useFuseSearch(allItems, {
    threshold: 0.5,
    keys: ["label"],
  });

  const filteredItems = searchQuery ? filter(searchQuery) : allItems;
  const [
    filteredDecisionItems,
    filteredInputItems,
    filteredOutputItems,
    filteredOutcomeItems,
  ] = [
    filteredItems.filter((item) => item.value.startsWith(decisionFieldsPrefix)),
    filteredItems.filter((item) => item.value.startsWith(inputFieldsPrefix)),
    filteredItems.filter((item) => item.value.startsWith(outputFieldsPrefix)),
    filteredItems.filter((item) => item.value.startsWith(outcomeFieldsPrefix)),
  ];

  return (
    <Listbox value={value} multiple onChange={onChange}>
      {({ open }) => (
        <>
          <Tooltip
            disabled={Boolean(button)}
            placement="top"
            title="View options"
            asChild
          >
            <ListboxButton className={twJoin("group", open && "is-open")}>
              {button ? (
                button
              ) : (
                <Icon
                  color="text-gray-500 group-hover:text-gray-800 group-[.is-open]:text-gray-800"
                  dataLoc="column-selector-button"
                  icon={faSliders}
                  size="xs"
                />
              )}
            </ListboxButton>
          </Tooltip>
          <ListboxOptions
            anchor={{
              to: "bottom end",
              padding: 24,
            }}
            className="w-75 relative z-10 min-h-[156px] origin-top overflow-hidden rounded-lg bg-white shadow-lg transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0"
            data-loc="decision-history-view-options-dropdown"
            transition
            unmount
          >
            <div className="sticky top-0 z-10 bg-white px-4 pt-4">
              <h3 className="mb-2 font-inter-semibold-13px">View Options</h3>
              <p className="font-inter-normal-12px">
                Changes are saved automatically for your view
              </p>
              <SearchBox value={searchQuery} onChange={setSearchQuery} />
            </div>
            {filteredItems.length === 0 && (
              <EmptyState
                description="Try searching for a different field"
                headline="No fields found"
                icon={faSearch}
              />
            )}

            {filteredDecisionItems.map(({ value: itemValue, label }) => (
              <Item
                key={itemValue}
                disabled={value.includes(itemValue) ? false : !canSelect(1)}
                label={label}
                readOnly={value.includes(itemValue) ? !canDeselect(1) : false}
                value={itemValue}
              />
            ))}
            {filteredInputItems.length > 0 && (
              <>
                <HeaderItem
                  dataLoc={`column-selector-input-${inputSelectedCount}-selected`}
                  selected={inputSelectedCount}
                  title="Input fields"
                  total={inputItems.length}
                />
                {filteredInputItems.map(({ value: itemValue, label }) => (
                  <Item
                    key={itemValue}
                    disabled={value.includes(itemValue) ? false : !canSelect(1)}
                    label={label}
                    readOnly={
                      value.includes(itemValue) ? !canDeselect(1) : false
                    }
                    value={itemValue}
                  />
                ))}
              </>
            )}
            {filteredOutputItems.length > 0 && (
              <>
                <HeaderItem
                  selected={outputSelectedCount}
                  title="Output fields"
                  total={outputItems.length}
                />
                {filteredOutputItems.map(({ value: itemValue, label }) => (
                  <Item
                    key={itemValue}
                    disabled={value.includes(itemValue) ? false : !canSelect(1)}
                    label={label}
                    readOnly={
                      value.includes(itemValue) ? !canDeselect(1) : false
                    }
                    value={itemValue}
                  />
                ))}
              </>
            )}
            {filteredOutcomeItems.length > 0 && (
              <>
                <HeaderItem
                  selected={outcomeSelectedCount}
                  title="Outcomes"
                  total={outcomeItems.length}
                />
                {Object.entries(
                  groupBy(filteredOutcomeItems as OutcomeItem[], "outcomeKey"),
                ).map(([outcomeKey, items]) => (
                  <Fragment key={outcomeKey}>
                    <GroupItem
                      canDeselect={canDeselect}
                      canSelect={canSelect}
                      items={items}
                      outcomeKey={outcomeKey}
                      value={value}
                      onChange={onChange}
                    />
                    {items.map(({ value: itemValue, label }) => (
                      <Item
                        key={itemValue}
                        disabled={
                          value.includes(itemValue) ? false : !canSelect(1)
                        }
                        label={label}
                        readOnly={
                          value.includes(itemValue) ? !canDeselect(1) : false
                        }
                        value={itemValue}
                        shifted
                      />
                    ))}
                  </Fragment>
                ))}
              </>
            )}
            <Footer
              isSaving={isSaving}
              onReset={searchQuery ? undefined : onReset}
              onSave={searchQuery ? undefined : onSave}
            />
          </ListboxOptions>
        </>
      )}
    </Listbox>
  );
};
