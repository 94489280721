import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import {
  featuresApi as featuresApiCreator,
  featureQueriesApi as featureQueriesApiCreator,
} from "src/api/endpoints";
import {
  FeatureQueryCreate,
  FeatureQuery,
  FeatureQueryPatch,
  PreviewQueryResponse,
} from "src/clients/features";
import { FeatureForm } from "src/featureCatalogue/Features/ManageFeatureModal";
import { useWorkspaceContext } from "src/router/routerContextHooks";
import { logger } from "src/utils/logger";

const PREFIX_CACHE_KEY = "features";

const useFeaturesApi = () => {
  const { workspace } = useWorkspaceContext();
  return featuresApiCreator(`${workspace.base_url}/features/control/api/v1`);
};

export const useFeatures = () => {
  const featuresApi = useFeaturesApi();

  return useQuery({
    queryKey: ["features"],
    queryFn: () => featuresApi.getFeaturesFeaturesGet(),
  });
};

export const useFeatureByKey = (key: string) => {
  const featuresApi = useFeaturesApi();
  return useQuery({
    queryKey: ["features", key],
    queryFn: () => featuresApi.getFeatureHandlerFeaturesKeyGet({ key }),
    enabled: Boolean(key),
    retry: (_, error) => {
      return !(error instanceof AxiosError && error.response?.status === 404);
    },
  });
};

export const useCreateFeature = () => {
  const featuresApi = useFeaturesApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (feature: FeatureForm) =>
      featuresApi.createFeatureHandlerFeaturesPost({
        featureCreate: {
          key: feature.key,
          name: feature.name,
          description: feature.description,
          query_id: null,
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PREFIX_CACHE_KEY] });
    },
  });
};

export const useUpdateFeature = () => {
  const featuresApi = useFeaturesApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      key,
      feature,
      etag,
    }: {
      key: string;
      feature: Partial<Omit<FeatureForm, "key">>;
      etag: string;
    }) =>
      featuresApi.patchFeatureHandlerFeaturesKeyPatch({
        key,
        featurePatch: {
          name: feature.name,
          description: feature.description,
        },
        ifMatch: etag,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PREFIX_CACHE_KEY] });
    },
  });
};

const useFeatureQueriesApi = () => {
  const { workspace } = useWorkspaceContext();
  return featureQueriesApiCreator(
    `${workspace.base_url}/features/control/api/v1`,
  );
};

export const useFeatureQueries = () => {
  const { workspace } = useWorkspaceContext();
  const featureQueriesApi = useFeatureQueriesApi();

  return useQuery({
    queryKey: ["feature-queries", workspace.id],
    queryFn: async () =>
      (await featureQueriesApi.getFeatureQueriesFeatureQueriesGet()).data,
  });
};

export const useFeatureQuery = (id: string) => {
  const featureQueriesApi = useFeatureQueriesApi();
  return useQuery({
    queryKey: ["feature-query", id],
    queryFn: async () =>
      (
        await featureQueriesApi.getFeatureQueryFeatureQueriesIdGet({
          id,
        })
      ).data,
    enabled: !!id,
  });
};

export const useFeatureQueryPreview = ({
  onSuccess,
}: {
  onSuccess?: (data: PreviewQueryResponse["data"]) => void;
} = {}) => {
  const featureQueriesApi = useFeatureQueriesApi();

  return useMutation<
    PreviewQueryResponse["data"],
    AxiosError<{ detail: string }>,
    { queryString: string; windowLength: number }
  >({
    mutationKey: ["feature-query-preview"],
    mutationFn: async ({
      queryString,
      windowLength = 300,
    }: {
      queryString: string;
      windowLength: number;
    }) => {
      return (
        await featureQueriesApi.previewQueryFeatureQueriesPreviewPost({
          previewQueryRequest: {
            query: queryString,
            limit: 100,
            window_length: windowLength,
          },
        })
      ).data.data;
    },
    onSuccess,
    onError: (error, variables) => {
      if (error.response?.status === 500) {
        logger.error(
          "Unexpected error: Feature query preview failed: ",
          error,
          "Query: ",
          variables,
        );
      }
    },
  });
};

export const useCreateFeatureQuery = () => {
  const { workspace } = useWorkspaceContext();
  const featureQueriesApi = useFeatureQueriesApi();
  const queryClient = useQueryClient();

  return useMutation<FeatureQuery, Error, FeatureQueryCreate>({
    mutationFn: async (query: FeatureQueryCreate) => {
      return (
        await featureQueriesApi.createFeatureQueryHandlerFeatureQueriesPost({
          featureQueryCreate: query,
        })
      ).data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["feature-queries", workspace.id],
      });
    },
  });
};

type UseUpdateFeatureQueryProps = {
  onError?: (error: AxiosError<{ detail: string }>) => void;
};

export const useUpdateFeatureQuery = ({
  onError,
}: UseUpdateFeatureQueryProps = {}) => {
  const { workspace } = useWorkspaceContext();
  const featureQueriesApi = useFeatureQueriesApi();
  const queryClient = useQueryClient();

  return useMutation<
    FeatureQuery,
    AxiosError<{ detail: string }>,
    FeatureQueryPatch & { id: string; etag: string }
  >({
    mutationFn: async ({ id, etag, ...query }) => {
      return (
        await featureQueriesApi.updateFeatureQueryHandlerFeatureQueriesIdPatch({
          id,
          featureQueryPatch: query,
          ifMatch: etag,
        })
      ).data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["feature-queries", workspace.id],
      });
    },
    onError,
  });
};
