import {
  faCube,
  faDiagramNested,
  faHome,
  faCalculatorSimple,
  faGear,
  faSearch,
  faTimer,
} from "@fortawesome/pro-regular-svg-icons";
import { isMacOs } from "react-device-detect";
import { Link, Outlet, useMatch, useOutletContext } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";

import { FlowT } from "src/api/flowTypes";
import TaktileLogo from "src/assets/Taktile.svg?react";
import { Divider } from "src/base-components/Divider";
import { Icon } from "src/base-components/Icon";
import { Pill } from "src/base-components/Pill";
import { Tooltip } from "src/design-system/Tooltip";
import { useCapabilities } from "src/hooks/useCapabilities";
import { ClickableSearchBox } from "src/layout/ClickableSearchBox";
import { DashboardContent } from "src/layout/DashboardContent";
import { LayoutHeader } from "src/layout/Header";
import { NavItem, NavSidebar } from "src/layout/NavSideBar";
import { useOmniboxActions } from "src/omnibox/OmniboxStore";
import { FEATURE_FLAGS, isFeatureFlagEnabled } from "src/router/featureFlags";
import {
  DashboardPageParamsT,
  getUrlToSettingsPage,
  getUrlToWsDashboard,
  WsDashboardPageCode,
} from "src/router/urls";
import { canAccessSettingsPage } from "src/settings/utils";
import { useParamsDecode } from "src/utils/useParamsDecode";

const MainSideBar = () => {
  const { orgId, wsId } = useParamsDecode<DashboardPageParamsT>();
  const [collapsed, setCollapsed] = useLocalStorage(
    "mainSideBarNavigationCollapsed",
    false,
  );
  const context = useOutletContext();
  const { flow } = context as { flow: FlowT | undefined };

  const { showOmnibox } = useOmniboxActions();
  const capabilities = useCapabilities();
  const isEntityViewPage = Boolean(
    useMatch("/org/:orgId/ws/:wsId/entities/:schema/:id"),
  );
  const isSidebarCollapsed = isEntityViewPage || collapsed;

  return (
    <div>
      <div className="mb-5 px-3.5">
        <Link
          to={getUrlToWsDashboard({
            orgId,
            wsId,
            folderId: flow?.flow_folder_id,
          })}
        >
          <div className="mb-7 mt-4 flex items-center gap-x-2">
            <TaktileLogo data-loc="header-taktile-logo" />
            {!isSidebarCollapsed && (
              <p className="font-inter-semibold-13px">Taktile</p>
            )}
          </div>
        </Link>
        {isSidebarCollapsed ? (
          <Tooltip
            placement="bottom-end"
            title={
              <>
                <span className="mr-2">Search</span>
                <Pill size="sm" variant="dark-gray">
                  <Pill.Text fontType="text">
                    {isMacOs ? "⌘" : "Ctrl"} K
                  </Pill.Text>
                </Pill>
              </>
            }
            asChild
          >
            <Icon
              color="text-gray-500"
              icon={faSearch}
              size="xs"
              onClick={showOmnibox}
            />
          </Tooltip>
        ) : (
          <ClickableSearchBox
            dataLoc="main-sidebar-search"
            text="Search"
            onClick={showOmnibox}
          />
        )}
      </div>
      <div className="px-2.5">
        <Divider />
      </div>
      <NavSidebar
        collapsed={isSidebarCollapsed}
        setCollapsed={isEntityViewPage ? null : setCollapsed}
      >
        <NavItem
          icon={faHome}
          title="Dashboard"
          to={getUrlToWsDashboard({
            orgId,
            wsId,
            page: WsDashboardPageCode.Dashboard,
          })}
        />
        <NavItem
          icon={faDiagramNested}
          title="Decision Flows"
          to={getUrlToWsDashboard({
            orgId,
            wsId,
            page: WsDashboardPageCode.Flows,
          })}
        />
        <NavItem
          icon={faCube}
          title="Entities"
          to={getUrlToWsDashboard({
            orgId,
            wsId,
            page: WsDashboardPageCode.Entities,
          })}
        />
        <NavItem
          icon={faTimer}
          title="Events"
          to={getUrlToWsDashboard({
            orgId,
            wsId,
            page: WsDashboardPageCode.Events,
          })}
        />
        <NavItem
          end={false}
          icon={faCalculatorSimple}
          title="Features"
          to={getUrlToWsDashboard({
            orgId,
            wsId,
            page: WsDashboardPageCode.Features,
          })}
        />
        {canAccessSettingsPage(capabilities) && (
          <>
            <Divider spacing="my-2" />
            <NavItem
              end={false}
              icon={faGear}
              title="Settings"
              to={getUrlToSettingsPage(orgId, wsId)}
            />
          </>
        )}
      </NavSidebar>
    </div>
  );
};

export const MainPageDashboard: React.FC = () => {
  const context = useOutletContext();
  return (
    <DashboardContent
      Header={
        !isFeatureFlagEnabled(FEATURE_FLAGS.entitiesBase) && <LayoutHeader />
      }
      sidebar={
        isFeatureFlagEnabled(FEATURE_FLAGS.entitiesBase) && <MainSideBar />
      }
      removePadding
    >
      <Outlet context={context} />
    </DashboardContent>
  );
};
