import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { getWsUrl } from "src/router/urls";

interface TabChangeProps {
  orgId: string;
  wsId: string;
  setActiveTab: (newTab: string) => void;
}

export const useTabChange = ({ orgId, wsId, setActiveTab }: TabChangeProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  return useCallback(
    (newTab: string) => {
      const wsUrl = getWsUrl(orgId, wsId);
      setActiveTab(newTab);
      navigate(
        `${wsUrl}entities/${encodeURIComponent(newTab)}${location.search}`,
        {
          replace: true,
        },
      );
    },
    [navigate, orgId, wsId, location.search, setActiveTab],
  );
};
