import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import React, { useEffect, useState } from "react";
import { twJoin } from "tailwind-merge";

import { FlowT } from "src/api/flowTypes";
import { useFlow } from "src/api/queries";
import { CopyTextIcon } from "src/base-components/CopyTextIcon";
import { FloatingWindowsProvider } from "src/base-components/FloatingWindow/FloatingWindowsProvider";
import { Icon } from "src/base-components/Icon";
import { LoadingView } from "src/base-components/LoadingView";
import { Pill } from "src/base-components/Pill";
import { Tabs, TabT } from "src/base-components/Tabs";
import { DecisionSidepaneProps } from "src/decisionsOverview/DecisionsSidepaneWrapper";
import { TraceDecisionButton } from "src/decisionsOverview/TraceDecisionButton";
import { getDisplayedId } from "src/decisionsOverview/utils";

type Props = Omit<DecisionSidepaneProps, "baseUrl"> & {
  tabs: TabT[];
  isInputSchemaError: boolean;
  defaultTab: string;
};

const Header: React.FC<Pick<Props, "decision" | "onClose">> = ({
  decision,
  onClose,
}) => {
  const flowQuery = useFlow(decision?.flow.id);
  return (
    <div className="flex w-full items-center justify-between">
      <h3 className="flex items-center gap-x-1 font-inter-semibold-13px">
        Decision ID
        <Pill dataLoc="decision-id-pill" size="sm" variant="gray" maxWidth>
          <Pill.Text fontType="code">
            {getDisplayedId(decision?.id ?? "-")}
          </Pill.Text>
          <CopyTextIcon
            dataLoc={`copy-id-${decision?.id}`}
            size="3xs"
            value={decision?.id ?? "-"}
          />
        </Pill>
      </h3>
      <div className="flex items-center gap-x-2">
        <LoadingView
          queryResult={flowQuery}
          renderUpdated={(flow: FlowT) =>
            decision ? (
              <div className="ml-3">
                <TraceDecisionButton decision={decision} flow={flow} />
              </div>
            ) : (
              <></>
            )
          }
          renderUpdating={() => <></>}
        />
        <div className="flex gap-x-1.5 text-gray-500">
          <Icon
            dataLoc="webhook-logs-close"
            icon={faTimes}
            size="xs"
            onClick={onClose}
          />
        </div>
      </div>
    </div>
  );
};

export type DecisionRequest = { data: Record<string, any> };

export const DecisionsSidepane: React.FC<Props> = ({
  isOpen,
  decision,
  onClose,
  widthClass,
  tabs,
  isInputSchemaError,
  defaultTab,
}) => {
  const [activeTab, setActiveTab] = useState<string>(defaultTab);
  // reset active tab when decision changes
  useEffect(() => {
    setActiveTab(defaultTab);
  }, [decision, defaultTab]);

  return (
    <FloatingWindowsProvider>
      <div
        className={twJoin(
          "right-0 top-[49px] ml-auto flex h-[calc(100vh-49px)] flex-col border-l border-gray-200 bg-white p-5 transition-opacity",
          widthClass,
          isOpen ? "fixed" : "hidden",
        )}
        data-loc="decision-sidepane"
      >
        <Header decision={decision} onClose={onClose} />
        <hr className="mb-2 mt-4 border-gray-100" />
        <Tabs
          containerClassName="flex h-full flex-col overflow-hidden"
          panelsClassName="decideScrollbar flex-1 overflow-y-auto flex flex-col"
          selectedKey={isInputSchemaError ? "input-tab-data" : activeTab}
          tabClassName="px-2.5"
          tabListClassName="border-b border-gray-100 mb-6 sticky bg-white"
          tabs={tabs}
          onChange={setActiveTab}
        />
      </div>
    </FloatingWindowsProvider>
  );
};
