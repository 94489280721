import { IconDefinition } from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import { Link } from "react-router-dom";

import { Icon } from "src/base-components/Icon";

type TabProps = {
  children: React.ReactNode;
  to: string;
  active?: boolean;
};

type TabContentProps = {
  icon: IconDefinition;
  label: string;
};

type TabsProps = {
  children: React.ReactNode;
};

type HeaderProps = {
  children: React.ReactNode;
};

const Tab: React.FC<TabProps> = ({ children, to, active }) => {
  return (
    <li className="relative h-full">
      <Link
        className="inline-flex h-full items-center px-1 text-gray-800 font-inter-semibold-13px"
        to={to}
      >
        {children}
      </Link>
      {active && (
        <div className="absolute bottom-0 h-[3px] w-full rounded-full bg-indigo-600" />
      )}
    </li>
  );
};

export const TabContent: React.FC<TabContentProps> = ({ icon, label }) => (
  <div className="flex items-center gap-x-1.5">
    <Icon color="text-gray-500" icon={icon} size="xs" />
    <span>{label}</span>
  </div>
);

const Tabs: React.FC<TabsProps> = ({ children }) => {
  return <ul className="ml-3.5 flex h-full gap-x-4 bg-white">{children}</ul>;
};

const FeatureCatalogueNavBase: React.FC<HeaderProps> = ({ children }) => {
  return (
    <nav className="sticky -top-8 z-10 mb-4.5 flex h-12 items-center justify-between gap-x-2 border-x border-b border-gray-200 bg-white">
      {children}
    </nav>
  );
};
export const FeatureCatalogueHeader = Object.assign(FeatureCatalogueNavBase, {
  Tab,
  Tabs,
});
