import { faArrowUpRight } from "@fortawesome/pro-regular-svg-icons";

import { Button } from "src/base-components/Button";
import { Pill, PillVariants } from "src/base-components/Pill";
import { NodeDiffView } from "src/changeHistory/DiffViewModal/NodeDiffView";
import { BeMappedNode } from "src/constants/NodeDataTypes";
import { NodeSuggestionType } from "src/copilot/NodeSuggestion";
import { Modal } from "src/design-system/Modal";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onApply: () => void;
  originalNode: BeMappedNode;
  nodeSuggestion: NodeSuggestionType;
};

type MetaInfoProps = {
  text: string;
  variant: PillVariants;
};

const MetaInfo: React.FC<MetaInfoProps> = ({ text, variant }) => (
  <div className="flex w-1/2 items-center gap-x-1">
    <Pill size="base" variant={variant}>
      <Pill.Text>{text}</Pill.Text>
    </Pill>
  </div>
);

export const InspectChangesModal: React.FC<Props> = ({
  isOpen,
  originalNode,
  nodeSuggestion,
  onApply,
  onClose,
}) => {
  const suggestedNode: BeMappedNode = {
    ...originalNode,
    data: {
      ...originalNode.data,
      label: nodeSuggestion.name,
      ...nodeSuggestion.meta,
    },
  };

  const handleApply = () => {
    onApply();
    onClose();
  };

  return (
    <Modal open={isOpen} size="lg" onClose={onClose}>
      <Modal.Header>Inspect changes</Modal.Header>
      <Modal.Content>
        <div className="flex gap-x-6 pb-5">
          <MetaInfo text="Before" variant="red" />
          <MetaInfo text="After" variant="green" />
        </div>
        <NodeDiffView changedNode={suggestedNode} originalNode={originalNode} />
      </Modal.Content>
      <Modal.Footer
        primaryButton={
          <Button
            dataLoc="copilot-apply-suggestion"
            iconLeft={faArrowUpRight}
            variant="primary"
            onClick={handleApply}
          >
            Apply changes
          </Button>
        }
      />
    </Modal>
  );
};
