import { useUserOrganizations } from "src/api/queries";
import { TAKTILE_ORG_ID } from "src/constants/OrgIds";

export const useIsTaktileOrgUser = (): boolean => {
  const userOrganizationsResult = useUserOrganizations();
  return (
    userOrganizationsResult.data?.some((org) => org.id === TAKTILE_ORG_ID) ??
    false
  );
};
