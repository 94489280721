import { faEye } from "@fortawesome/pro-regular-svg-icons";
import { Root } from "@radix-ui/react-accordion";
import React, { useEffect } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";

import { ErrorBaseInfo, FieldErrorsBET } from "src/api/types";
import { Button } from "src/base-components/Button";
import {
  EditorAccordionItem as AccordionItem,
  accordionRootClassName,
} from "src/base-components/EditorAccordionItem";
import { Switch } from "src/base-components/Switch";
import { useDiffViewContext } from "src/changeHistory/DiffViewModal/DiffViewContext";
import {
  AssignStrategyAny,
  AssignStrategyAnyTypeEnum,
  AssignStrategyReviewerOnly,
  ManualReviewResponseFormField,
} from "src/clients/flow-api";
import {
  ManualReviewNode,
  ManualReviewNodeDataT,
} from "src/constants/NodeDataTypes";
import { usePreviewTab } from "src/manualReview/previewTabHooks";
import { HighlightsEditor } from "src/manualReviewNode/HighlightsEditor";
import { ReviewResponseConfiguration } from "src/manualReviewNode/ReviewResponseConfiguration";
import { ReviewerAssignConfiguration } from "src/manualReviewNode/ReviewerAssignConfiguration";
import { NodeEditorBaseProps } from "src/nodeEditor/NodeEditor";
import { useSubmitForm } from "src/utils/useSubmitForm";

type PropsT = {
  node: ManualReviewNode;
  displayedError: ErrorBaseInfo | undefined;
} & NodeEditorBaseProps<ManualReviewNodeDataT>;

type ManualReviewResponseFormFieldWithUIEnum = Omit<
  ManualReviewResponseFormField,
  "enum"
> & {
  enum?: { value: string }[];
};

export type ManualReviewFormT = Omit<ManualReviewNodeDataT, "response_form"> & {
  response_form: {
    description: string;
    fields?: ManualReviewResponseFormFieldWithUIEnum[];
  };
};

const convertDataToForm = (
  nodeData: ManualReviewNodeDataT,
): ManualReviewFormT => ({
  ...nodeData,
  reviewer_assign_strategy_v2:
    nodeData.reviewer_assign_strategy_v2 ??
    (nodeData.reviewer_assign_strategy
      ? ({
          type: nodeData.reviewer_assign_strategy,
        } as unknown as AssignStrategyAny | AssignStrategyReviewerOnly)
      : undefined),
  // Replace legacy reviewer_assign_strategy with undefined, so it never interfere with the new reviewer_assign_strategy_v2
  reviewer_assign_strategy: undefined,
  response_form: {
    ...nodeData.response_form,
    fields: nodeData.response_form.fields?.map((field) => ({
      ...field,
      enum: field.enum?.map((enumValue) => ({ value: enumValue as string })),
    })),
  },
});

const convertFormToData = (
  nodeData: ManualReviewFormT,
): ManualReviewNodeDataT => ({
  ...nodeData,
  response_form: {
    ...nodeData.response_form,
    fields: nodeData.response_form.fields?.map((field) => ({
      ...field,
      enum: field.enum?.map((enumValue) => enumValue.value),
    })),
  },
});

export const ManualReviewAccordionHeader: React.FC<{
  title: string;
  action: React.ReactNode;
}> = ({ title, action }) => (
  <div className="flex w-full flex-row items-center justify-between">
    {title}
    {action}
  </div>
);

const ManualReviewAccordionPreviewHeader: React.FC<{
  title: string;
  nodeData: ManualReviewNodeDataT;
}> = ({ title, nodeData }) => {
  const { open } = usePreviewTab(nodeData);

  return (
    <ManualReviewAccordionHeader
      action={
        <Button
          iconLeft={faEye}
          size="sm"
          variant="secondary"
          onClick={(e) => {
            e.stopPropagation();
            open();
          }}
        >
          Preview
        </Button>
      }
      title={title}
    />
  );
};

const ManualReviewAssigneeHeader: React.FC<{
  immutable: boolean;
  onChange: (enabled: boolean) => void;
}> = ({ immutable, onChange }) => (
  <ManualReviewAccordionHeader
    action={
      <Controller
        name="reviewer_assign_strategy_v2"
        render={(props) => (
          <Switch
            disabled={immutable}
            enabled={Boolean(props.field.value)}
            onChange={onChange}
          />
        )}
      />
    }
    title="Configure case assignment logic"
  />
);

type EnumKey = "enum";
type ResponseFormErrorFieldKey = "name" | "key" | EnumKey;
type HihglightErrorFieldKey = "value" | "readable_name";

const getErrors = (fieldErrors?: FieldErrorsBET) =>
  fieldErrors?.reduce<Record<string, FieldErrorsBET[number]>>((sum, error) => {
    if (error.field_name === "response_metadata_key") {
      sum[error.field_name] = error;
    } else {
      const [id] = error.field_id.split(".");
      sum[id] = error;
    }

    return sum;
  }, {});

const DEFAULT_OPEN_ACCORDION_ITEMS = ["information", "review"];

export const ManualReviewNodeEditor: React.FC<PropsT> = ({
  immutable,
  node,
  isReactive,
  onUpdate,
  displayedError,
}) => {
  const { renderedInDiffView } = useDiffViewContext();
  const nodeData = convertDataToForm(node.data);
  const [accordionValue, setAccordionValue] = React.useState<string[]>(() => {
    if (nodeData.reviewer_assign_strategy_v2) {
      return [...DEFAULT_OPEN_ACCORDION_ITEMS, "assign-reviewers"];
    }
    return DEFAULT_OPEN_ACCORDION_ITEMS;
  });

  const formMethods = useForm<ManualReviewFormT>({
    mode: "onBlur",
    shouldFocusError: false,
    defaultValues: nodeData,
    ...(isReactive && { values: nodeData }),
  });

  useEffect(() => {
    if (displayedError) {
      const responseFormFields = formMethods.getValues("response_form.fields");
      const highlights = formMethods.getValues("highlights");
      const errors = getErrors(displayedError.field_errors);

      responseFormFields?.forEach((field, index) => {
        const error = errors?.[field.id];
        if (error) {
          const [_id, field, enumIndex] = error.field_id.split(".");
          const fieldName = field as ResponseFormErrorFieldKey;
          formMethods.setError(
            fieldName === "enum"
              ? `response_form.fields.${index}.${fieldName}.${parseInt(
                  enumIndex,
                )}.value`
              : `response_form.fields.${index}.${fieldName}`,
            {
              type: "validValue",
              message: error.message,
            },
          );
        }
      });

      highlights?.forEach((highlight, index) => {
        if (highlight.type !== "field") return;

        const error =
          errors?.[highlight.id_readable_name] ?? errors?.[highlight.id_value];
        if (error) {
          formMethods.setError(
            `highlights.${index}.${error.field_name as HihglightErrorFieldKey}`,
            {
              type: "validValue",
              message: error.message,
            },
          );
        }
      });

      if (errors?.response_metadata_key) {
        formMethods.setError("response_metadata_key", {
          type: "validValue",
          message: errors.response_metadata_key.message,
        });
      }
    }
  }, [displayedError, formMethods]);

  useSubmitForm({
    onChange: (data: ManualReviewFormT) => {
      onUpdate({ newData: convertFormToData(data) });
    },
    previousValues: nodeData,
    disabled: isReactive,
    watch: formMethods.watch,
  });

  const onAccordionValueChange = (value: string[]) => {
    if (immutable) return;

    const isAssignReviewersOpen = value.includes("assign-reviewers");
    const currentStrategy = formMethods.getValues(
      "reviewer_assign_strategy_v2",
    );

    // Sync accordion value with form value
    if (isAssignReviewersOpen && !currentStrategy) {
      formMethods.setValue("reviewer_assign_strategy_v2", {
        type: AssignStrategyAnyTypeEnum.ANY,
      });
    }
    if (!isAssignReviewersOpen && currentStrategy) {
      formMethods.setValue("reviewer_assign_strategy_v2", undefined);
    }

    setAccordionValue(value);
  };

  return (
    <Root
      className={accordionRootClassName}
      type="multiple"
      value={accordionValue}
      onValueChange={onAccordionValueChange}
    >
      <FormProvider {...formMethods}>
        <AccordionItem
          className="pb-3"
          disabled={renderedInDiffView}
          title={
            <ManualReviewAccordionPreviewHeader
              nodeData={node.data}
              title="Configure what the reviewer should see"
            />
          }
          value="information"
          headerAsChild
        >
          <HighlightsEditor immutable={immutable} />
        </AccordionItem>
        <AccordionItem
          className="pb-1"
          disabled={renderedInDiffView}
          title={
            <ManualReviewAccordionPreviewHeader
              nodeData={node.data}
              title="Configure what the reviewer should respond with"
            />
          }
          value="review"
          headerAsChild
        >
          <ReviewResponseConfiguration immutable={immutable} />
        </AccordionItem>
        <AccordionItem
          className="pb-1"
          disabled={renderedInDiffView}
          title={
            <ManualReviewAssigneeHeader
              immutable={immutable}
              onChange={(checked) => {
                onAccordionValueChange(
                  checked
                    ? [...accordionValue, "assign-reviewers"]
                    : accordionValue.filter(
                        (item) => item !== "assign-reviewers",
                      ),
                );
              }}
            />
          }
          value="assign-reviewers"
          headerAsChild
        >
          <ReviewerAssignConfiguration immutable={immutable} />
        </AccordionItem>
      </FormProvider>
    </Root>
  );
};
