import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { AxiosError, isAxiosError } from "axios";
import React, { useMemo } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { v4 as uuid } from "uuid";

import {
  ManifestIntegrationProvider,
  ManifestResourceDefinition,
} from "src/api/connectApi/manifestTypes";
import {
  useCreateManifestConnection,
  useUpdateManifestConnection,
} from "src/api/connectApi/queries";
import { ConnectionT } from "src/api/connectApi/types";
import { WorkspaceWithSettings } from "src/api/types";
import { Button } from "src/base-components/Button";
import { ErrorHint } from "src/base-components/ErrorHint";
import { FormItem } from "src/base-components/FormItem";
import { Input } from "src/base-components/Input";
import { Switch } from "src/base-components/Switch";
import { DataRetentionFields } from "src/connections/config/DataRetentionFields";
import { ManifestEnvironmentsConfig } from "src/connections/config/manifest/ManifestEnvironmentsConfig";
import {
  isManifestResourceAvailableInAWSRegion,
  ResourceConfigForm,
} from "src/connections/config/manifest/ResourceConfigForm";
import { Form } from "src/connections/config/manifest/common/Form";
import { getValidationRules } from "src/connections/config/manifest/common/ValidationRules";
import {
  FormPathT,
  ManifestFormType,
  ManifestResourceCreateT,
} from "src/connections/config/manifest/types";
import { convertBEConnectionToManifestConnectionInput } from "src/connections/model/manifest";
import { useConnectionManagementActions } from "src/connections/store/connectionManagementStore";
import { Modal } from "src/design-system/Modal";
import { toastActions } from "src/design-system/Toast/utils";
import { FEATURE_FLAGS, isFeatureFlagEnabled } from "src/router/featureFlags";
import { isConflictError } from "src/utils/predicates";
import { useCurrentWorkspace } from "src/utils/useCurrentWorkspace";

// Creates a default resource object for the form
const createResource = ([resource_key, resource_definition]: [
  string,
  ManifestResourceDefinition,
]): ManifestResourceCreateT => {
  return {
    id: uuid(),
    name: resource_definition.display_name,
    resource: resource_key,
    has_raw_response_enabled: false,
    configuration: {},
  };
};

type ManifestFormT = {
  onCancelClick: () => void;
  manifest: ManifestIntegrationProvider;
  workspace: WorkspaceWithSettings;
  resetFormStates: () => void;
  onClose: () => void;
  connectionToEdit?: ConnectionT;
};

export const ManifestForm: React.FC<ManifestFormT> = ({
  manifest,
  onCancelClick,
  onClose,
  workspace,
  resetFormStates,
  connectionToEdit,
}) => {
  const { setConnectionToEdit, setModalOpen } =
    useConnectionManagementActions();
  const workspaceData = useCurrentWorkspace();
  const AWSRegion = workspaceData.workspace?.aws_region;
  const defaultResourceDefinition = Object.entries(manifest.resources).find(
    ([_, resource]) =>
      isManifestResourceAvailableInAWSRegion(resource, AWSRegion),
  );

  const defaultValues = useMemo(() => {
    if (connectionToEdit) {
      return convertBEConnectionToManifestConnectionInput(connectionToEdit);
    } else {
      return {
        name: `${manifest.display_name} connection`,
        resource_configs: [createResource(defaultResourceDefinition!)],
        inputs: {
          ...(isFeatureFlagEnabled(FEATURE_FLAGS.manifestEnvironments)
            ? {}
            : { production: {} }),
        },
      };
    }
  }, [connectionToEdit, manifest, defaultResourceDefinition]);

  const createConnection = useCreateManifestConnection(workspace.base_url);
  const editConnection = useUpdateManifestConnection(workspace.base_url);
  const formMethods = useForm<ManifestFormType>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues,
  });
  const {
    formState: { isSubmitting: formIsSubmitting, errors: formErrors },
    handleSubmit: handleFormSubmit,
    watch,
    setValue: setFormValue,
  } = formMethods;

  const formValue = watch();

  const validateFullForm = (data: ManifestFormType): boolean => {
    const errors: Record<string, any> = {};
    if (data.inputs) {
      Object.entries(data.inputs).forEach(([environment, values]) => {
        const properties =
          manifest.environment_configs[environment]?.inputs?.properties;
        if (!properties) {
          return true;
        }

        Object.entries(properties).forEach(([key, schema]) => {
          const isRequired =
            manifest.environment_configs[environment].inputs.required?.includes(
              key,
            );
          const validationRules = getValidationRules(schema, !!isRequired, key);
          const validationResult = validationRules.validate(values[key]);
          if (validationResult !== true) {
            errors[`inputs.${environment}.${key}`] = {
              type: "validation",
              message: validationResult,
            };
          }
        });
      });
    }

    if (Object.keys(errors).length > 0) {
      Object.entries(errors).forEach(([path, error]) => {
        formMethods.setError(path as FormPathT, error);
      });
      return false;
    }
    return true;
  };

  const transformResourceInstance = (
    currentResourceInstance: ManifestResourceCreateT,
    newResourceName: string,
  ) => {
    // Switches the resource type of the current resource instance to the new one
    const newResource = createResource([
      newResourceName,
      manifest.resources[newResourceName],
    ]);

    // If the user changed the current resource's name, keep it
    // otherwise, use the default for the new resource
    const currentResourceDefaultName =
      manifest.resources[currentResourceInstance.resource].display_name;
    if (currentResourceInstance.name !== currentResourceDefaultName)
      newResource.name = currentResourceInstance.name;
    return newResource;
  };

  const resourceRemoveDisabled = formValue.resource_configs.length <= 1;

  const handleAddResource = () => {
    const newResource = createResource(defaultResourceDefinition!);
    setFormValue("resource_configs", [
      ...formValue.resource_configs,
      newResource,
    ]);
  };

  const handleRemoveResource = (resourceId: string) => {
    setFormValue(
      "resource_configs",
      formValue.resource_configs.filter(
        (resource) => resource.id !== resourceId,
      ),
    );
  };

  const onSelectResource = (
    currentResourceInstance: ManifestResourceCreateT,
    newResource: string,
  ) => {
    const newResourceInstance = transformResourceInstance(
      currentResourceInstance,
      newResource,
    );
    const newResourceConfigArray = [...formValue.resource_configs];
    const oldResourceIndex = newResourceConfigArray.findIndex(
      (element) => element.id === currentResourceInstance.id,
    );
    newResourceConfigArray[oldResourceIndex] = newResourceInstance;
    setFormValue("resource_configs", newResourceConfigArray);
  };

  const onSubmitCreateConnection = async (
    data: ManifestFormType,
  ): Promise<boolean> => {
    if (connectionToEdit) {
      throw new Error(
        "Unexpected function call, use 'onSubmitEditConnection' for editing connections",
      );
    }

    try {
      const result = await createConnection.mutateAsync({
        ...data,
        provider: manifest.name,
        manifest_version: manifest.version,
      });
      const toastId = toastActions.success({
        title: "Connection created",
        description: "Connection was created successfully",
        withSidebar: true,
        actionText: "View connection",
        onActionClick: () => {
          toastActions.dismiss(toastId);
          setConnectionToEdit(result);
          setModalOpen(true);
        },
      });

      return true;
    } catch (e) {
      if (isConflictError(e)) {
        toastActions.failure({
          title: "Connection creation failed",
          description: (e as AxiosError<any>).response?.data.detail,
          duration: Infinity,
        });
      } else {
        toastActions.failure({
          title: "Connection creation failed",
          description: "Connection could not be created.",
          withSidebar: true,
        });
      }

      return false;
    }
  };

  const onSubmitEditConnection = async (
    data: ManifestFormType,
  ): Promise<boolean> => {
    if (!connectionToEdit) {
      throw new Error(
        "Unexpected function call, use 'onSubmitCreateConnection' for creating connections",
      );
    }

    try {
      await editConnection.mutateAsync({
        connectionId: connectionToEdit.id,
        payload: {
          ...data,
          provider: manifest.name,
          manifest_version: manifest.version,
        },
      });
      toastActions.success({
        title: "Connection updated",
        description: "Connection was updated successfully",
        withSidebar: true,
      });

      return true;
    } catch (e) {
      if (isConflictError(e)) {
        toastActions.failure({
          title: "Connection update failed",
          description: (e as AxiosError<any>).response?.data.detail,
          duration: Infinity,
        });
      } else {
        toastActions.failure({
          title: "Connection edit failed",
          description:
            isAxiosError(e) && e.response?.data?.detail?.msg
              ? e.response.data.detail.msg
              : `Connection ${connectionToEdit.name} could not be edited`,
        });
      }

      return false;
    }
  };

  const onSubmit = async (data: ManifestFormType) => {
    formMethods.clearErrors();

    if (!validateFullForm(data)) {
      return;
    }

    let success;
    if (connectionToEdit) {
      success = await onSubmitEditConnection(data);
    } else {
      success = await onSubmitCreateConnection(data);
    }

    if (success) {
      onClose();
      resetFormStates();
    }
  };

  const isPowerToolsEnabled = isFeatureFlagEnabled(FEATURE_FLAGS.powertools);
  return (
    <FormProvider {...formMethods}>
      <form
        className="relative"
        data-loc="manifest-connection-config-form"
        onSubmit={handleFormSubmit(onSubmit)}
      >
        <Modal.Content>
          {isPowerToolsEnabled && (
            <>
              <FormItem
                key="name"
                gap="sm"
                id="manifest-connection-form_name"
                isRequired={true}
                label="Connection Name"
              >
                {formErrors.name && (
                  <ErrorHint>Connection name is required</ErrorHint>
                )}
                <div className="flex w-full">
                  <div className="flex-1">
                    <Input
                      data-loc="manifest-connection-config-form-name"
                      errored={Boolean(formErrors.name)}
                      placeholder="Connection name"
                      type="text"
                      fullWidth
                      required
                      {...formMethods.register("name", {
                        required: true,
                      })}
                    />
                  </div>
                </div>
              </FormItem>
              {manifest.environment_configs.sandbox &&
                !isFeatureFlagEnabled(FEATURE_FLAGS.manifestEnvironments) && (
                  <FormItem
                    className="mb-4 flex items-center justify-between"
                    label="Is sandbox connection"
                  >
                    <Controller
                      control={formMethods.control}
                      defaultValue={false}
                      name="is_sandbox"
                      render={({ field: { value, onChange } }) => (
                        <Switch
                          enabled={value as boolean}
                          onChange={onChange}
                        />
                      )}
                    />
                  </FormItem>
                )}
            </>
          )}
          {!isFeatureFlagEnabled(FEATURE_FLAGS.manifestEnvironments) ? (
            // This is the unedited, pre-environments form
            <Form
              formPath="inputs.production"
              isConnectionUpdate={!!connectionToEdit}
              prefix="manifest-connection-config-form"
              schema={manifest.environment_configs.production.inputs}
            />
          ) : (
            <ManifestEnvironmentsConfig
              connectionToEdit={connectionToEdit}
              formValue={formValue}
              manifest={manifest}
              setFormValue={setFormValue}
            />
          )}
          {Object.entries(formValue.resource_configs).map(
            ([key, resource], i) => (
              <ResourceConfigForm
                key={key}
                index={i}
                manifest={manifest}
                removeDisabled={resourceRemoveDisabled}
                resource={resource}
                onRemove={handleRemoveResource}
                onSelectResource={onSelectResource}
              />
            ),
          )}
          <div>
            <Button
              dataLoc="resource-add-button"
              iconLeft={faPlus}
              size="sm"
              variant="secondary"
              onClick={handleAddResource}
            >
              Add resource
            </Button>
          </div>
          <hr className="mb-8 mt-6 h-px w-full bg-gray-100" />
          <DataRetentionFields
            fieldName="data_retention"
            workspace={workspace}
          />
        </Modal.Content>
        <Modal.Footer
          primaryButton={
            <Button
              dataLoc="manifest-connection-save"
              htmlType="submit"
              loading={formIsSubmitting}
              size="base"
              variant="primary"
            >
              Save
            </Button>
          }
          secondaryButton={
            <Button
              disabled={formIsSubmitting}
              variant="secondary"
              onClick={onCancelClick}
            >
              Cancel
            </Button>
          }
        />
      </form>
    </FormProvider>
  );
};
