import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faBuildings,
  faDownload,
  faEdit,
  faFlask,
} from "@fortawesome/pro-regular-svg-icons";
import { capitalize } from "lodash";
import React, { useEffect, useState } from "react";

import { useWorkspace } from "src/api/queries";
import { DecisionEnvironment } from "src/api/types";
import { Icon } from "src/base-components/Icon";
import { EllipsisOptionsDropdown } from "src/base-components/OptionsDropdown/EllipsisOptionsDropdown";
import {
  MENU_DIVIDER,
  OptionsDropdownElement,
} from "src/base-components/OptionsDropdown/OptionsDropdownItems";
import { EntitiesTable } from "src/entities/EntitiesTable";
import { useEntities } from "src/entities/queries";
import { useTabChange } from "src/entities/useTabChange";
import { SubHeader } from "src/flow/SubHeader";
import { PageHeader } from "src/layout/PageHeader";
import { EntitiesParams } from "src/router/urls";
import { useParamsDecode } from "src/utils/useParamsDecode";

type LabelProps = {
  icon: IconProp;
  label: string;
};

const Label: React.FC<LabelProps> = ({ icon, label }) => {
  return (
    <div className="flex flex-row items-center gap-x-1">
      <Icon color="text-gray-500" icon={icon} size="xs" />
      <p className="text-gray-800">{capitalize(label)}</p>
    </div>
  );
};

export const Entities = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const { wsId, orgId, schema } = useParamsDecode<EntitiesParams>();
  const [activeTab, setActiveTab] = useState<string>(schema ?? "");
  const workspace = useWorkspace(wsId);
  const baseUrl = workspace.data?.base_url ?? "";
  const handleTabChange = useTabChange({ orgId, wsId, setActiveTab });

  const { data } = useEntities({
    schema: "schemas",
    env: DecisionEnvironment.LIVE,
    baseUrl,
  });

  const handleSearch = (value: string) => {
    setSearchTerm(value);
  };

  useEffect(() => {
    if (!schema && data?.entities.length) {
      handleTabChange(data.entities[0]._plural_display_name);
    }
  }, [data?.entities, handleTabChange, schema]);

  const tabs =
    data?.entities.map((entity) => ({
      label: <Label icon={faBuildings} label={entity._plural_display_name} />,
      key: entity._plural_display_name,
      content: (
        <EntitiesTable baseUrl={baseUrl} schema={entity._plural_display_name} />
      ),
    })) ?? [];

  //harcoded value to test tab-changing, to be removed later
  tabs.push({
    label: <Label icon={faBuildings} label="Companies" />,
    key: "companies",
    content: <EntitiesTable baseUrl={baseUrl} schema="companies" />,
  });

  const dropdownItems: OptionsDropdownElement[] = [
    {
      key: "Edit Schema",
      icon: faEdit,
      action: () => {},
    },
    {
      key: "Download Entities",
      icon: faDownload,
      action: () => {},
    },
    MENU_DIVIDER,
    { key: "Switch to Sandbox", icon: faFlask, action: () => {} },
  ] as const;

  return (
    <div>
      <PageHeader title="Entities" />
      <div className="relative">
        <SubHeader.Tabs
          activeTab={activeTab}
          tabs={tabs}
          onChange={handleTabChange}
        >
          <SubHeader.SearchBox
            defaultValue={searchTerm}
            placeholder="Search a user or a property..."
            onChange={handleSearch}
          />
          <span className="border-l border-gray-200 p-3.5">
            <EllipsisOptionsDropdown
              elements={dropdownItems}
              iconSize="sm"
              placement="bottom-start"
              renderMenuInPlace
            />
          </span>
        </SubHeader.Tabs>
      </div>
    </div>
  );
};
