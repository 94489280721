import { uniqBy } from "lodash";

import {
  DecisionHistoryRecordV2,
  DecisionHistoryRecordV3,
} from "src/api/decisionHistoryV2/decisionHistoryQueries";
import { FlowVersionT, NodeBET, PropertyTypeUIT } from "src/api/flowTypes";
import { EdgeDb } from "src/clients/flow-api";
import { buildUiSchemas } from "src/datasets/utils";
import {
  inputFieldsPrefix,
  outcomeFieldsPrefix,
  outputFieldsPrefix,
} from "src/decisionsOverview/useViewOptions";
import { OutcomeType } from "src/outcomes/types";
import { SchemaOptions } from "src/router/SearchParams";
import { PropertyUIT, SchemaConverter } from "src/schema/utils";

type ColumnItem = {
  label: string;
  value: string;
  type: PropertyTypeUIT[0];
};

export type OutcomeItem = ColumnItem & {
  outcomeName: string;
  outcomeKey: string;
  filterable: boolean;
  enumValues: string[] | undefined;
};

export type OutcomeOptionWithType = {
  outcomeName: string;
  outcomeKey: string;
  propertyName: string;
  propertyKey: string;
  filterable: boolean;
  isOutcomeType: boolean;
};

const invalidEntityIds = [
  "None",
  "null",
  "none",
  "undefined",
  "string",
] as readonly string[];

const invalidEntityIdsStr = invalidEntityIds.map((id) => `${id}$`).join("|");
export const invalidEntityIdsPattern = new RegExp(
  `^(?!${invalidEntityIdsStr}).*$`,
  "i",
);

export const isValidEntityId = (entityId?: string): boolean =>
  !!entityId && entityId.length > 0 && !invalidEntityIds.includes(entityId);

export const findPendingNode = (
  decision: DecisionHistoryRecordV2,
  nodes: NodeBET[],
  edges: EdgeDb[],
) => {
  return nodes.find((node: NodeBET) => {
    const nodeHasResults = !!decision.node_results.nodes[node.id];
    const outgoingEdges = edges.filter(
      (edge) => edge.start_node_id === node.id,
    );
    const followingNodesExistAndHaveNoResults =
      outgoingEdges.length > 0 &&
      outgoingEdges.every(
        (outgoingEdge) =>
          !decision.node_results.nodes[outgoingEdge.end_node_id],
      );

    return nodeHasResults && followingNodesExistAndHaveNoResults;
  });
};

export const hasOutputSchemaError = (
  decision: DecisionHistoryRecordV2 | DecisionHistoryRecordV3,
) => {
  return "error" in decision
    ? decision.error?.status_code === 409
    : decision.status_code === "409";
};

export const hasInputSchemaError = (
  decision: DecisionHistoryRecordV2 | DecisionHistoryRecordV3,
) => {
  return "error" in decision
    ? decision.error?.status_code === 422
    : decision.status_code === "422";
};

export const transformSchemaToOptions = (flowVersions: FlowVersionT[]) => {
  const { inputFields, outputFields } = flowVersions.reduce(
    (acc, fv) => {
      const { input, output } = buildUiSchemas(fv);
      return {
        inputFields: [...acc.inputFields, ...(input?.properties ?? [])],
        outputFields: [...acc.outputFields, ...(output?.properties ?? [])],
      };
    },
    { inputFields: [] as PropertyUIT[], outputFields: [] as PropertyUIT[] },
  );

  const [uniqInputFields, uniqOutputFields] = [
    uniqBy(inputFields, "fieldName"),
    uniqBy(outputFields, "fieldName"),
  ];

  const mapFn =
    (prefix: string) =>
    (field: PropertyUIT): ColumnItem => ({
      label: field.fieldName,
      value: [prefix, field.fieldName].join(""),
      type: field.type[0],
    });
  return {
    input: uniqInputFields.map(mapFn(inputFieldsPrefix)),
    output: uniqOutputFields.map(mapFn(outputFieldsPrefix)),
  };
};

export const transformOutcomeTypesToOptions = (
  outcomeTypes: OutcomeType[],
): OutcomeItem[] => {
  return (outcomeTypes ?? []).flatMap((outcome) => {
    const payloadSchema = SchemaConverter.beToUI(
      outcome.payload_schema,
      SchemaOptions.Output,
    );

    return payloadSchema.properties.map((property) => ({
      label: property.fieldName,
      value: `${outcomeFieldsPrefix}${outcome.key}.${property.fieldName}`,
      type: property.type[0],
      outcomeKey: `${outcomeFieldsPrefix}${outcome.key}`,
      outcomeName: outcome.name,
      filterable:
        outcome.payload_schema.properties[property.fieldName].filterable ??
        false,
      enumValues: property.enum?.map((enumValue) => enumValue.value),
    }));
  });
};

export const transformOutcomesToOptionsWithType = (
  outcomeTypes: OutcomeType[],
  onlyFilterable?: boolean,
): OutcomeOptionWithType[] => {
  return outcomeTypes.flatMap((outcome) => {
    const payloadSchema = SchemaConverter.beToUI(
      outcome.payload_schema,
      SchemaOptions.Output,
    );
    return [
      {
        outcomeKey: outcome.key,
        outcomeName: outcome.name,
        propertyName: "",
        propertyKey: "",
        filterable: false,
        isOutcomeType: true,
      },
      ...payloadSchema.properties
        .map((property) => ({
          outcomeKey: outcome.key,
          outcomeName: outcome.name,
          propertyName: property.fieldName,
          propertyKey: `${outcome.key}.${property.fieldName}`,
          filterable:
            outcome.payload_schema.properties[property.fieldName].filterable ??
            false,
          isOutcomeType: false,
        }))
        .filter(onlyFilterable ? (option) => option.filterable : () => true),
    ];
  });
};

export const getDisplayedId = (decisionId: string): string => {
  const lastGroup = decisionId.split("-").pop();
  return `...-${lastGroup}`;
};
