import { PostgreSQL, sql } from "@codemirror/lang-sql";
import { highlightTree } from "@lezer/highlight";
import { memo } from "react";

import { SQLSyntaxHighlighting } from "src/base-components/CodeInput/Themes";

export const highlightSql = (sqlString: string) => {
  const parser = sql({
    dialect: PostgreSQL,
  }).language.parser;
  const tree = parser.parse(sqlString);

  let highlighted: { key: string; content: string; className?: string }[] = [];
  let pos = 0;

  highlightTree(tree, SQLSyntaxHighlighting, (from, to, className) => {
    if (from > pos) {
      highlighted.push({
        key: `${pos}-${from}`,
        content: sqlString.slice(pos, from),
      });
    }

    const key = `${from}-${to}-${className}`;
    if (className) {
      highlighted.push({ key, content: sqlString.slice(from, to), className });
    } else {
      highlighted.push({ key, content: sqlString.slice(from, to) });
    }

    pos = to;
  });

  highlighted.push({
    key: `${pos}-${sqlString.length}`,
    content: sqlString.slice(pos),
  });

  return highlighted;
};

export const HighlightedSql: React.FC<{ sqlString: string }> = memo(
  ({ sqlString }) => (
    <div className="inline truncate font-code-13">
      {highlightSql(sqlString).map(({ key, content, className }) => (
        <span key={key} className={className}>
          {content}
        </span>
      ))}
    </div>
  ),
);
