import { faDiagramNested } from "@fortawesome/pro-regular-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import React from "react";
import { useNavigate } from "react-router-dom";

import { useWorkspaceUsers } from "src/api/taktile/queries";
import { ReviewCase } from "src/api/types";
import { DataValue } from "src/base-components/DataList";
import { Pill } from "src/base-components/Pill";
import { Cell, TableComp } from "src/base-components/Table";
import { LinkedEntityPill } from "src/entities/entityView/LinkedEntityPill";
import { ShortDateFromNow } from "src/flowReview/ReviewLogEntry";
import { ReviewCaseStatus } from "src/manualReview/ReviewCaseStatus";
import { UserPickerCell } from "src/manualReview/UserPicker";
import { useWorkspaceContext } from "src/router/routerContextHooks";
import { EntitiesParams, getUrlToAuthoringPage } from "src/router/urls";
import { useParamsDecode } from "src/utils/useParamsDecode";

type TableRow = {
  displayName: string;
  renderValue: React.ReactNode;
};

type Props = {
  flowSlug: string;
  reviewCase: ReviewCase | undefined;
  isLoading: boolean;
};

const columnHelper = createColumnHelper<TableRow>();

const columns = [
  columnHelper.accessor("displayName", {
    header: () => null,
    cell: (info) => <Cell info={info}>{info.getValue()}</Cell>,
  }),
  columnHelper.accessor("renderValue", {
    header: () => null,
    cell: (info) => <Cell info={info}>{info.getValue()}</Cell>,
  }),
];

export const ReviewCaseDetails: React.FC<Props> = ({
  flowSlug,
  reviewCase,
  isLoading: caseIsLoading,
}) => {
  const { workspace } = useWorkspaceContext();
  const { orgId } = useParamsDecode<EntitiesParams>();

  const navigate = useNavigate();
  const users = useWorkspaceUsers(orgId, workspace.id, {
    include_deactivated: false,
  });

  const tableData = reviewCase
    ? [
        {
          displayName: "Request time",
          renderValue: (
            <DataValue field="created_at" value={reviewCase.created_at} />
          ),
        },
        {
          displayName: "Status",
          renderValue: (
            <Pill size="sm">
              <ReviewCaseStatus status={reviewCase.status} />
            </Pill>
          ),
        },
        {
          displayName: "Needs review since",
          renderValue: <ShortDateFromNow date={reviewCase.created_at} />,
        },
        {
          displayName: "Assigned to",
          renderValue: (
            <UserPickerCell
              caseId={reviewCase.id}
              etag={reviewCase.etag}
              flowSlug={flowSlug}
              users={users.data ?? []}
              value={reviewCase.assignee}
            />
          ),
        },
        {
          displayName: "Decision Flow",
          renderValue: (
            <LinkedEntityPill
              entityIcon={faDiagramNested}
              name={reviewCase.flow.slug}
              onClick={() => {
                navigate(
                  getUrlToAuthoringPage(
                    orgId,
                    workspace.id,
                    reviewCase.flow.id,
                    reviewCase.flow.version_id,
                  ),
                );
              }}
            />
          ),
        },
        {
          displayName: "Version",
          renderValue: (
            <Pill size="sm" variant="gray">
              {reviewCase.flow.version_name}
            </Pill>
          ),
        },
        {
          displayName: "Node",
          renderValue: <p>{reviewCase.node_name}</p>,
        },
      ]
    : [];

  return (
    <TableComp
      columns={columns}
      data={tableData}
      frameClassName="pb-6"
      isLoading={caseIsLoading || users.isLoading}
      loadingRowsCount={7}
      variant="compact"
    />
  );
};
