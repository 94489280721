/* tslint:disable */
/* eslint-disable */
/**
 * Entities
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface EntityResource
 */
export interface EntityResource {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof EntityResource
     */
    '_id': string;
    /**
     * 
     * @type {string}
     * @memberof EntityResource
     */
    '_schema': string;
    /**
     * 
     * @type {number}
     * @memberof EntityResource
     */
    '_etag': number;
    /**
     * 
     * @type {object}
     * @memberof EntityResource
     */
    '_related': object;
    /**
     * 
     * @type {string}
     * @memberof EntityResource
     */
    '_updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof EntityResource
     */
    '_singular_display_name': string;
    /**
     * 
     * @type {string}
     * @memberof EntityResource
     */
    '_plural_display_name': string;
    /**
     * 
     * @type {string}
     * @memberof EntityResource
     */
    '_display_symbol': string;
    /**
     * 
     * @type {EntityResourceFirstNameLastName}
     * @memberof EntityResource
     */
    'first_name_last_name'?: EntityResourceFirstNameLastName;
}
/**
 * @type EntityResourceFirstNameLastName
 * @export
 */
export type EntityResourceFirstNameLastName = SchemaFieldDefinition | string;

/**
 * 
 * @export
 * @interface EntityResourcePage
 */
export interface EntityResourcePage {
    /**
     * 
     * @type {Array<EntityResource>}
     * @memberof EntityResourcePage
     */
    'entities': Array<EntityResource>;
    /**
     * 
     * @type {string}
     * @memberof EntityResourcePage
     */
    'next_page_token'?: string | null;
}
/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface SchemaFieldDefinition
 */
export interface SchemaFieldDefinition {
    /**
     * 
     * @type {string}
     * @memberof SchemaFieldDefinition
     */
    '_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof SchemaFieldDefinition
     */
    '_display_name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SchemaFieldDefinition
     */
    '_indexed'?: boolean;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<ValidationErrorLocInner>}
     * @memberof ValidationError
     */
    'loc': Array<ValidationErrorLocInner>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface ValidationErrorLocInner
 */
export interface ValidationErrorLocInner {
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Entities By Schema
         * @param {EntitiesBySchemaV1EnvSchemaGetEnvEnum} env 
         * @param {string} schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entitiesBySchemaV1EnvSchemaGet: async (env: EntitiesBySchemaV1EnvSchemaGetEnvEnum, schema: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'env' is not null or undefined
            assertParamExists('entitiesBySchemaV1EnvSchemaGet', 'env', env)
            // verify required parameter 'schema' is not null or undefined
            assertParamExists('entitiesBySchemaV1EnvSchemaGet', 'schema', schema)
            const localVarPath = `/v1/entities/{env}/{schema}`
                .replace(`{${"env"}}`, encodeURIComponent(String(env)))
                .replace(`{${"schema"}}`, encodeURIComponent(String(schema)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Entities By Schema
         * @param {EntitiesBySchemaV1EnvSchemaGetEnvEnum} env 
         * @param {string} schema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async entitiesBySchemaV1EnvSchemaGet(env: EntitiesBySchemaV1EnvSchemaGetEnvEnum, schema: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityResourcePage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.entitiesBySchemaV1EnvSchemaGet(env, schema, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.entitiesBySchemaV1EnvSchemaGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Entities By Schema
         * @param {DefaultApiEntitiesBySchemaV1EnvSchemaGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entitiesBySchemaV1EnvSchemaGet(requestParameters: DefaultApiEntitiesBySchemaV1EnvSchemaGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<EntityResourcePage> {
            return localVarFp.entitiesBySchemaV1EnvSchemaGet(requestParameters.env, requestParameters.schema, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for entitiesBySchemaV1EnvSchemaGet operation in DefaultApi.
 * @export
 * @interface DefaultApiEntitiesBySchemaV1EnvSchemaGetRequest
 */
export interface DefaultApiEntitiesBySchemaV1EnvSchemaGetRequest {
    /**
     * 
     * @type {'sandbox' | 'live'}
     * @memberof DefaultApiEntitiesBySchemaV1EnvSchemaGet
     */
    readonly env: EntitiesBySchemaV1EnvSchemaGetEnvEnum

    /**
     * 
     * @type {string}
     * @memberof DefaultApiEntitiesBySchemaV1EnvSchemaGet
     */
    readonly schema: string
}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Get Entities By Schema
     * @param {DefaultApiEntitiesBySchemaV1EnvSchemaGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public entitiesBySchemaV1EnvSchemaGet(requestParameters: DefaultApiEntitiesBySchemaV1EnvSchemaGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).entitiesBySchemaV1EnvSchemaGet(requestParameters.env, requestParameters.schema, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
  * @export
  * @enum {string}
  */
export enum EntitiesBySchemaV1EnvSchemaGetEnvEnum {
    SANDBOX = 'sandbox',
    LIVE = 'live'
}


