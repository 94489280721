import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import { isMacOs } from "react-device-detect";

import { Icon } from "src/base-components/Icon";
import { Pill } from "src/base-components/Pill";

type ClickableSearchBoxProps = {
  onClick: () => void;
  text: string;
  dataLoc?: string;
};

export const ClickableSearchBox: React.FC<ClickableSearchBoxProps> = ({
  dataLoc,
  onClick,
  text,
}) => (
  <div
    className="flex h-8 w-full cursor-pointer items-center rounded-lg border border-gray-200 bg-white px-2 py-1"
    onClick={onClick}
  >
    <div className="mr-1">
      <Icon color="text-gray-500" icon={faSearch} size="xs" />
    </div>
    <div
      className="flex w-full items-center justify-between bg-white font-inter-normal-12px focus:outline-none"
      data-loc={dataLoc}
    >
      <p className="text-gray-500">{text}</p>
      <Pill size="sm" variant="gray">
        <Pill.Text fontType="text">{isMacOs ? "⌘" : "Ctrl"} K</Pill.Text>
      </Pill>
    </div>
  </div>
);
