import {
  faAdd,
  faCircleNotch,
  faExternalLink,
  faPercentage,
  faTrashAlt,
} from "@fortawesome/pro-regular-svg-icons";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { m, AnimatePresence } from "framer-motion";
import { cloneDeep } from "lodash";
import React, { useEffect, useImperativeHandle, useRef } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { twJoin } from "tailwind-merge";

import { FlowVersionStatusT, FlowVersionT } from "src/api/flowTypes";
import { useCreateTrafficPolicy } from "src/api/queries";
import { Button } from "src/base-components/Button";
import { Icon } from "src/base-components/Icon";
import { Pill } from "src/base-components/Pill";
import { Select } from "src/base-components/Select";
import { ProbabilisticPolicy } from "src/clients/flow-api";
import { Callout } from "src/design-system/Callout";
import { Modal } from "src/design-system/Modal";

export type Props = {
  open: boolean;
  onClose: () => void;
  publishedVersions: FlowVersionT[];
  currentTrafficPolicy: ProbabilisticPolicy;
  selectedVersion?: FlowVersionT;
  flowId: string;
  afterLeave: () => void;
};

const getInitialTrafficPolicy = (
  currentTrafficPolicy: ProbabilisticPolicy,
  selectedVersion: FlowVersionT | undefined,
) => {
  // Don't modify react query's copy
  const initialTrafficPolicy = cloneDeep(currentTrafficPolicy);

  const addSelectedVersionToTrafficPolicy =
    selectedVersion?.status === FlowVersionStatusT.PUBLISHED &&
    !initialTrafficPolicy.flow_versions.find(
      (v) => v.flow_version_id === selectedVersion.id,
    );

  if (addSelectedVersionToTrafficPolicy) {
    initialTrafficPolicy.flow_versions.push({
      flow_version_id: selectedVersion.id,
      percentage: 0,
    });
  }

  return initialTrafficPolicy;
};

export const ManageTrafficModal: React.FC<Props> = ({
  open,
  onClose,
  publishedVersions,
  currentTrafficPolicy,
  selectedVersion,
  flowId,
  afterLeave,
}) => {
  const {
    control,
    handleSubmit,
    watch,
    reset: resetForm,
    formState,
    setFocus,
  } = useForm<ProbabilisticPolicy>({
    defaultValues: getInitialTrafficPolicy(
      currentTrafficPolicy,
      selectedVersion,
    ),
    reValidateMode: "onSubmit",
    resolver: (submittedPolicy) => {
      if (submittedPolicy.flow_versions.length === 0) {
        // Reset isDirty state so we disable
        // the submit button until the user
        // does anything
        resetForm({}, { keepValues: true });
        return {
          errors: {
            flow_versions: {
              message:
                "There should be at least one version to route traffic towards",
            },
          },
          values: {},
        };
      }
      const percentageTotal = submittedPolicy.flow_versions.reduce(
        (prev, version) => prev + version.percentage,
        0,
      );
      if (percentageTotal !== 100) {
        resetForm({}, { keepValues: true });
        return {
          errors: {
            flow_versions: {
              message: "Traffic percentages should add up to 100%",
            },
          },
          values: {},
        };
      } else {
        return { values: submittedPolicy, errors: {} };
      }
    },
  });
  const {
    fields: flowVersionFields,
    append: appendVersion,
    remove: removeVersion,
  } = useFieldArray({
    control,
    name: "flow_versions",
  });

  useEffect(() => {
    if (open) {
      const initialTrafficPolicy = getInitialTrafficPolicy(
        currentTrafficPolicy,
        selectedVersion,
      );
      resetForm(initialTrafficPolicy);
      const lastVersionIndex = initialTrafficPolicy.flow_versions.length - 1;
      setTimeout(() => {
        setFocus(`flow_versions.${lastVersionIndex}.percentage`);
      }, 0);
    }
    // Avoid changing the form while the modal fades away
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const currentVersionsIds = watch("flow_versions").map(
    (version) => version.flow_version_id,
  );

  const publishedVersionsWithoutTraffic = publishedVersions.filter(
    (version) => !currentVersionsIds.includes(version.id),
  );
  const onAddVersion = () => {
    const versionToAppend = publishedVersionsWithoutTraffic.at(0);

    if (versionToAppend) {
      const versionToAppendIndex = currentVersionsIds.length;
      appendVersion(
        { flow_version_id: versionToAppend.id, percentage: 0 },
        {
          focusName: `flow_versions.${versionToAppendIndex}.percentage`,
          shouldFocus: true,
        },
      );
    }
  };

  const createTrafficPolicy = useCreateTrafficPolicy();

  const onSubmit = async (submittedPolicy: ProbabilisticPolicy) => {
    await createTrafficPolicy.mutateAsync({
      createPayload: {
        flow_id: flowId,
        policy: submittedPolicy,
      },
      activate: true,
    });
    onClose();
  };

  const thereIsOnlyOneVersion = currentVersionsIds.length === 1;

  return (
    <Modal afterLeave={afterLeave} open={open} onClose={onClose}>
      <Modal.Header description="Route production traffic to different versions of your decision logic.">
        Manage traffic
      </Modal.Header>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Modal.Content>
          {formState.errors.flow_versions ? (
            <Callout type="error">
              {formState.errors.flow_versions.message}
            </Callout>
          ) : (
            <Callout type="info">
              Routing only applies when no version is specified in the API
              request
              <a
                href="https://docs.taktile.com/versioning-and-deployment/traffic-routing"
                rel="noopener noreferrer"
                target="_blank"
              >
                <Icon icon={faExternalLink} size="3xs" />
              </a>
            </Callout>
          )}
          <div className="decideScrollbar mt-6 h-80 pl-0.5 pt-0.5">
            <div className="space-y-2">
              <AnimatePresence initial={false}>
                {flowVersionFields.map((flowVersionField, i) => (
                  <m.div
                    key={flowVersionField.id}
                    animate={{
                      opacity: 1,
                      transition: {
                        type: "tween",
                        ease: "easeOut",
                        delay: 0.15,
                      },
                    }}
                    className="flex items-center"
                    initial={{ opacity: 0 }}
                    transition={{ type: "tween", ease: "easeOut" }}
                    layout
                  >
                    <Controller
                      control={control}
                      name={`flow_versions.${i}.flow_version_id`}
                      render={({ field: { onChange, value } }) => (
                        <FlowVersionSelector
                          publishedVersionsWithoutTraffic={
                            publishedVersionsWithoutTraffic
                          }
                          selectedVersion={
                            publishedVersions.find(
                              (version) => version.id === value,
                            )!
                          }
                          onChange={onChange}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name={`flow_versions.${i}.percentage`}
                      render={({ field: { onChange, value, ref } }) => (
                        <PercentageInput
                          ref={ref}
                          value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                    <div
                      className={twJoin(
                        "ml-4",
                        thereIsOnlyOneVersion && "invisible",
                      )}
                    >
                      <Icon
                        color="text-gray-500 hover:text-gray-600 active:text-gray-700"
                        icon={faTrashAlt}
                        size="xs"
                        onClick={() => removeVersion(i)}
                      />
                    </div>
                  </m.div>
                ))}
              </AnimatePresence>
            </div>
            <m.div
              className="mt-2"
              transition={{ type: "tween", ease: "easeOut" }}
              layout
            >
              <Button
                dataLoc="policy-add-version-button"
                disabled={publishedVersionsWithoutTraffic.length === 0}
                iconLeft={faAdd}
                variant="secondary"
                onClick={onAddVersion}
              >
                Add version
              </Button>
            </m.div>
          </div>
        </Modal.Content>
        <Modal.Footer
          primaryButton={
            <Button
              dataLoc="policy-save-button"
              disabled={formState.errors.flow_versions && !formState.isDirty}
              htmlType="submit"
              variant="primary"
              fullWidth
            >
              {formState.isSubmitting ? (
                <FontAwesomeIcon icon={faCircleNotch} width={12} spin />
              ) : (
                "Save"
              )}
            </Button>
          }
        />
      </form>
    </Modal>
  );
};

const PercentageInput = React.forwardRef<
  HTMLInputElement,
  {
    value: number;
    onChange: (value: number) => void;
  }
>(({ value, onChange }, ref) => {
  const innerRef = useRef<HTMLInputElement>(null);
  useImperativeHandle(ref, () => innerRef.current!);
  return (
    <div className="relative w-max">
      <div
        className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-500 font-inter-normal-13px"
        onClick={() => {
          innerRef.current?.focus();
        }}
      >
        <Icon cursorType="text" icon={faPercentage} size="xs" />
      </div>
      <input
        ref={innerRef}
        className="h-8 w-[90px] rounded-lg border border-gray-300 pl-4 pr-8 text-gray-800 outline-1 -outline-offset-1 outline-indigo-500 font-inter-normal-13px [appearance:textfield] focus:outline [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
        data-loc="percentage-input"
        max="100"
        min="0"
        type="number"
        value={value}
        onChange={(e) => onChange(e.target.valueAsNumber)}
      />
    </div>
  );
});

const FlowVersionSelector: React.FC<{
  selectedVersion: FlowVersionT;
  onChange: (id: string) => void;
  publishedVersionsWithoutTraffic: FlowVersionT[];
}> = ({ selectedVersion, onChange, publishedVersionsWithoutTraffic }) => {
  const versionNameToOption = (versionName?: string, isSelected = false) => {
    return (
      <div
        className={twJoin(
          "flex min-w-0 flex-1 items-center justify-between",
          isSelected ? "mr-3" : "mr-8",
        )}
      >
        <div className="mr-3 min-w-0 flex-1 truncate">{versionName}</div>
        <div className="h-5.5 flex-shrink-0 -translate-y-px">
          <Pill variant="green">
            <Pill.Icon icon={faCircle} size="xs" />
            <Pill.Text>Published</Pill.Text>
          </Pill>
        </div>
      </div>
    );
  };
  const options = publishedVersionsWithoutTraffic
    .map((v) => ({ key: v.id, value: versionNameToOption(v.name) }))
    .concat([
      {
        key: selectedVersion.id,
        value: versionNameToOption(selectedVersion.name, true),
      },
    ]);
  return (
    <div className="relative mr-4 min-w-0 flex-1">
      <Select
        options={options}
        value={selectedVersion.id}
        listMatchesButtonWidth
        onChange={onChange}
      />
    </div>
  );
};
