import {
  useMotionValue,
  animate as motionAnimate,
  useTransform,
} from "framer-motion";
import { useEffect, useState } from "react";

import { TextEditor } from "src/base-components/TextEditor/TextEditor";
import { normalizeAnimationSpeed } from "src/copilot/utils";

type TextContentProps = {
  content: string;
  delay: number;
  animate?: boolean;
};

export const CopilotTextContent: React.FC<TextContentProps> = ({
  content,
  delay = 0,
  animate = false,
}) => {
  const [displayTextValue, setDisplayTextValue] = useState("");
  const count = useMotionValue(0);
  useEffect(() => {
    if (animate) {
      const duration = normalizeAnimationSpeed(content.length);
      const controls = motionAnimate(count, content.length, {
        duration,
        delay,
        ease: "linear",
      });
      return controls.stop;
    }
  }, [content.length, count, delay, animate]);

  const rounded = useTransform(count, (latest) => Math.round(latest));
  const displayText = useTransform(rounded, (latest) =>
    content.slice(0, latest),
  );

  useEffect(() => {
    displayText.on("change", (value) => {
      setDisplayTextValue(value);
    });

    return () => displayText.destroy();
  }, [displayText]);

  return (
    <TextEditor value={animate ? displayTextValue : content} inline readOnly />
  );
};
