import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faArchive,
  faBallotCheck,
  faCheckCircle,
  faClockRotateLeft,
  faCopy,
  faEdit,
  faEye,
  faMessageLines,
  faPlus,
  faSearch,
  faTrashCan,
} from "@fortawesome/pro-regular-svg-icons";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { isMacOs } from "react-device-detect";
import { twJoin } from "tailwind-merge";

import { FlowT, FlowVersionT } from "src/api/flowTypes";
import { Icon } from "src/base-components/Icon";
import { EllipsisOptionsDropdown } from "src/base-components/OptionsDropdown/EllipsisOptionsDropdown";
import {
  MENU_DIVIDER,
  OptionsDropdownElement,
} from "src/base-components/OptionsDropdown/OptionsDropdownItems";
import { Pill } from "src/base-components/Pill";
import {
  etag_including_comments,
  useLoadChangeHistory,
} from "src/changeHistory/queries";
import {
  useLatestSeenVersionEtag,
  useLatestSeenReviewEtag,
} from "src/changeHistory/useLatestSeenVersionEtag";
import { FlowVersionStatus } from "src/clients/flow-api";
import { Tooltip } from "src/design-system/Tooltip";
import { FlowVersionControlModals } from "src/flow/FlowVersionControlModals";
import { getExportToJSONOption } from "src/flow/getExportToJSON";
import { useFlowVersionModals } from "src/flow/useFlowVersionModals";
import { usePublishFlowVersionCallback } from "src/flow/usePublishFlowVersionCallback";
import { RunFlowButtonWrapper } from "src/flowContainer/RunFlowButton/RunFlowButtonWrapper";
import { RightPanes, useRightPane } from "src/flowContainer/hooks/useRightPane";
import {
  canAddReview,
  canRequestReview,
  publishingBlockedReason,
} from "src/flowContainer/versionActionConditions";
import { useFlowVersionReview } from "src/flowReview/api/queries";
import { useCapabilities } from "src/hooks/useCapabilities";
import { useOmniboxActions } from "src/omnibox/OmniboxStore";
import { useAuthoringContext } from "src/router/routerContextHooks";
import { useCurrentUserId } from "src/store/AuthStore";

type ExcludeFalse<T> = Exclude<T, false>;
type HeaderIconProps = {
  icon: IconProp;
  onClick: () => void;
  showNotification?: boolean;
  dataLoc?: string;
  isActive: boolean;
  tooltipTitle: React.ReactNode;
  tooltipBody?: string;
};

const HeaderIcon: React.FC<HeaderIconProps> = ({
  icon,
  onClick,
  showNotification,
  dataLoc,
  isActive,
  tooltipTitle,
  tooltipBody,
}) => {
  return (
    <div className="relative items-center">
      <Tooltip
        body={tooltipBody}
        placement="bottom-end"
        title={tooltipTitle}
        asChild
      >
        <Icon
          color={twJoin(
            isActive ? "text-indigo-600" : "text-gray-500 hover:text-gray-700",
          )}
          dataLoc={dataLoc}
          icon={icon}
          size="xs"
          onClick={onClick}
        />
      </Tooltip>
      {showNotification && (
        <div className="border-1 absolute right-0.5 top-[3px] h-1.5 w-1.5 rounded-full border border-white bg-indigo-600" />
      )}
    </div>
  );
};

const VersionOptionsDropdown: React.FC<{
  flow: FlowT;
  flowVersion: FlowVersionT;
}> = ({ flow, flowVersion }) => {
  const { setRightPane } = useRightPane();
  const {
    currentModal,
    buildOpenModalAction,
    onClose,
    onAfterLeave,
    selectedVersion,
  } = useFlowVersionModals();
  const { flowVersions: flowVersionsCapabilities } = useCapabilities();
  const { data: review } = useFlowVersionReview(flowVersion.id);
  const handlePublish = usePublishFlowVersionCallback({
    flowId: flow.id,
    buildOpenModalAction,
  });
  const exportToJSONOption = getExportToJSONOption(flow.name, flowVersion);

  const optionElements: OptionsDropdownElement[] = useMemo(() => {
    const { canEdit, canCreate, canDeleteDraft, canArchive } =
      flowVersionsCapabilities;
    const isDraft = flowVersion.status === FlowVersionStatus.DRAFT;
    const isPublished = flowVersion.status === FlowVersionStatus.PUBLISHED;
    const isArchived = flowVersion.status === FlowVersionStatus.ARCHIVED;
    const canPublish = flowVersionsCapabilities.canPublish(flow);

    const secondGroup = [
      canRequestReview(flow, flowVersion.status, review) && {
        key: "Request review",
        icon: faEye,
        action: buildOpenModalAction("review_version", flowVersion),
      },
      canAddReview(review, flowVersion.status) && {
        key: "Add review",
        icon: faPlus,
        action: () =>
          setRightPane(RightPanes.ReviewProcess, {
            focusAddReview: true,
          }),
      },
      canPublish &&
        isDraft && {
          key: "Publish",
          icon: faCheckCircle,
          action: () => handlePublish(flowVersion),
          disabled: publishingBlockedReason(flow, review),
        },
      canPublish &&
        isArchived && {
          key: "Republish",
          icon: faCheckCircle,
          action: buildOpenModalAction("republish_version", flowVersion),
        },
    ].filter((x): x is ExcludeFalse<typeof x> => Boolean(x));

    const thirdGroup = [
      canArchive &&
        isPublished && {
          key: "Archive",
          icon: faArchive,
          action: buildOpenModalAction("archive_version", flowVersion),
        },
      canDeleteDraft &&
        isDraft && {
          key: "Delete",
          icon: faTrashCan,
          action: buildOpenModalAction("delete_version", flowVersion),
        },
    ].filter((x): x is ExcludeFalse<typeof x> => Boolean(x));

    return [
      canCreate && {
        key: "Duplicate",
        icon: faCopy,
        action: buildOpenModalAction("add_version", flowVersion),
      },
      exportToJSONOption,
      canEdit &&
        !isPublished && {
          key: "Edit version details",
          icon: faEdit,
          action: buildOpenModalAction("edit_version", flowVersion),
        },
      (canEdit || canCreate) && MENU_DIVIDER,
      ...secondGroup,
      secondGroup.length > 0 && thirdGroup.length > 0 && MENU_DIVIDER,
      ...thirdGroup,
    ].filter((x): x is ExcludeFalse<typeof x> => Boolean(x));
  }, [
    flowVersionsCapabilities,
    flowVersion,
    flow,
    review,
    handlePublish,
    buildOpenModalAction,
    setRightPane,
  ]);

  return (
    <>
      <EllipsisOptionsDropdown
        buttonDataLoc="flow-header-version-options"
        elements={optionElements}
      />
      <FlowVersionControlModals
        afterLeave={onAfterLeave}
        flow={flow}
        modal={currentModal}
        version={selectedVersion}
        onClose={onClose}
      />
    </>
  );
};

export const FlowHeaderButtons: React.FC = observer(() => {
  const { rightPane, setRightPane } = useRightPane();
  const { flowVersions } = useCapabilities();
  const { showOmnibox } = useOmniboxActions();

  const { flow, version } = useAuthoringContext();
  const changeHistory = useLoadChangeHistory(
    version.id,
    /* To ensure we query include comments against current flow_version,
we issue a before_etag with higher extended etag */
    etag_including_comments(version.etag),
    {
      includeLogic: true,
      includeReviewComments: true,
    },
  );
  const userId = useCurrentUserId();
  const { data: flowVersionReview } = useFlowVersionReview(version.id);

  const [latestSeenEtag] = useLatestSeenVersionEtag({
    userId: userId!!,
    versionId: version.id,
  });

  const [latestSeenReviewEtag] = useLatestSeenReviewEtag({
    userId: userId!!,
    versionId: version.id,
  });

  const unseenRevisions: boolean =
    changeHistory.data?.pages
      ?.flatMap((page) => page)
      .some(
        (changeLog) =>
          changeLog.diff &&
          changeLog.etag > latestSeenEtag &&
          changeLog.authored_by !== userId,
      ) || false;

  const unseenReview: boolean =
    changeHistory.data?.pages
      ?.flatMap((page) => page)
      .some((changeLog) => {
        // change logic or comments should be considered a review change
        return (
          changeLog.etag > latestSeenReviewEtag &&
          changeLog.authored_by !== userId
        );
      }) || false;

  const handleTogglePane = (pane: RightPanes) => {
    setRightPane(rightPane === pane ? null : pane);
  };

  return (
    <div className="flex flex-row items-center">
      <div className="mr-2 flex flex-row items-center gap-x-2">
        <div className="px-1">
          <HeaderIcon
            dataLoc="flow-header-omnibox"
            icon={faSearch}
            isActive={false}
            tooltipTitle={
              <>
                <span className="mr-2">Search</span>
                <Pill size="sm" variant="dark-gray">
                  <Pill.Text fontType="text">
                    {isMacOs ? "⌘" : "Ctrl"} K
                  </Pill.Text>
                </Pill>
              </>
            }
            onClick={showOmnibox}
          />
        </div>
        <div className="px-1">
          <HeaderIcon
            dataLoc="flow-header-comments-button"
            icon={faMessageLines}
            isActive={rightPane === RightPanes.Comments}
            tooltipBody="View, reply and collaborate with your team on comments."
            tooltipTitle="Comments"
            onClick={() => handleTogglePane(RightPanes.Comments)}
          />
        </div>
        <div className="pr-1">
          <HeaderIcon
            dataLoc="change-history-open-history-icon"
            icon={faClockRotateLeft}
            isActive={rightPane === RightPanes.ChangeHistory}
            showNotification={unseenRevisions}
            tooltipBody="Inspect changes in chronological order"
            tooltipTitle="History"
            onClick={() => handleTogglePane(RightPanes.ChangeHistory)}
          />
        </div>
        {flowVersionReview && (
          <div className="pr-1">
            <HeaderIcon
              dataLoc="review-process-open-pane-icon"
              icon={faBallotCheck}
              isActive={rightPane === RightPanes.ReviewProcess}
              showNotification={unseenReview}
              tooltipBody="View review request, leave comments, and collaborate on the review process"
              tooltipTitle="Review process"
              onClick={() => handleTogglePane(RightPanes.ReviewProcess)}
            />
          </div>
        )}
      </div>
      {flowVersions.canEdit && (
        <div className="pr-4">
          <RunFlowButtonWrapper flow={flow} version={version} />
        </div>
      )}

      <VersionOptionsDropdown flow={flow} flowVersion={version} />
    </div>
  );
});
