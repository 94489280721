import {
  InfiniteData,
  QueryKey,
  useInfiniteQuery,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { produce, type Draft } from "immer";
import { OpenAPIV3_1 } from "openapi-types";
import { useEffect, useMemo } from "react";

import * as flowApi from "src/api";
import { flowDbToFlowT, loadFlow, loadFlows } from "src/api";
import {
  CasePatchBody,
  CreateMultiversionTestRunParams,
  FlowRouterEndpoint,
  FlowRouterReviewCasesEndpoint,
  flowsApi,
  ListReviewCasesParams,
  RouterMultiversionTestRun,
  trafficPoliciesApi,
} from "src/api/endpoints";
import { FlowT, FlowVersionT } from "src/api/flowTypes";
import { organizationsApi } from "src/api/taktileApi";
import {
  ListReviewCasesResponse,
  ParsedModelDataPendingT,
  ParsedModelDataSuccessT,
  ReviewCase,
  ReviewCaseWithDecisionData,
  UploadModelDataT,
  WorkspaceWithSettings,
} from "src/api/types";
import {
  FlowFolderListResponse,
  FlowNaming,
  GetFlowsApiV1FlowsGetOrderByEnum,
  TrafficPolicyCreateService,
  TrafficPolicyInDB,
  WorkspaceDataplane,
} from "src/clients/flow-api";
import { OrganizationInDB } from "src/clients/taktile-api";
import { AwsRegionTypeT } from "src/constants/AwsRegions";
import { TAKTILE_ORG_ID } from "src/constants/OrgIds";
import { folderKeys } from "src/flowsOverview/v2/folderQueries";
import * as mlNodeApi from "src/mlNode/api/mlNodeApi";
import { queryClient } from "src/queryClient";
import { shouldShowInternalResources } from "src/router/featureFlags";
import { DashboardPageParamsT } from "src/router/urls";
import { useParamsDecode } from "src/utils/useParamsDecode";

export const NEVER_REFETCH_OPTIONS: UseQueryOptions<any, any, any, any> = {
  staleTime: Infinity,
  cacheTime: Infinity,
  refetchOnMount: false,
  refetchInterval: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
};

// Flows hooks
export const flowKeys = {
  all: ["flows"] as const,
  workspaceListFiltered: (
    workspaceId: string | undefined,
    folderId: string | undefined,
  ) => [...flowKeys.all, workspaceId, folderId] as const,
  workspaceListFilteredOrdered: (
    workspaceId: string | undefined,
    folderId: string | undefined,
    orderBy?: GetFlowsApiV1FlowsGetOrderByEnum | undefined,
  ) =>
    [
      ...flowKeys.workspaceListFiltered(workspaceId, folderId),
      orderBy,
    ] as const,
  detail: (id: string) => [...flowKeys.all, id] as const,
  slugDetail: (workspaceId: string, slug: string, options: string) =>
    [...flowKeys.all, workspaceId, slug, options] as const,
};

const workspaceKeys = {
  all: ["workspaces"] as const,
  detail: (id: string, includeSettings?: boolean) =>
    [...workspaceKeys.all, id, includeSettings] as const,
};

const flowTrafficPoliciesKeys = {
  all: ["flowsTrafficPolicies"] as const,
  detail: (id: string) => [...flowTrafficPoliciesKeys.all, id] as const,
};

const sortByName = (data: WorkspaceDataplane[]) =>
  data
    .slice()
    .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

export const useWorkspaces = (refetchOnMount: boolean = false) => {
  return useQuery<WorkspaceDataplane[], Error>(
    workspaceKeys.all,
    flowApi.loadWorkspaces,
    {
      refetchOnMount,
      select: sortByName,
    },
  );
};

type UseWorkspaceOptions = {
  refetchOnPending?: boolean;
  includeSettings?: boolean;
};

const REFETCH_ON_PENDING_FN = (data: WorkspaceDataplane | undefined) =>
  data?.status === "PENDING" ? 5000 : false;

export const useWorkspace = (
  workspaceId?: string,
  options: UseWorkspaceOptions = {},
) => {
  return useQuery<WorkspaceDataplane, Error>(
    workspaceKeys.detail(workspaceId!, options.includeSettings ?? false),
    () => flowApi.loadWorkspace(workspaceId!, options.includeSettings),
    {
      enabled: Boolean(workspaceId),
      refetchInterval: options.refetchOnPending
        ? REFETCH_ON_PENDING_FN
        : undefined,
    },
  );
};

// Speacial case for the workspace data which always includes the settings
export const useWorkspaceWithSettings = (workspaceId?: string) => {
  return useWorkspace(workspaceId, { includeSettings: true }) as UseQueryResult<
    WorkspaceWithSettings,
    Error
  >;
};

export type CreateWorkspaceParams = {
  name: string;
  slug: string;
  organizationId: string;
  awsRegion: AwsRegionTypeT;
};

export const useCreateWorkspace = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (workspaceParams: CreateWorkspaceParams) =>
      flowApi.createWorkspace(
        workspaceParams.name,
        workspaceParams.slug,
        workspaceParams.organizationId,
        workspaceParams.awsRegion.identifier,
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(workspaceKeys.all);
      },
    },
  );
};

export type UpdateWorkspaceParams = Pick<
  WorkspaceDataplane,
  "id" | "name" | "flow_services_version" | "settings"
>;

export const useUpdateWorkspace = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (workspaceParams: UpdateWorkspaceParams) =>
      flowApi.editWorkspace(workspaceParams),
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(workspaceKeys.all);
        queryClient.invalidateQueries(workspaceKeys.detail(variables.id));
      },
    },
  );
};

const FLOW_PAGE_SIZE = 100;

export const useFlows = ({
  workspaceId,
  folderId,
  orderBy,
  disabled,
}: {
  workspaceId?: string;
  folderId?: string;
  orderBy?: GetFlowsApiV1FlowsGetOrderByEnum;
  disabled?: boolean;
}) => {
  const {
    data,
    hasNextPage,
    isFetching,
    fetchNextPage,
    isLoading,
    isError,
    isRefetching,
    error,
  } = useInfiniteQuery({
    queryKey: flowKeys.workspaceListFilteredOrdered(
      workspaceId,
      folderId,
      orderBy,
    ),
    queryFn: async ({ pageParam = 0 }) => {
      const skip = pageParam * FLOW_PAGE_SIZE;
      return loadFlows({
        workspaceId,
        folderId,
        orderBy,
        skip,
        limit: FLOW_PAGE_SIZE,
        include_internal_flows: shouldShowInternalResources(),
      });
    },
    getNextPageParam: (lastPage, allPages) => {
      // TODO: The check really should be lastPage.length < FLOW_PAGE_SIZE
      // relying on checking the length of the last page is 0, causes one extra request
      // but it's a workaround for a bug in flow api - it returns 1 less item for first page
      if (lastPage.length < 1) {
        return undefined;
      }

      return allPages.length;
    },
    enabled: !disabled,
  });

  // eagerly load all pages
  useEffect(() => {
    if (!isFetching && hasNextPage && !disabled) {
      fetchNextPage();
    }
  }, [isFetching, hasNextPage, disabled, fetchNextPage]);

  return useMemo(
    () => ({
      data: data?.pages.flat() ?? [],
      error: error as Error | null,
      isLoading: (data?.pages.length ?? 0) > 0 ? false : isLoading,
      isRefetching,
      isError,
    }),
    [data, error, isLoading, isRefetching, isError],
  );
};

type UseFlowOptions = {
  refetchOnMount?: boolean;
  refetchInterval?: number | false;
  enabled?: boolean;
};

export const useFlow = (flowId?: string, options?: UseFlowOptions) => {
  const { refetchOnMount = false } = options ?? {};

  return useQuery<FlowT, Error>(
    flowKeys.detail(flowId!),
    () => loadFlow(flowId!),
    {
      enabled: !!flowId && (options?.enabled ?? true),
      refetchOnMount,
      refetchInterval: options?.refetchInterval,
      notifyOnChangeProps: options?.refetchInterval
        ? ["data", "error"]
        : undefined,
    },
  );
};

type UseFlowBySlugOptions = {
  refetchOnMount?: boolean;
  versionNameFilter?: string;
  versionIdFilter?: string;
  defaultVersionFilter?: boolean;
  defaultSandboxVersionFilter?: boolean;
  minFlowVersionEtagFilter?: string;
  shallow?: boolean;
};

export const useFlowBySlug = (
  workspaceId?: string,
  slug?: string,
  options: UseFlowBySlugOptions = {},
) => {
  const { refetchOnMount = false, ...requestOptions } = options;
  return useQuery<FlowT, Error>(
    flowKeys.slugDetail(workspaceId!, slug!, JSON.stringify(requestOptions)),
    async () => {
      const response =
        await flowsApi.getFlowBySlugApiV1FlowsWorkspaceIdWorkspaceIdSlugSlugGet(
          workspaceId!,
          slug!,
          requestOptions.versionNameFilter,
          requestOptions.versionIdFilter,
          requestOptions.defaultVersionFilter,
          requestOptions.defaultSandboxVersionFilter,
          requestOptions.minFlowVersionEtagFilter,
          requestOptions.shallow,
        );
      return flowDbToFlowT(response.data);
    },
    {
      enabled: Boolean(slug) && Boolean(workspaceId),
      refetchOnMount,
    },
  );
};

export const useAddFlow = () => {
  const queryClient = useQueryClient();
  return useMutation(flowApi.createFlow, {
    onSuccess: () => {
      queryClient.invalidateQueries(flowKeys.all);
      queryClient.invalidateQueries(folderKeys.all);
    },
  });
};

type CopyFlowArgs = {
  sourceOrgId: string;
  flowId: string;
  flowVersionId: string;
  newName: string;
  newSlug: string;
  childFlowNamings: { [key: string]: FlowNaming };
  folderId?: string;
};

export const useCopyFlow = (targetWorkspaceId: string) => {
  const { wsId } = useParamsDecode<DashboardPageParamsT>();
  return useMutation(
    async (args: CopyFlowArgs) => {
      const flowCopyReturn = (
        await flowsApi.postCopyFlowApiV1FlowsFlowIdCopyPost(args.flowId, {
          new_name: args.newName,
          new_slug: args.newSlug,
          target_workspace_id: targetWorkspaceId,
          flow_version_ids: [args.flowVersionId],
          child_flow_namings: args.childFlowNamings,
          flow_folder_id: args.folderId,
          copy_comments: true,
          list_datasets: true,
        })
      ).data;

      // Datasets can only be copied from taktile org
      if (args.sourceOrgId === TAKTILE_ORG_ID) {
        const allRemappedFlows = {
          [args.flowId]: flowCopyReturn.flow.id,
          ...flowCopyReturn.remapped_child_flow_ids,
        };
        const datasetCopyCalls: Promise<any>[] = Object.entries(
          allRemappedFlows,
        ).flatMap(([oldFlowId, newFlowId]) => {
          return flowCopyReturn.datasets_to_copy[oldFlowId].map((datasetId) =>
            flowsApi.postCopyFlowSampleDataApiV1FlowsFlowIdCopySampleDataPost(
              oldFlowId,
              {
                target_flow_id: newFlowId,
                dataset_ids: [datasetId],
              },
            ),
          );
        });
        await Promise.all(datasetCopyCalls);
      }
    },
    {
      onSuccess: () => {
        if (wsId === targetWorkspaceId) {
          queryClient.invalidateQueries(flowKeys.all);
        }
      },
    },
  );
};

/**
 *
 * @returns The query keys mutated by the optimistic update
 */
export const optimisticallyUpdatesOnFolderMove = ({
  flowId,
  folderId,
  oldFolderId,
  workspaceId,
}: {
  flowId: string;
  folderId: string | null | undefined;
  oldFolderId: string | null | undefined;
  workspaceId: string;
}): QueryKey[] => {
  const updatedQueryKeys: QueryKey[] = [];
  if (folderId === undefined || folderId === oldFolderId)
    return updatedQueryKeys;

  // Optimistically remove the flow from its current folder
  const flowListsByOldFolder = queryClient.getQueriesData<{
    pageParams: number[];
    pages: FlowT[][] | undefined;
  }>(
    flowKeys.workspaceListFiltered(
      workspaceId,
      oldFolderId || flowApi.NO_FOLDER_FILTER,
    ),
  );
  flowListsByOldFolder.forEach(([key, data]) => {
    if (data && data.pages) {
      updatedQueryKeys.push(key);
      queryClient.setQueryData(key, {
        ...data,
        pages: data.pages.map((page) =>
          page.filter((flow) => flow.id !== flowId),
        ),
      });
    }
  });
  // Update the counts on folders
  const updatedFoldersData = queryClient.getQueryData<FlowFolderListResponse>(
    folderKeys.workspaceList(workspaceId),
  );
  if (updatedFoldersData) {
    if (folderId) {
      const folder = updatedFoldersData.folders.find(
        (folder) => folder.id === folderId,
      );
      if (folder) {
        folder.flow_count += 1;
      }
    } else {
      updatedFoldersData.flows_outside_folders_count += 1;
    }

    if (oldFolderId) {
      const folder = updatedFoldersData.folders.find(
        (folder) => folder.id === oldFolderId,
      );
      if (folder) {
        folder.flow_count -= 1;
      }
    } else {
      updatedFoldersData.flows_outside_folders_count -= 1;
    }

    updatedQueryKeys.push(folderKeys.workspaceList(workspaceId));
    queryClient.setQueryData(
      folderKeys.workspaceList(workspaceId),
      updatedFoldersData,
    );
  }

  return updatedQueryKeys;
};

export const useEditFlow = (workspaceId: string) => {
  const queryClient = useQueryClient();
  return useMutation(flowApi.updateFlow, {
    onMutate: async (flowUpdate) => {
      return optimisticallyUpdatesOnFolderMove({
        flowId: flowUpdate.flowId,
        folderId: flowUpdate.flow_folder_id,
        oldFolderId: flowUpdate.old_folder_id,
        workspaceId,
      });
    },
    onSuccess: (_, request) => {
      queryClient.invalidateQueries(flowKeys.all);
      queryClient.invalidateQueries(flowKeys.detail(request.flowId));
      queryClient.invalidateQueries(folderKeys.all);
      // Prefetch the folder lists to ensure a smooth interaction when the user navigates to the new folder
      if (request.flow_folder_id) {
        queryClient.refetchQueries(
          flowKeys.workspaceListFiltered(workspaceId, request.flow_folder_id),
        );
      }
    },
    onSettled: (_response, _error, _request, context) => {
      // Get the query keys mutated from the optimistic update and invalidate them in any case.
      context?.forEach((key) => {
        queryClient.invalidateQueries(key);
      });
    },
  });
};

export const useDeleteFlow = () => {
  const queryClient = useQueryClient();
  return useMutation((flowId: string) => flowApi.deleteFlow(flowId), {
    onSuccess: () => {
      queryClient.invalidateQueries(flowKeys.all);
      queryClient.invalidateQueries(folderKeys.all);
    },
  });
};

export const useCreateTrafficPolicy = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      createPayload,
      activate,
    }: {
      createPayload: TrafficPolicyCreateService;
      activate: boolean;
    }) =>
      trafficPoliciesApi.createTrafficPolicyApiV1TrafficPoliciesPost(
        createPayload,
        activate,
      ),
    {
      onSuccess: (_, data) => {
        queryClient.invalidateQueries(flowKeys.all);
        queryClient.invalidateQueries(
          flowTrafficPoliciesKeys.detail(data.createPayload.flow_id),
        );
      },
    },
  );
};

export const useActivateTrafficPolicy = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      trafficPolicyId,
      flowId,
    }: {
      trafficPolicyId: string;
      flowId: string;
    }) =>
      trafficPoliciesApi.activateTrafficPolicyApiV1TrafficPoliciesTrafficPolicyIdActivatePost(
        trafficPolicyId,
        flowId,
      ),
    {
      onSuccess: (_, data) => {
        queryClient.invalidateQueries(flowKeys.all);
        queryClient.invalidateQueries(
          flowTrafficPoliciesKeys.detail(data.flowId),
        );
      },
    },
  );
};

export const useTrafficPolicies = (flowId?: string) => {
  return useQuery<TrafficPolicyInDB[], Error>(
    flowTrafficPoliciesKeys.detail(flowId!),
    async () =>
      (
        await trafficPoliciesApi.listFlowTrafficPoliciesApiV1TrafficPoliciesGet(
          flowId!,
        )
      ).data,

    { enabled: !!flowId },
  );
};

// Docs hooks
export const useDocs = (flowId: string, version: FlowVersionT) => {
  return useQuery<OpenAPIV3_1.Document, Error>(
    ["docs", flowId, version.id],
    async () =>
      (
        await flowsApi.getVersionedFlowDocsApiV1FlowsFlowIdVersionsVersionIdDocsGet(
          flowId,
          version.id,
        )
      ).data,
  );
};

export const userOrganizationsQueryKey = ["userOrganizations"];

// User Organization hooks
export const useUserOrganizations = () => {
  return useQuery<OrganizationInDB[], Error>(
    userOrganizationsQueryKey,
    async () => {
      const orgs =
        await organizationsApi.getMyOrganizationsApiV1OrganizationsGet();
      return orgs.data
        .filter((org) => org.allows_decide_access)
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    {
      ...NEVER_REFETCH_OPTIONS,
    },
  );
};

export const useUserOrganizationLogo = (orgId: string) => {
  return useQuery(
    [...userOrganizationsQueryKey, orgId, "logo"],
    async () =>
      (
        await organizationsApi.getOrganizationLogoApiV1OrganizationsOrganizationIdLogoGet(
          orgId,
          { responseType: "blob" },
        )
      ).data as unknown as Blob,
    { refetchOnMount: false, retry: false },
  );
};

export const useUpdateOrganizationLogo = () => {
  return useMutation({
    mutationKey: ["updateOrganizationLogo"],
    mutationFn: ({
      organizationId,
      logo,
    }: {
      organizationId: string;
      logo: File;
    }) => {
      return organizationsApi.postOrganizationLogoApiV1OrganizationsOrganizationIdLogoPost(
        organizationId,
        logo,
      );
    },
    onSettled: (_, __, variables) => {
      queryClient.invalidateQueries([
        userOrganizationsQueryKey,
        variables.organizationId,
        "logo",
      ]);
    },
  });
};

export const useUpdateOrganizationAllowsSignup = () => {
  return useMutation({
    mutationKey: ["updateOrganizationAllowsSignup"],
    mutationFn: ({
      organizationId,
      newAllowsSignup,
    }: {
      organizationId: string;
      newAllowsSignup: boolean;
    }) => {
      return organizationsApi.patchOrganizationApiV1OrganizationsOrganizationIdPatch(
        organizationId,
        { allows_signup: newAllowsSignup },
      );
    },
    onSuccess: () => queryClient.invalidateQueries(userOrganizationsQueryKey),
  });
};

export const useDeleteOrganizationLogo = () => {
  return useMutation({
    mutationKey: ["deleteOrganizationLogo"],
    mutationFn: ({ organizationId }: { organizationId: string }) => {
      return organizationsApi.deleteOrganizationLogoApiV1OrganizationsOrganizationIdLogoDelete(
        organizationId,
      );
    },
    onSettled: (_, __, variables) => {
      queryClient.invalidateQueries([
        ...userOrganizationsQueryKey,
        variables.organizationId,
        "logo",
      ]);
    },
  });
};

// Flow running hooks

const lastRunKeys = {
  all: ["lastRun"] as const,
  specific: (
    baseUrl: string | undefined,
    versionId: string,
    flowSlug: string,
  ) => [...lastRunKeys.all, baseUrl, versionId, flowSlug] as const,
};

export const useAnalyzeFlow = () =>
  useMutation(
    (params: { baseUrl: string; flowSlug: string; versionId: string }) =>
      FlowRouterEndpoint.analyze(
        params.baseUrl,
        params.flowSlug,
        params.versionId,
      ),
  );

export type UploadModelContextT = {
  oldFileName?: string;
};

export const useUploadModelFile = (
  options: UseMutationOptions<any, any, UploadModelDataT, UploadModelContextT>,
) => {
  return useMutation(
    (modelData) =>
      mlNodeApi.uploadModel(
        modelData.baseUrl,
        modelData.flowSlug,
        modelData.file,
      ),
    options,
  );
};

const PARSE_MODEL_REFETCH_INTERVAL_MS = 1000;

export const useParsedModelData = (
  baseUrl: string | undefined,
  flowSlug: string | undefined,
  modelUuid: string | undefined,
  options: UseQueryOptions<
    any,
    any,
    ParsedModelDataSuccessT | ParsedModelDataPendingT,
    string[]
  > = {},
) => {
  return useQuery(
    ["model", modelUuid!],
    () => mlNodeApi.getParsedModel(baseUrl!, flowSlug!, modelUuid!),
    {
      enabled: !!modelUuid,
      retry: false,
      refetchInterval: (data, query) => {
        // refetch if status is pending
        // data is the latest successful response
        // so we need to check the current state status to be not error
        if (data?.type === "pending" && query.state.status !== "error") {
          return PARSE_MODEL_REFETCH_INTERVAL_MS;
        }

        return false;
      },
      ...options,
    },
  );
};

type UseReviewCasesOptions = {
  enabled?: boolean;
};

export const useReviewCases = (
  baseUrl: string | undefined,
  flowSlug: string | undefined,
  params: ListReviewCasesParams = {},
  options: UseReviewCasesOptions = { enabled: true },
) =>
  useInfiniteQuery<ListReviewCasesResponse, Error>({
    queryKey: ["reviewCases", baseUrl, flowSlug, JSON.stringify(params)],
    getNextPageParam: (lastPage) =>
      lastPage.cases.length > 0 ? lastPage.page_min_timestamp : undefined,
    queryFn: async ({
      pageParam = undefined,
    }): Promise<ListReviewCasesResponse> =>
      FlowRouterReviewCasesEndpoint.getCases(baseUrl!, flowSlug!, {
        ...params,
        before_timestamp: pageParam ?? params.before_timestamp,
      }),
    enabled: options.enabled && !!baseUrl && !!flowSlug,
    staleTime: 0,
    // If we don't refetch on mount, changing the filters may show old of date data
    refetchOnMount: true,
    refetchInterval: 20 * 1000,
  });

export const useReviewCase = (
  baseUrl: string | undefined,
  flowSlug: string,
  caseId: string,
) =>
  useQuery<ReviewCaseWithDecisionData, Error>({
    queryKey: ["reviewCase", baseUrl, flowSlug, caseId],
    queryFn: () =>
      FlowRouterReviewCasesEndpoint.getCase(baseUrl!, flowSlug, caseId),
    enabled: !!baseUrl,
    retry: false,
  });

export const useReviewCasesAvailableFilters = (
  baseUrl: string | undefined,
  flowSlug: string | undefined,
  params: Pick<
    ListReviewCasesParams,
    "after_timestamp" | "before_timestamp" | "environment" | "statuses"
  > = {},
) =>
  useQuery(
    [
      "reviewCasesAvailableFilters",
      baseUrl,
      flowSlug,
      params.after_timestamp,
      params.before_timestamp,
      params.environment,
      params.statuses,
    ],
    () => FlowRouterReviewCasesEndpoint.getFilters(baseUrl!, flowSlug!, params),
    {
      enabled: !!baseUrl && !!flowSlug,
      refetchOnMount: true,
    },
  );

const updateCase = (
  draft: Draft<ReviewCaseWithDecisionData | ReviewCase>,
  patch: CasePatchBody,
) => {
  if (patch.assignee !== undefined) {
    draft.assignee = patch.assignee;
  }
  if (patch.status !== undefined) {
    draft.status = patch.status;
  }
  if (patch.response_data !== undefined) {
    draft.response.data = patch.response_data;
  }
};

const updateCaseInCasesList = (
  cache: Draft<InfiniteData<ListReviewCasesResponse>> | undefined,
  caseId: string,
  patch: CasePatchBody,
) => {
  cache?.pages.forEach((page) => {
    page.cases.forEach((reviewCase) => {
      if (reviewCase.id !== caseId) return;

      updateCase(reviewCase, patch);
    });
  });
};

type ReviewCaseMutationContext = {
  oldReviewCasesDataCaches?: [
    QueryKey,
    InfiniteData<ListReviewCasesResponse> | undefined,
  ][];
  oldReviewCaseCache?: ReviewCaseWithDecisionData;
};

export const useReviewCaseMutation = (
  baseUrl: string | undefined,
  flowSlug: string,
  caseId: string,
) => {
  const queryClient = useQueryClient();
  const casesQueryKey = ["reviewCases", baseUrl, flowSlug];
  const singleCaseQueryKey = ["reviewCase", baseUrl, flowSlug, caseId];

  return useMutation(
    ({ etag, ...data }: CasePatchBody) =>
      FlowRouterReviewCasesEndpoint.patchCase(
        baseUrl!,
        flowSlug!,
        caseId,
        data,
        etag,
      ),
    {
      onMutate: async (rowPatch: CasePatchBody) => {
        const context: ReviewCaseMutationContext = {};

        // Update cases list cache
        await queryClient.cancelQueries(casesQueryKey);
        const oldReviewCasesDataCaches =
          queryClient.getQueriesData<InfiniteData<ListReviewCasesResponse>>(
            casesQueryKey,
          );

        if (oldReviewCasesDataCaches) {
          const updatedInifiniteData = produce(
            oldReviewCasesDataCaches,
            (queries) => {
              queries.forEach(([_queryKey, reviewCases]) => {
                // Update the review case in every found cache
                updateCaseInCasesList(reviewCases, caseId, rowPatch);
              });
            },
          );

          updatedInifiniteData.forEach(([queryKey, updatedData]) => {
            queryClient.setQueryData(queryKey, updatedData);
          });

          context.oldReviewCasesDataCaches = oldReviewCasesDataCaches;
        }

        // Update single case cache
        await queryClient.cancelQueries(singleCaseQueryKey);
        const oldReviewCaseCache =
          queryClient.getQueryData<ReviewCaseWithDecisionData>(
            singleCaseQueryKey,
          );

        if (oldReviewCaseCache) {
          queryClient.setQueryData<ReviewCaseWithDecisionData>(
            singleCaseQueryKey,
            (cachedData) => {
              if (cachedData) {
                return produce(cachedData, (reviewCaseDraft) => {
                  updateCase(reviewCaseDraft, rowPatch);
                });
              }
              return cachedData;
            },
          );

          context.oldReviewCaseCache = oldReviewCaseCache;
        }

        return context;
      },
      onError: (_, __, context) => {
        if (context) {
          context.oldReviewCasesDataCaches?.forEach(
            ([queryKey, updatedData]) => {
              queryClient.setQueryData(queryKey, updatedData);
            },
          );

          if (context.oldReviewCaseCache) {
            queryClient.setQueryData(
              singleCaseQueryKey,
              context.oldReviewCaseCache,
            );
          }
        }
      },
      onSuccess: (updatedCase) => {
        // Update the single case cache with the PATCH result
        queryClient.setQueryData<ReviewCaseWithDecisionData>(
          singleCaseQueryKey,
          (existingCase) => {
            if (!existingCase) return updatedCase;
            return {
              ...updatedCase,
              inspect_data: existingCase.inspect_data,
              external_data: existingCase.external_data,
            };
          },
        );
        // Update available filters in table
        queryClient.invalidateQueries([
          "reviewCasesAvailableFilters",
          baseUrl,
          flowSlug,
        ]);
        // If the assignee changes we want to update the summaries and the cases list
        queryClient.invalidateQueries(["reviewSummary", baseUrl, flowSlug]);
        queryClient.invalidateQueries(casesQueryKey);
      },
    },
  );
};
export const useReviewSummary = (
  baseUrl: string | undefined,
  flowSlug: string | undefined,
  environment: "prod" | "sandbox" = "prod",
) =>
  useQuery(
    ["reviewSummary", baseUrl, flowSlug, environment],
    () =>
      FlowRouterReviewCasesEndpoint.getSummary(
        baseUrl!,
        flowSlug!,
        environment,
      ),
    {
      enabled: !!baseUrl && !!flowSlug,
      refetchOnMount: true,
    },
  );

export const useReviewCaseSubmit = (
  baseUrl: string | undefined,
  flowSlug: string,
  caseId: string,
) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ etag }: { etag: string }) =>
      FlowRouterReviewCasesEndpoint.submit(baseUrl!, flowSlug!, caseId, etag),
    {
      onSuccess: () => {
        // Update available filters in table
        queryClient.invalidateQueries([
          "reviewCasesAvailableFilters",
          baseUrl,
          flowSlug,
        ]);
        queryClient.invalidateQueries([
          "reviewCase",
          baseUrl,
          flowSlug,
          caseId,
        ]);
        queryClient.invalidateQueries(["reviewCases", baseUrl, flowSlug]);
      },
    },
  );
};

export const useMultiversionTestRun = (
  baseUrl: string,
  flowId: string,
  flowSlug: string,
) => {
  return useMutation(
    async (
      params: Omit<
        CreateMultiversionTestRunParams,
        "flow_id" | "flow_slug" | "infer_types"
      >,
    ) => {
      let response = undefined;
      let retries = 4;
      while (
        response === undefined ||
        (response.status === 404 && retries-- > 0)
      ) {
        if (response !== undefined)
          await new Promise((r) => setTimeout(r, 1000));
        response = await RouterMultiversionTestRun.post(baseUrl, {
          ...params,
          flow_id: flowId,
          flow_slug: flowSlug,
          infer_types: true,
        });
      }
      return response.data;
    },
  );
};
