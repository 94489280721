import { BACKEND_URL as FLOW_API_BACKEND_URL } from "src/api/endpoints";
import { BACKEND_URL as TAKTILE_API_BACKEND_URL } from "src/api/taktileApi";

export const isTaktileRequest = (url: string) => {
  try {
    return (
      url.startsWith(TAKTILE_API_BACKEND_URL) ||
      url.startsWith(FLOW_API_BACKEND_URL) ||
      new URL(url).hostname.endsWith("taktile.com") ||
      new URL(url).hostname.endsWith("tktl.io")
    );
  } catch (e) {
    return false;
  }
};
