import {
  faCog,
  faEdit,
  faIndustry,
  faPlus,
} from "@fortawesome/pro-regular-svg-icons";
import { useState } from "react";
import Dropzone from "react-dropzone";

import { AddUserModal } from "src/adminPanel/AddUserModal";
import { CreateOrganizationModal } from "src/adminPanel/CreateOrganizationModal";
import { ModifyWsConfigModal } from "src/adminPanel/ModifyWsConfigModal";
import { OrganizationT } from "src/api/flowTypes";
import {
  useDeleteOrganizationLogo,
  useUpdateOrganizationAllowsSignup,
  useUpdateOrganizationLogo,
} from "src/api/queries";
import { Button } from "src/base-components/Button";
import { Checkbox } from "src/base-components/Checkbox";
import { Icon } from "src/base-components/Icon";
import { OrgLogo } from "src/base-components/OrgLogo";
import { Pill, PillVariants } from "src/base-components/Pill";
import { Spinner } from "src/base-components/Spinner";
import { WorkspaceDataplane } from "src/clients/flow-api";
import { EmptyState } from "src/design-system/EmptyState";
import { WorkspaceFormModal } from "src/layout/WorkspaceFormModal/WorkspaceFormModal";
import { AuthProviders } from "src/userManagement/AuthProviders";

export const OrganizationsDisplay: React.FC<{
  org: OrganizationT;
  workspaces: WorkspaceDataplane[];
  handleOrgSelection: (orgId: string) => void;
  handleWsSelection: (wsId: string | null) => void;
  selectedOrg: string | null;
  setAddUserModalIsOpen: (isOpen: boolean) => void;
  setWorkspaceModalIsOpen: (isOpen: boolean) => void;
}> = ({
  org,
  workspaces,
  handleOrgSelection,
  handleWsSelection,
  setWorkspaceModalIsOpen,
  setAddUserModalIsOpen,
}) => {
  const deleteLogo = useDeleteOrganizationLogo();
  const uploadLogo = useUpdateOrganizationLogo();
  const changeAllowsSignup = useUpdateOrganizationAllowsSignup();
  const [wsSelected, setWsSelected] = useState<WorkspaceDataplane | null>(null);
  const [wsModalIsOpen, setWsModalIsOpen] = useState(false);

  const variantLevels = ["gray", "green", "yellow", "red"];
  const getVariant = (level: number) => {
    return variantLevels[
      Math.min(level, variantLevels.length - 1)
    ] as PillVariants;
  };

  return (
    <>
      {wsSelected && wsModalIsOpen && (
        <ModifyWsConfigModal
          isOpen={wsModalIsOpen}
          selectedWs={wsSelected}
          onClose={() => setWsModalIsOpen(false)}
        />
      )}
      <div
        key={org.id}
        className="mb-4 rounded-lg border border-gray-50 bg-white py-2 shadow-base"
      >
        <div className="flex flex-row items-center justify-between gap-2 px-3 py-2">
          <div className="flex flex-row items-center gap-2">
            <OrgLogo org={org} size="medium" />
            <h3 className="font-inter-semibold-16px">{org.name}</h3>
            {workspaces.some((ws) => ws.organization_id === org.id) && (
              <Pill
                variant={getVariant(
                  Math.max(
                    ...workspaces
                      .filter((ws) => ws.organization_id === org.id)
                      .map((ws) => ws.config?.profile_level ?? 0),
                  ),
                )}
              >
                Max Level:{" "}
                {Math.max(
                  ...workspaces
                    .filter((ws) => ws.organization_id === org.id)
                    .map((ws) => ws.config?.profile_level ?? 0),
                )}
              </Pill>
            )}
          </div>
          <div className="flex flex-row items-center gap-2">
            <label className="mr-2 flex flex-row items-center gap-x-1">
              Allows sign up
              {changeAllowsSignup.isLoading ? (
                <Spinner />
              ) : (
                <Checkbox
                  checked={org.allows_signup}
                  onChange={() => {
                    changeAllowsSignup.mutate({
                      organizationId: org.id,
                      newAllowsSignup: !org.allows_signup,
                    });
                  }}
                />
              )}
            </label>
            <Dropzone
              onDrop={(acceptedFiles) => {
                const file = acceptedFiles[0];
                if (file) {
                  uploadLogo.mutate({
                    organizationId: org.id,
                    logo: file,
                  });
                }
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div
                  {...getRootProps({
                    className:
                      "bg-gray-100 border border-dashed rounded-md px-2 py-0.5 cursor-pointer",
                  })}
                >
                  <p className="font-inter-medium-12px">
                    Click or Drop new logo here <input {...getInputProps()} />
                  </p>
                </div>
              )}
            </Dropzone>
            <Button
              loading={deleteLogo.isLoading}
              size="sm"
              variant="secondary"
              onClick={() => deleteLogo.mutate({ organizationId: org.id })}
            >
              Delete existing logo
            </Button>
            <Button
              iconLeft={faPlus}
              size="sm"
              variant="secondary"
              onClick={() => {
                handleOrgSelection(org.id);
                setAddUserModalIsOpen(true);
              }}
            >
              Add user
            </Button>
          </div>
        </div>
        <div className="flex flex-row">
          <div className="w-1/2 rounded-md p-2">
            <AuthProviders orgId={org.id} />
          </div>

          <div className="w-1/2 py-2 pl-2 pr-4">
            <div className="rounded-lg !border !border-gray-100 p-2">
              {workspaces.filter((ws) => ws.organization_id === org.id).length >
              0 ? (
                <div className="mb-1 flex w-full flex-row items-center justify-between px-2">
                  <p className="truncate py-2.5 pr-4 text-left text-gray-800 font-inter-medium-12px">
                    Workspaces
                  </p>
                  <Button
                    iconLeft={faPlus}
                    size="sm"
                    variant="secondary"
                    onClick={() => {
                      handleOrgSelection(org.id);
                      handleWsSelection(null);
                      setWorkspaceModalIsOpen(true);
                    }}
                  >
                    Add Workspace
                  </Button>
                </div>
              ) : (
                <></>
              )}
              {(() => {
                const filteredWorkspaces = workspaces.filter(
                  (ws) => ws.organization_id === org.id,
                );

                return filteredWorkspaces.length > 0 ? (
                  filteredWorkspaces.map((ws) => (
                    <div className="flex w-full flex-col items-center gap-2 px-2 py-1">
                      <div className="flex w-full flex-row">
                        <div
                          key={ws.id}
                          className="block flex w-full flex-row items-center truncate text-left font-inter-normal-12px"
                        >
                          <div className="mr-2">{ws.name}</div>
                          <Pill variant="outlined-gray">
                            {ws.flow_services_version}
                          </Pill>
                          <div className="ml-2">
                            <Pill
                              variant={getVariant(
                                ws.config?.profile_level ?? 0,
                              )}
                            >
                              Level: {ws.config?.profile_level}
                            </Pill>
                          </div>
                        </div>
                        <div className="mr-2">
                          <Icon
                            color="text-gray-500 hover:text-gray-700"
                            disabled={ws.status !== "SUCCESS"}
                            icon={faEdit}
                            size="xs"
                            onClick={() => {
                              handleWsSelection(ws.id);
                              handleOrgSelection(org.id);
                              setWorkspaceModalIsOpen(true);
                            }}
                          />
                        </div>
                        <div className="mr-2">
                          <Icon
                            color="text-gray-500 hover:text-gray-700"
                            disabled={ws.status !== "SUCCESS"}
                            icon={faCog}
                            size="xs"
                            onClick={() => {
                              setWsSelected(ws);
                              setWsModalIsOpen(true);
                            }}
                          />
                        </div>
                      </div>
                      <div className="h-px w-full rounded-full bg-gray-100"></div>
                    </div>
                  ))
                ) : (
                  <div className="h-60">
                    <EmptyState
                      actionButton={{
                        icon: faPlus,
                        text: "Add Workspace",
                        onClick: () => {
                          handleOrgSelection(org.id);
                          handleWsSelection(null);
                          setWorkspaceModalIsOpen(true);
                        },
                      }}
                      dataLoc="admin-org-no-workspaces"
                      description="There are currently no workspaces available for this organization!"
                      headline="No Workspaces available"
                      icon={faIndustry}
                    ></EmptyState>
                  </div>
                );
              })()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const OrganizationsWorkspacesSettings: React.FC<{
  organizations: OrganizationT[];
  workspaces: WorkspaceDataplane[];
}> = ({ organizations, workspaces }) => {
  const [selectedOrg, setSelectedOrg] = useState<string | null>(null);
  const [selectedWs, setSelectedWs] = useState<string | null>(null);
  const [workspaceModalIsOpen, setWorkspaceModalIsOpen] = useState(false);
  const [orgModalIsOpen, setOrgModalIsOpen] = useState(false);

  const [addUserModalIsOpen, setAddUserModalIsOpen] = useState(false);
  return (
    <>
      <div>
        <div className="m-1">
          {organizations.map((org) => (
            <OrganizationsDisplay
              handleOrgSelection={setSelectedOrg}
              handleWsSelection={setSelectedWs}
              org={org}
              selectedOrg={selectedOrg}
              setAddUserModalIsOpen={setAddUserModalIsOpen}
              setWorkspaceModalIsOpen={setWorkspaceModalIsOpen}
              workspaces={workspaces}
            />
          ))}
        </div>
        <Button onClick={() => setOrgModalIsOpen(true)}>
          Create an Organisation
        </Button>
      </div>
      {workspaceModalIsOpen && !!selectedOrg && (
        <>
          <WorkspaceFormModal
            isOpen={workspaceModalIsOpen && !!selectedOrg && !selectedWs}
            orgId={selectedOrg || ""}
            variant="Create"
            onClose={() => setWorkspaceModalIsOpen(false)}
          />
          <WorkspaceFormModal
            isOpen={workspaceModalIsOpen && !!selectedOrg && !!selectedWs}
            orgId={selectedOrg || ""}
            variant="Edit"
            wsId={selectedWs || ""}
            onClose={() => setWorkspaceModalIsOpen(false)}
          />
        </>
      )}
      <CreateOrganizationModal
        isOpen={orgModalIsOpen}
        onClose={() => setOrgModalIsOpen(false)}
      />
      <AddUserModal
        isOpen={addUserModalIsOpen}
        selectedOrg={selectedOrg ?? ""}
        onClose={() => setAddUserModalIsOpen(false)}
      />
    </>
  );
};
