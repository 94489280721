import {
  faBuilding,
  faCalculatorSimple,
  faCode,
  faCopy,
  faEdit,
  faPlus,
  faToggleOff,
  faToggleOn,
} from "@fortawesome/pro-regular-svg-icons";
import { CellContext, createColumnHelper } from "@tanstack/react-table";
import { capitalize } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Button } from "src/base-components/Button";
import { CodeEditor } from "src/base-components/CodeInput/CodeEditor";
import { CopyTextIcon } from "src/base-components/CopyTextIcon";
import { EllipsisOptionsDropdown } from "src/base-components/OptionsDropdown/EllipsisOptionsDropdown";
import { Pill } from "src/base-components/Pill";
import { CustomPopover } from "src/base-components/Popover";
import { Cell, Header, TableComp } from "src/base-components/Table";
import { FeatureQueryStatus } from "src/clients/features";
import { EmptyState } from "src/design-system/EmptyState";
import { useModal } from "src/design-system/Modal";
import { TAKTILE_TEAM_NOTIFIED } from "src/design-system/Toast/constants";
import { toastActions } from "src/design-system/Toast/utils";
import { ManageQueryModal } from "src/featureCatalogue/Queries/ManageQueryModal";
import { StatusPill } from "src/featureCatalogue/StatusPill";
import {
  useFeatureQueries,
  useFeatureQuery,
  useUpdateFeatureQuery,
} from "src/featureCatalogue/queries";
import {
  ENTITY_ICON_MAP,
  formatAthenaSqlQuery,
  formatDuration,
  isActiveQuery,
  Query,
} from "src/featureCatalogue/utils";
import { ExcludesFalse } from "src/flow/types";
import { useCapabilities } from "src/hooks/useCapabilities";
import { WhitePane } from "src/jobs/common/WhitePane";
import { formatDate } from "src/utils/datetime";
import { HighlightedSql } from "src/utils/highlighter";
import { useShowOnHover } from "src/utils/useShowOnHover";

const columnHelper = createColumnHelper<Query>();

const COLUMNS = [
  columnHelper.accessor("id", {
    header: (info) => <Header info={info}>Query ID</Header>,
    cell: (info) => (
      <Cell info={info}>
        <CopyTextIcon feedback="inline" value={info.getValue()} />
      </Cell>
    ),
    size: 70,
  }),
  columnHelper.accessor("query", {
    header: (info) => <Header info={info}>SQL Query</Header>,
    cell: (info) => <DisplaySqlCell info={info} />,
    size: 300,
    maxSize: 400,
  }),
  columnHelper.accessor("status", {
    header: (info) => <Header info={info}>Status</Header>,
    cell: (info) => (
      <Cell info={info}>
        <StatusPill status={info.getValue()} />
      </Cell>
    ),
    size: 100,
  }),
  columnHelper.accessor("columns.features", {
    header: "Features",
    cell: (info) => {
      const features = info.getValue() ?? [];
      const [displayFeatures, remainingFeatures] =
        features.length > 2
          ? [features.slice(0, 1), features.slice(1)]
          : [features, []];

      return (
        <Cell info={info}>
          <div className="flex gap-2">
            {displayFeatures?.map((feature) => (
              <Pill key={feature.feature_key} size="sm" variant="gray">
                <Pill.Icon icon={faCalculatorSimple} />
                <Pill.Text>{feature.feature_key}</Pill.Text>
              </Pill>
            ))}
            {remainingFeatures?.length > 0 && (
              <RemainingFeaturesPill features={remainingFeatures} />
            )}
          </div>
        </Cell>
      );
    },
    size: 180,
    maxSize: 280,
  }),
  {
    accessorKey: "columns.entities.0.entity_name",
    header: (info: any) => <Header info={info}>Entity</Header>,
    cell: (info: any) => (
      <Cell className="flex space-x-2" info={info}>
        <Pill size="sm" variant="gray">
          <Pill.Icon
            icon={
              ENTITY_ICON_MAP[
                info.getValue() as keyof typeof ENTITY_ICON_MAP
              ] ?? faBuilding
            }
          />
          <Pill.Text>{capitalize(info.getValue())}</Pill.Text>
        </Pill>
      </Cell>
    ),
  },
  {
    accessorKey: "event",
    header: (info: any) => <Header info={info}>Event</Header>,
    cell: (info: any) => (
      <Cell info={info}>
        <Pill size="sm" variant="gray">
          <Pill.Icon icon={faCalculatorSimple} />
          <Pill.Text>{info.getValue()}</Pill.Text>
        </Pill>
      </Cell>
    ),
  },
  {
    accessorKey: "window_hop",
    header: (info: any) => <Header info={info}>Time window</Header>,
    cell: (info: any) => (
      <Cell info={info}>{formatDuration(info.getValue())}</Cell>
    ),
  },
  {
    accessorKey: "window_length",
    header: (info: any) => <Header info={info}>Freshness</Header>,
    cell: (info: any) => (
      <Cell info={info}>{formatDuration(info.getValue())}</Cell>
    ),
  },
  columnHelper.accessor("created_at", {
    header: (info) => <Header info={info}>Created at</Header>,
    cell: (info) => (
      <Cell info={info}>
        {formatDate(info.getValue(), "d MMM yyyy h:mm aaa")}
      </Cell>
    ),
  }),
  columnHelper.display({
    id: "actions",
    header: (info) => <Header info={info} />,
    cell: (info) => (
      <Cell className="justify-end" info={info}>
        <QueryActionsMenu
          query={info.row.original}
          onChangeQueryStatus={
            info.table.options.meta?.actions?.onChangeQueryStatus
          }
          onDuplicateQuery={info.table.options.meta?.actions?.onDuplicateQuery}
          onEditQuery={info.table.options.meta?.actions?.onEditQuery}
        />
      </Cell>
    ),
    size: 40,
  }),
];

export const Queries: React.FC = () => {
  const {
    openModal,
    closeModal,
    afterLeave,
    isOpen,
    data: queryToEdit,
  } = useModal<Query | Omit<Query, "key">>();
  const { data, isLoading, isSuccess } = useFeatureQueries();
  const { mutate: updateFeatureQuery } = useUpdateFeatureQuery({
    onError: (error) => {
      toastActions.failure({
        title: "Failed to activate feature query",
        description: error.response?.data.detail ?? TAKTILE_TEAM_NOTIFIED,
      });
    },
  });

  useOpenEditQueryModalFromParams(openModal);

  return (
    <WhitePane>
      {isSuccess && (data ?? []).length === 0 ? (
        <EmptyState
          action={
            <Button
              iconLeft={faPlus}
              variant="secondary"
              onClick={() => openModal()}
            >
              Create Feature Query
            </Button>
          }
          description="Create Feature Queries"
          headline="No Feature Queries found"
          icon={faCode}
        />
      ) : (
        <TableComp
          actions={{
            onEditQuery: openModal,
            onChangeQueryStatus: updateFeatureQuery,
            onDuplicateQuery: ({ id: _dropId, ...query }) => openModal(query),
          }}
          columns={COLUMNS}
          data={
            (data?.filter((item): item is Query => item.id !== undefined) ??
              []) as Query[]
          }
          frameClassName="w-full p-4"
          isLoading={isLoading}
          variant="compact"
        />
      )}
      <ManageQueryModal
        afterLeave={afterLeave}
        open={isOpen}
        query={queryToEdit}
        onClose={closeModal}
      />
    </WhitePane>
  );
};

const DisplaySqlCell: React.FC<{ info: CellContext<Query, string> }> = ({
  info,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <CustomPopover
      button={
        <Cell info={info}>
          <HighlightedSql sqlString={info.getValue()} />
        </Cell>
      }
      className="h-[400px] w-[400px] border-0"
      isOpen={isOpen}
      offsetX={0}
      placement="top"
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      {isOpen && (
        <CodeEditor
          language="sql"
          value={formatAthenaSqlQuery(info.getValue())}
          lineWrapping
          readOnly
        />
      )}
    </CustomPopover>
  );
};

const QueryActionsMenu: React.FC<{
  query: Query;
  onEditQuery: ((query: Query) => void) | undefined;
  onChangeQueryStatus:
    | ((params: {
        status: FeatureQueryStatus;
        id: string;
        etag: string;
      }) => void)
    | undefined;
  onDuplicateQuery: ((query: Query) => void) | undefined;
}> = ({ query, onEditQuery, onChangeQueryStatus, onDuplicateQuery }) => {
  const { featureQueriesCatalogue } = useCapabilities();
  const menuElements = [
    featureQueriesCatalogue.canActivate && {
      key: isActiveQuery(query) ? "Deactivate query" : "Activate query",
      icon: isActiveQuery(query) ? faToggleOff : faToggleOn,
      action: () =>
        onChangeQueryStatus?.({
          id: query.id,
          status: isActiveQuery(query)
            ? FeatureQueryStatus.INACTIVE
            : FeatureQueryStatus.ACTIVE,
          etag: query.etag,
        }),
    },
    featureQueriesCatalogue.canEdit && {
      key: "Edit Query",
      icon: faEdit,
      action: () => onEditQuery?.(query),
      disabled:
        query.status === "active" ? "Cannot edit active query" : undefined,
    },
    featureQueriesCatalogue.canDuplicate && {
      key: "Duplicate",
      icon: faCopy,
      action: () => onDuplicateQuery?.(query),
    },
  ].filter(Boolean as unknown as ExcludesFalse);

  return (
    <EllipsisOptionsDropdown
      buttonClassName="text-gray-500 ml-1 group-hover/row:opacity-100"
      elements={menuElements}
      iconSize="xs"
      placement="bottom-end"
      timeoutDuration={200}
    />
  );
};

const useOpenEditQueryModalFromParams = (openModal: (query: Query) => void) => {
  const hasBeenOpened = useRef(false);
  const [searchParams] = useSearchParams();
  const queryId = searchParams.get("queryId");
  const { data: queryParams } = useFeatureQuery(queryId ?? "");

  // Open the modal with the query data when the queryId is in the search params
  useEffect(() => {
    if (queryParams?.id && !hasBeenOpened.current) {
      openModal(queryParams as Query);
      hasBeenOpened.current = true;
    }
  }, [openModal, queryParams]);
};

const RemainingFeaturesPill: React.FC<{
  features: { feature_key: string }[];
}> = ({ features }) => {
  const { isHovered, onMouseEnter, onMouseLeave } = useShowOnHover(700);

  return (
    <CustomPopover
      button={
        <span className="cursor-default">
          <Pill size="sm" variant="gray">
            <Pill.Icon icon={faPlus} />
            <Pill.Text>{features.length} more</Pill.Text>
          </Pill>
        </span>
      }
      className="flex flex-wrap justify-start gap-2 p-4"
      isOpen={isHovered}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {features.map((feature) => (
        <Pill key={feature.feature_key} size="sm" variant="gray">
          <Pill.Icon icon={faCalculatorSimple} />
          <Pill.Text>{feature.feature_key}</Pill.Text>
        </Pill>
      ))}
    </CustomPopover>
  );
};
