import React, { useState } from "react";

import { Button } from "src/base-components/Button";
import { Modal } from "src/design-system/Modal";
import { Tooltip } from "src/design-system/Tooltip";

type ModalProps = React.ComponentProps<typeof Modal>;

export type ConfirmationModalPropsT = Pick<
  ModalProps,
  "size" | "afterLeave" | "open" | "onClose" | "variant"
> & {
  onConfirm: (() => Promise<void>) | (() => void);
  title: string;
  description?: React.ReactNode;
  disableConfirmationButton?: boolean;
  disableConfirmationButtonTooltip?: React.ReactNode;
  confirmationButtonText?: string;
  cancelationButtonText?: string;
  children?: React.ReactNode;
};

export const ConfirmationModal: React.FC<ConfirmationModalPropsT> = ({
  open,
  title,
  description,
  onConfirm,
  children,
  confirmationButtonText = "Confirm",
  cancelationButtonText = "Cancel",
  size,
  afterLeave,
  onClose,
  variant,
  disableConfirmationButton = false,
  disableConfirmationButtonTooltip,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const onConfirmClick = async () => {
    setLoading(true);
    try {
      await onConfirm();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      afterLeave={() => {
        afterLeave?.();
        setLoading(false);
      }}
      open={open}
      size={size}
      variant={variant}
      onClose={onClose}
    >
      <Modal.Header description={description}>{title}</Modal.Header>
      <Modal.Content>{children}</Modal.Content>
      <Modal.Footer
        primaryButton={
          <Tooltip
            body={disableConfirmationButtonTooltip}
            disabled={!disableConfirmationButton}
          >
            <Button
              dataLoc="confirm"
              disabled={disableConfirmationButton}
              loading={loading}
              variant={variant === "danger" ? "warning" : "primary"}
              fullWidth
              onClick={onConfirmClick}
            >
              {confirmationButtonText}
            </Button>
          </Tooltip>
        }
        secondaryButton={cancelationButtonText}
      />
    </Modal>
  );
};
