import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowUpRight,
  faQuestionCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { capitalize } from "lodash";
import React from "react";

import { Pill } from "src/base-components/Pill";

type Props = {
  name: string;
  onClick: VoidFunction;
  entityIcon: IconProp;
};

export const LinkedEntityPill: React.FC<Props> = ({
  name,
  onClick,
  entityIcon = faQuestionCircle,
}) => {
  return (
    <Pill size="sm" variant="gray" onClick={onClick}>
      <Pill.Icon icon={entityIcon} />
      <Pill.Text>{capitalize(name)}</Pill.Text>
      <Pill.Icon icon={faArrowUpRight} />
    </Pill>
  );
};
