import { faScaleUnbalancedFlip } from "@fortawesome/pro-regular-svg-icons";

import { OutcomeReport } from "src/api/types";
import { DataList, DataValue } from "src/base-components/DataList";
import { SkeletonPlaceholder } from "src/base-components/SkeletonPlaceholder";
import { EmptyState } from "src/design-system/EmptyState";
import {
  useOutcomeReportsForAllTypes,
  useOutcomeTypes,
} from "src/outcomes/queries";

type OutcomesTabContentProps = {
  decisionId: string | undefined;
  flowId: string | undefined;
};

type OutcomeTypeReportsProps = {
  outcomeTypeName: string | undefined;
  outcomes: OutcomeReport[];
};

export const OutcomesTabContent: React.FC<OutcomesTabContentProps> = ({
  decisionId,
  flowId,
}) => {
  const { data: outcomeTypes, isLoading: isLoadingOutcomeTypes } =
    useOutcomeTypes({ flowId: flowId ?? "" });
  const {
    data: outcomeReportsDataForAllTypes,
    isLoading: isLoadingOutcomeReports,
  } = useOutcomeReportsForAllTypes(decisionId ?? "", outcomeTypes);

  if (isLoadingOutcomeTypes || isLoadingOutcomeReports) {
    return (
      <div
        className="mx-4 flex-col rounded-md border border-b border-gray-200 p-4 pl-3"
        data-loc="loading-container"
      >
        <div className="mb-3">
          <SkeletonPlaceholder height="h-5" />
        </div>
        <div>
          <div className="divide-y divide-gray-100 rounded-lg bg-gray-50 p-2">
            <DataList loadingRows={4} dividers isLoading />
          </div>
        </div>
      </div>
    );
  }

  if (!outcomeReportsDataForAllTypes?.length) {
    return (
      <EmptyState
        actionButton={{
          onClick: () =>
            window.open(
              "https://docs.taktile.com/monitoring/decision-outcomes",
              "_blank",
            ),
          text: "Learn more",
        }}
        dataLoc="no-outcomes-container"
        description="No Outcomes have been reported for this decision yet."
        headline="No Outcomes reported"
        icon={faScaleUnbalancedFlip}
      />
    );
  }

  return (
    <>
      <div
        className="h-full flex-col divide-y divide-gray-200 rounded-md border border-gray-200"
        data-loc="outcomes-reports-container"
      >
        {outcomeReportsDataForAllTypes.map((outcomeType) => (
          <OutcomeTypeReports
            key={outcomeType.outcomeTypeName}
            {...outcomeType}
          />
        ))}
      </div>
    </>
  );
};

const OutcomeTypeReports: React.FC<OutcomeTypeReportsProps> = ({
  outcomeTypeName,
  outcomes,
}: OutcomeTypeReportsProps) => {
  return (
    <div className="w-full p-4 pl-3">
      <h2 className="mb-3 text-gray-800 font-inter-semibold-13px">
        {outcomeTypeName}
      </h2>
      <div className="flex max-h-[300px] flex-col gap-y-3 overflow-y-auto">
        {outcomes.map((outcome) => (
          <div
            key={outcome.id}
            className="divide-y divide-gray-100 rounded-lg border border-gray-100 bg-gray-50 p-2"
          >
            <DataList
              rows={Object.entries(outcome.payload).map(([key, value]) => [
                key,
                <DataValue field={key} value={value} />,
              ])}
              dividers
            />
          </div>
        ))}
      </div>
    </div>
  );
};
