import {
  faArrowRight,
  faBracketsCurly,
  faCircleNotch,
  faInfoCircle,
  faPlug,
  faWarning,
} from "@fortawesome/pro-regular-svg-icons";
import { AxiosError } from "axios";
import { endOfDay, isBefore, startOfDay, startOfMinute, sub } from "date-fns";
import { m } from "framer-motion";
import { orderBy } from "lodash";
import { useEffect, useState } from "react";

import { DecisionsOutcomeFilter } from "src/api/decisionHistoryV2/decisionHistoryQueries";
import { FlowT, FlowVersionT } from "src/api/flowTypes";
import { useFlowVersion } from "src/api/flowVersionQueries";
import { useWorkspace } from "src/api/queries";
import { Button } from "src/base-components/Button";
import {
  DateRange,
  DateRangePicker,
} from "src/base-components/DateRangePicker";
import { ErrorHint } from "src/base-components/ErrorHint";
import { FormItem } from "src/base-components/FormItem";
import { Icon } from "src/base-components/Icon";
import { Input } from "src/base-components/Input";
import { Pill } from "src/base-components/Pill";
import { Select, SELECT_DIVIDER } from "src/base-components/Select";
import { SimpleRadioGroup } from "src/base-components/SimpleRadioGroup";
import { Switch } from "src/base-components/Switch";
import { FlowVersionStatus, TrafficPolicyInDB } from "src/clients/flow-api";
import { DownloadButton } from "src/datasets/AssembleDatasetModal/DownloadButton";
import { EmptyState } from "src/datasets/AssembleDatasetModal/EmptyState";
import { OutcomesFilter } from "src/datasets/AssembleDatasetModal/OutcomesFilter";
import { AssembleDataTable } from "src/datasets/AssembleDatasetModal/Table";
import { TableColumns } from "src/datasets/AssembleDatasetModal/TableColumns";
import { TableSkeleton } from "src/datasets/AssembleDatasetModal/TableSkeleton";
import { useAvailableIntegrationNodes } from "src/datasets/DatasetTable/hooks";
import {
  useCreateAssembleDatasetJob,
  useHistoryData,
} from "src/datasets/api/queries";
import { EmptyState as CommonEmptyState } from "src/design-system/EmptyState";
import { Modal } from "src/design-system/Modal";
import { toastActions } from "src/design-system/Toast/utils";
import { Tooltip } from "src/design-system/Tooltip";
import { POSSIBLE_DECISION_STATUS_CODES } from "src/flow/decisionHistory/SharedStatusColumn";
import { SchemaOptions } from "src/router/SearchParams";
import { AuthorPageParamsT } from "src/router/urls";
import { SchemaConverter } from "src/schema/utils";
import { formatDate } from "src/utils/datetime";
import { useParamsDecode } from "src/utils/useParamsDecode";

/**
 * When initialized with a traffic policy id as prop, it automatically
 * sets the date range mode to "traffic_policy"
 */
type Props = {
  open: boolean;
  onClose: () => void;
  flow: FlowT;
  trafficPolicies: TrafficPolicyInDB[];

  trafficPolicyIdDefaultOverride?: string;
};

// We don't allow pending decisions to be added to datasets
const CREATE_DATASET_DECISION_CODES = POSSIBLE_DECISION_STATUS_CODES.filter(
  (entry) => entry.key !== "202",
);

const EXPORT_LIMIT = 100_000;
const SAMPLE_SIZE_DEFAULT = 10_000;

const dateRangeCanBeTimeWindow = (
  dateRange: DateRange | undefined,
): dateRange is { from: Date; to: Date } => {
  return !!dateRange && !!dateRange.from && !!dateRange.to;
};

const dateRangeToTimeWindow = (dateRange: {
  from: Date;
  to: Date;
}): [string, string] => {
  const startDate = startOfDay(dateRange.from);
  const endDate = endOfDay(dateRange.to);

  return [startDate.toISOString(), endDate.toISOString()];
};

const getTimeWindowForTrafficPolicy = (
  trafficPolicy: TrafficPolicyInDB,
): [string, string] => {
  // Fallback in case the policy has no time windows
  if (
    !trafficPolicy.traffic_policy_windows ||
    trafficPolicy.traffic_policy_windows.length === 0
  ) {
    return [trafficPolicy.created_at, startOfMinute(new Date()).toISOString()];
  }
  const timeWindowsSorted = trafficPolicy.traffic_policy_windows
    .slice()
    .sort(
      (a, b) =>
        new Date(a.created_at).valueOf() - new Date(b.created_at).valueOf(),
    );

  /* The time window of a policy is a date range between
     the start date of its earliest time window and the end
     date of its latest time window. If the latest time window
     is still active, then it sets the current date.
  */
  const startOfFirstWindow = timeWindowsSorted[0].starts_at;
  const endOfLastWindow =
    timeWindowsSorted.at(-1)?.ends_at ??
    startOfMinute(new Date()).toISOString();

  return [startOfFirstWindow, endOfLastWindow];
};

const getReadableTrafficPolicyTimeWindow = (
  trafficPolicy: TrafficPolicyInDB,
) => {
  if (
    !trafficPolicy.traffic_policy_windows ||
    trafficPolicy.traffic_policy_windows.length === 0
  ) {
    return null;
  }

  const timeWindowsSorted = trafficPolicy.traffic_policy_windows
    .slice()
    .sort(
      (a, b) =>
        new Date(a.created_at).valueOf() - new Date(b.created_at).valueOf(),
    );

  const start = formatDate(timeWindowsSorted[0].starts_at, "MMMM d");

  const lastWindow = timeWindowsSorted.at(-1);
  const end = lastWindow?.ends_at
    ? formatDate(lastWindow.ends_at, "MMMM d")
    : "present";

  return `(${start} - ${end})`;
};

const useAssembleDatasetModalQuery = ({
  statusCodes,
  flowVersionIds,
  flowSlug,
  timeWindow,
  trafficPolicyId,
  subSamplingSize,
  outcomeFilters,
}: {
  statusCodes: string[];
  flowVersionIds: string[];
  flowSlug: string;
  timeWindow: [string, string];
  trafficPolicyId?: string;
  subSamplingSize?: number;
  outcomeFilters?: DecisionsOutcomeFilter[];
}) => {
  const { wsId } = useParamsDecode<AuthorPageParamsT>();
  const workspace = useWorkspace(wsId);

  return useHistoryData({
    flowVersionIds,
    timeWindow,
    statusCodes,
    baseUrl: workspace.data?.base_url ?? "",
    flowSlug,
    trafficPolicyId,
    subSamplingSize,
    outcomeFilters,
  });
};

const useCreateDatasetFromHistoryMutation = () => {
  const { wsId, version_id } = useParamsDecode<AuthorPageParamsT>();
  const workspace = useWorkspace(wsId);
  const version = useFlowVersion(version_id);

  return useCreateAssembleDatasetJob(workspace.data?.base_url, version.data);
};

export const AssembleDatasetModal: React.FC<Props> = (props) => {
  const [showBody, setShowBody] = useState(props.open);

  // Reset the whole body state
  // and avoid flickering during transitions
  useEffect(() => {
    if (props.open) {
      setShowBody(true);
    }
  }, [props.open]);

  return (
    <Modal
      afterLeave={() => setShowBody(false)}
      open={props.open}
      size="lg"
      onClose={props.onClose}
    >
      {showBody && <AssembleDatasetModalBody {...props} />}
    </Modal>
  );
};

const AssembleDatasetModalBody: React.FC<Props> = ({
  onClose,
  flow,
  trafficPolicies,
  trafficPolicyIdDefaultOverride,
}) => {
  const [mockNodeNamesToInclude, setMockNodeNamesToInclude] = useState<
    string[]
  >([]);
  const [outputColumnNamesToInclude, setOutputColumnNamesToInclude] = useState<
    string[]
  >([]);
  const { version_id } = useParamsDecode<AuthorPageParamsT>();
  const version = useFlowVersion(version_id);
  const mockableNodes = useAvailableIntegrationNodes(version.data);

  const flowPublishedAndArchivedVersions = flow.versions.filter(
    (version) =>
      version.status === FlowVersionStatus.PUBLISHED ||
      version.status === FlowVersionStatus.ARCHIVED,
  );

  const flowPublishedAndArchivedVersionOrdered = orderBy(
    flowPublishedAndArchivedVersions,
    ["status", (v) => new Date(v.created_at).valueOf()],
    ["desc", "asc"],
  );

  const { version_id: currentVersionId } = useParamsDecode<AuthorPageParamsT>();
  const currentVersionIsPublishedOrArchived =
    flowPublishedAndArchivedVersionOrdered.some(
      (v) => v.id === currentVersionId,
    );

  const flowNewestVersion = flowPublishedAndArchivedVersionOrdered.at(-1);

  const [selectedVersions, setSelectedVersions] = useState<string[]>(
    currentVersionIsPublishedOrArchived
      ? [currentVersionId]
      : flowNewestVersion
        ? [flowNewestVersion.id]
        : [],
  );

  /**
   * Date range (picker value) and time window (what's actually used)
   * are two separate states because date range can be in an intermediate
   * or undefined state (i.e. only the start is selected) and we want the
   * table to still use the last value until a convertable date range picker
   * value is selected again
   */
  const DEFAULT_DATERANGE = {
    from: sub(new Date(), { days: 1 }),
    to: new Date(),
  };
  const [dateRange, setDateRange] = useState<DateRange | undefined>(
    DEFAULT_DATERANGE,
  );
  const [timeWindow, setTimeWindow] = useState<[string, string]>(
    dateRangeToTimeWindow(DEFAULT_DATERANGE),
  );

  const [statusCodes, setStatusCodes] = useState<string[]>(
    CREATE_DATASET_DECISION_CODES.map((code) => code.key),
  );
  const [name, setName] = useState("");

  const [dateRangeMode, setDateRangeMode] = useState<
    "time_window" | "traffic_policy"
  >(trafficPolicyIdDefaultOverride ? "traffic_policy" : "time_window");

  const [enableRandomSampling, setEnableRandomSampling] = useState(false);
  const [sampleSize, setSampleSize] = useState(SAMPLE_SIZE_DEFAULT);

  /**
   * The default traffic policy selected can be, in this order of priority:
   * - One policy that was passed as prop
   * - The first policy of the flow
   */
  const defaultTrafficPolicyId =
    trafficPolicyIdDefaultOverride ?? trafficPolicies.at(0)?.id;

  const [selectedTrafficPolicyId, setSelectedTrafficPolicyId] = useState(
    defaultTrafficPolicyId,
  );

  const selectedTrafficPolicy = trafficPolicies.find(
    (p) => p.id === selectedTrafficPolicyId,
  );

  // Select the current traffic policy's versions
  // if the user tries to filter by that
  useEffect(() => {
    if (selectedTrafficPolicy?.policy && dateRangeMode === "traffic_policy") {
      const trafficPolicyVersions =
        selectedTrafficPolicy.policy.flow_versions.map(
          (version) => version.flow_version_id,
        );
      setSelectedVersions(trafficPolicyVersions);
    }
  }, [dateRangeMode, selectedTrafficPolicy]);

  const [outcomesFilter, setOutcomesFilter] =
    useState<Nullable<DecisionsOutcomeFilter>>(null);

  const result = useAssembleDatasetModalQuery(
    dateRangeMode === "time_window" || !selectedTrafficPolicy
      ? {
          timeWindow,
          flowSlug: flow.slug,
          flowVersionIds: selectedVersions,
          statusCodes,
          subSamplingSize: enableRandomSampling ? sampleSize : undefined,
          outcomeFilters: outcomesFilter ? [outcomesFilter] : undefined,
        }
      : {
          trafficPolicyId: selectedTrafficPolicy.id,
          flowSlug: flow.slug,
          flowVersionIds: selectedVersions,
          statusCodes,
          timeWindow: getTimeWindowForTrafficPolicy(selectedTrafficPolicy),
          subSamplingSize: enableRandomSampling ? sampleSize : undefined,
          outcomeFilters: outcomesFilter ? [outcomesFilter] : undefined,
        },
  );

  const createDatasetMutation = useCreateDatasetFromHistoryMutation();

  const handleOnSubmit = async () => {
    if (result.data) {
      try {
        if (dateRangeMode === "time_window" || !selectedTrafficPolicy) {
          await createDatasetMutation.mutateAsync({
            flow_id: flow.id,
            name,
            filters: {
              start_date: timeWindow[0],
              end_date: timeWindow[1],
              flow_versions: selectedVersions,
              status_codes: statusCodes,
              outcome_filters: outcomesFilter ? [outcomesFilter] : undefined,
            },
            sub_sampling_size: enableRandomSampling ? sampleSize : undefined,
            mock_node_names_to_include:
              mockNodeNamesToInclude.length > 0
                ? mockNodeNamesToInclude
                : undefined,
            output_column_names_to_include:
              outputColumnNamesToInclude.length > 0
                ? outputColumnNamesToInclude
                : undefined,
          });
        } else {
          const timeWindowForTrafficPolicy = getTimeWindowForTrafficPolicy(
            selectedTrafficPolicy,
          );
          await createDatasetMutation.mutateAsync({
            flow_id: flow.id,
            name,
            filters: {
              start_date: timeWindowForTrafficPolicy[0],
              end_date: timeWindowForTrafficPolicy[1],
              flow_versions: selectedVersions,
              status_codes: statusCodes,
              traffic_policy_id: selectedTrafficPolicy.id,
              outcome_filters: outcomesFilter ? [outcomesFilter] : undefined,
            },
            sub_sampling_size: enableRandomSampling ? sampleSize : undefined,
            mock_node_names_to_include: mockNodeNamesToInclude,
            output_column_names_to_include: outputColumnNamesToInclude,
          });
        }
        toastActions.success({
          title: "Dataset has been created",
        });
        onClose();
      } catch {
        toastActions.failure({
          title: "Something went wrong while trying to save the dataset",
        });
      }
    }
  };

  const queryIsEnabled = !!selectedVersions.length;

  const numberFormatter = new Intl.NumberFormat(undefined, {
    compactDisplay: "short",
    notation: "compact",
  });

  const numberOfDecisionsFormatted = result.data
    ? numberFormatter.format(result.data.total_count)
    : 0;

  const exportLimitFormatted = new Intl.NumberFormat().format(EXPORT_LIMIT);

  const numberOfDecisionsExceedLimit =
    !!result.data && result.data.limit_reached;

  const wrapWithSubSamplingTooltip = (children: React.ReactNode) => {
    return (
      <Tooltip
        body={`Please modify your filters to stay within this limit or generate a random sub-sample within this limit. The maximum number of decisions allowed in a single dataset is ${exportLimitFormatted} decisions.`}
        disabled={!(numberOfDecisionsExceedLimit && !enableRandomSampling)}
        footerAction={{
          text: "Enable random sampling",
          onClick: () => setEnableRandomSampling(true),
        }}
        placement="top"
        title="Dataset limit exceeded"
        asChild
      >
        <button className="cursor-default">{children}</button>
      </Tooltip>
    );
  };

  const downloadAvailable =
    result.data &&
    result.data.total_count > 0 &&
    (!numberOfDecisionsExceedLimit || enableRandomSampling);

  const submitAvailable = downloadAvailable && name;

  const startDateIsBeforeAvailableData = isBefore(
    new Date(timeWindow[0]),
    new Date("2023-04-01"),
  );

  const [sampleSizeError, setSampleSizeError] = useState<string | undefined>(
    undefined,
  );

  const [enableExpectedOutputs, setEnableExpectedOutputs] = useState(false);

  const [enableMockData, setEnableMockData] = useState(false);

  return (
    <>
      <Modal.Header>
        <div className="flex items-center">
          Create new historical dataset
          {queryIsEnabled && (
            <div className="ml-2.5 flex items-center">
              {result.isLoading ? (
                <Pill size="sm">
                  <Pill.Text>
                    <Icon
                      color="text-indigo-500"
                      icon={faCircleNotch}
                      size="xs"
                      spin
                    />
                  </Pill.Text>
                </Pill>
              ) : numberOfDecisionsExceedLimit && !enableRandomSampling ? (
                wrapWithSubSamplingTooltip(
                  <Pill size="sm" variant="red">
                    <Pill.Text>{`${numberOfDecisionsFormatted}+ decisions`}</Pill.Text>
                    <Pill.Icon icon={faWarning} />
                  </Pill>,
                )
              ) : result.data ? (
                <Pill size="sm">
                  <Pill.Text>{`${numberOfDecisionsFormatted}${
                    numberOfDecisionsExceedLimit ? "+" : ""
                  } decisions`}</Pill.Text>
                </Pill>
              ) : (
                <Pill size="sm" variant="red">
                  <Pill.Text>Error</Pill.Text>
                </Pill>
              )}
              {enableRandomSampling && (
                <>
                  <m.div
                    animate={{ opacity: 1 }}
                    className="mx-1"
                    initial={{ opacity: 0 }}
                  >
                    <Icon
                      color="text-gray-500"
                      icon={faArrowRight}
                      size="3xs"
                    />
                  </m.div>
                  <m.div
                    animate={{ opacity: 1 }}
                    initial={{ opacity: 0 }}
                    transition={{ delay: 0.3 }}
                  >
                    <Pill size="sm">
                      <Pill.Text>{`${numberFormatter.format(
                        sampleSize,
                      )} random decisions`}</Pill.Text>
                    </Pill>
                  </m.div>
                </>
              )}
            </div>
          )}
        </div>
      </Modal.Header>
      <Modal.Content noScroll>
        <div className="flex min-h-0 flex-1">
          <div className="decideScrollbar flex w-[424px] flex-col gap-y-5 overflow-auto border-r border-gray-200 py-6 pr-2">
            <FormItem gap={false} label="Name this dataset" isRequired>
              <Input
                data-loc="assemble-dataset-name-input"
                placeholder="e.g. Jan_March_2023"
                value={name}
                fullWidth
                onChange={(e) => setName(e.target.value)}
              />
            </FormItem>
            <FormItem
              description="Which Decision Flow should the historical data come from?"
              gap={false}
              label="Decision Flow"
              isRequired
            >
              <Select
                options={[
                  {
                    key: flow.id,
                    value: flow.name,
                  },
                ]}
                value={flow.id}
                disabled
              />
            </FormItem>
            <FormItem gap={false} label="Decision Flow version" isRequired>
              {flowPublishedAndArchivedVersions.length ? (
                <VersionsSelect
                  value={selectedVersions}
                  versions={flowPublishedAndArchivedVersionOrdered}
                  onChange={setSelectedVersions}
                />
              ) : (
                <p className="h-10 text-gray-500 font-inter-normal-12px">
                  No published or archived versions available for this Flow
                </p>
              )}
            </FormItem>
            <div>
              <SimpleRadioGroup
                className="mb-2 gap-x-13"
                orientation="horizontal"
                value={dateRangeMode}
                onValueChange={(value) =>
                  setDateRangeMode(value as "time_window" | "traffic_policy")
                }
              >
                <SimpleRadioGroup.Item
                  label="Date range"
                  labelClassName="text-xs-sm ml-2.5"
                  value="time_window"
                  boldLabel
                />
                <SimpleRadioGroup.Item
                  label="Routing policy"
                  labelClassName="text-xs-sm ml-2.5"
                  value="traffic_policy"
                  boldLabel
                />
              </SimpleRadioGroup>
              {dateRangeMode === "time_window" && (
                <>
                  <DateRangePicker
                    value={dateRange}
                    onChange={(range) => {
                      setDateRange(range);
                      if (dateRangeCanBeTimeWindow(range)) {
                        setTimeWindow(dateRangeToTimeWindow(range));
                      }
                    }}
                  />
                  {startDateIsBeforeAvailableData && (
                    <p className="mt-1 text-gray-500 font-inter-normal-12px">
                      Data before 1 Apr 2023 is not available
                    </p>
                  )}
                </>
              )}
              {dateRangeMode === "traffic_policy" &&
                (trafficPolicies.length ? (
                  <Select
                    dataLoc="assemble-dataset-traffic-policy-select"
                    options={trafficPolicies.map((policy) => ({
                      key: policy.id,
                      value: (
                        <span>
                          {policy.name}
                          <span className="ml-1.5 text-gray-400">
                            {getReadableTrafficPolicyTimeWindow(policy)}
                          </span>
                        </span>
                      ),
                    }))}
                    value={selectedTrafficPolicyId}
                    onChange={setSelectedTrafficPolicyId}
                  />
                ) : (
                  <p className="h-10 text-gray-500 font-inter-normal-12px">
                    No Routing Policies available for this flow
                  </p>
                ))}
            </div>
            <FormItem gap={false} label="Filter by status">
              <Select
                options={CREATE_DATASET_DECISION_CODES}
                placeholder="Select status"
                selectAllText="All statuses"
                value={statusCodes}
                multiple
                showSelectAllAsOption
                onChange={setStatusCodes}
              />
            </FormItem>
            <div className="flex justify-between">
              <FormItem
                description="Randomly select a subset of historical decisions"
                gap={false}
                label="Random sub-sampling"
              />
              <Switch
                enabled={enableRandomSampling}
                onChange={setEnableRandomSampling}
              />
            </div>
            {enableRandomSampling && (
              <m.div
                animate={{ opacity: 1 }}
                className="rounded-lg bg-gray-50 px-3 py-4"
                initial={{ opacity: 0 }}
              >
                <FormItem
                  description={`Maximum sample size is ${exportLimitFormatted} decisions.`}
                  gap="sm"
                  label="Sample size"
                  isRequired
                >
                  <div className="relative">
                    <div className="pointer-events-none absolute bottom-[1px] right-[1px] top-[1px] flex items-center justify-center rounded-r-lg border-l border-gray-200 bg-gray-100 px-2 text-gray-500 font-inter-normal-12px">
                      decisions
                    </div>
                    <Input
                      defaultValue={SAMPLE_SIZE_DEFAULT}
                      errored={!!sampleSizeError}
                      type="number"
                      fullWidth
                      onChange={(e) => {
                        const parsedValue = e.target.valueAsNumber;
                        if (isNaN(parsedValue)) {
                          setSampleSizeError("Invalid number");
                          return;
                        }
                        if (parsedValue > EXPORT_LIMIT) {
                          setSampleSizeError(
                            `Maximum sample size is ${exportLimitFormatted} decisions`,
                          );
                          return;
                        }
                        if (parsedValue < 1) {
                          setSampleSizeError(
                            `Minimum sample size is 1 decision`,
                          );
                          return;
                        }
                        setSampleSize(parsedValue);
                        setSampleSizeError(undefined);
                      }}
                    />
                  </div>
                  {sampleSizeError && <ErrorHint>{sampleSizeError}</ErrorHint>}
                </FormItem>
              </m.div>
            )}
            <div className="flex justify-between">
              <FormItem
                description="Select which nodes to mock in your Test Dataset. Selecting a Decision Flow node includes mock data for both its overall output and individual external responses within the Child Flow"
                gap={false}
                label={
                  <div className="flex items-center gap-x-1">
                    Limit mock data
                    <Tooltip
                      body="Select which integrations should have their historical data included as mock data in the Test Dataset. This data is used in test runs to reproduce the nodes’ historical behaviour. Removing unneeded mock data will make your Test Datasets smaller and may allow you to assemble more historical decisions. Not including mock data may cause issues with test running. Mock data for Loop nodes is always included."
                      placement="top"
                      title="Limit mock data"
                    >
                      <span className="flex">
                        <Icon
                          color="text-gray-500"
                          icon={faInfoCircle}
                          size="xs"
                        />
                      </span>
                    </Tooltip>
                  </div>
                }
              />
              <Switch enabled={enableMockData} onChange={setEnableMockData} />
            </div>
            {enableMockData && (
              <m.div animate={{ opacity: 1 }} initial={{ opacity: 0 }}>
                <Select
                  dropdownPlaceholder={
                    <CommonEmptyState
                      description="This Decision Flow does not contain any external connections."
                      headline="No connection nodes"
                      icon={faPlug}
                      size="sm"
                    />
                  }
                  options={mockableNodes.map((node) => ({
                    key: node.name,
                    value: node.name,
                  }))}
                  placeholder="Select mock node columns..."
                  value={mockNodeNamesToInclude}
                  multiple
                  onChange={setMockNodeNamesToInclude}
                />
              </m.div>
            )}
            {version.data?.output_schema &&
              Object.keys(version.data.output_schema).length > 0 && (
                <>
                  <div className="flex justify-between">
                    <FormItem
                      description="Select which Outputs to include as Expected Output in your Test Dataset"
                      gap={false}
                      label={
                        <div className="flex items-center gap-x-1">
                          Limit Expected Outputs
                          <Tooltip
                            body="Select which Flow Outputs to include as Expected Outputs in your Test Dataset. Expected Outputs let you compare a Flow’s Output against the historical Outputs to see how a Flow’s behaviour has changed in a Test Run. Removing unneeded Expected Outputs will make your Test Datasets smaller and may allow you to assemble more historical decisions."
                            placement="top"
                            title="Limit Expected Outputs"
                          >
                            <span className="flex">
                              <Icon
                                color="text-gray-500"
                                icon={faInfoCircle}
                                size="xs"
                              />
                            </span>
                          </Tooltip>
                        </div>
                      }
                    />
                    <Switch
                      enabled={enableExpectedOutputs}
                      onChange={setEnableExpectedOutputs}
                    />
                  </div>
                  {enableExpectedOutputs && (
                    <m.div animate={{ opacity: 1 }} initial={{ opacity: 0 }}>
                      <Select
                        dropdownPlaceholder={
                          <CommonEmptyState
                            description="This Decision Flow does not have an output schema. No Expected Outcomes can be added to this Test Dataset."
                            headline="No output schema"
                            icon={faBracketsCurly}
                            size="sm"
                          />
                        }
                        options={SchemaConverter.beToUI(
                          version.data.output_schema,
                          SchemaOptions.Output,
                        ).properties.map((property) => ({
                          key: property.fieldName,
                          value: property.fieldName,
                        }))}
                        placeholder="Select expected output columns..."
                        value={outputColumnNamesToInclude}
                        multiple
                        onChange={setOutputColumnNamesToInclude}
                      />
                    </m.div>
                  )}
                </>
              )}
            <OutcomesFilter
              value={outcomesFilter}
              onChange={setOutcomesFilter}
            />
          </div>
          <div className="min-w-0 flex-1 pl-4">
            {!queryIsEnabled ? (
              <EmptyState type="no_version_selected" />
            ) : !result.data ? (
              result.error ? (
                <EmptyState
                  type={
                    result.error instanceof AxiosError &&
                    result.error.response?.status === 408
                      ? "timeout"
                      : "unknown_error"
                  }
                />
              ) : (
                <TableSkeleton />
              )
            ) : result.data.total_count === 0 ? (
              <EmptyState type="no_rows_matching" />
            ) : (
              <AssembleDataTable
                columns={TableColumns({
                  requestColumnNames: result.data.request_column_names,
                })}
                data={result.data.preview}
              />
            )}
          </div>
        </div>
      </Modal.Content>
      <Modal.Footer
        primaryButton={
          <Tooltip
            disabled={!(!submitAvailable && !name && downloadAvailable)}
            placement="top"
            title="The dataset must have a name"
          >
            {wrapWithSubSamplingTooltip(
              <Button
                dataLoc="assemble-dataset-save-button"
                disabled={!submitAvailable}
                loading={createDatasetMutation.isLoading}
                variant="primary"
                onClick={handleOnSubmit}
              >
                Save dataset
              </Button>,
            )}
          </Tooltip>
        }
      >
        {wrapWithSubSamplingTooltip(
          <DownloadButton
            disabled={!downloadAvailable}
            downloadDetails={
              downloadAvailable
                ? dateRangeMode === "time_window" || !selectedTrafficPolicy
                  ? {
                      timeWindow,
                      flowSlug: flow.slug,
                      flowVersionIds: selectedVersions,
                      statusCodes,
                      subSamplingSize: enableRandomSampling
                        ? sampleSize
                        : undefined,
                      outcomeFilters: outcomesFilter
                        ? [outcomesFilter]
                        : undefined,
                    }
                  : {
                      trafficPolicyId: selectedTrafficPolicy.id,
                      flowSlug: flow.slug,
                      flowVersionIds: selectedVersions,
                      statusCodes,
                      timeWindow: getTimeWindowForTrafficPolicy(
                        selectedTrafficPolicy,
                      ),
                      subSamplingSize: enableRandomSampling
                        ? sampleSize
                        : undefined,
                      outcomeFilters: outcomesFilter
                        ? [outcomesFilter]
                        : undefined,
                    }
                : undefined
            }
          />,
        )}
      </Modal.Footer>
    </>
  );
};

const VersionSelectValue: React.FC<{ version: FlowVersionT }> = ({
  version,
}) => (
  <div className="flex justify-between">
    {version.name}{" "}
    {version.status === FlowVersionStatus.ARCHIVED && (
      <Pill variant="gray">
        <Pill.Text>Archived</Pill.Text>
      </Pill>
    )}
  </div>
);

const VersionsSelect: React.FC<{
  versions: FlowVersionT[];
  value: string[];
  onChange: React.Dispatch<React.SetStateAction<string[]>>;
}> = ({ versions, value, onChange }) => {
  const firstArchivedVersionIndex = versions.findIndex(
    (version) => version.status === FlowVersionStatus.ARCHIVED,
  );
  const options = versions.flatMap((version, index) => {
    const option = {
      key: version.id,
      value: <VersionSelectValue version={version} />,
    };

    if (firstArchivedVersionIndex === index) {
      return [SELECT_DIVIDER, option];
    }
    return [option];
  });
  return (
    <Select
      dataLoc="assemble-dataset-version-select"
      options={options}
      placeholder="Select Decision Flow version"
      selectAllText="All versions"
      value={value}
      multiple
      showSelectAllAsOption
      onChange={onChange}
    />
  );
};
