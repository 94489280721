import { offset } from "@floating-ui/dom";
import { toJS } from "mobx";
import { useCallback, useLayoutEffect, useRef } from "react";
import { v4 } from "uuid";
import { create } from "zustand";

import { ErrorBaseInfo } from "src/api/types";
import { useFloatingWindowsActions } from "src/base-components/FloatingWindow/hooks";
import { useGraphStore } from "src/store/StoreProvider";

type CopilotStore = ZustandStore<
  { nodeConversationIds: Record<string, string | null> },
  {
    setConversationId: (nodeId: string, conversationId: string) => void;
  }
>;

const useCopilotStore = create<CopilotStore>((set) => ({
  nodeConversationIds: {},
  actions: {
    setConversationId: (nodeId, conversationId) => {
      set((state) => ({
        nodeConversationIds: {
          ...state.nodeConversationIds,
          [nodeId]: conversationId,
        },
      }));
    },
  },
}));

const useCopilotStoreActions = () => useCopilotStore((state) => state.actions);
const useCopilotConversationId = (nodeId: string | null) =>
  useCopilotStore((state) => state.nodeConversationIds[nodeId ?? ""] ?? null);

/**
 * @note Should only be used inside components that are wrapped in observer to
 *       be able to access GraphStore
 */
export const useCopilotWindow = () => {
  const { open: openWindow } = useFloatingWindowsActions();
  const { selectedNode } = useGraphStore();
  const copilotTargetRef = useRef<HTMLDivElement | null>(null);
  const nodeConversationId = useCopilotConversationId(selectedNode?.id ?? "");
  const { setConversationId } = useCopilotStoreActions();

  const openCopilot = useCallback(
    (error?: ErrorBaseInfo) => {
      if (selectedNode?.id) {
        const selectedNodeId = selectedNode.id;
        const conversationId = nodeConversationId ?? v4();

        setConversationId(selectedNodeId, conversationId);

        openWindow(
          {
            id: "copilot",
            type: "copilot",
            target: copilotTargetRef,
            positionOptions: {
              placement: "left-start",
              middleware: [offset({ alignmentAxis: 10, mainAxis: 10 })],
            },
          },
          {
            conversationId,
            nodeId: selectedNodeId,
            error: error ? toJS(error) : undefined,
            resetConversationId: () => setConversationId(selectedNodeId, v4()),
          },
        );
      }
    },
    [selectedNode?.id, nodeConversationId, setConversationId, openWindow],
  );

  useLayoutEffect(() => {
    const nodeEditor = document.querySelector("[data-loc=node-editor-pane]");
    copilotTargetRef.current = nodeEditor as HTMLDivElement;
  }, []);

  return {
    openCopilot,
  };
};
