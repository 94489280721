import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { SortableContext } from "@dnd-kit/sortable";
import { faAdd } from "@fortawesome/pro-regular-svg-icons";
import { UseMutateFunction } from "@tanstack/react-query";
import { debounce } from "lodash";
import { isEmpty } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo } from "react";
import {
  useForm,
  useFieldArray,
  FieldErrorsImpl,
  Merge,
  FieldError,
  FormProvider,
} from "react-hook-form";

import { FlowVersionT, SchemaT } from "src/api/flowTypes";
import { SchemaUpdateT } from "src/api/flowVersionQueries";
import { useLockableChildFocused } from "src/authoringMultiplayerLock/useLockableChildFocused";
import { useAcquireVersionResourceLock } from "src/authoringMultiplayerLock/useVersionResourceLock";
import { Button } from "src/base-components/Button";
import { ResourceType } from "src/clients/flow-api";
import { SchemaOptions } from "src/router/SearchParams";
import { SchemaEditRow } from "src/schema/SchemaEditRow";
import { SchemaEmptyState } from "src/schema/SchemaEmptyState";
import {
  SchemaConverter,
  getNewProperty,
  PropertyUIT,
  SchemaUIT,
} from "src/schema/utils";
import { UPDATE_DEBOUNCE_WAIT_TIME } from "src/store/GraphStore";
import { useSubmitForm } from "src/utils/useSubmitForm";

type PropsT = {
  schema: SchemaT;
  setSchemaInvalid: (errored: boolean) => void;
  type: SchemaOptions;
  flowVersionId: string;
  immutable: boolean;
  mutateSchema: UseMutateFunction<
    FlowVersionT,
    unknown,
    SchemaUpdateT,
    unknown
  >;
};

export const SchemaEditor: React.FC<PropsT> = observer(
  ({
    schema,
    setSchemaInvalid,
    type,
    flowVersionId,
    immutable,
    mutateSchema,
  }) => {
    const isShowingInputSchema = type === "input";
    const editorRef = React.useRef<HTMLDivElement>(null);
    const editorHasLockableFocus = useLockableChildFocused({
      refList: [editorRef],
    });

    const { lockedByOtherUser, lockAcquired } = useAcquireVersionResourceLock(
      ResourceType.SCHEMA,
      flowVersionId,
      !immutable && editorHasLockableFocus,
    );

    const isReadonly = immutable || lockedByOtherUser;

    const isReactive =
      (!editorHasLockableFocus && !lockAcquired) || lockedByOtherUser;

    const formMethods = useForm<SchemaUIT>({
      mode: "onChange",
      shouldFocusError: false,
      defaultValues: SchemaConverter.beToUI(schema, type),
      ...(isReactive && {
        values: SchemaConverter.beToUI(schema, type),
      }),
    });

    const {
      control,
      getValues,
      formState: { errors },
      watch,
      reset,
    } = formMethods;

    const { fields, append, remove, move } = useFieldArray({
      control,
      name: "properties",
    });

    const mutateSchemaDebounce = useMemo(() => {
      const updateBackendSchema = (
        updatedSchema: SchemaUIT,
        type: SchemaOptions,
      ) => {
        const backendSchema = SchemaConverter.uiToBE(
          {
            $schema: updatedSchema.$schema,
            type: updatedSchema.type,
            properties: updatedSchema.properties,
          },
          type,
        );
        mutateSchema({ schema: backendSchema, type: type });
      };
      return debounce(updateBackendSchema, UPDATE_DEBOUNCE_WAIT_TIME);
    }, [mutateSchema]);

    useSubmitForm({
      onChange: (newValues) => {
        // We want to filter out the fields with empty names here
        mutateSchemaDebounce(
          {
            ...newValues,
            properties: newValues.properties.filter(
              (p: PropertyUIT) => p.fieldName !== "",
            ),
          },
          type,
        );
      },
      disabled: isReactive,
      watch: watch,
    });

    useEffect(() => {
      if (lockedByOtherUser) {
        // Cancel pending invocations of schema updates and reset the form
        // in case another user acquires a lock while editing the form.
        reset();
        mutateSchemaDebounce.cancel();
      } else {
        // Ensure debounced mutation is instantly performed when the schema editor is dismounted.
        return () => {
          mutateSchemaDebounce.flush();
        };
      }
    }, [lockedByOtherUser, mutateSchemaDebounce, reset]);

    useEffect(() => {
      setSchemaInvalid(!isEmpty(errors));
    }, [errors, errors.properties, setSchemaInvalid]);

    const onAddField = () => {
      if (isReadonly) {
        return;
      }
      append(getNewProperty());
    };

    const checkNameUnique = (name: string) =>
      getValues("properties").filter((p) => p.fieldName === name).length < 2;

    const handleDragEnd = (event: DragEndEvent) => {
      const { active, over } = event;
      if (over && active.id !== over.id) {
        const fieldToMove = fields.findIndex((field) => field.id === active.id);
        const fieldToInsert = fields.findIndex((field) => field.id === over.id);
        if (fieldToMove !== -1 && fieldToInsert !== -1) {
          move(fieldToMove, fieldToInsert);
        }
      }
    };

    return (
      <div
        ref={editorRef}
        className="decideScrollbar h-full overflow-y-auto pl-6 pr-5 pt-4"
      >
        {fields.length > 0 && (
          <>
            <h3 className="mb-4 font-inter-semibold-13px">Fields</h3>
            <div className="flex flex-row items-center gap-x-2 text-gray-500 font-inter-normal-12px">
              <div className="basis-[20px]" />
              <div className="grow">Name</div>
              <div className="w-[140px]">Type</div>
              <div
                className={
                  isShowingInputSchema ? "basis-[82px]" : "basis-[52px]"
                }
              />
            </div>
          </>
        )}
        {fields.length === 0 && (
          <SchemaEmptyState
            handleAddField={onAddField}
            isReadonly={isReadonly}
            type={type}
          />
        )}
        <form>
          <DndContext onDragEnd={handleDragEnd}>
            <SortableContext
              disabled={isReadonly}
              items={fields.map((param) => param.id)}
            >
              <FormProvider {...formMethods}>
                {fields &&
                  fields.map((field, index) => (
                    <SchemaEditRow
                      key={field.id}
                      checkNameUnique={checkNameUnique}
                      displaySensitiveToggle={isShowingInputSchema}
                      errorStatus={
                        errors.properties?.at?.(index) as Merge<
                          FieldError,
                          FieldErrorsImpl<PropertyUIT>
                        >
                      }
                      fieldIndex={index}
                      id={field.id}
                      property={field}
                      readonly={isReadonly}
                      type={type}
                      onDelete={() => remove(index)}
                    />
                  ))}
              </FormProvider>
            </SortableContext>
          </DndContext>

          {fields.length > 0 && (
            <div className="mb-8 mt-5">
              <Button
                dataLoc="add-schema-field"
                disabled={isReadonly}
                iconLeft={faAdd}
                variant="secondary"
                fullWidth
                onClick={onAddField}
              >
                Add field
              </Button>
            </div>
          )}
        </form>
      </div>
    );
  },
);
