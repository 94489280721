import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { createContext, useContext } from "react";
import { NavLinkProps, NavLink, To } from "react-router-dom";
import { twJoin } from "tailwind-merge";

import { Icon } from "src/base-components/Icon";
import { Tooltip } from "src/design-system/Tooltip";

type CollapsedContextProps = {
  collapsed: boolean;
};
const CollapsedContext = createContext<CollapsedContextProps | undefined>(
  undefined,
);
const useCollapsed = (): CollapsedContextProps => {
  const context = useContext(CollapsedContext);
  if (!context) {
    throw new Error(
      `The NavSideBar collapsed context must be used within a NavSideBar`,
    );
  }
  return context;
};

type CustomNavLinkProps = NavLinkProps & {
  disabled?: boolean;
};

const CustomNavLink: React.FC<CustomNavLinkProps> = ({ disabled, ...rest }) => {
  if (disabled) {
    const disabledState = {
      isActive: false,
      isPending: false,
      isTransitioning: false,
    } as const;
    return (
      <span
        className={
          typeof rest.className === "function"
            ? rest.className(disabledState)
            : rest.className
        }
      >
        {typeof rest.children === "function"
          ? rest.children(disabledState)
          : rest.children}
      </span>
    );
  }

  return <NavLink {...rest} />;
};

type NavItemProps = {
  title: string;
  message?: string;
  to: To;
  icon: IconProp;
  disabled?: boolean;
  dataLoc?: string;
  neverShowActive?: boolean;
  end?: boolean;
};

export const NavItem: React.FC<NavItemProps> = ({
  disabled,
  dataLoc,
  title,
  message,
  to,
  icon,
  neverShowActive = false,
  end = true,
}) => {
  const { collapsed } = useCollapsed();
  return (
    <Tooltip
      body={message}
      disabled={!(collapsed || Boolean(message))}
      placement="right"
      title={collapsed ? title : undefined}
      asChild
    >
      <li className="cursor-default">
        <CustomNavLink
          className={({ isActive }) =>
            twJoin(
              "flex items-center gap-x-2 rounded-lg p-1.5",
              isActive && !neverShowActive && "bg-gray-100",
              disabled ? "cursor-default" : "hover:bg-gray-50",
            )
          }
          data-loc={dataLoc}
          disabled={disabled}
          end={end}
          to={to}
        >
          <Icon
            color={disabled ? "text-gray-300" : "text-gray-500"}
            icon={icon}
            size="xs"
          />
          {!collapsed && (
            <span
              className={twJoin(
                "font-inter-medium-13px",
                disabled
                  ? "pointer-events-none text-gray-400"
                  : "text-gray-800",
              )}
            >
              {title}
            </span>
          )}
        </CustomNavLink>
      </li>
    </Tooltip>
  );
};

type NavSidebarProps = {
  collapsed: boolean;
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>> | null;
  children: React.ReactNode;
};
export const NavSidebar: React.FC<NavSidebarProps> = ({
  collapsed,
  setCollapsed,
  children,
}) => {
  return (
    <aside
      className={twJoin(
        "z-[1] h-full overflow-auto bg-white px-2.5 py-3.5 shadow-base",
        !collapsed && "w-52 flex-shrink-0",
      )}
    >
      <nav className="h-full" data-loc="flow-subnavigation">
        <CollapsedContext.Provider value={{ collapsed }}>
          <ul className="flex h-full flex-col gap-y-2">
            {children}
            <li
              className="h-full min-h-[32px] grow"
              onClick={() => setCollapsed?.((prev) => !prev)}
            />
          </ul>
        </CollapsedContext.Provider>
      </nav>
    </aside>
  );
};
