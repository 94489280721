import React from "react";

import { DecisionEnvironment } from "src/api/types";
import { TableComp } from "src/base-components/Table";
import { useEntities } from "src/entities/queries";
import { WhitePane } from "src/jobs/common/WhitePane";

type EntitiesTableProps = {
  schema: string;
  baseUrl: string;
};

export const EntitiesTable: React.FC<EntitiesTableProps> = ({
  schema,
  baseUrl,
}) => {
  const { data } = useEntities({
    schema: schema,
    env: DecisionEnvironment.LIVE,
    baseUrl,
  });

  return (
    <div className="m-5">
      <p>{schema}</p>
      <WhitePane>
        <TableComp
          columns={[]}
          data={data?.entities ?? []}
          frameClassName="w-full p-4"
          variant="compact"
        />
      </WhitePane>
    </div>
  );
};
