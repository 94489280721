import {
  faCheckCircle,
  faCopy,
  faEdit,
  faEye,
  faFlask,
  faTrashCan,
} from "@fortawesome/pro-regular-svg-icons";
import React, { useState } from "react";
import { twJoin } from "tailwind-merge";
import { v4 as uuid } from "uuid";

import { FlowVersionFlowChild } from "src/api/flowTypes";
import { EllipsisOptionsDropdown } from "src/base-components/OptionsDropdown/EllipsisOptionsDropdown";
import {
  MENU_DIVIDER,
  OptionsDropdownElement,
} from "src/base-components/OptionsDropdown/OptionsDropdownItems";
import { Skeleton } from "src/base-components/Skeleton";
import { FlowReviewConfiguration } from "src/clients/flow-api";
import { DescriptionRow } from "src/flow/DescriptionRow";
import { RelatedFlowsPill, SandboxDefaultPill } from "src/flow/Pills";
import { UserFromId } from "src/flow/User";
import { VersionNameExpandable } from "src/flow/VersionName";
import { JobsPill } from "src/flow/VersionsTable/JobsPill";
import { Cell, Row, Table } from "src/flow/VersionsTable/VersionsTable";
import { VersionsTableColumn } from "src/flow/VersionsTable/types";
import { getExportToJSONOption } from "src/flow/getExportToJSON";
import { ExcludesFalse } from "src/flow/types";
import {
  canRequestReview,
  publishingBlockedReason,
} from "src/flowContainer/versionActionConditions";
import { useFlowVersionReview } from "src/flowReview/api/queries";
import { useCapabilities } from "src/hooks/useCapabilities";
import { useJobs } from "src/jobs/api/queries";
import { useFlowContext } from "src/router/routerContextHooks";
import { getUrlToAuthoringPage } from "src/router/urls";
import { formatDate } from "src/utils/datetime";

type DraftVersionRowProps = {
  version: FlowVersionFlowChild;
  isDefaultSandbox: boolean;
  reviewConfig?: FlowReviewConfiguration;
  onSetDefaultSandbox: () => void;
  onDuplicate: () => void;
  onDuplicateToFlow: () => void;
  onEdit: () => void;
  onDelete: () => void;
  onRequestReview: () => void;
  onPublish: () => void;
  canDelete: boolean;
};

export const DraftVersionRow: React.FC<DraftVersionRowProps> = ({
  version,
  isDefaultSandbox,
  onSetDefaultSandbox,
  onDuplicate,
  onDuplicateToFlow,
  onEdit,
  onDelete,
  onRequestReview,
  onPublish,
  canDelete,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const { orgId, workspace, flow } = useFlowContext();
  const { data: review } = useFlowVersionReview(version.id);
  const { flowVersions } = useCapabilities();

  const canPublish = flowVersions.canPublish(flow);
  const options: OptionsDropdownElement[] = [
    { key: "Edit", action: onEdit, icon: faEdit },
    { key: "Duplicate", action: onDuplicate, icon: faCopy },
    getExportToJSONOption(flow.name, version),
    !isDefaultSandbox && {
      key: "Set Sandbox Default",
      action: onSetDefaultSandbox,
      icon: faFlask,
    },
    canPublish && MENU_DIVIDER,
    canRequestReview(flow, version.status, review) && {
      key: "Request review",
      icon: faEye,
      action: onRequestReview,
    },
    canPublish && {
      key: "Publish",
      disabled: publishingBlockedReason(flow, review),
      icon: faCheckCircle,
      action: onPublish,
    },
    flowVersions.canCreate && MENU_DIVIDER,
    flowVersions.canCreate && {
      key: "Duplicate to another Decision Flow",
      action: onDuplicateToFlow,
      icon: faCopy,
    },
    canDelete && MENU_DIVIDER,
    canDelete && { key: "Delete", action: onDelete, icon: faTrashCan },
  ].filter(Boolean as any as ExcludesFalse);

  const jobs = useJobs(workspace.base_url!, flow.id);

  const onMouseEnter = () => setIsHover(true);
  const onMouseLeave = () => setIsHover(false);
  const onClickExpand = () => setExpanded(!expanded);

  return (
    <>
      <Row
        className={twJoin("cursor-pointer", isHover && "!bg-gray-50")}
        to={getUrlToAuthoringPage(orgId, workspace.id, flow.id, version.id)}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Cell data-loc="version-name" font="inter-medium-13px">
          <VersionNameExpandable
            expanded={expanded}
            version={version}
            onClickExpand={onClickExpand}
          />
        </Cell>
        <Cell>
          <UserFromId userId={version.meta.created_by_id || ""} />
        </Cell>
        <Cell>
          <div className="whitespace-nowrap">
            {formatDate(version.updated_at)}
          </div>
        </Cell>
        <Cell>
          <JobsPill
            jobs={jobs.data?.filter(
              (job) => job.flow_version_id === version.id,
            )}
          />
        </Cell>
        <Cell>
          <div className="inline-flex items-center gap-2">
            {Boolean(version.child_flows?.length) && (
              <RelatedFlowsPill
                flows={version.child_flows!}
                includeVersions
                isParent
              />
            )}
            {Boolean(version.parent_flows?.length) && (
              <RelatedFlowsPill flows={version.parent_flows!} includeVersions />
            )}
          </div>
        </Cell>
        <Cell align="right">
          <div className="inline-flex items-center gap-2">
            {isDefaultSandbox && <SandboxDefaultPill />}
          </div>
        </Cell>
        <Cell align="right">
          {flowVersions.canEdit && (
            <EllipsisOptionsDropdown
              buttonClassName="text-gray-300 w-5"
              buttonDataLoc={`${version.name}-options`}
              elements={options}
              menuClassName="inline-block"
              timeoutDuration={200}
            />
          )}
        </Cell>
      </Row>

      {expanded && (
        <tr
          className={twJoin(
            "group border-t-transparent transition-colors duration-150",
            isHover && "bg-gray-50",
          )}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <DescriptionRow description={version.meta.release_note} />
        </tr>
      )}
    </>
  );
};

export const DraftVersionRowSkeleton: React.FC = () => (
  <tr>
    <Cell data-loc="version-name" font="inter-medium-13px">
      <Skeleton />
    </Cell>
    <Cell>
      <Skeleton />
    </Cell>
    <Cell>
      <Skeleton />
    </Cell>
    <Cell>
      <Skeleton />
    </Cell>
    <Cell>
      <Skeleton />
    </Cell>
    <Cell>
      <Skeleton />
    </Cell>
    <Cell align="right">
      <Skeleton />
    </Cell>
  </tr>
);

type DraftVersionsTableProps = {
  children: React.ReactNode;
  animate: boolean;
};

const DRAFT_TABLE_COLUMNS: VersionsTableColumn[] = [
  {
    id: uuid(),
    name: "Draft",
    width: "w-1/5",
  },
  { id: uuid(), name: "Created by", width: "w-1/6" },
  { id: uuid(), name: "Last edited", width: "w-1/6" },
  {
    id: uuid(),
    name: "Jobs",
    width: "w-1/8",
  },
  { id: uuid(), name: "Decision Flows", width: "w-1/8" },
  { id: uuid(), name: "" },
  { id: uuid(), name: "", width: "w-16" },
].filter(Boolean as any as ExcludesFalse);

export const DraftVersionsTable: React.FC<DraftVersionsTableProps> = ({
  children,
  animate,
}) => (
  <Table
    animate={animate}
    columns={DRAFT_TABLE_COLUMNS}
    dataLoc="draft-versions-table"
  >
    {children}
  </Table>
);
