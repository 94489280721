import axios from "axios";
import { isEmpty } from "lodash";
import { useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { FlowT, FlowVersionFlowChild, FlowVersionT } from "src/api/flowTypes";
import { useDuplicateFlowVersion } from "src/api/flowVersionQueries";
import { useFlows } from "src/api/queries";
import { FlowVersionDuplicateT } from "src/api/types";
import { Button } from "src/base-components/Button";
import { Checkbox } from "src/base-components/Checkbox";
import { ErrorHint } from "src/base-components/ErrorHint";
import { Input } from "src/base-components/Input";
import { Label } from "src/base-components/Label";
import { LoadingView } from "src/base-components/LoadingView";
import { Select } from "src/base-components/Select";
import { Modal } from "src/design-system/Modal";
import {
  getNameErrorMessage,
  groupRelatedFlows,
  nameValidations,
} from "src/flow/modals/utils";
import {
  tracker,
  trackingEvents,
} from "src/instrumentation/customTrackingEvents";
import { DashboardPageParamsT, getUrlToAuthoringPage } from "src/router/urls";
import { useUserData } from "src/store/AuthStore";
import { logger } from "src/utils/logger";
import { useParamsDecode } from "src/utils/useParamsDecode";

type DuplicateForm = Required<
  Pick<FlowVersionDuplicateT, "targetFlowId" | "include_comments" | "name">
>;

const FlowSelector: React.FC<{
  flows: FlowT[];
  value: string;
  onChange: (...event: any[]) => void;
  isErrored: boolean;
  version: FlowVersionFlowChild;
}> = ({ flows, value, onChange, isErrored, version }) => {
  const [parentFlowIds, _] = useMemo(
    () => groupRelatedFlows(version?.parent_flows ?? []),
    [version?.parent_flows],
  );
  const flowOptions = flows.map((flow) => ({
    key: flow.id,
    searchText: flow.name,
    value: <span>{flow.name}</span>,
    disabled:
      parentFlowIds.includes(flow.id) ||
      (parentFlowIds.length !== 0 && flow.child_flows?.length !== 0)
        ? "Cannot select this Decision Flow as it is a Parent Flow, and the version being duplicated is being called by another Decision Flow."
        : version.child_flows?.map((flow) => flow.id).includes(flow.id) ||
            (version.child_flows?.length !== 0 &&
              flow.parent_flows?.length !== 0)
          ? "Cannot select this Decision Flow as it is a Child Flow, and the version being duplicated calls another Decision Flow."
          : false,
  }));

  return (
    <Select
      dataLoc="flow-select"
      errored={isErrored}
      options={flowOptions}
      placeholder="Select Decision Flow"
      searchPlaceholder="Search a Decision Flow"
      value={value}
      clearAfterClose
      searchable
      onChange={onChange}
    />
  );
};

export const DuplicateVersionToFlowModal: React.FC<{
  open: boolean;
  onClose: () => void;
  version: FlowVersionFlowChild;
  afterLeave?: () => void;
}> = ({ open, onClose, version, afterLeave }) => {
  const { orgId, wsId } = useParamsDecode<DashboardPageParamsT>();
  const flowResult = useFlows({
    workspaceId: wsId,
  });
  const [submitError, setSubmitError] = useState<boolean>(false);
  const { mutateAsync: duplicateVersion } = useDuplicateFlowVersion();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    control,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<DuplicateForm>({
    mode: "onChange",
    defaultValues: { include_comments: true },
  });

  const { signed_in_user_id } = useUserData();

  const onFormConfirm = handleSubmit(async (data) => {
    try {
      const newVersion: FlowVersionT = await duplicateVersion({
        ...data,
        flowVersionId: version.id,
        created_by_id: signed_in_user_id ?? "",
        release_note: "",
      });
      tracker.emit(
        trackingEvents.createNewFlowVersion({
          source_flow_version_id: version.id,
          flow_version_name: data.name,
          description: "",
          flow_version_id: newVersion.id,
          flow_id: data.targetFlowId,
          organization_id: orgId,
        }),
      );
      onClose();
      navigate(
        getUrlToAuthoringPage(orgId, wsId, data.targetFlowId, newVersion.id),
      );
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.status === 409) {
        setError("name", { type: "notAvailable" }, { shouldFocus: true });
      } else {
        logger.error(e);
        setSubmitError(true);
      }
    }
  });

  return (
    <Modal
      afterLeave={afterLeave}
      autoFocus={false}
      open={open}
      size="sm"
      onClose={onClose}
    >
      <Modal.Header
        description="Choose the Decision Flow adjust the version details. The version
            will be duplicated in draft state."
      >
        Duplicate {version.name} to another Decision Flow
      </Modal.Header>
      <form onSubmit={onFormConfirm}>
        <Modal.Content>
          <Label required>Select Decision Flow</Label>
          <div className="mb-6">
            <Controller
              control={control}
              name="targetFlowId"
              render={(props) => (
                <LoadingView
                  queryResult={flowResult}
                  renderUpdated={(flows: FlowT[]) => (
                    <FlowSelector
                      flows={flows}
                      isErrored={Boolean(props.fieldState.error)}
                      value={props.field.value}
                      version={version}
                      onChange={props.field.onChange}
                    />
                  )}
                />
              )}
              rules={{ required: true }}
            />
          </div>
          <div className="mb-6">
            <Label required>Version Name</Label>
            <Input
              data-loc="version-name-input"
              errored={Boolean(errors.name)}
              placeholder="Type version name"
              type="text"
              fullWidth
              {...register("name", {
                validate: nameValidations,
                required: true,
              })}
            />
            <ErrorHint height="h-2.5">
              {errors.name && getNameErrorMessage(errors.name.type)}
            </ErrorHint>
          </div>
          <Controller
            control={control}
            name="include_comments"
            render={(props) => (
              <label className="flex items-center text-gray-800 font-inter-normal-13px">
                <Checkbox
                  checked={props.field.value}
                  className="mr-2"
                  onChange={props.field.onChange}
                />
                Duplicate comments to new Decision Flow version
              </label>
            )}
          />
          {submitError && (
            <ErrorHint className="mr-2 mt-2">
              Error submitting the data - please try again
            </ErrorHint>
          )}
        </Modal.Content>
        <Modal.Footer
          primaryButton={
            <Button
              dataLoc="save"
              disabled={!isEmpty(errors)}
              htmlType="submit"
              loading={isSubmitting}
              variant="primary"
            >
              Duplicate
            </Button>
          }
          secondaryButton="Cancel"
        />
      </form>
    </Modal>
  );
};
