import { useHistoryDecisionV2 } from "src/api/decisionHistoryV2/decisionHistoryQueries";
import { InspectDataBase } from "src/dataTable/DetailedView/tabs/InspectData";
import { DecisionRequest } from "src/decisionsOverview/DecisionsSidepane";
import { useWorkspaceContext } from "src/router/routerContextHooks";

type Props = {
  decisionId: string;
  type: "request" | "response";
};

export const DecisionFields: React.FC<Props> = ({ decisionId, type }) => {
  const { workspace } = useWorkspaceContext();
  const { data, isLoading } = useHistoryDecisionV2({
    decisionId,
    baseUrl: workspace.base_url!,
  });

  const rowData = (() => {
    if (data?.isQueryError || !data?.decision) {
      return undefined;
    }

    const decision = data.decision;

    return type === "request"
      ? (decision.request as DecisionRequest).data
      : (decision.response as DecisionRequest).data;
  })();

  return (
    <InspectDataBase
      accessedFields={[]}
      isFetching={isLoading}
      rowData={rowData}
      selectedRowIndex={null}
      withAccessedFields={false}
      borders
      dividers
      isHistorical
    />
  );
};
