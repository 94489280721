export enum ConnectionDataSources {
  "live" = "production",
  "sandbox_mode_data_source" = "sandbox_mode_data_source",
  "authoring_mode_data_source" = "authoring_mode_data_source",
}

export enum ConnectionDataSourcesValues {
  "production" = "production",
  "sandbox" = "sandbox",
  "mock_data" = "mock_data",
  "empty_response" = "empty_response",
}

export enum ConnectionDataSourcesLabels {
  "production" = "Production credentials",
  "sandbox" = "Sandbox credentials",
  "mock_data" = "Mock data",
  "empty_response" = "Empty response",
}

export type EnvironmentsConfigT = Record<
  ConnectionDataSources,
  ConnectionDataSourcesValues | null
>;
