export const HighlightedText = ({
  text,
  highlight,
  highlightClassName,
}: {
  text?: Nullable<string>;
  highlight?: Nullable<string>;
  highlightClassName: string;
}) => {
  const parts =
    text && highlight
      ? text.split(new RegExp(`(${highlight})`, "gi"))
      : [text ?? ""];

  return (
    <span>
      {parts.map((part, i) =>
        part.toLowerCase() === highlight?.toLowerCase() ? (
          <span key={i} className={highlightClassName}>
            {part}
          </span>
        ) : (
          part
        ),
      )}
    </span>
  );
};
