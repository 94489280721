import { faClock } from "@fortawesome/pro-regular-svg-icons";
import React from "react";

import {
  DecisionHistoryRecordV2,
  useHistoryDecisionV2,
} from "src/api/decisionHistoryV2/decisionHistoryQueries";
import { Icon } from "src/base-components/Icon";
import { HistoricalDecisionV3 } from "src/clients/history-v3";
import { DecisionFields } from "src/decisionsOverview/DecisionFields";
import { DecisionsSidepane } from "src/decisionsOverview/DecisionsSidepane";
import { ErrorTabContent } from "src/decisionsOverview/ErrorTabContent";
import { OutcomesTabContent } from "src/decisionsOverview/OutcomesTabContent";
import { PendingOutputTabContent } from "src/decisionsOverview/PendingOutputTabContent";
import { hasInputSchemaError } from "src/decisionsOverview/utils";
import { ExcludesFalse } from "src/flow/types";

export type DecisionSidepaneProps = {
  baseUrl: string;
  isOpen: boolean;
  decision: HistoricalDecisionV3 | null;
  onClose: () => void;
  widthClass: string;
};

export const DecisionsSidepaneWrapper: React.FC<DecisionSidepaneProps> = ({
  baseUrl,
  decision,
  ...props
}) => {
  const { data: decisionV2Fetch } = useHistoryDecisionV2({
    baseUrl: baseUrl,
    decisionId: decision?.id!,
  });

  const decisionV2:
    | (DecisionHistoryRecordV2 & {
        is_error: boolean;
      })
    | undefined = decisionV2Fetch?.isQueryError
    ? undefined
    : decisionV2Fetch?.decision;
  const isPendingDecision = decision?.status_code === "202";
  const isInputSchemaError = decisionV2
    ? hasInputSchemaError(decisionV2)
    : false;

  const tabs = [
    {
      label: "Input",
      key: "input-data-tab",
      content:
        decisionV2?.is_error && isInputSchemaError ? (
          <ErrorTabContent decision={decisionV2} />
        ) : decisionV2 && decisionV2.request ? (
          <DecisionFields decisionId={decisionV2.id} type="request" />
        ) : null,
    },
    !isInputSchemaError && {
      label: (
        <span className="flex items-center gap-x-1.5">
          Output
          {isPendingDecision && (
            <Icon color="text-gray-500" icon={faClock} size="xs" />
          )}
        </span>
      ),
      key: "output-data-tab",
      content:
        decision?.status_code === "200" && !decisionV2?.is_error ? (
          <DecisionFields decisionId={decision.id} type="response" />
        ) : isPendingDecision && decisionV2 ? (
          <PendingOutputTabContent decision={decisionV2} />
        ) : decisionV2?.is_error ? (
          <ErrorTabContent decision={decisionV2} />
        ) : null,
    },
    {
      label: "Outcomes",
      key: "outcomes-data-tab",
      content: (
        <OutcomesTabContent
          decisionId={decisionV2?.id}
          flowId={decisionV2?.flow.id}
        />
      ),
    },
  ].filter(Boolean as unknown as ExcludesFalse);

  return (
    <DecisionsSidepane
      {...props}
      decision={decision}
      defaultTab="output-data-tab"
      isInputSchemaError={isInputSchemaError}
      tabs={tabs}
    />
  );
};
