import {
  SchemaT,
  SchemaTypesBET,
  PropertyTypeUIT,
  PropertyValueT,
  SchemaTypesT,
  PropertyTypeBET,
  SchemaTypesBE,
  PropertyTitleBET,
  EnumOptionsBET,
} from "src/api/flowTypes";
import { SchemaOptions } from "src/router/SearchParams";
import { isValidEnumOptionInput } from "src/schema/SchemaEnumRow";

export const defaultSchema: SchemaT = {
  $schema: "https://json-schema.org/draft/2020-12/schema",
  properties: {},
  required: [],
  order: [],
  sensitive: [],
  type: "object",
};

export type EnumValueObject = { value: string };

export type PropertyUIT = {
  fieldName: string;
  type: PropertyTypeUIT;
  required: boolean;
  timestamp: string;
  sensitive: boolean;
  enum?: EnumValueObject[];
};

export type SchemaUIT = {
  $schema: string;
  properties: PropertyUIT[];
  type: "object";
};

export type CommonSchemaEditRowPropsT = {
  rowName: `properties.${number}`;
  disabled: boolean;
};

export type SchemaTypeSelectorProps = CommonSchemaEditRowPropsT & {
  type: SchemaOptions;
  index: number;
};

export const getNewProperty = (): PropertyUIT => {
  return {
    fieldName: "",
    type: ["string", false],
    timestamp: Date.now().toString(),
    required: true,
    sensitive: false,
  };
};

const comparePropertiesByOrder =
  (order: string[] | undefined) => (a: PropertyUIT, b: PropertyUIT) => {
    if (!order) {
      return 0; // Keep original order
    }
    const firstIndex = order.indexOf(a.fieldName);
    const secondIndex = order.indexOf(b.fieldName);
    return firstIndex - secondIndex;
  };

export class PropertyConverter {
  static uiToBEEnum = (
    enumOptions: EnumValueObject[],
    nullable: boolean,
  ): EnumOptionsBET => {
    const beEnums: EnumOptionsBET = enumOptions.map(({ value: option }) => {
      const number = Number(option);
      if (!isNaN(number)) {
        return number;
      }

      if (isValidEnumOptionInput(option)) {
        return option.substring(1, option.length - 1);
      }

      return option;
    });
    if (nullable) {
      beEnums.push(null);
    }
    return beEnums;
  };
  static beToUIEnum = (enumOptions: EnumOptionsBET) => {
    return enumOptions
      .filter((option) => option !== null)
      .map((option) => {
        // This check is just to satisfy TS, in the filter above we make sure there are no nulls here
        if (option === null) {
          return { value: "" };
        }
        if (typeof option === "number") {
          return { value: String(option) };
        }
        if (option.includes('"')) {
          return { value: `'${option}'` };
        }

        return { value: `"${option}"` };
      });
  };
  static uiToBEProperty = (
    property: PropertyUIT,
    schemaType: SchemaOptions,
  ): PropertyValueT => {
    const type: PropertyTypeBET = this.typeSetter(property);
    const isNullable = property.type[1] === "null";
    if (isNullable) {
      type.push("null");
    }

    return {
      type,
      $comment: property.timestamp,
      format: this.formatSetter(property),
      title:
        schemaType === SchemaOptions.Input
          ? this.titleSetter(property)
          : undefined,
      ...(property.enum
        ? { enum: this.uiToBEEnum(property.enum, isNullable) }
        : {}),
      ...(property.type.includes("array") ? { items: {} } : {}),
    };
  };
  /**
   * We're setting schema title to make sure that the backend knows that
   * it needs to transform the fields to native date/datetime types. This is
   * required for preserving backwards compatibility with the old date/datetime fields.
   */
  static titleSetter = (property: PropertyUIT) => {
    const [type] = property.type;
    if (type === "datetime") {
      return "native-datetime";
    }
    if (type === "date") {
      return "native-date";
    }
    return undefined;
  };

  static formatSetter = (property: PropertyUIT) => {
    const [type] = property.type;
    if (type === "datetime" || type === "datetime-str") {
      return "date-time";
    }
    if (type === "date" || type === "date-str") {
      return "date";
    }
    return undefined;
  };

  static typeSetter = (property: PropertyUIT): SchemaTypesBET[] => {
    const [propertyType] = property.type;

    if (propertyType === "any") {
      // "any" is basically every type we support
      return Object.values(SchemaTypesBE);
    }
    if (propertyType === "enum") {
      return ["string", "number"];
    }

    if (
      propertyType === "datetime" ||
      propertyType === "date" ||
      propertyType === "datetime-str" ||
      propertyType === "date-str"
    ) {
      return ["string"];
    }

    return [propertyType];
  };

  static beToUiProperty = (
    schema: SchemaT,
    schemaType: SchemaOptions,
  ): PropertyUIT[] => {
    const property: PropertyUIT[] = Object.entries(schema.properties || {}).map(
      ([name, { type, $comment, format, title, ...restProperties }]) => ({
        fieldName: name,
        type: [
          this.typeSetterBE(
            type,
            format,
            schemaType,
            title,
            restProperties.enum,
          ),
          this.nullableSetterBE(type),
        ],
        timestamp: $comment,
        required: schema.required.includes(name),
        sensitive:
          schema.sensitive !== undefined
            ? schema.sensitive.includes(name)
            : false,
        enum: restProperties.enum
          ? this.beToUIEnum(restProperties.enum)
          : undefined,
      }),
    );
    return property;
  };

  static typeSetterBE = (
    type: PropertyTypeBET,
    format: any,
    schemaType: SchemaOptions,
    title: PropertyTitleBET,
    enumOptions: EnumOptionsBET | undefined,
  ): SchemaTypesT => {
    if (enumOptions !== undefined) {
      return "enum";
    }
    // Backward compatibility code, when type is just a string notation or undefined
    if (!type || typeof type === "string") {
      if (type === undefined) {
        return "any";
      }

      if (type === "string" && format === "date-time") {
        if (schemaType === SchemaOptions.Input) {
          if (title === "native-datetime") {
            return "datetime";
          }
          return "datetime-str";
        }
        // Output schema
        return "datetime";
      }

      if (type === "string" && format === "date") {
        if (schemaType === SchemaOptions.Input) {
          if (title === "native-date") {
            return "date";
          }
          return "date-str";
        }
        // Output schema
        return "date";
      }

      return type;
    }

    // Type is an array of types
    // Filter out "null" type value, and take what's left
    const types = type.filter<SchemaTypesBET>(
      (t): t is SchemaTypesBET => t !== "null",
    );

    // If there are more than 1 type in array
    // It is only case for any type
    if (types.length > 1) {
      return "any";
    }

    if (types[0] === "string" && format === "date-time") {
      if (schemaType === SchemaOptions.Input) {
        if (title === "native-datetime") {
          return "datetime";
        }
        return "datetime-str";
      }
      // Output schema
      return "datetime";
    }

    if (types[0] === "string" && format === "date") {
      if (schemaType === SchemaOptions.Input) {
        if (title === "native-date") {
          return "date";
        }
        return "date-str";
      }
      // Output schema
      return "date";
    }

    return types[0];
  };

  static nullableSetterBE = (type: PropertyTypeBET) => {
    if (Array.isArray(type)) {
      return type.includes("null") ? "null" : false;
    }

    if (type === undefined) {
      // Legacy `any` type, nullable by default
      return "null";
    }

    return false;
  };
}

export class SchemaConverter {
  static uiToBE(schema: SchemaUIT, schemaType: SchemaOptions): SchemaT {
    let convertedSchema: SchemaT = {
      $schema: schema.$schema,
      type: schema.type,
      properties: {},
      required: [],
      sensitive: [],
      order: [],
    };
    schema.properties.forEach((property) => {
      convertedSchema.properties = {
        ...convertedSchema.properties,
        [property.fieldName]: PropertyConverter.uiToBEProperty(
          property,
          schemaType,
        ),
      };
      if (property.required) {
        convertedSchema.required.push(property.fieldName);
      }
      // maintain schema order
      convertedSchema.order.push(property.fieldName);
      if (property.sensitive && convertedSchema.sensitive) {
        convertedSchema.sensitive.push(property.fieldName);
      }
    });
    return convertedSchema;
  }

  static beToUI(schema: SchemaT, schemaType: SchemaOptions): SchemaUIT {
    const convertedProperties: PropertyUIT[] = PropertyConverter.beToUiProperty(
      schema,
      schemaType,
    );

    const sortedProperties = [...convertedProperties].sort(
      comparePropertiesByOrder(schema.order),
    );

    return {
      $schema: schema.$schema,
      type: schema.type,
      properties: sortedProperties,
    };
  }
}
