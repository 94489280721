import { Edge } from "reactflow";

import { DecisionsOutcomeFilter } from "src/api/decisionHistoryV2/decisionHistoryQueries";
import { DatasetColumn, DatasetPurpose } from "src/api/types";
import {
  Chart,
  EdgeDb,
  FlowDb,
  FlowDbShallowVersionNoPolicy,
  FlowMeta,
  FlowVersionDb,
  FlowVersionDbShallowChildren,
  FlowVersionMeta,
  GraphDb,
  NodeDb,
  NodeGroupDb,
} from "src/clients/flow-api";
import { GroupNode, BeMappedNode } from "src/constants/NodeDataTypes";

export type GenericObjectT = { [key: string]: any };

export type NodeBET = Omit<NodeDb, "meta"> & {
  meta: GenericObjectT;
};

export type GraphT = Omit<GraphDb, "nodes" | "edges" | "node_groups"> & {
  nodes: NodeBET[];
  edges: EdgeDb[];
  node_groups: NodeGroupDb[];
};

// All the supported types from JSON Schema
export const SchemaTypesBE = {
  string: "string",
  boolean: "boolean",
  number: "number",
  integer: "integer",
  object: "object",
  array: "array",
} as const;

export const InputSchemaTypes = {
  ...SchemaTypesBE,
  "datetime-str": "datetime-str",
  "date-str": "date-str",
  datetime: "datetime",
  date: "date",
  any: "any",
  enum: "enum",
} as const;

export const OutputSchemaTypes = {
  ...SchemaTypesBE,
  datetime: "datetime",
  date: "date",
  any: "any",
  enum: "enum",
} as const;

export const SchemaTypes = {
  ...InputSchemaTypes,
  ...OutputSchemaTypes,
} as const;

type NullableT = "null";
export type SchemaTypesT = keyof typeof SchemaTypes;
export type SchemaTypesBET = keyof typeof SchemaTypesBE;

export type PropertyTypeUIT = [type: SchemaTypesT, nullable: NullableT | false];
export type PropertyTypeBET =
  // undefined and single SchemaTypesBET for backward compatibility
  undefined | SchemaTypesBET | (SchemaTypesBET | NullableT)[];
export type PropertyTitleBET = "native-date" | "native-datetime" | undefined;
export type EnumOptionsBET = (string | number | null)[];

// We use the $comment to store the unix timestamp when the property was created
export type PropertyValueT = {
  type: PropertyTypeBET;
  title: PropertyTitleBET;
  format?: string;
  $comment: string;
  items?: {};
  enum?: EnumOptionsBET;
};

// The sentitive field is currently only used for input schemas in the backend.
// With the upcoming rework it could makes sense to define separate Input and Ouput schema types.
export type SchemaT<T = PropertyValueT> = {
  $schema: string;
  type: "object";
  properties: Record<string, T>;
  required: string[];
  order: string[];
  sensitive?: string[];
};

export type FlowVersionMetaT = FlowVersionMeta & {
  number_decisions?: number;
};

export type FlowVersionT = Omit<
  FlowVersionDb,
  | "meta"
  | "input_schema"
  | "output_schema"
  | "graph"
  | "child_versions"
  | "parent_versions"
> & {
  name: string;
  graph?: GraphT;
  meta: Omit<
    FlowVersionMetaT,
    // deprecated fields
    | "created_by_avatar"
    | "created_by"
    | "updated_by"
    | "updated_by_avater"
    | "published_by"
    | "published_by_avatar"
    | "archived_by"
    | "archived_by_avatar"
  >;
  published_name?: string;
  /**
   * Not present on shallow versions
   */
  input_schema?: SchemaT;
  /**
   * Not present on shallow versions
   */
  output_schema?: SchemaT;
  /**
   * Only available on versions queried directly from a flow version endpoint
   */
  child_versions?: FlowVersionDb["child_versions"];
  /**
   * Only available on versions queried directly from a flow version endpoint
   */
  parent_versions?: FlowVersionDb["parent_versions"];
};

/**
 * Flow-api returns slightly different types depending on which endpoint returns them.
 * These extra fields are returned when the version is listed as part of a flow but not
 * when the version is queried on its own. We should consider switching to using backend types for flow-api.
 */
export type FlowVersionFlowChild = FlowVersionT & {
  child_flows?: FlowVersionDbShallowChildren["child_flows"];
  parent_flows?: FlowVersionDbShallowChildren["parent_flows"];
  review?: FlowVersionDbShallowChildren["review"] | null;
};

export type FlowT = Omit<
  FlowDb,
  "versions" | "charts" | "chart_order" | "meta" | "flow_folder_id"
> & {
  // Typed wrongly by the generated api (given as string? when the right typing is string | null)
  flow_folder_id: string | null;
  versions: FlowVersionFlowChild[];
  decisionsLastMonth?: string;
  parent_flows?: FlowDbShallowVersionNoPolicy[];
  child_flows?: FlowDbShallowVersionNoPolicy[];
  meta: FlowMeta;
  charts: Chart[];
  chart_order: string[];
  ui_default_decision_fields_exclude: string[];
  ui_default_inputs_include: string[];
  ui_default_outputs_include: string[];
  ui_default_outcomes_include: string[];
};

export interface DecisionFilters {
  flow_versions: string[];
  status_codes: string[];
  start_date: string;
  end_date: string;
  traffic_policy_id?: string;
  job_run_id?: string;
  outcome_filters?: DecisionsOutcomeFilter[];
}

export type CreateDatasetFromHistory = {
  name: string;
  flow_id: string;
  filters: DecisionFilters;
  input_columns_to_overwrite: DatasetColumn[];
  outcome_columns: DatasetColumn[];
  sub_sampling_size?: number;
  mock_node_names_to_include?: string[];
  output_column_names_to_include?: string[];
};

export type CreateDatasetFromScratch = {
  name: string;
  flow_id: string;
  input_columns: DatasetColumn[];
  mock_columns: DatasetColumn[];
  purpose: DatasetPurpose;
};

export type OrganizationT = {
  id: string;
  name: string;
  principal: string;
  allows_decide_access: boolean;
  allows_signup: boolean;
  whitelabel: boolean;
  github_id: number;
  google_hosted_domain: string | null;
};

export { FlowVersionStatus as FlowVersionStatusT } from "src/clients/flow-api";

export type NodeUpsertT = Record<string, Nullable<BeMappedNode>>;
export type EdgeUpsertT = Record<string, Nullable<Edge>>;
export type GroupUpsertT = Record<string, Nullable<GroupNode>>;

export type FlowGroup = {
  flow: FlowT;
};

export type FlowGroupForCount = {
  flowId: string;
  count: number;
};
