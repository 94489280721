import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons";
import { useState } from "react";
import { useFormContext, UseFormRegisterReturn } from "react-hook-form";

import { Input } from "src/base-components/Input";
import { Tooltip } from "src/design-system/Tooltip";

type PropsT = {
  dataLoc?: string;
  immutable: boolean;
  formProps: UseFormRegisterReturn;
  errorMessage?: string;
  maxTimeout?: number;
};

const handleNumericInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
  // Allow only numbers and special set of keys for the timeout input
  const validKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"];

  if (!/[0-9]/.test(event.key) && !validKeys.includes(event.key)) {
    event.preventDefault();
  }
};

export const TimeoutInput = ({
  dataLoc,
  immutable,
  formProps,
  errorMessage,
  maxTimeout,
}: PropsT) => {
  const { onChange, ...restOfProps } = formProps;

  const { getValues } = useFormContext();
  const [isErrored, setIsErrored] = useState(
    () => !!maxTimeout && parseInt(getValues(restOfProps.name)) > maxTimeout,
  );

  return (
    <Tooltip body={errorMessage} disabled={!isErrored}>
      <Input
        data-loc={dataLoc}
        disabled={immutable}
        errored={isErrored}
        placeholder="Enter timeout in seconds"
        suffixIcon={
          isErrored
            ? {
                icon: faExclamationTriangle,
                variant: "danger",
              }
            : undefined
        }
        onChange={(e) => {
          onChange(e);
          setIsErrored(
            !!maxTimeout && parseInt(e.currentTarget.value) > maxTimeout,
          );
        }}
        onKeyDown={handleNumericInput}
        {...restOfProps}
      />
    </Tooltip>
  );
};
