import { capitalize } from "lodash";

import { ManifestJSONSchema } from "src/api/connectApi/manifestTypes";
import {
  isFieldNumber,
  isFieldInteger,
} from "src/connections/config/manifest/utils";

const validateValue = (
  value: any,
  schema: ManifestJSONSchema,
  isRequired: boolean,
): boolean => {
  if (isRequired && (value === undefined || value === null || value === "")) {
    return false;
  }
  if (value === undefined) return true;
  if (isFieldInteger(schema) && !Number.isInteger(value)) {
    return false;
  }
  return !(isFieldNumber(schema) && isNaN(value));
};

const getValidationError = (
  value: any,
  schema: ManifestJSONSchema,
  isRequired: boolean,
  key: string,
): string | true => {
  if (isRequired && (value === undefined || value === null || value === "")) {
    return `${schema.title || capitalize(key)} is required`;
  }
  if (value === undefined) return true;
  if (isFieldInteger(schema) && !Number.isInteger(value)) {
    return "This field must contain an integer";
  }
  if (isFieldNumber(schema) && isNaN(value)) {
    return "This field must contain a number";
  }
  return true;
};

export const getValidationRules = (
  value: ManifestJSONSchema,
  isRequired: boolean,
  key: string,
) => {
  return {
    required: isRequired
      ? `${value.title || capitalize(key)} is required`
      : false,
    setValueAs: (v: any) => {
      if (isFieldInteger(value) || isFieldNumber(value)) {
        if (["", null, undefined].includes(v)) {
          return undefined;
        }
        if (v.toString().length !== v.toString().trim().length) {
          return NaN;
        }
        return Number(v);
      }
      return v;
    },
    validate: (v: any) =>
      validateValue(v, value, isRequired) ||
      getValidationError(v, value, isRequired, key),
  };
};
