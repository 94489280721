import {
  faBarsStaggered,
  faRefresh,
  faUserCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { useState } from "react";

import { Pill } from "src/base-components/Pill";
import { DecisionsTabContent } from "src/entities/entityView/DecisionsTabContent";
import { SubHeader } from "src/flow/SubHeader";
import { ExtendedHistoricalDecisionV3 } from "src/flow/decisionHistory/Columns";

type Props = {
  selectedDecision: ExtendedHistoricalDecisionV3 | null;
  onSelectDecision: (decision: ExtendedHistoricalDecisionV3 | null) => void;
};

export const EntityTabs: React.FC<Props> = ({
  selectedDecision,
  onSelectDecision,
}) => {
  const [activeTab, setActiveTab] = useState<string>("decisions-data-tab");
  const numberOfDecisions = 6;
  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };

  const tabs = [
    {
      label: (
        <div className="flex h-5 items-center gap-1">
          Decisions
          <Pill size="sm">
            <Pill.Icon icon={faUserCircle} />
            <Pill.Text>{numberOfDecisions}</Pill.Text>
          </Pill>
        </div>
      ),
      key: "decisions-data-tab",
      content: (
        <DecisionsTabContent
          selectedDecision={selectedDecision}
          onSelectDecision={onSelectDecision}
        />
      ),
    },
    {
      label: "Events",
      key: "events-data-tab",
      content: <div className="p-5">Events</div>,
    },
    {
      label: "Features",
      key: "features-data-tab",
      content: <div className="p-5">Features</div>,
    },
    {
      label: "Related Entities",
      key: "related-entities-data-tab",
      content: <div className="p-5">Related Entities</div>,
    },
  ];

  return (
    <div className="flex-1 overflow-x-hidden">
      <SubHeader.Tabs
        activeTab={activeTab}
        tabs={tabs}
        onChange={handleTabChange}
      >
        <SubHeader.Button
          icon={faBarsStaggered}
          tooltip="Reorder"
          onClick={() => {}}
        />
        <SubHeader.Button
          icon={faRefresh}
          tooltip="Refresh"
          onClick={() => {}}
        />
      </SubHeader.Tabs>
    </div>
  );
};
