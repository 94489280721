import { BeMappedNode } from "src/constants/NodeDataTypes";
import { NODE_TYPE } from "src/constants/NodeTypes";
import {
  RenderMentionFnArgs,
  renderSuggestion,
  renderTextContent,
} from "src/copilot/ConversationMessageItem";
import { NodeSuggestionType } from "src/copilot/NodeSuggestion";

export const NODE_SUGGESTION_OPEN = "<node_suggestion>";
const NODE_SUGGESTION_CLOSE = "</node_suggestion>";

export const NODE_MENTION_OPEN = "<node_mention>";
export const NODE_MENTION_CLOSE = "</node_mention>";

export const failureMessage =
  "I apologize, but I'm having trouble generating a valid response for this request. Despite multiple attempts, I couldn't find a solution that works within the current constraints. Our team has been notified of this issue and will investigate to improve future interactions. \n\n" +
  "To move forward, you could try:\n" +
  "1. Simplifying your request or breaking it down into smaller parts\n" +
  "2. Providing more context about what you're trying to achieve\n" +
  "3. Referencing other relevant nodes using @node_name to give me more information\n" +
  "4. Starting a new chat with a different approach \n\n" +
  "Thank you for your patience. I'm here to help if you'd like to try again with a new query.\n";

export const normalizeAnimationSpeed = (length: number) =>
  Math.min(0.0075 * length, 4);

type ReplaceNodeSuggestionsFnArgs = {
  baseKey: number;
  content: string;
  originalNode: BeMappedNode;
  onApply: (suggestion: NodeSuggestionType) => void;
  animate: boolean;
  baseAnimationDelay: number;
  isPublished: boolean;
};

type ReplaceNodeMentionsFnArgs = {
  content: string;
  renderMention: (args: RenderMentionFnArgs) => React.ReactNode[];
};

type SplitContentFnArgs = {
  content: string;
  openTag: string;
  closeTag: string;
};

const splitContent = ({ content, openTag, closeTag }: SplitContentFnArgs) => {
  const [tagOpenIndex, tagCloseIndex] = [
    content.indexOf(openTag),
    content.indexOf(closeTag),
  ];
  return [
    content.slice(0, tagOpenIndex),
    content.slice(tagOpenIndex + openTag.length, tagCloseIndex),
    content.slice(tagCloseIndex + closeTag.length),
  ];
};

export const replaceNodeMentions = ({
  content,
  renderMention,
}: ReplaceNodeMentionsFnArgs): React.ReactNode[] => {
  if (!content.includes(NODE_MENTION_OPEN)) {
    return [content];
  }

  const parts: React.ReactNode[] = [];
  let key = 0;
  let contentToSearch = content;

  while (true) {
    const [textBlock, mention, rest] = splitContent({
      content: contentToSearch,
      openTag: NODE_MENTION_OPEN,
      closeTag: NODE_MENTION_CLOSE,
    });

    parts.push(textBlock.trim());
    key++;

    parts.push(
      renderMention({
        nodeName: mention,
        key,
        delay: normalizeAnimationSpeed(textBlock.length),
      }),
    );
    key++;

    if (rest.includes(NODE_MENTION_OPEN)) {
      contentToSearch = rest;
    } else {
      parts.push(rest.trim());
      break;
    }
  }

  return parts;
};

export const replaceNodeSuggestions = ({
  baseKey,
  content,
  originalNode,
  onApply,
  animate,
  baseAnimationDelay,
  isPublished,
}: ReplaceNodeSuggestionsFnArgs): React.ReactNode[] => {
  if (!content.includes(NODE_SUGGESTION_OPEN)) {
    return [
      renderTextContent({
        animate,
        content,
        delay: baseAnimationDelay,
        key: baseKey,
      }),
    ];
  }

  const parts: React.ReactNode[] = [];
  let key = baseKey;
  let contentToSearch = content;
  let animationDelay = baseAnimationDelay;

  while (true) {
    const [textBlock, suggestion, rest] = splitContent({
      content: contentToSearch,
      openTag: NODE_SUGGESTION_OPEN,
      closeTag: NODE_SUGGESTION_CLOSE,
    });

    parts.push(
      renderTextContent({
        key,
        animate,
        content: textBlock.trim(),
        delay: animationDelay,
      }),
    );

    animationDelay += normalizeAnimationSpeed(textBlock.length);
    key++;

    parts.push(
      renderSuggestion({
        suggestion,
        originalNode,
        key,
        onApply,
        delay: animate ? animationDelay : 0,
        isPublished,
      }),
    );
    key++;

    if (rest.includes(NODE_SUGGESTION_OPEN)) {
      contentToSearch = rest;
    } else {
      parts.push(
        renderTextContent({
          key,
          animate,
          content: rest.trim(),
          delay: animationDelay,
        }),
      );
      break;
    }
  }

  return parts;
};

export const getIsCopilotEnabled = (nodeType: BeMappedNode) =>
  [
    NODE_TYPE.CODE_NODE,
    NODE_TYPE.ASSIGNMENT_NODE,
    NODE_TYPE.RULE_NODE_V2,
  ].includes(nodeType.type);
