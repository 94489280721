import { cloneDeep } from "lodash";
import Quill from "quill";

import { NodeBET } from "src/api/flowTypes";
import { BeMappedNode } from "src/constants/NodeDataTypes";
import { NODE_TYPE } from "src/constants/NodeTypes";
import { NODE_MENTION_OPEN, NODE_MENTION_CLOSE } from "src/copilot/utils";
import { GraphTransform } from "src/utils/GraphUtils";

export type MentionItemT = BeMappedNode & {
  id: string;
  value: string;
  index: number;
};

type InsertMention = {
  value: string;
  id: string;
};

const getMentionsData = (nodes: BeMappedNode[]) =>
  nodes
    .filter(
      (node) =>
        ![
          NODE_TYPE.INPUT_NODE,
          NODE_TYPE.OUTPUT_NODE,
          NODE_TYPE.SPLIT_BRANCH_NODE_V2,
          NODE_TYPE.SPLIT_MERGE_NODE,
        ].includes(node.type),
    )
    .map((node) => ({
      ...node,
      value: node.data.label,
    }));

export const filterQuillValues = (
  searchTerm: string,
  renderList: (values: BeMappedNode[], searchTerm: string) => void,
  nodes: BeMappedNode[],
) => {
  const mentionsData = getMentionsData(nodes);
  const normalizedSearchTerm = searchTerm.trim();

  if (normalizedSearchTerm.length === 0) {
    renderList(mentionsData, searchTerm);
  } else {
    const matches = [];
    for (let i = 0; i < mentionsData.length; i++)
      if (
        ~mentionsData[i].value
          .toLowerCase()
          .indexOf(normalizedSearchTerm.toLowerCase())
      )
        matches.push(mentionsData[i]);
    renderList(matches, normalizedSearchTerm);
  }
};

const getNodeDefinition = (nodeId: string, nodesArray: BeMappedNode[]) => {
  const node = nodesArray.find((node) => node.id === nodeId);
  return node ? GraphTransform.nodeReverseMapper(node) : null;
};

export const getQuillContent = (
  quill: Nullable<Quill>,
  nodesArray: BeMappedNode[],
) => {
  const mentionedNodes: NodeBET[] = [];

  const content = quill
    ?.getContents()
    .ops.map((op) => {
      if (typeof op.insert === "object" && op.insert?.mention) {
        const { value, id } = op.insert.mention as InsertMention;
        const nodeDefinition = getNodeDefinition(id, nodesArray);
        if (nodeDefinition) {
          mentionedNodes.push(nodeDefinition);
        }
        return NODE_MENTION_OPEN + value + NODE_MENTION_CLOSE;
      }
      return op.insert;
    })
    .join("");

  return { content, mentionedNodes };
};

export const quillOnSelect = (
  item: MentionItemT,
  insertItem: (item: MentionItemT) => void,
  nodes: BeMappedNode[],
) => {
  const node = nodes.find((node) => node.id === item.id);
  insertItem({ ...item, ...cloneDeep(node) });
};
