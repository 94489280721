import { ConnectionT, ProviderT } from "src/api/connectApi/types";
import {
  CONNECTIONS_MAP,
  providerInConfigMap,
} from "src/connections/connectionConfigs";
import { isManualReviewNodeConnection } from "src/connections/model/inboundWebhook";
import { assertUnreachable } from "src/utils/typeUtils";

export const getProviderDescription = (connection: ConnectionT): string => {
  const provider = connection.provider as ProviderT;
  if (providerInConfigMap(provider)) {
    return CONNECTIONS_MAP[provider]!.description;
  }

  switch (provider) {
    case "schufa": {
      return "Credit information in Germany since 1927";
    }
    case "boniversum": {
      return "Delivering B2C solvency information";
    }
    case "codat": {
      return "Connecting financial products for small businesses";
    }
    case "data_x": {
      return "Provides insights into a consumer's non-prime tradelines, payments, and inquiry activity not found in traditional credit files";
    }
    case "dnb": {
      return "Solutions that help you manage risk and compliance";
    }
    case "crif_b2b": {
      return "Supports companies and financial institutions in the management of their digital customer journey";
    }
    case "crif_b2c": {
      return "The end-to-end knowledge company";
    }
    case "crif_high_mark": {
      return "India's leading RBI-licensed credit bureau";
    }
    case "custom": {
      return "Add a Custom Connection";
    }
    case "equifax_uk": {
      return "Predictive consumer data to help make more profitable decisions";
    }
    case "equifax_us": {
      return "Make smarter, faster decisions with direct access to superior data and analytics";
    }
    case "equifax_us_commercial_risk": {
      // TODO: change this?
      return "Make smarter, faster decisions with direct access to superior data and analytics";
    }
    case "experian_us_b2b": {
      return "Data-driven solutions for every industry";
    }
    case "experian_us": {
      return "Global leader in consumer and business credit reporting and marketing services";
    }
    case "experian_us_clarity_services": {
      return "Experian's Clarity Services specializes in alternative financial services data and solutions";
    }
    case "creditsafe": {
      return "Understand the companies you do business with";
    }
    case "webhook": {
      if (isManualReviewNodeConnection(connection)) {
        return "Wait for human input";
      }
      return "Wait for an incoming webhook";
    }
    case "postgresql": {
      return "PostgreSQL";
    }
    case "bigquery": {
      return "BigQuery";
    }
    case "snowflake": {
      return "Snowflake";
    }
    case "redshift": {
      return "Redshift";
    }
    case "mysql": {
      return "MySQL";
    }
    case "retool": {
      return "Retool";
    }
    default: {
      assertUnreachable(provider);
    }
  }
  return "";
};

export const getProviderName = (provider: ProviderT): string => {
  if (providerInConfigMap(provider)) {
    return CONNECTIONS_MAP[provider]!.name;
  }
  switch (provider) {
    case "schufa": {
      return "SCHUFA";
    }
    case "boniversum": {
      return "Boniversum";
    }
    case "custom": {
      return "Custom";
    }
    case "codat": {
      return "Codat";
    }
    case "data_x": {
      return "DataX (an Equifax Company)";
    }
    case "dnb": {
      return "Dun & Bradstreet";
    }
    case "crif_b2b": {
      return "CRIF (B2B)";
    }
    case "crif_b2c": {
      return "CRIF (B2C)";
    }
    case "crif_high_mark": {
      return "CRIF High Mark";
    }
    case "equifax_uk": {
      return "Equifax (UK)";
    }
    case "equifax_us": {
      return "Equifax (US)";
    }
    case "equifax_us_commercial_risk": {
      return "Equifax Commercial Risk (US)";
    }
    case "experian_us_b2b": {
      return "Experian B2B (US)";
    }
    case "experian_us": {
      return "Experian B2C (US)";
    }
    case "experian_us_clarity_services": {
      return "Experian Clarity Services (US)";
    }
    case "creditsafe": {
      return "Creditsafe";
    }
    case "webhook": {
      return "Inbound Webhook";
    }
    case "postgresql": {
      return "PostgreSQL";
    }
    case "bigquery": {
      return "BigQuery";
    }
    case "snowflake": {
      return "Snowflake";
    }
    case "redshift": {
      return "Redshift";
    }
    case "mysql": {
      return "MySQL";
    }
    case "retool": {
      return "Retool";
    }
    default:
      assertUnreachable(provider);
      return "";
  }
};
