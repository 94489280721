import {
  faAsterisk,
  faBracketsSquare,
  faHashtag,
  faText,
  faToggleOff,
  faCalendar,
  faListUl,
  faTags,
} from "@fortawesome/pro-regular-svg-icons";
import { faBracketsCurly } from "@fortawesome/pro-solid-svg-icons";

export const COLORS = [
  "#8085FF",
  "#9ED334",
  "#FBBF24",
  "#38BDF8",
  "#F472B6",
  "#34D399",
  "#FB923C",
  "#60A5FA",
  "#FB7185",
  "#A78BFA",
  "#2DD4BF",
  "#0284C7",
  "#EA580C",
  "#22D3EE",
  "#10B981",
  "#F6E334",
  "#5946E5",
  "#DB2777",
  "#C9E909",
  "#6C459E",
];

export const COLORS_LIGHT = [
  "#EEF1FF",
  "#F9FDEC",
  "#FFFBEB",
  "#F0F9FF",
  "#FDF2F8",
  "#ECFDF5",
  "#FFF7ED",
  "#EFF6FF",
  "#FFF1F2",
  "#F5F3FF",
  "#F0FDFA",
  "#BAE6FD",
  "#FFEDD5",
  "#ECFEFF",
  "#D1FAE5",
  "#FEFCEB",
  "#E0E5FF",
  "#FCE7F3",
  "#FAFDE6",
  "#F0ECF5",
];

export const DECISION_ERROR_STATUS_CODES = [
  "500",
  "424",
  "422",
  "409",
  "408",
  "403",
] as const;

export const TYPE_ICONS = {
  string: faText,
  boolean: faToggleOff,
  number: faHashtag,
  integer: faHashtag,
  object: faBracketsCurly,
  array: faBracketsSquare,
  datetime: faCalendar,
  date: faCalendar,
  any: faAsterisk,
  enum: faListUl,
  tags: faTags,
};

export const MAX_DISPLAY_COUNT = 99;
