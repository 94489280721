import { v4 as uuidV4 } from "uuid";

import {
  defaultResourceConfig,
  getDefaultOutputMapping,
  getNamedOutputMapping,
} from "src/integrationNode/integrationResources/DefaultResourceConfig";
import {
  CommonOutputMappingKey,
  getCommonOutputMappings,
} from "src/integrationNode/integrationResources/common";
import {
  InputMappingGroupT,
  InputMappingT,
  InputMappingsT,
  IntegrationResourceT,
  OutputMappingT,
} from "src/integrationNode/types";

const getDefaultCrifB2CBankAccount = (): InputMappingGroupT => ({
  displayName: "bank account",
  getDefaultElements: () => ({
    type: {
      id: uuidV4(),
      type: "text",
      displayName: "Type",
      assignedTo: "",
      hint: `Possible values are "SEPA" or "BANK_ACCOUNT"`,
    },
    account_number: {
      id: uuidV4(),
      type: "text",
      displayName: "Account number",
      assignedTo: "",
      hint: `An identifier of the account (in SEPA format: IBAN code).`,
    },
    bank_code: {
      id: uuidV4(),
      type: "text",
      displayName: "Bank code",
      assignedTo: "",
      hint: `An identifier of the bank (in SEPA format: BIC code).`,
    },
  }),
});

const getBasicAddressFields = (): { [key: string]: InputMappingT } => ({
  street: {
    id: uuidV4(),
    type: "text",
    displayName: "Street",
    assignedTo: "",
    rules: {
      required: true,
    },
  },
  house_number: {
    id: uuidV4(),
    type: "text",
    displayName: "House number",
    assignedTo: "",
  },
  postal_code: {
    id: uuidV4(),
    type: "text",
    displayName: "Postal code",
    assignedTo: "",
    hint: `Note that either the postal code or the city must be given.`,
  },
  city: {
    id: uuidV4(),
    type: "text",
    displayName: "City",
    assignedTo: "",
    hint: `Note that either the postal code or the city must be given.`,
  },
  country: {
    id: uuidV4(),
    type: "text",
    displayName: "Country",
    assignedTo: "",
    hint: `Two-letter country code according to ISO 3166-1 alpha-2 (e.g, "DE" for Germany).`,
  },
});

const getDefaultCrifB2CAddress = (): InputMappingGroupT => ({
  getDefaultElements: getBasicAddressFields,
  elements: getBasicAddressFields(),
  rules: {
    required: true,
  },
});

const getDefaultAdditionalAddress = (): { [key: string]: InputMappingT } => {
  let basicFields = getBasicAddressFields();
  basicFields.street.rules = {
    ...basicFields.street.rules,
    required: false,
  };
  return {
    type: {
      id: uuidV4(),
      type: "text",
      displayName: "Type",
      assignedTo: "",
      hint: `The type of additional address (e.g., "former", "bill", "delivery", "employer")`,
    },
    first_name: {
      id: uuidV4(),
      type: "text",
      displayName: "First name",
      assignedTo: "",
    },
    last_name: {
      id: uuidV4(),
      type: "text",
      displayName: "Last name",
      assignedTo: "",
    },
    phone: {
      id: uuidV4(),
      type: "text",
      displayName: "Phone",
      assignedTo: "",
      hint: `The individual's phone number (containing country code and dialling code if possible).`,
    },
    email: {
      id: uuidV4(),
      type: "text",
      displayName: "Email",
      assignedTo: "",
    },
    ...basicFields,
  };
};

const crifB2CInputMapping = (): InputMappingsT => ({
  ungrouped: {
    customer_reference: {
      id: uuidV4(),
      type: "text",
      displayName: "Customer reference",
      assignedTo: "",
      hint: `Any ID you input here will be present in the API response from this provider. This can help you match a request to a response.`,
    },
    correlation_id: {
      id: uuidV4(),
      type: "text",
      displayName: "Correlation ID",
      assignedTo: "",
      hint: `You may add a transaction correlation ID from your own system here to trace the response. This basically provides the same functionality as the customer reference.`,
    },
    provider_country: {
      id: uuidV4(),
      type: "text",
      displayName: "Provider country",
      assignedTo: "",
      rules: {
        required: true,
      },
      hint: `ISO 3166-1 alpha-2 code of the country of the provider (e.g., credit bureau) delivering the data required for performing the check. For example, "AT" if the requested address shall be verified by an Austrian credit bureau. Default value is "DE" for Germany.`,
    },
    first_name: {
      id: uuidV4(),
      type: "text",
      displayName: "First name",
      assignedTo: "",
      rules: {
        required: true,
      },
    },
    last_name: {
      id: uuidV4(),
      type: "text",
      displayName: "Last name",
      assignedTo: "",
      rules: {
        required: true,
      },
    },
    gender: {
      id: uuidV4(),
      type: "text",
      displayName: "Gender",
      assignedTo: "",
      hint: `Possible values: "MALE", "FEMALE".`,
    },
    phone: {
      id: uuidV4(),
      type: "text",
      displayName: "Phone",
      assignedTo: "",
      hint: `The individual's phone number (containing country code and dialling code if possible).`,
    },
    email: {
      id: uuidV4(),
      type: "text",
      displayName: "Email",
      assignedTo: "",
    },
    date_of_birth: {
      id: uuidV4(),
      type: "text",
      displayName: "Date of birth",
      assignedTo: "",
      hint: `Must adhere to the ISO format "YYYY-MM-DD", e.g., "1960-12-20".`,
    },
  },
  grouped: {
    bank_account: getDefaultCrifB2CBankAccount(),
    address: getDefaultCrifB2CAddress(),
  },
  lists: {
    additional_addresses: {
      displayName: "Additional addresses",
      getDefaultElement: getDefaultAdditionalAddress,
      elements: [getDefaultAdditionalAddress()],
    },
  },
});

const getBaseCrifB2CInsights = (): { [key: string]: OutputMappingT } => ({
  ...getCommonOutputMappings(CommonOutputMappingKey.RETRIEVAL_DATETIME),
  identification_type: {
    ...getNamedOutputMapping("Identification type"),
    hint: `Tells you to what extent the requested person is known in the CRIF data universe. Possible values are: IDENTITY_IN_HOUSE, IDENTITY_IN_STREET, IDENTITY_IN_CITY, IDENTITY_IN_UNIVERSE, LASTNAME_IN_HOUSE, NOT_IDENTIFIED, OWNER.`,
  },
  location_identification_type: {
    ...getNamedOutputMapping("Location identification type"),
    hint: `Tells you to what extent the requested address is known in the CRIF data universe. Possible values are: HOUSE_CONFIRMED, STREET_CONFIRMED_HOUSE_NOT_PROVIDED, STREET_CONFIRMED_HOUSE_NOT_IDENTIFIED, CITY_CONFIRMED_STREET_NOT_PROVIDED, CITY_CONFIRMED_STREET_NOT_IDENTIFIED, NOT_PROVIDED, NOT_CONFIRMED.`,
  },
  ident_stability: {
    ...getNamedOutputMapping("Ident stability"),
    hint: `Evaluates anomalies of the requested address. It is assessed on a scale from A-D, with A being viewed as neutral. B-C indicate increased anomalies regarding the identification confirmation. D stands for a high risk of suspected fraud. Note that this insight is only available if you paid for CRIF's "Fraud Kit" feature.`,
  },
  trust_rank: {
    ...getNamedOutputMapping("Trust rank"),
    hint: `Rates the stability of a requested address on an integer scale from 1 to 10. The lower the trust rank value, the higher the risk of the requested address being suspected of being fraudulent. It is available only in Germany and only if you paid for CRIF's "Fraud Kit" feature.`,
  },
  traffic_light_score: {
    ...getNamedOutputMapping("Traffic light score"),
    hint: `A simple "GREEN", "YELLOW", or "RED" classification of the individual's creditworthiness. Note that this insight is only available if you paid for the "Decisioning Scoring" feature by CRIF.`,
  },
});

export const getDefaultCrifB2CCreditCheck = (): IntegrationResourceT => ({
  providerResource: {
    provider: "crif_b2c",
    resource: "credit_check",
  },
  connectionId: "",
  resourceConfigId: "",
  input: crifB2CInputMapping(),
  output: {
    default: getDefaultOutputMapping(),
    insights: {
      ...getBaseCrifB2CInsights(),
      consumer_probability_index: {
        ...getNamedOutputMapping("Consumer probability index"),
        hint: `An assessment of the person's creditworthiness, given as an integer between 250 (bad) and 572 (good). The value -1 is returned if the data basis for the score calculation is insufficient or if the individual could not be identified.`,
      },
    },
  },
  config: defaultResourceConfig,
});

export const getDefaultCrifB2CIdentityCheck = (): IntegrationResourceT => ({
  providerResource: {
    provider: "crif_b2c",
    resource: "identity_check",
  },
  connectionId: "",
  resourceConfigId: "",
  input: crifB2CInputMapping(),
  output: {
    default: getDefaultOutputMapping(),
    insights: getBaseCrifB2CInsights(),
  },
  config: defaultResourceConfig,
});
