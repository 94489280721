import {
  ColumnDef,
  HeaderGroup,
  Row,
  getCoreRowModel,
  useReactTable,
  flexRender,
} from "@tanstack/react-table";
import { times } from "lodash";
import React, { useRef } from "react";
import { VirtualItem, useVirtual } from "react-virtual";
import { twJoin, twMerge } from "tailwind-merge";

import { HistoricalDecisionV3 } from "src/clients/history-v3";
import { OPTIONS_COLUMN_ID } from "src/flow/decisionHistory/Columns";

export type FlowDecisionTableProps = {
  dataLoc?: string;
  data: HistoricalDecisionV3[];
  columns: ColumnDef<HistoricalDecisionV3, any>[];
  frameClassName?: string;
  columnVisibility?: Record<string, boolean>;

  canFetchNextPage: boolean;
  fetchNextPage: () => void;
  isFetching: boolean;
  selectedDecisionId?: string;
  onDecisionClick: {
    /**
     *
     * @param decision
     * predicate checks if the effect is available for the row
     * effect is the trigger effect for the row
     * @returns
     */
    predicate: (decision: HistoricalDecisionV3) => boolean;
    effect: (decision: HistoricalDecisionV3) => void;
  };
};

const renderRow = (
  row: Row<HistoricalDecisionV3>,
  virtualRow: VirtualItem,
  decisionOnClick: FlowDecisionTableProps["onDecisionClick"],
  selectedDecisionId?: string,
) => {
  return (
    <tr
      key={row.id}
      ref={virtualRow.measureRef}
      className={twJoin(
        "group/row group",
        decisionOnClick.predicate(row.original) && "cursor-pointer",
        row.original.id === selectedDecisionId &&
          "ring-1 ring-inset ring-indigo-600",
      )}
      data-loc={`decision-history-row-${row.original.status_code}`}
      onClick={() => decisionOnClick.effect(row.original)}
    >
      {row.getVisibleCells().map((cell) => {
        return (
          <td key={cell.id} className="border border-gray-100 p-0">
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
        );
      })}
    </tr>
  );
};

const renderHeaderGroup = (headerGroup: HeaderGroup<HistoricalDecisionV3>) => {
  return (
    <tr
      key={headerGroup.id}
      className={twJoin(
        "sticky top-0 bg-white bg-opacity-100",
        headerGroup.depth === 0 &&
          headerGroup.headers.filter(
            (header) => header.column.id !== OPTIONS_COLUMN_ID,
          ).length <= 1 &&
          "hidden",
      )}
    >
      {headerGroup.headers.map((column) => {
        const ctx = column.getContext();

        return (
          <th
            key={column.id}
            className="p-0"
            colSpan={column.colSpan}
            style={{
              minWidth: ctx.column.columnDef.minSize,
              maxWidth: ctx.column.columnDef.maxSize,
              width: ctx.column.columnDef.size ? column.getSize() : undefined,
            }}
          >
            {flexRender(column.column.columnDef.header, column.getContext())}
          </th>
        );
      })}
    </tr>
  );
};

export const TableComp: React.FC<FlowDecisionTableProps> = ({
  dataLoc,
  data,
  columns,
  frameClassName,
  onDecisionClick,
  canFetchNextPage,
  fetchNextPage,
  isFetching,
  selectedDecisionId,
  columnVisibility,
}) => {
  const fetchMoreOnCloseToBottom = (
    containerRefElement?: HTMLDivElement | null,
  ) => {
    if (containerRefElement) {
      const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
      //once the user has scrolled within 1000px of the bottom of the table, fetch more data if there is any
      if (scrollHeight - scrollTop - clientHeight < 1000 && canFetchNextPage) {
        fetchNextPage();
      }
    }
  };
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const tableInstance = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: { size: undefined },
    state: {
      columnVisibility,
    },
  });
  const { getHeaderGroups } = tableInstance;

  const headerGroups = getHeaderGroups();
  const rows = tableInstance.getRowModel().rows;

  const nextPageSkeleton = times(data.length === 0 ? 20 : 3, (index) => {
    return (
      <tr
        key={index}
        className="border-y border-gray-200"
        data-loc="decision-history-row-skeleton"
      >
        {tableInstance.getVisibleLeafColumns().map((_, index, columns) => (
          <td key={index}>
            <div
              className={twJoin(
                "flex h-11 items-center py-1.5",
                index === 0 && "w-[25px]",
                index === 1 && "w-[25px]",
                index === 2 && "w-[125px]",
                index === 3 && "w-[150px]",
                index === 4 && "w-[125px]",
                index === 5 && "w-[125px]",
                index > 5 && index !== columns.length - 1 && "w-[150px]",
                index === columns.length - 1 && "w-16",
              )}
            >
              <div className="relative h-5 w-full overflow-hidden rounded bg-gray-100">
                <div
                  className="absolute inset-0 -translate-x-full transform bg-gradient-to-r from-gray-100 via-gray-50 to-gray-100"
                  style={{ animation: "shimmer 1s infinite" }}
                />
              </div>
            </div>
          </td>
        ))}
      </tr>
    );
  });

  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: 10,
  });
  const { virtualItems: virtualRows, totalSize: totalRowSize } = rowVirtualizer;
  // Dynamic top and botton padding is added to the table content to maintain the correct scroll bar size and position
  const paddingTop = virtualRows?.[0]?.start || 0;
  const paddingBottom =
    totalRowSize - (virtualRows?.[virtualRows.length - 1]?.end || 0);

  return (
    <div
      ref={tableContainerRef}
      className={twMerge(
        "decideScrollbar h-full overflow-auto pb-3",
        frameClassName,
      )}
      onScroll={(e) => fetchMoreOnCloseToBottom(e.target as HTMLDivElement)}
    >
      <table
        className="w-full table-auto border-collapse border-spacing-0"
        data-loc={dataLoc}
      >
        <thead className="sticky top-0">
          {headerGroups.map(renderHeaderGroup)}
        </thead>
        <tbody>
          {paddingTop > 0 && (
            <tr>
              <td style={{ height: `${paddingTop}px` }} />
            </tr>
          )}
          {virtualRows.map((virtualRow) => {
            const row = rows[virtualRow.index];
            return renderRow(
              row,
              virtualRow,
              onDecisionClick,
              selectedDecisionId,
            );
          })}

          {isFetching && nextPageSkeleton}
          {paddingBottom > 0 && (
            <tr>
              <td style={{ height: `${paddingBottom}px` }} />
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
