import { capitalize } from "lodash";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { twJoin } from "tailwind-merge";

import { DecisionSidepaneWrapper } from "src/entities/entityView/DecisionSidepaneWrapper";
import { EntityDetailsSidePane } from "src/entities/entityView/EntityDetailsSidePane";
import { EntityTabs } from "src/entities/entityView/EntityTabs";
import { ExtendedHistoricalDecisionV3 } from "src/flow/decisionHistory/Columns";
import { PageHeader } from "src/layout/PageHeader";
import { useWorkspaceContext } from "src/router/routerContextHooks";
import { EntitiesParams } from "src/router/urls";
import { getWsUrl } from "src/router/urls";
import { useParamsDecode } from "src/utils/useParamsDecode";

const sidePaneWidthClass = "w-[420px]" as const;

export const EntityView = () => {
  const { workspace } = useWorkspaceContext();
  const baseUrl = workspace.base_url!;
  const [selectedDecision, setSelectedDecision] =
    useState<ExtendedHistoricalDecisionV3 | null>(null);
  const { schema, orgId, wsId } = useParamsDecode<EntitiesParams>();
  const location = useLocation();

  const buildBreadcrumbUrl = (schema: string) =>
    `${getWsUrl(orgId, wsId)}entities/${encodeURIComponent(schema)}${location.search}`;

  const breadcrumbs = [
    {
      key: "entity-schema",
      breadcrumb: (
        <PageHeader.Breadcrumb
          dataLoc="entity-schema"
          href={buildBreadcrumbUrl(schema!)}
          label={capitalize(schema)}
          hideIcon
        />
      ),
    },
    {
      key: "entity-name",
      breadcrumb: (
        <PageHeader.Breadcrumb
          dataLoc="entity-name"
          href={`${location.pathname}${location.search}`}
          label="Charles Darwin"
          active
        />
      ),
    },
  ];

  return (
    <div className="h-full">
      <PageHeader breadcrumbs={breadcrumbs} />
      <div className="flex h-full">
        <EntityDetailsSidePane />
        <EntityTabs
          selectedDecision={selectedDecision}
          onSelectDecision={setSelectedDecision}
        />
        {Boolean(selectedDecision) && (
          <div className={twJoin(sidePaneWidthClass, "flex-shrink-0 p-5")} />
        )}
        <DecisionSidepaneWrapper
          baseUrl={baseUrl}
          decision={selectedDecision}
          isOpen={Boolean(selectedDecision)}
          widthClass={sidePaneWidthClass}
          onClose={() => {
            setSelectedDecision(null);
          }}
        />
      </div>
    </div>
  );
};
