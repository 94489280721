import {
  faExclamationTriangle,
  IconDefinition,
} from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import { twJoin } from "tailwind-merge";

import { ProviderT } from "src/api/connectApi/types";
import { BetaPill } from "src/base-components/BetaPill";
import { Icon } from "src/base-components/Icon";
import { Pill } from "src/base-components/Pill";
import { ProviderIcon } from "src/connections/config/Icon";
import { Tooltip, TooltipProps } from "src/design-system/Tooltip";

type ConnectionCardTooltipPropsT = Omit<TooltipProps, "children"> & {
  icon: IconDefinition;
};

type PropsT = {
  title: string;
  onClick: () => void;
  isBeta: boolean;
  isDeprecated?: boolean;
  dataLoc?: string;
  provider: ProviderT | string;
  side?: "left" | "right";
  size?: "half" | "full";
  helpTooltip?: ConnectionCardTooltipPropsT;
  manifestVersion?: string;
};

const getCardClass = (
  size: "half" | "full",
  side: "left" | "right" | undefined,
) => {
  const sizeClass = size === "full" ? "w-full" : "w-1/2";
  const sideClass = size === "full" ? "" : side === "left" ? "pr-1" : "pl-1";
  return twJoin("mb-2", sizeClass, sideClass);
};

export const ConnectionCardTooltip: React.FC<ConnectionCardTooltipPropsT> = (
  props,
) => {
  return (
    <div className="text-gray-500">
      <Tooltip
        body={props.body}
        placement={props.placement}
        title={props.title}
        asChild
      >
        <Icon icon={props.icon} size="3xs" />
      </Tooltip>
    </div>
  );
};

export const CreateConnectionCard: React.FC<PropsT> = ({
  title,
  onClick,
  isBeta,
  isDeprecated,
  dataLoc,
  provider,
  side,
  size = "full",
  helpTooltip,
  manifestVersion,
}) => {
  return (
    <div className={`${getCardClass(size, side)}`}>
      <div
        className="box-border h-full rounded-lg border border-gray-200 transition-all hover:cursor-pointer hover:border-indigo-400"
        data-loc={dataLoc}
        onClick={onClick}
      >
        <div className="flex items-center">
          <div className="h-10 w-10 pl-2 pt-2">
            <ProviderIcon
              manifestVersion={manifestVersion}
              provider={provider}
              size="md"
            />
          </div>
          <div className="flex flex-col">
            <div className="text-gray-800 font-inter-medium-12px">{title}</div>
          </div>
          <div className="ml-auto flex items-center space-x-2 pr-2">
            {isBeta && <BetaPill tooltipPlacement="right" />}
            {isDeprecated && (
              <Pill size="sm" variant="red">
                <Pill.Icon icon={faExclamationTriangle} />
                <Pill.Text>Deprecated T1</Pill.Text>
              </Pill>
            )}
            {helpTooltip && <ConnectionCardTooltip {...helpTooltip} />}
          </div>
        </div>
      </div>
    </div>
  );
};
