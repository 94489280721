import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons";
import { CellContext, createColumnHelper } from "@tanstack/react-table";
import { capitalize } from "lodash";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { validate as validateUUID } from "uuid";

import { DecisionEnvironment } from "src/api/types";
import ManyToOneIcon from "src/assets/ManyToOneIcon.svg?react";
import OneToManyIcon from "src/assets/OneToManyIcon.svg?react";
import { CopyTextIcon } from "src/base-components/CopyTextIcon";
import { DataValue } from "src/base-components/DataList";
import { Divider } from "src/base-components/Divider";
import { Icon } from "src/base-components/Icon";
import { Pill } from "src/base-components/Pill";
import { Cell, TableComp } from "src/base-components/Table";
import { SCHEMA_TYPE_ICONS } from "src/base-components/TypeIcons";
import { LinkedEntityPill } from "src/entities/entityView/LinkedEntityPill";
import { mockedEntity } from "src/entities/entityView/mockData";
import { mockedSchemaDefinition } from "src/entities/entityView/mockData";
import { entityIcons } from "src/entities/entityView/utils";
import {
  Cardinality,
  EntityResource,
  EntitySchemaResource,
  EntitySchemaResourcePage,
  EntityType,
  Property,
  useEntities,
} from "src/entities/queries";
import { useWorkspaceContext } from "src/router/routerContextHooks";
import { EntityViewParams, getWsUrl } from "src/router/urls";
import { useParamsDecode } from "src/utils/useParamsDecode";

type NavigateToEntityFn = (schema: string, id: string) => void;

export type TableValue = string | string[];

type TableRow = Property & { value: TableValue };

const getTableData = (
  entitySchema: EntitySchemaResource | undefined,
  entity: EntityResource | undefined,
) => {
  if (!entitySchema || !entity) {
    return [];
  }

  const tableData: TableRow[] = [];

  // remove mockedEntity and mockedSchemaDefinition after dummy api gets updated
  Object.entries(entity).forEach(([key, value]) => {
    if (key[0] !== "_") {
      tableData.push({
        value: value as TableValue,
        ...entitySchema[key],
        ...mockedSchemaDefinition[key],
      });
    }
  });
  return tableData;
};

const getType = (info: CellContext<TableRow, TableValue>) =>
  info.row.original._type;

const renderByType = (
  navigateToEntity: NavigateToEntityFn,
  type: EntityType,
  value: string,
  entitySchema: string | undefined,
) => {
  if (type === "relation" && entitySchema) {
    return (
      <div className="mr-1">
        <LinkedEntityPill
          entityIcon={entityIcons[entitySchema as keyof typeof entityIcons]}
          name={value}
          onClick={() => {
            navigateToEntity(entitySchema, value);
          }}
        />
      </div>
    );
  }

  if (validateUUID(value)) {
    return <CopyTextIcon value={value} />;
  }

  if (["enum", "tags"].includes(type)) {
    return (
      <div className="mr-1">
        <Pill size="sm" variant="gray">
          <p>{value}</p>
        </Pill>
      </div>
    );
  }

  if (type === "string") {
    return <p>{value}</p>;
  }

  return <DataValue field="" value={value} />;
};

const renderValue = (
  navigateToEntity: NavigateToEntityFn,
  info: CellContext<TableRow, TableValue>,
) => {
  const value = info.row.original.value;
  const entitySchema = info.row.original._rel_schema;
  const type = getType(info);

  if (Array.isArray(value)) {
    return value.map((val) =>
      renderByType(navigateToEntity, type, val, entitySchema),
    );
  }

  return renderByType(navigateToEntity, type, value, entitySchema);
};

const DisplayIcon = ({
  type,
  cardinality,
}: {
  type: EntityType;
  cardinality: Cardinality | undefined;
}) => {
  if (type === "relation") {
    return cardinality === "1" ? (
      <div className="ml-1 mr-0.5">
        <OneToManyIcon />
      </div>
    ) : (
      <ManyToOneIcon />
    );
  }
  return (
    <Icon color="text-gray-500" icon={SCHEMA_TYPE_ICONS[type]} size="2xs" />
  );
};

const columnHelper = createColumnHelper<TableRow>();

const getColumns = (navigateToEntity: NavigateToEntityFn) => [
  columnHelper.accessor("_display_name", {
    header: () => null,
    cell: (info: CellContext<TableRow, TableValue>) => {
      const type = getType(info);
      const cardinality = info.row.original._cardinality;
      return (
        <Cell info={info}>
          <DisplayIcon cardinality={cardinality} type={type} />
          <p>{capitalize(info.row.original._display_name)}</p>
        </Cell>
      );
    },
  }),
  columnHelper.accessor("value", {
    header: () => null,
    cell: (info: CellContext<TableRow, TableValue>) => {
      return <Cell info={info}>{renderValue(navigateToEntity, info)}</Cell>;
    },
  }),
];

const findSchema = (
  schema: string,
  data: EntitySchemaResourcePage | undefined,
): EntitySchemaResource => {
  const entitySchema = data?.entities.find((entity) => entity._id === schema);
  // TODO: remove after dummy api gets updated
  if (entitySchema) {
    entitySchema._display_symbol = "users";
    entitySchema._primary_property = "first_name_last_name";
    entitySchema._secondary_property = "email";
  }
  return entitySchema as EntitySchemaResource;
};

export const EntityDetailsSidePane = () => {
  const navigate = useNavigate();
  const { schema, wsId, orgId, id } = useParamsDecode<EntityViewParams>();
  const { workspace } = useWorkspaceContext();
  const wsUrl = getWsUrl(orgId, wsId);
  const location = useLocation();

  const navigateToEntity: NavigateToEntityFn = (schema, id) => {
    navigate(`${wsUrl}entities/${schema}/${id}${location.search}`);
  };

  const { data: schemas, isLoading: isLoadingSchemas } = useEntities({
    schema: "schemas",
    env: DecisionEnvironment.LIVE,
    baseUrl: workspace.base_url ?? "",
  });

  const entitySchema = findSchema(schema, schemas);

  const { data: entitiesResource, isLoading: isLoadingEntities } = useEntities({
    schema,
    env: DecisionEnvironment.LIVE,
    baseUrl: workspace.base_url ?? "",
  });

  const currentEntity = {
    ...entitiesResource?.entities.find((e) => e._id === id),
    ...mockedEntity,
  } as EntityResource;

  const tableData = getTableData(entitySchema, currentEntity);

  return (
    <div className="h-full w-90 shrink-0 border-r border-gray-100 bg-white p-5">
      <div className="flex gap-3">
        {entitySchema && (
          <span className="rounded-lg border border-gray-200 p-2">
            <Icon
              color="text-gray-600"
              icon={
                entityIcons[entitySchema?._display_symbol] ?? faQuestionCircle
              }
              size="md"
            />
          </span>
        )}
        {entitySchema && (
          <div>
            <p className="font-inter-normal-13px">
              {currentEntity?.[entitySchema._primary_property]}
            </p>
            <p className="text-gray-500 font-inter-medium-11px">
              {currentEntity?.[entitySchema._secondary_property]}
            </p>
          </div>
        )}
      </div>
      <Divider spacing="my-4" />
      <TableComp
        columns={getColumns(navigateToEntity)}
        data={tableData}
        isLoading={isLoadingSchemas || isLoadingEntities}
        variant="compact"
      />
    </div>
  );
};
