import { faExternalLink } from "@fortawesome/pro-regular-svg-icons";
import axios from "axios";
import React, { useRef } from "react";
import { useForm, FormProvider, Controller } from "react-hook-form";
import slugify from "slugify";

import { Button } from "src/base-components/Button";
import { ErrorHint } from "src/base-components/ErrorHint";
import { Icon } from "src/base-components/Icon";
import { Input } from "src/base-components/Input";
import { Label } from "src/base-components/Label";
import { TextEditor } from "src/base-components/TextEditor/TextEditor";
import { Toolbar } from "src/base-components/TextEditor/Toolbar";
import { Modal } from "src/design-system/Modal";
import { TAKTILE_TEAM_NOTIFIED } from "src/design-system/Toast/constants";
import { toastActions } from "src/design-system/Toast/utils";
import {
  useCreateFeature,
  useUpdateFeature,
} from "src/featureCatalogue/queries";
import { useWorkspaceContext } from "src/router/routerContextHooks";
import { getBaseUrl, getUrlToQueryPreviewPage } from "src/router/urls";
import { logger } from "src/utils/logger";

export type FeatureForm = {
  name: string;
  key: string;
  description: string;
};

type EditFeature = FeatureForm & {
  etag: string;
  queryId: string;
};

type ManageFeatureModalMode = "create" | "edit" | "duplicate";

type ManageFeatureModalProps = {
  afterLeave: () => void;
  isOpen: boolean;
  mode: ManageFeatureModalMode;
  onClose: () => void;
} & (
  | { mode: "create"; feature?: never }
  | { mode: "edit"; feature: EditFeature }
  | { mode: "duplicate"; feature: FeatureForm }
);
export const ManageFeatureModal: React.FC<ManageFeatureModalProps> = ({
  afterLeave,
  isOpen,
  feature,
  mode,
  onClose,
}) => {
  const { workspace } = useWorkspaceContext();
  const isEditing = mode === "edit";

  const defaultValues = {
    name: "",
    key: "",
    description: "",
  };

  const formMethods = useForm<FeatureForm>({
    values: feature,
    defaultValues,
  });

  const { mutateAsync: createFeature } = useCreateFeature();
  const { mutateAsync: updateFeature } = useUpdateFeature();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = formMethods;

  const handleAfterLeave = () => {
    afterLeave();
    if (mode === "create") {
      reset(defaultValues);
    } else {
      reset(feature);
    }
  };

  const onSubmit = async (data: FeatureForm) => {
    try {
      if (isEditing) {
        await updateFeature({
          key: feature.key,
          feature: {
            name: data.name,
            description: data.description,
          },
          etag: feature.etag,
        });
      } else {
        await createFeature(data);
      }
      onClose();
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 409) {
        formMethods.setError("key", {
          message:
            "This key is already used in another Feature, please try with a different one",
        });
      } else {
        toastActions.failure({
          title: `Failed to ${isEditing ? "edit" : "create"} Feature`,
          description: TAKTILE_TEAM_NOTIFIED,
        });
        logger.error(error);
      }
    }
  };

  const toolbarRef = useRef<HTMLUListElement>(null);

  return (
    <Modal afterLeave={handleAfterLeave} open={isOpen} onClose={onClose}>
      <Modal.Header>
        {isEditing ? "Edit Feature" : "Create Feature"}
      </Modal.Header>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Content>
            <div
              className="flex flex-col gap-y-6"
              data-loc="feature-modal-form"
            >
              <div>
                <Label required>Feature name</Label>
                <Input
                  data-loc="feature-modal-name-input"
                  {...register("name", {
                    required: {
                      value: true,
                      message: "Feature name is required",
                    },
                    onChange: (e) => {
                      const { isDirty } = formMethods.getFieldState("key");

                      if (!isDirty && !isEditing) {
                        setValue(
                          "key",
                          slugify(e.target.value, {
                            lower: true,
                            strict: true,
                            replacement: "_",
                          }),
                          {
                            shouldValidate: true,
                          },
                        );
                      }
                    },
                  })}
                  errored={!!errors.name}
                  placeholder="Add Feature name"
                  fullWidth
                />
                {errors.name && <ErrorHint>{errors.name.message}</ErrorHint>}
              </div>

              <div>
                <Label required>Feature key</Label>
                <Input
                  data-loc="feature-modal-key-input"
                  {...register("key", {
                    ...KEY_VALIDATIONS,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                      const value = e.target.value;
                      formMethods.clearErrors("key");

                      if (
                        value &&
                        value.length < KEY_VALIDATIONS.minLength.value
                      ) {
                        formMethods.setError("key", {
                          message: KEY_VALIDATIONS.minLength.message,
                        });
                        return;
                      }

                      if (
                        value &&
                        value.length > KEY_VALIDATIONS.maxLength.value
                      ) {
                        formMethods.setError("key", {
                          message: KEY_VALIDATIONS.maxLength.message,
                        });
                        return;
                      }

                      if (value && !KEY_VALIDATIONS.pattern.value.test(value)) {
                        formMethods.setError("key", {
                          message: KEY_VALIDATIONS.pattern.message,
                        });
                      }
                    },
                  })}
                  disabled={isEditing}
                  errored={!!errors.key}
                  placeholder="Add Feature key"
                  fullWidth
                />
                {errors.key && (
                  <ErrorHint dataLoc="feature-modal-key-error">
                    {errors.key.message}
                  </ErrorHint>
                )}
              </div>

              <div>
                <Label>Feature description</Label>
                <div className="flex flex-col rounded border border-gray-200 bg-white p-3">
                  <Controller
                    control={formMethods.control}
                    name="description"
                    render={({ field }) => (
                      <TextEditor
                        dataLoc="feature-modal-description-input"
                        placeholder="Add Feature description"
                        toolbarRef={toolbarRef}
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                  <div className="flex justify-between gap-x-3 pt-3">
                    <Toolbar ref={toolbarRef} exclude={["heading"]} />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Content>
          <Modal.Footer
            primaryButton={
              <Button
                dataLoc={
                  isEditing
                    ? "feature-modal-save-button"
                    : "feature-modal-create-button"
                }
                disabled={isSubmitting}
                htmlType="submit"
                loading={isSubmitting}
              >
                {isEditing ? "Save Feature" : "Create Feature"}
              </Button>
            }
          >
            {isEditing && (
              <Button
                dataLoc="feature-modal-view-query-button"
                disabled={!feature.queryId}
                variant="secondary"
                onClick={() => {
                  const url =
                    getBaseUrl() +
                    getUrlToQueryPreviewPage(
                      workspace.organization_id,
                      workspace.id,
                      feature.queryId,
                    );
                  window.open(url, "_blank");
                }}
              >
                <span className="flex items-center gap-x-0.5">
                  View query
                  <Icon
                    color="text-gray-600"
                    icon={faExternalLink}
                    size="3xs"
                  />
                </span>
              </Button>
            )}
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  );
};

const KEY_VALIDATIONS = {
  required: {
    value: true,
    message: "Key is required",
  },
  minLength: {
    value: 4,
    message: "Key has to be between 4 and 20 characters long",
  },
  maxLength: {
    value: 20,
    message: "Key has to be between 4 and 20 characters long",
  },
  pattern: {
    value: /^[a-zA-Z_][a-zA-Z0-9_]*$/,
    message:
      "Key must start with a letter or underscore, and contain only letters, numbers, or underscores",
  },
};
