import {
  faChevronDown,
  faCircleInfo,
  faCircleNotch,
  faClose,
  faInfoCircle,
  faPlus,
  faTimes,
  faTrashAlt,
} from "@fortawesome/pro-regular-svg-icons";
import { faPlay } from "@fortawesome/pro-solid-svg-icons";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui2/react";
import { useKeyDown } from "@react-hooks-library/core";
import { AnimatePresence, m } from "framer-motion";
import { produce } from "immer";
import { maxBy, uniqueId } from "lodash";
import { observer } from "mobx-react-lite";
import React, { ComponentProps, useEffect, useState } from "react";
import useMeasure from "react-use-measure";
import { twJoin } from "tailwind-merge";

import { loadVersionOverridingIfNoneMatch } from "src/api";
import {
  CreateMultiversionTestRunVersion,
  MultiversionTestRunResults,
  RouterMultiversionTestRun,
  TestRunVersionProgress,
} from "src/api/endpoints";
import { FlowT, FlowVersionT } from "src/api/flowTypes";
import { useMultiversionTestRun } from "src/api/queries";
import {
  Dataset,
  ErrorBaseInfo,
  FlowRunnerResultV2,
  GraphRunResultType,
} from "src/api/types";
import { Breakdown } from "src/base-components/Breakdown";
import { Button } from "src/base-components/Button";
import { Divider } from "src/base-components/Divider";
import { Icon } from "src/base-components/Icon";
import { Pill } from "src/base-components/Pill";
import { Select } from "src/base-components/Select";
import { FlowVersionStatus } from "src/clients/flow-api";
import { NODE_TYPE } from "src/constants/NodeTypes";
import { DatasetSelector } from "src/datasets/DatasetSelector";
import { Callout } from "src/design-system/Callout";
import { toastActions } from "src/design-system/Toast/utils";
import { Tooltip } from "src/design-system/Tooltip";
import { DraftPill, PublishedPill } from "src/flow/Pills";
import { RunFlowWarning } from "src/flowContainer/RunFlowButton/RunFlowWarning";
import {
  tracker,
  trackingEvents,
} from "src/instrumentation/customTrackingEvents";
import {
  fetchSingleErrorPage,
  invalidateCompletionData,
} from "src/nodeEditor/api/queries";
import { useAuthoringContext } from "src/router/routerContextHooks";
import { useNodeHighlighting } from "src/store/NodeHighlighting";
import { useGraphStore } from "src/store/StoreProvider";
import { loginWithRefreshToken } from "src/store/api";
import { logger } from "src/utils/logger";
import { errorMessage } from "src/utils/stringUtils";

type PropsT = {
  flow: FlowT;
  version: FlowVersionT;
  datasets: Dataset[];
  isLoading: boolean;
};

const onRunError = async (err: unknown) => {
  logger.error(err);
  toastActions.failure({
    title: "Test run failed",
    description: "Please check your internet connection.",
    actionText: "Send error report to Taktile",
    onActionClick: () => {
      window.open(
        "mailto:support@taktile.com",
        "_blank",
        "noopener noreferrer",
      );
    },
  });
};
const fillEtagFallbackIfNeeded = (
  versions: FlowVersionT[],
): CreateMultiversionTestRunVersion[] =>
  versions.map((version) => ({
    id: version.id,
    etag: version.etag ?? "0000000",
    children: version.child_versions?.length
      ? version.child_versions?.map((child) => ({
          id: child.id,
          etag: child.etag ?? "0000000",
        }))
      : undefined,
  }));

// Wrapper for the additional version dropdowns
// since we need a way to preserve identity of the dropdowns
// for the animations to work
type AdditionalVersionSelector = { selectorId: string; version: FlowVersionT };

const getProgressPercentage = (progress: TestRunVersionProgress[]) => {
  const totalRowsToRun = progress.reduce((acc, curr) => acc + curr.total, 0);

  const processedRows = progress.reduce(
    (acc, curr) =>
      acc + curr.failed + curr.ignored + curr.successful + curr.output_mismatch,
    0,
  );

  return Math.floor((processedRows / totalRowsToRun) * 100);
};

const PLACEHOLDER_PROGRESS = {
  total: 100,
  successful: 0,
  failed: 0,
  ignored: 0,
  output_mismatch: 0,
};

const executionResultsToProgress = (
  result: FlowRunnerResultV2,
): TestRunVersionProgress => {
  if (result.type === GraphRunResultType.ERROR) {
    return PLACEHOLDER_PROGRESS;
  }
  return {
    total: result.nodes.output.total_count,
    successful: result.nodes.output.success_count,
    failed: result.nodes.output.failure_count,
    ignored: result.nodes.output.ignored_count,
    output_mismatch: result.nodes.output.expected_output_mismatch_count ?? 0,
  };
};

const MiniProgressBar: React.FC<{ progress: number }> = ({ progress }) => {
  return (
    <m.div
      key="mini-progress-bar"
      animate={{ width: 44 }}
      className="flex gap-x-0.5"
      // Start and end with the same
      // width as the play button
      exit={{ width: 18 }}
      initial={{ width: 18 }}
      role="progressbar"
    >
      {progress > 0 && (
        <m.div
          animate={{ width: `${progress}%` }}
          className="h-[3px] rounded-[100px] bg-white"
          initial={false}
          role="meter"
        ></m.div>
      )}
      <m.div
        animate={{ width: `${100 - progress}%` }}
        className="h-[3px] rounded-[100px] bg-white opacity-20"
        initial={false}
        role="meter"
      ></m.div>
    </m.div>
  );
};

export const RunFlowButton: React.FC<PropsT> = observer(
  ({ flow, version: versionInFocus, datasets, isLoading }) => {
    const {
      selectedNode,
      selectedDatasetId,
      updateSelectedDatasetId,
      nodes,
      nodesArray,
      selectErroredNode,
      selectOutputNode,
      setLastRunDatasetId,
      setDisplayedErroredRow,
      updateResults,
    } = useGraphStore();
    const { clearHighlighting } = useNodeHighlighting();

    const { workspace: currentWs, orgId } = useAuthoringContext();

    const [additionalVersions, setAdditionalVersions] = useState<
      AdditionalVersionSelector[]
    >([]);

    const [testRunState, setTestRunState] = useState<
      | { type: "STANDBY" }
      | {
          type: "INITIATING";
          versions: string[];
          abortController: AbortController;
        }
      | {
          type: "IN_PROGRESS";
          versions: { name: string; progress: TestRunVersionProgress }[];
          abortController: AbortController;
        }
    >({ type: "STANDBY" });

    const testRunMutation = useMultiversionTestRun(
      currentWs.base_url!,
      flow.id,
      flow.slug,
    );

    useEffect(() => {
      if (!datasets.some((data) => data.id === selectedDatasetId)) {
        const mostRecentUpload = maxBy(datasets, (data) =>
          new Date(data.created_at).valueOf(),
        );
        updateSelectedDatasetId(mostRecentUpload?.id ?? null);
      }
    }, [datasets, selectedDatasetId, updateSelectedDatasetId]);

    const showNotificationAndTrack = (data: FlowRunnerResultV2) => {
      if (data.type === GraphRunResultType.SUCCESS) {
        if (
          data.nodes.output.errors_pages_urls.length > 0 &&
          currentWs.base_url
        ) {
          fetchSingleErrorPage({
            workspaceUrl: currentWs.base_url,
            result: data.nodes.output,
          }).then((errors) => {
            errors.forEach((error) => {
              tracker.emit(
                trackingEvents.authoringTestExecError({
                  flow_id: flow.id,
                  flow_version_id: versionInFocus.id,
                  run_id: testRunMutation.data?.id,
                  error_message: errorMessage(error.example_failure.msg),
                  exception_type: error.example_failure.exc_type,
                  status_code: error.example_failure.status_code,
                  organization_id: orgId,
                  node_id: error.example_failure.node_id,
                  node_type: error.example_failure.node_id
                    ? nodes.get(error.example_failure.node_id)?.type
                    : undefined,
                  type: "row_level",
                  failure_count: error.failure_count,
                }),
              );
            });
          });
        }
        toastActions.success({ title: "Test run completed" });
      } else {
        const trackError = (error: ErrorBaseInfo) => {
          tracker.emit(
            trackingEvents.authoringTestExecError({
              flow_id: flow.id,
              flow_version_id: versionInFocus.id,
              run_id: testRunMutation.data?.id,
              error_message: errorMessage(error.msg),
              exception_type: error.exc_type,
              status_code: error.status_code,
              organization_id: orgId,
              type: "flow_level",
              node_id: error.node_id,
              node_type: error.node_id
                ? nodes.get(error.node_id)?.type
                : undefined,
              failure_count: undefined,
            }),
          );
        };
        const numberOfNodesWithCompileError = new Set(
          data.errors?.filter((e) => e.node_id).map((e) => e.node_id),
        ).size;
        if (numberOfNodesWithCompileError <= 1) {
          const error = data.error;
          trackError(error);
          if (error.node_id) {
            const nodeName =
              nodes.get(error.node_id)?.type === NODE_TYPE.INPUT_NODE
                ? "input"
                : "output";

            toastActions.failure({
              title: "Test run failed",
              description: (
                <>
                  Check node{" "}
                  <span className="text-gray-800 font-inter-semibold-13px">
                    {nodeName}
                  </span>{" "}
                  for details. Autocomplete suggestions will not be updated.
                </>
              ),
            });
          } else {
            toastActions.failure({
              title: "Test run failed",
              description: `Error: ${data.error.msg}`,
            });
          }
        } else if (data.errors) {
          data.errors.forEach(trackError);

          toastActions.failure({
            title: "Test run failed",
            description: `${numberOfNodesWithCompileError} nodes failed to compile. Autocomplete suggestions will not be updated.`,
          });
        }
      }
    };

    const onTestRunFinished = (data: MultiversionTestRunResults) => {
      const versionInFocusResults = data.flow_versions.find(
        (version) => version.id === versionInFocus.id,
      )!.execution_results!;
      showNotificationAndTrack(versionInFocusResults);
      updateResults(nodesArray, versionInFocusResults);
      invalidateCompletionData(versionInFocus.id);
      setLastRunDatasetId(selectedDatasetId ?? null);
      setDisplayedErroredRow(undefined);
      const erroredNode = selectErroredNode();
      if (!erroredNode && !selectedNode) {
        selectOutputNode();
      }
    };
    const selectedDataset = datasets.find((d) => d.id === selectedDatasetId);

    const onRunClick = async () => {
      // This abort controller is used in case
      // the test run is stopped, we check in multiple
      // points whether its been aborted
      const abortController = new AbortController();
      abortController.signal.addEventListener("abort", () => {
        setTestRunState({ type: "STANDBY" });
        toastActions.default({ title: "Test run stopped" });
      });

      const testRunVersions = [
        versionInFocus,
        ...additionalVersions.map((v) => v.version),
      ];
      setTestRunState({
        type: "INITIATING",
        versions: testRunVersions.map((v) => v.name),
        abortController,
      });
      try {
        if (!selectedDataset) throw Error("no selectedDataset for run");
        clearHighlighting();

        // Make sure to get the latest etags
        const testRunVersionIds = testRunVersions.map((v) => v.id);
        const versions = await Promise.all(
          testRunVersionIds.map(loadVersionOverridingIfNoneMatch),
        );

        await loginWithRefreshToken();

        if (abortController.signal.aborted) {
          return;
        }

        const mutationResult = await testRunMutation.mutateAsync({
          dataset_id: selectedDataset.id,
          flow_versions: fillEtagFallbackIfNeeded(versions),
        });
        tracker.emit(
          trackingEvents.clickTestRun({
            organization_id: orgId,
            flow_id: flow.id,
            flow_version_id: versionInFocus.id,
            sample_data_id: selectedDataset.id,
            run_id: mutationResult.id,
          }),
        );
        const isVersionInFocusFinished = (
          response: MultiversionTestRunResults,
        ) => {
          const versionInFocusResult = response.flow_versions.find(
            (version) => version.id === versionInFocus.id,
          );

          return !!versionInFocusResult?.execution_results;
        };

        if (abortController.signal.aborted) {
          return;
        }

        if (isVersionInFocusFinished(mutationResult)) {
          onTestRunFinished(mutationResult);
          return;
        }

        let result = await RouterMultiversionTestRun.get(
          currentWs.base_url!,
          mutationResult.id,
        );

        if (abortController.signal.aborted) {
          return;
        }
        while (
          result.status === 200 &&
          !isVersionInFocusFinished(result.data)
        ) {
          await new Promise((r) => setTimeout(r, 1000));
          result = await RouterMultiversionTestRun.get(
            currentWs.base_url!,
            mutationResult.id,
          );
          const theresProgressAvailable = result.data.flow_versions.some(
            (version) => version.progress,
          );
          if (abortController.signal.aborted) {
            return;
          }
          if (theresProgressAvailable) {
            setTestRunState({
              type: "IN_PROGRESS",
              versions: result.data.flow_versions.map((version) => ({
                name: testRunVersions.find((v) => version.id === v.id)?.name!,
                // If the progress is available we use it, otherwise we check
                // if the version has finished already (execution results would be populated)
                // if not we use a placeholder progress where everything is 0
                progress:
                  version.progress ??
                  (version.execution_results
                    ? executionResultsToProgress(version.execution_results)
                    : PLACEHOLDER_PROGRESS),
              })),
              abortController,
            });
          }
        }

        if (result.status !== 200) {
          throw new Error(
            `Error fetching results url, status: ${result.status} ${result.statusText}`,
          );
        }

        if (!abortController.signal.aborted) {
          onTestRunFinished(result.data);
        }
      } catch (e) {
        if (!abortController.signal.aborted) {
          onRunError(e);
        }
      } finally {
        if (!abortController.signal.aborted) {
          setTestRunState({ type: "STANDBY" });
        }
      }
    };

    /**
     * CMD/CTRL + Enter should be equivalent as clicking the button
     * if the shortcut is enabled. We disable it if a modal (which would intercept a click too)
     * is open.
     */

    const buttonRef = React.useRef<HTMLDivElement>(null);

    useKeyDown(["Enter"], (e) => {
      const modalIsOpen = document.querySelector('[data-loc="modal"]');
      if ((e.metaKey || e.ctrlKey) && !modalIsOpen) {
        e.preventDefault();
        buttonRef.current?.click();
      }
    });

    const [dropdownState, setDropdownState] = useState<
      "opened_by_click" | "opened_by_hover" | "closed"
    >("closed");

    return (
      <Popover
        as="div"
        className="relative"
        onMouseEnter={() => {
          if (dropdownState === "closed" && testRunState.type !== "STANDBY")
            setDropdownState("opened_by_hover");
        }}
        onMouseLeave={() => {
          if (dropdownState === "opened_by_hover") setDropdownState("closed");
        }}
      >
        <PopoverButton
          as="div"
          className="relative flex cursor-pointer items-center rounded-lg bg-indigo-600 text-white font-inter-normal-13px"
          onClick={() => {
            if (dropdownState === "closed") {
              setDropdownState("opened_by_click");
            } else {
              setDropdownState("closed");
            }
          }}
        >
          {/* This invisible element prevents the popover from being hidden when the mouse goes from the button to the popover panel */}
          <div className="absolute -bottom-3 left-0 right-0 h-3"></div>
          <m.div
            ref={buttonRef}
            animate={{
              width: testRunState.type === "STANDBY" ? "69px" : "126px",
            }}
            className="flex items-center py-1.5 pl-3 pr-2"
            data-loc="run-flow-button"
            onClick={async (e) => {
              if (
                selectedDatasetId &&
                !isLoading &&
                testRunState.type === "STANDBY"
              ) {
                e.stopPropagation();
                onRunClick();
              }
            }}
          >
            <div className="relative mr-1 flex items-center">
              <AnimatePresence initial={false} mode="wait">
                {testRunState.type === "STANDBY" ? (
                  <m.div
                    key="play-icon"
                    animate={{
                      opacity: 1,
                    }}
                    initial={{ opacity: 0 }}
                  >
                    <Icon icon={faPlay} size="2xs" />
                  </m.div>
                ) : (
                  <MiniProgressBar
                    key="progress-bar"
                    progress={
                      testRunState.type === "IN_PROGRESS"
                        ? getProgressPercentage(
                            testRunState.versions.map((v) => v.progress),
                          )
                        : 0
                    }
                  />
                )}
              </AnimatePresence>
            </div>
            <span className="overflow-hidden font-inter-medium-13px">
              {testRunState.type === "STANDBY" ? "Test" : "Testing..."}
            </span>
          </m.div>
          <div
            className="py-1.5 pr-3"
            data-loc="run-flow-button-dropdown"
            onClick={(e) => {
              if (testRunState.type !== "STANDBY") {
                e.stopPropagation();
                testRunState.abortController.abort();
              }
            }}
          >
            <div className="flex items-center border-l border-indigo-500 py-0.5 pl-1.5">
              <div className="block">
                <Icon
                  icon={
                    testRunState.type === "STANDBY" ? faChevronDown : faTimes
                  }
                  size="2xs"
                />
              </div>
            </div>
          </div>
        </PopoverButton>
        <AnimatePresence>
          {dropdownState !== "closed" && (
            <PopoverPanel
              anchor={{
                to: "bottom end",
                gap: 6,
              }}
              animate="visible"
              as={m.div}
              className="rounded-lg bg-white text-gray-800 shadow-2xl outline-none"
              exit="hidden"
              initial="hidden"
              variants={{
                visible: {
                  opacity: 1,
                  scale: 1,
                  transition: {
                    type: "tween",
                    ease: "easeOut",
                    duration: 0.15,
                  },
                },
                hidden: {
                  opacity: 0,
                  scale: 0.95,
                  transition: {
                    type: "tween",
                    ease: "easeOut",
                    duration: 0.15,
                  },
                },
              }}
              static
            >
              {testRunState.type === "STANDBY" ? (
                <RunTestDropdown
                  key={versionInFocus.id}
                  additionalVersions={additionalVersions}
                  datasets={datasets}
                  flow={flow}
                  isLoading={isLoading}
                  selectedDatasetId={selectedDatasetId}
                  setAdditionalVersions={setAdditionalVersions}
                  version={versionInFocus}
                  onClose={() => setDropdownState("closed")}
                  onSelectData={updateSelectedDatasetId}
                />
              ) : (
                <ProgressDropdown
                  showOutputMismatch={true}
                  versions={
                    testRunState.type === "IN_PROGRESS"
                      ? testRunState.versions
                      : testRunState.versions.map((versionName) => ({
                          name: versionName,
                          progress: PLACEHOLDER_PROGRESS,
                        }))
                  }
                />
              )}
            </PopoverPanel>
          )}
        </AnimatePresence>
      </Popover>
    );
  },
);

export const RunTestDropdownWrapper: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  // The height of this component is animated as
  // additional versions are added or removed
  const [ref, { height }] = useMeasure({
    offsetSize: true,
  });

  return (
    <m.div
      animate={{
        height: height === 0 ? "auto" : height,
      }}
      className="overflow-hidden"
      initial={false}
    >
      <div ref={ref} className="w-122 cursor-default pb-6 pt-4">
        {children}
      </div>
    </m.div>
  );
};

const MAX_ADDITIONAL_VERSIONS = 2;

type RunTestDropdownProps = Pick<PropsT, "datasets" | "isLoading"> & {
  selectedDatasetId: string | null;
  onSelectData: (datasetId: string | null) => void;
  onClose: () => void;
  flow: FlowT;
  version: FlowVersionT;
  additionalVersions: AdditionalVersionSelector[];
  setAdditionalVersions: (value: AdditionalVersionSelector[]) => void;
};

const RunTestDropdown: React.FC<RunTestDropdownProps> = ({
  datasets,
  selectedDatasetId,
  isLoading,
  flow,
  version,
  onSelectData,
  onClose,
  additionalVersions,
  setAdditionalVersions,
}) => {
  const thereAreParallelVersions = !!additionalVersions.length;

  const availableVersions = flow.versions.filter(
    (v) =>
      v.status !== FlowVersionStatus.ARCHIVED &&
      v.id !== version.id &&
      !additionalVersions.some((selector) => selector.version.id === v.id),
  );

  const additionalVersionLimitReached =
    additionalVersions.length === MAX_ADDITIONAL_VERSIONS;

  const addMoreButtonDisabled =
    additionalVersionLimitReached || availableVersions.length === 0;

  const tooltipMsg = additionalVersionLimitReached
    ? `You can only run up to ${
        MAX_ADDITIONAL_VERSIONS + 1
      } versions in parallel`
    : "All the versions available for this flow are already selected";

  return (
    <RunTestDropdownWrapper>
      <div className="flex items-center justify-between border-b border-gray-100 px-5 pb-3">
        <p className="text-gray-800 font-inter-semibold-16px">Test run</p>
      </div>
      <div className="absolute right-4 top-4">
        <Icon
          color="text-gray-500 hover:text-gray-700"
          dataLoc="close-test-run-dropdown"
          icon={faClose}
          size="sm"
          onClick={onClose}
        />
      </div>
      <div className="flex flex-col gap-y-3 px-5 pt-4">
        <div>
          <div className="mb-2 flex">
            <p className="text-gray-800 font-inter-semibold-13px">Dataset</p>
            <Tooltip
              body="Test this version of the Decision Flow using test data to fine-tune the flow and its steps."
              placement="top-start"
              asChild
            >
              <span className="ml-0.5">
                <Icon color="text-gray-500" icon={faInfoCircle} size="xs" />
              </span>
            </Tooltip>
          </div>
          <DatasetSelector
            datasets={datasets}
            isLoading={isLoading}
            selectedDatasetId={selectedDatasetId}
            onSelectData={onSelectData}
          />
          <RunFlowWarning
            flow={flow}
            selectedDatasetId={selectedDatasetId}
            version={version}
          />
        </div>
        <div>
          <p className="font-inter-medium-13px">Versions</p>
          <p className="text-gray-500 font-inter-normal-12px">
            You can select multiple versions to compare results and metrics
          </p>
        </div>
        <FixedVersion version={version} />
        {thereAreParallelVersions && (
          <m.div
            animate={{
              opacity: 1,
              transition: { delay: 0.15 },
            }}
            className="flex flex-col gap-y-3"
            initial={{ opacity: 0 }}
          >
            <Divider />
            <p className="font-inter-medium-13px">Compare with</p>
            {additionalVersions.map((value, i) => (
              <VersionSelector
                key={value.selectorId}
                otherAvailableVersions={availableVersions}
                selectedVersion={value.version}
                onChange={(newValueId) => {
                  const updatedAdditionalVersions = produce(
                    additionalVersions,
                    (draft) => {
                      const newValue = availableVersions.find(
                        (v) => v.id === newValueId,
                      );
                      if (!newValue) return;
                      draft[i].version = newValue;
                    },
                  );
                  setAdditionalVersions(updatedAdditionalVersions);
                }}
                onDelete={() => {
                  const updatedAdditionalVersions = produce(
                    additionalVersions,
                    (draft) => {
                      draft.splice(i, 1);
                    },
                  );
                  setAdditionalVersions(updatedAdditionalVersions);
                }}
              />
            ))}
          </m.div>
        )}
        <m.div
          className="flex items-center justify-between"
          transition={{ type: "tween" }}
          layout
        >
          <Tooltip
            disabled={!addMoreButtonDisabled}
            placement="bottom"
            title={tooltipMsg}
            asChild
          >
            <span>
              <Button
                dataLoc="add-more-versions-button"
                disabled={addMoreButtonDisabled}
                iconLeft={faPlus}
                variant="secondary"
                onClick={() =>
                  setAdditionalVersions([
                    ...additionalVersions,
                    { selectorId: uniqueId(), version: availableVersions[0] },
                  ])
                }
              >
                Add more
              </Button>
            </span>
          </Tooltip>
        </m.div>
      </div>
    </RunTestDropdownWrapper>
  );
};

const FixedVersion: React.FC<{ version: FlowVersionT }> = ({ version }) => {
  return (
    <div className="flex items-center gap-x-2">
      <div className="flex min-h-[32px] flex-1 items-center justify-between gap-x-3 rounded-lg border border-gray-200 py-0.5 pl-3 pr-2 font-inter-normal-12px">
        {version.name}

        {version.status === FlowVersionStatus.PUBLISHED ? (
          <PublishedPill />
        ) : (
          <DraftPill />
        )}
      </div>
    </div>
  );
};

const VersionSelector: React.FC<{
  selectedVersion: FlowVersionT;
  onChange: (id: string) => void;
  otherAvailableVersions: FlowVersionT[];
  onDelete: () => void;
}> = ({ selectedVersion, onChange, otherAvailableVersions, onDelete }) => {
  const versionToOption = (version: FlowVersionT, isSelected = false) => {
    return (
      <div
        className={twJoin(
          "flex min-w-0 flex-1 items-center justify-between",
          isSelected ? "mr-3" : "mr-8",
        )}
      >
        <div className="mr-3 min-w-0 flex-1 truncate">{version.name}</div>
        <div className="h-5.5 flex-shrink-0 -translate-y-px">
          {version.status === FlowVersionStatus.PUBLISHED ? (
            <PublishedPill />
          ) : (
            <DraftPill />
          )}
        </div>
      </div>
    );
  };
  const options = otherAvailableVersions
    .map((v) => ({ key: v.id, value: versionToOption(v) }))
    .concat([
      {
        key: selectedVersion.id,
        value: versionToOption(selectedVersion, true),
      },
    ]);
  return (
    <m.div
      animate={{
        opacity: 1,
        transition: { delay: 0.15 },
      }}
      className="flex items-center"
      initial={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      layout
    >
      <div className="relative mr-3 min-w-0 flex-1">
        <Select
          options={options}
          value={selectedVersion.id}
          listMatchesButtonWidth
          onChange={onChange}
        />
      </div>
      <Icon
        color="text-gray-600"
        icon={faTrashAlt}
        size="xs"
        onClick={onDelete}
      />
    </m.div>
  );
};

const ProgressDropdown: React.FC<{
  versions: { name: string; progress: TestRunVersionProgress }[];
  showOutputMismatch: boolean;
}> = ({ versions, showOutputMismatch }) => {
  const everyVersionHasFinished = versions.every((version) => {
    return (
      version.progress.total ===
      version.progress.successful +
        version.progress.failed +
        version.progress.ignored +
        version.progress.output_mismatch
    );
  });

  const nothingHasBeenExecutedYet = versions.every(
    (version) =>
      version.progress.successful === 0 &&
      version.progress.failed === 0 &&
      version.progress.ignored === 0 &&
      version.progress.output_mismatch === 0,
  );

  const statusToColors = {
    successful: "green",
    output_mismatch: "yellow",
    failed: "red",
    ignored: "gray",
    waiting: "neutral",
  } as const;

  const statuses = [
    {
      key: "successful",
      color: statusToColors.successful,
      label: "Successful",
      pillColor: "green",
    },
    {
      key: "failed",
      color: statusToColors.failed,
      label: "Failed",
      pillColor: "red",
    },
    {
      key: "output_mismatch",
      color: statusToColors.output_mismatch,
      label: "Output Mismatch",
      pillColor: "yellow",
    },
    {
      key: "ignored",
      color: statusToColors.ignored,
      label: "Ignored",
      pillColor: "gray",
    },
    { key: "total", color: null, label: "Total", pillColor: null },
  ] as const;

  const progressToBreakdownBars = (
    progress: TestRunVersionProgress,
  ): ComponentProps<typeof Breakdown>["bars"] => {
    return [
      { color: statusToColors.successful, count: progress.successful },
      { color: statusToColors.failed, count: progress.failed },
      {
        color: statusToColors.output_mismatch,
        count: progress.output_mismatch,
      },
      { color: statusToColors.ignored, count: progress.ignored },
      {
        color: statusToColors.waiting,
        count:
          progress.total -
          progress.successful -
          progress.failed -
          progress.ignored -
          progress.output_mismatch,
      },
    ];
  };

  const isMultiversion = versions.length > 1;

  return (
    <div className="relative w-122 cursor-default">
      <div className="px-5 pb-6 pt-4">
        {isMultiversion ? (
          <div className="border border-gray-100 text-left text-gray-800">
            <div className="flex h-8 bg-gray-50 font-inter-medium-12px">
              <div className="flex min-w-[96px] items-center border-r border-gray-100 px-2">
                Version
              </div>
              <div className="flex min-w-[120px] items-center border-r border-gray-100 px-2">
                Decisions
              </div>
              <div className="flex flex-1 items-center px-2">Test Status</div>
            </div>
            {versions.map(({ name, progress }) => (
              <div
                key={name}
                className="flex h-8 border-t border-gray-100 font-inter-normal-12px"
              >
                <div className="flex w-[96px] items-center border-r border-gray-100 px-2">
                  <p className="min-w-0 truncate">{name}</p>
                </div>
                <div className="flex min-w-[120px] items-center border-r border-gray-100 px-2">
                  {Math.floor(
                    ((progress.successful +
                      progress.output_mismatch +
                      progress.failed +
                      progress.ignored) /
                      progress.total) *
                      100,
                  )}
                  %
                </div>
                <div className="flex flex-1 items-center px-2">
                  <Breakdown bars={progressToBreakdownBars(progress)} />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="border border-gray-100 text-left text-gray-800">
            <div className="flex h-8 bg-gray-50 font-inter-medium-12px">
              <div className="flex min-w-[139px] items-center border-r border-gray-100 px-2">
                Status
              </div>
              <div className="flex min-w-[78px] items-center border-r border-gray-100 px-2">
                Decisions
              </div>
              <div className="flex flex-1 items-center px-2"></div>
            </div>
            {statuses
              .filter(
                ({ key }) => showOutputMismatch || key !== "output_mismatch",
              )
              .map(({ color, key, label, pillColor }) => {
                const progress = versions[0].progress;
                return (
                  <div
                    key={key}
                    className="flex h-8 border-t border-gray-100 font-inter-normal-12px"
                  >
                    <div className="flex w-[139px] items-center border-r border-gray-100 px-2">
                      {pillColor ? (
                        <Pill variant={pillColor}>
                          <Pill.Text>{label}</Pill.Text>
                        </Pill>
                      ) : (
                        label
                      )}
                    </div>
                    <div className="flex min-w-[78px] items-center border-r border-gray-100 px-2">
                      {Math.floor((progress[key] / progress.total) * 100)}%
                    </div>
                    <div className="flex flex-1 items-center px-2">
                      <Breakdown
                        bars={progressToBreakdownBars(progress)}
                        highlightOnly={color ?? undefined}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
        )}
        <div className="mt-3">
          <Callout
            icon={
              nothingHasBeenExecutedYet || everyVersionHasFinished
                ? faCircleNotch
                : faCircleInfo
            }
            iconSpin={nothingHasBeenExecutedYet || everyVersionHasFinished}
            type="neutral"
          >
            {nothingHasBeenExecutedYet
              ? "Initializing test run"
              : everyVersionHasFinished
                ? "Collating results..."
                : "Numbers displayed above are estimates. Detailed results will be available once the test run is complete."}
          </Callout>
        </div>
      </div>
    </div>
  );
};
