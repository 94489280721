import { ConnectionT } from "src/api/connectApi/types";
import { ManifestFormType } from "src/connections/config/manifest/types";
import { FEATURE_FLAGS, isFeatureFlagEnabled } from "src/router/featureFlags";

export const convertBEConnectionToManifestConnectionInput = (
  connection: ConnectionT,
): ManifestFormType => {
  if (!connection.manifest_version) {
    throw new Error(`Invalid connection type: ${connection.provider}`);
  }

  const inputs = connection.environment_configurations || {
    ...(isFeatureFlagEnabled(FEATURE_FLAGS.manifestEnvironments)
      ? {}
      : { production: {} }),
  };

  return {
    name: connection.name,
    data_retention: connection.data_retention,
    resource_configs: connection.resource_configs,
    inputs,
    is_sandbox: connection.is_sandbox,
  };
};
