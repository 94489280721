import { faEye } from "@fortawesome/pro-regular-svg-icons";
import React from "react";

import { FlowT } from "src/api/flowTypes";
import { DecisionEnvironment } from "src/api/types";
import { Button } from "src/base-components/Button";
import { HistoricalDecisionV3 } from "src/clients/history-v3";
import { useDecisionsOverviewQueryParams } from "src/decisionsOverview/DecisionsOverview";
import { Tooltip } from "src/design-system/Tooltip";
import { useDecisionTracingDisabledCb } from "src/flow/decisionHistory/useDecisionTracingDisabledCb";
import {
  tracker,
  trackingEvents,
} from "src/instrumentation/customTrackingEvents";
import {
  DashboardPageParamsT,
  getUrlToHistoricDecisionTrace,
} from "src/router/urls";
import { useParamsDecode } from "src/utils/useParamsDecode";
import {
  DecisionDisabledReason,
  getDisableReasonMessage,
} from "src/versionDecisionHistory/Table";

type TraceDecisionButtonProps = {
  flow: FlowT;
  decision: HistoricalDecisionV3;
};

export const TraceDecisionButton: React.FC<TraceDecisionButtonProps> = ({
  flow,
  decision,
}) => {
  const decisionTracingDisabledCb = useDecisionTracingDisabledCb(flow);
  const { isDisabled, disabledReason } = decisionTracingDisabledCb(decision);
  const { orgId, wsId } = useParamsDecode<DashboardPageParamsT>();

  const { entityId: urlEntityId, decisionId: urlDecisionId } =
    useDecisionsOverviewQueryParams();

  const openDecisionTrace = (decision: HistoricalDecisionV3) => {
    const flowId = decision.flow?.id;
    const versionId = decision.flow?.version?.id;
    if (!flowId || !versionId) {
      return;
    }
    const decisionTraceUrl = getUrlToHistoricDecisionTrace({
      orgId,
      wsId,
      flowId,
      versionId,
      decisionId: decision.id,
      decisionEnv:
        decision.environment === DecisionEnvironment.SANDBOX
          ? DecisionEnvironment.SANDBOX
          : DecisionEnvironment.LIVE,
    });
    window.open(window.location.origin + decisionTraceUrl, "_blank");
  };

  return (
    <Tooltip
      disabled={!isDisabled}
      placement="top"
      title={getDisableReasonMessage(disabledReason as DecisionDisabledReason)}
      asChild
    >
      <div className="cursor-default">
        <Button
          disabled={isDisabled}
          iconLeft={faEye}
          size="sm"
          variant="secondary"
          onClick={() => {
            tracker.emit(
              trackingEvents.gotoDecisionTrace({
                query_param_decision_id: urlDecisionId,
                query_param_entity_id: urlEntityId,
                decision_id: decision.id,
                organization_id: orgId,
                env: decision.environment as DecisionEnvironment,
              }),
            );
            openDecisionTrace(decision);
          }}
        >
          Trace Decision
        </Button>
      </div>
    </Tooltip>
  );
};
