import {
  faBuilding,
  faCreditCard,
  faUser,
} from "@fortawesome/pro-regular-svg-icons";

export const entityIcons = {
  users: faUser,
  cards: faCreditCard,
  companies: faBuilding,
} as const;
