import { useQuery } from "@tanstack/react-query";
import { isEqual, isString, uniqWith } from "lodash";

import {
  AnalyticsKey,
  getAnalyticsKeys,
  FLOW_ANALYTICS_OUTCOMES_DATA_KEY_PREFIX,
  preprocessAnalyticsData,
  SectionKey,
} from "src/analytics/utils";
import {
  MultiversionTestRunResults,
  MultiversionTestRunVersionResults,
  PresignedUrlRequest,
  RouterMultiversionTestRun,
  RouterPresignedUrls,
} from "src/api/endpoints";
import { FlowT, GenericObjectT } from "src/api/flowTypes";
import { NodeTestRunResult } from "src/api/types";
import { NodeTypes } from "src/clients/flow-api";
import { SUB_COLUMN_SEPARATOR } from "src/datasets/DatasetTable/utils";
import {
  useAuthoringContext,
  useWorkspaceContext,
} from "src/router/routerContextHooks";
import { useNodeRunState } from "src/store/runState/RunState";
import { logger } from "src/utils/logger";

const getSomeVersionsErroredDetails = (
  results: MultiversionTestRunVersionResults[],
  flow: FlowT,
  isMultiversion: boolean,
): Extract<ComparativeAnalyticsData, { reason: "SOME_VERSION_ERRORED" }> => {
  const erroredVersionNames: string[] = [];

  for (const version of results) {
    if (version.execution_results?.type === "error") {
      const versionName =
        flow.versions.find((v) => v.id === version.id)?.name ?? version.id;
      erroredVersionNames.push(`"${versionName}"`);
    }
  }

  return {
    type: "error",
    reason: "SOME_VERSION_ERRORED",
    erroredVersionNames,
    isMultiversion,
  };
};

const getSummary = (
  results: NodeTestRunResult[],
  flow: FlowT,
): ComparativeAnalyticsSummary => {
  const summary: ComparativeAnalyticsSummary = {};

  for (const result of results) {
    const versionName =
      flow.versions.find((v) => v.id === result.version_id)?.name ??
      result.version_id;

    summary[versionName] = {
      success_count: result.success_count,
      failed_count: result.failure_count,
      ignored_count: result.ignored_count,
    };
  }

  return summary;
};

export type ComparativeAnalyticsFile = {
  data: GenericObjectT[];
  combined_successful_count: number;
  successful_count_by_version: Record<string, number>;
};

export type ComparativeAnalyticsSummary = Record<
  string,
  { success_count: number; ignored_count: number; failed_count: number }
>;

export type ComparativeAnalyticsData =
  | {
      type: "error";
      reason: "SOME_VERSION_ERRORED";
      erroredVersionNames: string[];
      isMultiversion: boolean;
    }
  | {
      type: "error";
      reason: "NO_SUCCESSFUL_ROWS";
      isMultiversion: boolean;
    }
  | ({
      type: "success";
      keys: AnalyticsKey[];
      total_count: number;
      summary: ComparativeAnalyticsSummary;
      isMultiversion: boolean;
    } & ComparativeAnalyticsFile);

export type OutcomeDataFile = {
  // The string is the row id
  data: Record<string, GenericObjectT>;
};

const useMultiversionTestRun = () => {
  const { workspace } = useAuthoringContext();
  const baseUrl = workspace.base_url!;

  const runState = useNodeRunState("output");
  const testRunId =
    runState?.type === "test-run" ? runState.testResult.test_run_id : null;

  const allVersionsAreFinished = (response: MultiversionTestRunResults) => {
    return response.flow_versions.every(
      (version) => !!version.execution_results,
    );
  };

  return useQuery<MultiversionTestRunResults>(
    ["multiversionTestRun", baseUrl, testRunId],
    async () => {
      let testRunResult = await RouterMultiversionTestRun.get(
        baseUrl,
        testRunId!,
      );

      while (
        testRunResult.status === 200 &&
        !allVersionsAreFinished(testRunResult.data)
      ) {
        await new Promise((r) => setTimeout(r, 1000));
        testRunResult = await RouterMultiversionTestRun.get(
          baseUrl,
          testRunId!,
        );
      }

      if (testRunResult.status !== 200) {
        throw new Error(
          `Got an error ${testRunResult.status} while polling the test run endpoint`,
        );
      }

      return testRunResult.data;
    },
    {
      enabled: Boolean(testRunId && baseUrl),
    },
  );
};

const useGetPresignedFile = <T>(
  testRunId?: string,
  presignedUrlParams?: PresignedUrlRequest,
  options: {
    enabled?: boolean;
    initialData?: T;
  } = { enabled: true },
) => {
  const { workspace } = useWorkspaceContext();
  const baseUrl = workspace.base_url!;

  return useQuery(
    ["presignedFile", baseUrl, testRunId, JSON.stringify(presignedUrlParams)],
    async () => {
      if (!presignedUrlParams) {
        logger.error(
          `useGetPresignedFile: Presigned url params are not defined ${testRunId}`,
        );
        return;
      }

      const presignedUrlResponse = await RouterPresignedUrls.post(
        baseUrl,
        testRunId!,
        presignedUrlParams,
      );

      if (presignedUrlResponse.status !== 200) {
        throw new Error(
          `Got an error ${presignedUrlResponse.status} while getting a presigned url for testRunId: ${testRunId}, workspaceId: ${workspace.id}, params: ${JSON.stringify(presignedUrlParams)}`,
        );
      }

      return await fetchWithPolling<T>(
        presignedUrlResponse.data.url,
        presignedUrlParams,
      );
    },
    {
      enabled: Boolean(
        options.enabled && testRunId && baseUrl && presignedUrlParams,
      ),
      retry: false, // Do not retry, as we poll inside the query
      refetchOnMount: false,
      initialData: options.initialData,
    },
  );
};

export const useComparativeAnalyticsData = (
  options: {
    enabled?: boolean;
  } = { enabled: true },
) => {
  const { data: testRunResult } = useMultiversionTestRun();
  const testRunId = testRunResult?.id;

  const { data: combinedAnalyticsFile } =
    useGetPresignedFile<ComparativeAnalyticsFile>(testRunId, {
      type: "analytics",
    });
  const { data: outcomesFile } = useGetPresignedFile<OutcomeDataFile>(
    testRunId,
    testRunResult
      ? {
          type: "outcome_data",
          dataset_id: testRunResult.dataset.id,
          dataset_etag: testRunResult.dataset.etag,
        }
      : undefined,
    {
      enabled: options.enabled,
    },
  );

  const { flow, workspace } = useAuthoringContext();
  const baseUrl = workspace.base_url!;

  return useQuery<ComparativeAnalyticsData>(
    ["comparativeAnalytics", baseUrl, testRunId],
    async () => {
      if (!testRunResult || !combinedAnalyticsFile || !outcomesFile) {
        throw new Error(
          !testRunResult
            ? "Test run result is not defined"
            : !combinedAnalyticsFile
              ? "Combined analytics file is not defined"
              : "Outcome file is not defined",
        );
      }

      // All execution results (the output_parent and output node of each version)
      // are stored in these arrays for later.
      // We use two different arrays because the output node doesn't have some keys
      // that we'll have in the analytics file (those stripped out by the output schema)
      // and the output parent node doesn't have information about failures in previous nodes.
      // If any version was not successful we exit early
      const outputExecutionResults: NodeTestRunResult[] = []; // Used for summary
      const outputParentExecutionResults: NodeTestRunResult[] = []; // Used for analytics keys

      for (const version of testRunResult.flow_versions) {
        if (version.execution_results?.type !== "success") {
          return getSomeVersionsErroredDetails(
            testRunResult.flow_versions,
            flow,
            testRunResult.flow_versions.length > 1,
          );
        }
        outputExecutionResults.push(version.execution_results.nodes["output"]);
        outputParentExecutionResults.push(
          version.execution_results.nodes["output_parent"],
        );
      }

      // If the intersection of successful rows is empty we exit early
      if (combinedAnalyticsFile.combined_successful_count === 0) {
        return {
          type: "error",
          reason: "NO_SUCCESSFUL_ROWS",
          isMultiversion: testRunResult.flow_versions.length > 1,
        };
      }

      // Get the union of all data keys and aux keys
      // so we can use them in the analytics chart
      const analyticsKeysUnion: AnalyticsKey[] = [];

      const originalData = combinedAnalyticsFile.data;
      const originalOutcomeData = outcomesFile.data;

      const combinedAnalyticsData = originalData.map((row) => {
        const outcomeRow = originalOutcomeData[row.__tktl_row_id];

        return {
          ...row,
          [FLOW_ANALYTICS_OUTCOMES_DATA_KEY_PREFIX]: outcomeRow,
        };
      });

      const disabledOutputDataKeys = combinedAnalyticsData.reduce(
        (acc, row) => {
          Object.entries(row).forEach(([key, value]) => {
            if (acc[key] !== true) {
              acc[key] = isArrayOrObject(value);
            }
          });
          return acc;
        },
        {} as Record<string, boolean>,
      );

      const outcomeDataColumns = getOutcomeDataColumns(outcomesFile.data);

      for (const version of outputParentExecutionResults) {
        analyticsKeysUnion.push(
          ...getAnalyticsKeys(
            version.data_columns,
            version.aux_data_columns,
            outcomeDataColumns,
            disabledOutputDataKeys,
          ),
        );
      }

      // Remove duplicates
      const analyticsKeys = uniqWith(analyticsKeysUnion, isEqual).sort((a, b) =>
        a.displayKey.localeCompare(b.displayKey),
      );

      const preprocessedData = preprocessAnalyticsData(
        combinedAnalyticsData,
        analyticsKeys,
      );

      const totalCount = outputExecutionResults[0].total_count;

      const summary = getSummary(outputExecutionResults, flow);

      return {
        type: "success",
        ...combinedAnalyticsFile,
        total_count: totalCount,
        keys: analyticsKeys,
        data: preprocessedData,
        summary,
        isMultiversion: testRunResult.flow_versions.length > 1,
      };
    },
    {
      enabled: Boolean(
        options.enabled &&
          baseUrl &&
          testRunResult &&
          combinedAnalyticsFile &&
          outcomesFile,
      ),
    },
  );
};

export type NodeAnalyticsRow = {
  row_id: string;
  meta: {
    case_id: string;
    case_idx: number;
    case_name: string;
    index: number;
    node_name: string;
    node_type: NodeTypes.SPLIT_2 | NodeTypes.RULE_2 | NodeTypes.DECISION_TABLE;
    start_time?: string;
    entity_id?: string;
    decision_id?: string;
  };
  node_data: GenericObjectT;
  output_data: GenericObjectT;
  aux_data: GenericObjectT;
};

type NodeAnalyticsFile = {
  data: NodeAnalyticsRow[];
  successful_count: number;
};

export type NodeAnalyticsData =
  | {
      type: "error";
      reason: "SOME_VERSION_ERRORED";
      erroredVersionNames: string[];
      isMultiversion: false;
    }
  | {
      type: "error";
      reason: "NO_SUCCESSFUL_ROWS";
      isMultiversion: false;
    }
  | ({
      type: "success";
      keys: AnalyticsKey[];
      isMultiversion: false;
    } & NodeAnalyticsFile);

const META_FIELDS = ["start_time", "entity_id"] as const;
const META_FIELDS_DISPLAY_KEYS: Record<(typeof META_FIELDS)[number], string> = {
  start_time: "metadata.timestamp",
  entity_id: "metadata.entity_id",
};

export const useNodeAnalyticsData = (
  nodeId: string,
  options: { enabled?: boolean } = { enabled: true },
) => {
  const { data: testRunResult } = useMultiversionTestRun();
  const testRunId = testRunResult?.id;
  const runState = useNodeRunState("output");

  const shortTestResult =
    runState?.type === "test-run" ? runState.testResult : null;
  const versionId = shortTestResult?.version_id;

  const { data: nodeAnalyticsFile } = useGetPresignedFile<NodeAnalyticsFile>(
    testRunId,
    testRunId && versionId && nodeId
      ? {
          type: "node_analytics",
          run_id: testRunId,
          version_id: versionId,
          node_id: nodeId,
        }
      : undefined,
  );

  const { data: outcomesFile } = useGetPresignedFile<OutcomeDataFile>(
    testRunId,
    testRunResult
      ? {
          type: "outcome_data",
          dataset_id: testRunResult.dataset.id,
          dataset_etag: testRunResult.dataset.etag,
        }
      : undefined,
    {
      enabled: options.enabled,
    },
  );

  const { flow, workspace } = useAuthoringContext();
  const baseUrl = workspace.base_url!;

  const executionResults = testRunResult?.flow_versions.find(
    (v) => v.id === versionId,
  )?.execution_results;

  const nodeWasExecuted =
    executionResults?.type === "success" && executionResults?.nodes[nodeId];

  return useQuery<NodeAnalyticsData>(
    ["nodeAnalytics", baseUrl, testRunId, nodeId],
    async (): Promise<NodeAnalyticsData> => {
      if (!nodeAnalyticsFile || !outcomesFile) {
        throw new Error(
          !nodeAnalyticsFile
            ? "Node analytics file is not defined"
            : "Outcome file is not defined",
        );
      }

      if (executionResults?.type !== "success") {
        const flowVersions = testRunResult?.flow_versions!;

        return {
          ...getSomeVersionsErroredDetails(flowVersions, flow, false),
          isMultiversion: false,
        };
      }

      // All execution results (the output_parent and output node of each version)
      // are stored in these arrays for later.
      // We use two different arrays because the output node doesn't have some keys
      // that we'll have in the analytics file (those stripped out by the output schema)
      // and the output parent node doesn't have information about failures in previous nodes.
      // If any version was not successful we exit early
      const nodeParentExecutionResults: NodeTestRunResult =
        executionResults.nodes[nodeId];
      const outputParentExecutionResults: NodeTestRunResult =
        executionResults.nodes["output_parent"];

      // If no successful rows is empty we exit early
      if (nodeAnalyticsFile.successful_count === 0) {
        return {
          type: "error",
          reason: "NO_SUCCESSFUL_ROWS",
          isMultiversion: false,
        };
      }

      const combinedAnalyticsData = nodeAnalyticsFile.data.map((row) => {
        const outcomeRow = outcomesFile.data[row.row_id] ?? {};
        return {
          ...row,
          outcome_data: outcomeRow,
        };
      });

      const disabledOutputDataKeys = combinedAnalyticsData.reduce<{
        node_data: Record<string, boolean>;
        output_data: Record<string, boolean>;
        aux_data: Record<string, boolean>;
        outcome_data: Record<string, boolean>;
      }>(
        (acc, row) => {
          Object.entries(row.node_data).forEach(([key, value]) => {
            if (acc.node_data[key] !== true) {
              acc.node_data[key] = isArrayOrObject(value);
            }
          });
          Object.entries(row.output_data).forEach(([key, value]) => {
            if (acc.output_data[key] !== true) {
              acc.output_data[key] = isArrayOrObject(value);
            }
          });
          Object.entries(row.aux_data).forEach(([key, value]) => {
            if (acc.aux_data[key] !== true) {
              acc.aux_data[key] = isArrayOrObject(value);
            }
          });
          Object.entries(row.outcome_data).forEach(([key, value]) => {
            if (acc.outcome_data[key] !== true) {
              acc.outcome_data[key] = isArrayOrObject(value);
            }
          });
          return acc;
        },
        {
          node_data: {},
          output_data: {},
          aux_data: {},
          outcome_data: {},
        },
      );

      const analyticsKeysUnion: AnalyticsKey[] = [];

      analyticsKeysUnion.push(
        ...nodeParentExecutionResults.data_columns.map((column) => ({
          displayKey: `data.${column}`,
          actualKey: `node_data.${column}`,
          sectionKey: SectionKey.data,
          disabled: disabledOutputDataKeys.node_data[column],
        })),
      );

      const firstRow = nodeAnalyticsFile.data[0];

      if (
        firstRow.meta.node_type === NodeTypes.SPLIT_2 ||
        firstRow.meta.node_type === NodeTypes.RULE_2 ||
        firstRow.meta.node_type === NodeTypes.DECISION_TABLE
      ) {
        analyticsKeysUnion.push({
          displayKey:
            firstRow.meta.node_type === NodeTypes.SPLIT_2
              ? "Branch split"
              : firstRow.meta.node_type === NodeTypes.RULE_2
                ? "Rule"
                : "Case",
          actualKey: "meta.case_name",
          sectionKey: SectionKey.node_logic,
          disabled: false,
        });
      }

      const chartingMetaFields = META_FIELDS.filter(
        (key) => firstRow.meta[key],
      );
      if (chartingMetaFields.length > 0) {
        chartingMetaFields.forEach((key) => {
          analyticsKeysUnion.push({
            displayKey: META_FIELDS_DISPLAY_KEYS[key],
            actualKey: `meta.${key}`,
            sectionKey: SectionKey.other,
            disabled: false,
          });
        });
      }

      // Output node keys
      analyticsKeysUnion.push(
        ...outputParentExecutionResults.data_columns.map((column) => ({
          displayKey: `data.${column}`,
          actualKey: `output_data.${column}`,
          sectionKey: SectionKey.output_data,
          disabled: disabledOutputDataKeys.output_data[column],
        })),
      );

      // Auxiliary data keys
      analyticsKeysUnion.push(
        ...outputParentExecutionResults.aux_data_columns.map((column) => ({
          displayKey: column,
          actualKey: `aux_data.${column}`,
          sectionKey: SectionKey.aux_data,
          disabled: disabledOutputDataKeys.aux_data[column],
        })),
      );

      // TODO: To discuss if this proper approach
      const outcomesDataColumns = getOutcomeDataColumns(outcomesFile.data);

      // Outcome data keys
      analyticsKeysUnion.push(
        ...outcomesDataColumns.map((column) => ({
          displayKey: column.replace(SUB_COLUMN_SEPARATOR, "."),
          actualKey: `outcome_data.${column}`,
          sectionKey: SectionKey.outcomes,
          disabled: disabledOutputDataKeys.outcome_data[column],
        })),
      );

      const analyticsKeys: AnalyticsKey[] = uniqWith(
        analyticsKeysUnion,
        isEqual,
      ).sort((a, b) => a.displayKey.localeCompare(b.displayKey));

      const preparedData = preprocessAnalyticsData(
        combinedAnalyticsData,
        analyticsKeys,
      );

      return {
        type: "success",
        ...nodeAnalyticsFile,
        keys: analyticsKeys,
        data: preparedData,
        isMultiversion: false,
      };
    },
    {
      enabled: Boolean(
        options.enabled &&
          baseUrl &&
          nodeWasExecuted &&
          nodeAnalyticsFile &&
          nodeId,
      ),
      refetchInterval: Infinity,
      refetchOnMount: false,
      keepPreviousData: true,
    },
  );
};

const fetchWithPolling = async <T>(
  url: string,
  params: GenericObjectT,
  timeout = 5 * 60 * 1000,
): Promise<T> => {
  const startTime = Date.now();

  while (true) {
    const response = await fetch(url);

    if (response.status === 200) {
      return response.json();
    }

    if (Date.now() - startTime > timeout) {
      throw new Error(
        `Timeout after polling the file for ${timeout / 1000} seconds: ${JSON.stringify(params)}`,
      );
    }

    if (response.status !== 404) {
      throw new Error(
        `Got an error ${response.status} while getting the file: ${JSON.stringify(params)}`,
      );
    }

    await new Promise((resolve) => setTimeout(resolve, 1000));
  }
};

const isArrayOrObject = (value: unknown): boolean =>
  isString(value) ? value === "Array" || value === "Object" : false;

const getOutcomeDataColumns = (outcomesData: OutcomeDataFile["data"]) => {
  return Array.from(new Set(Object.values(outcomesData).flatMap(Object.keys)));
};
