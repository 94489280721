import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { Content, Header, Item, Trigger } from "@radix-ui/react-accordion";
import React from "react";
import { twJoin, twMerge } from "tailwind-merge";

import { Icon, IconSizes } from "src/base-components/Icon";

export const accordionRootClassName =
  "border-gray-200 border rounded-lg divide-y divide-gray-200";

const contentChildBaseClassName = "pl-11 pr-4";
const contentClassName =
  "radix-state-open:animate-accordionSlideDownAndShowOverflow radix-state-closed:animate-accordionSlideUpAndHideOverflow radix-state-open";

type PropsT = {
  value: string;
  title: React.ReactNode;
  className?: string;
  children: React.ReactNode;
  disabled?: boolean;
  dataLoc?: string;
  headerContent?: React.ReactNode;
  headerClassName?: string;
  headerAsChild?: boolean;
  iconSize?: IconSizes;
  contentWrapperClassName?: string;
};

export const EditorAccordionItem: React.FC<PropsT> = ({
  value,
  title,
  className,
  children,
  contentWrapperClassName,
  disabled,
  dataLoc,
  headerContent,
  headerClassName,
  headerAsChild,
  iconSize = "xs",
}) => (
  <Item data-loc={dataLoc} disabled={disabled} value={value}>
    <Header className={headerContent ? "flex items-center" : ""}>
      <Trigger
        asChild={headerAsChild}
        className={twMerge(
          "group flex h-13 flex-row items-center border-gray-200 px-4 text-gray-800 font-inter-semibold-13px",
          !headerContent && "w-full",
          // The trigger is the clickable element that opens the accordion, so it should grow to fill as much space as possible
          headerContent && "grow",
          headerClassName,
        )}
        data-loc={`${dataLoc}-trigger`}
      >
        <div
          className={twJoin("flex", headerAsChild && "cursor-pointer")}
          tabIndex={headerAsChild ? 0 : undefined}
        >
          <div className="mr-2 group-radix-state-closed:-rotate-90 group-radix-state-closed:duration-300 group-radix-state-open:duration-300">
            <Icon icon={faChevronDown} size={iconSize} />
          </div>
          {title}
        </div>
      </Trigger>
      {headerContent}
    </Header>
    <Content className={twMerge(contentClassName, contentWrapperClassName)}>
      <div className={twMerge(contentChildBaseClassName, className)}>
        {children}
      </div>
    </Content>
  </Item>
);

export { Root as AccordionRoot } from "@radix-ui/react-accordion";
