import {
  faBolt,
  faChartLine,
  faCog,
  faLayerPlus,
  faSplit,
  faUserCircle,
  faWavePulse,
  faWebhook,
} from "@fortawesome/pro-regular-svg-icons";
import { faScaleUnbalancedFlip } from "@fortawesome/pro-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";

import { FlowT, FlowVersionStatusT } from "src/api/flowTypes";
import { EditFlowModal } from "src/flowsOverview/EditFlowModal";
import { useSubmitFlowSettingsUpdate } from "src/flowsOverview/v2/useSubmitFlowSettingsUpdate";
import { useCapabilities } from "src/hooks/useCapabilities";
import { NavItem, NavSidebar } from "src/layout/NavSideBar";
import { HashKeys } from "src/router/SearchParams";
import { appendCurrentFlags } from "src/router/featureFlags";
import {
  FlowPageParamsT,
  getFlowSubpageUrl,
  getFlowVersionsUrl,
} from "src/router/urls";
import { useParamsDecode } from "src/utils/useParamsDecode";

export const FlowNavSidebar = ({ flow }: { flow?: FlowT }) => {
  const { flow_id: flowId, orgId, wsId } = useParamsDecode<FlowPageParamsT>();
  const navigate = useNavigate();
  const onSubmitFlowSettings = useSubmitFlowSettingsUpdate(wsId, flow);
  const [collapsed, setCollapsed] = useLocalStorage(
    "flowDetailNavigationCollapsed",
    false,
  );

  const hasActiveTrafficPolicies = Boolean(flow?.active_traffic_policy);
  const hasPublishedVersion = flow?.versions.some(
    (v) => v.status === FlowVersionStatusT.PUBLISHED,
  );
  const {
    decisionHistory,
    outgoingWebhooks,
    manualReview,
    flowVersions,
    jobs,
    outcomes,
    flows,
  } = useCapabilities();

  const currentPageUrl = useLocation();

  return (
    <div>
      <NavSidebar collapsed={collapsed} setCollapsed={setCollapsed}>
        {flowVersions.canAccess && (
          <NavItem
            dataLoc="versions"
            icon={faLayerPlus}
            title="Versions"
            to={getFlowVersionsUrl(orgId, wsId, flowId)}
          />
        )}
        {flowVersions.canAccess && (
          <NavItem
            disabled={!hasActiveTrafficPolicies}
            icon={faSplit}
            message={
              hasActiveTrafficPolicies
                ? undefined
                : "This Decision Flow does not have published versions. Routing policies are used to define routing patterns for your published versions."
            }
            title="Routing policies"
            to={getFlowSubpageUrl(orgId, wsId, flowId, "traffic")}
          />
        )}
        {manualReview.canAccess && (
          <NavItem
            icon={faUserCircle}
            title="Review queue"
            to={getFlowSubpageUrl(orgId, wsId, flowId, "review-queue")}
          />
        )}
        {decisionHistory.canAccess && (
          <NavItem
            dataLoc="decision-history"
            icon={faWavePulse}
            title="Decision history"
            to={getFlowSubpageUrl(orgId, wsId, flowId, "history")}
          />
        )}
        {flows.canEdit && (
          <NavItem
            disabled={!hasPublishedVersion}
            icon={faChartLine}
            message={
              hasPublishedVersion
                ? undefined
                : "This Decision Flow does not have published versions. Performance metrics are only available for live decisions made to published versions."
            }
            title="Performance"
            to={getFlowSubpageUrl(orgId, wsId, flowId, "performance")}
          />
        )}
        {outgoingWebhooks.canAccess && (
          <NavItem
            dataLoc="webhooks"
            icon={faWebhook}
            title="Webhooks"
            to={getFlowSubpageUrl(orgId, wsId, flowId, "webhooks")}
          />
        )}
        {jobs.canAccess && (
          <NavItem
            dataLoc="jobs"
            icon={faBolt}
            title="Jobs"
            to={getFlowSubpageUrl(orgId, wsId, flowId, "jobs")}
          />
        )}
        {outcomes.canAccess && (
          <NavItem
            dataLoc="outcomes"
            icon={faScaleUnbalancedFlip}
            title="Outcomes"
            to={getFlowSubpageUrl(orgId, wsId, flowId, "outcomes")}
          />
        )}
        {flowVersions.canEdit && (
          <NavItem
            dataLoc="flow-settings"
            icon={faCog}
            title="Settings"
            to={appendCurrentFlags(currentPageUrl.pathname) + HashKeys.Edit}
            neverShowActive
          />
        )}
      </NavSidebar>
      <EditFlowModal
        flow={flow}
        mode="edit"
        open={!!flow && window.location.hash === HashKeys.Edit}
        organizationId={orgId}
        title="Edit Decision Flow"
        workspaceId={wsId}
        onClose={() => {
          navigate(appendCurrentFlags(currentPageUrl.pathname));
        }}
        onConfirm={onSubmitFlowSettings}
      />
    </div>
  );
};
