/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface ColumnMapping
 */
export interface ColumnMapping {
    /**
     * 
     * @type {Array<EntityMapping>}
     * @memberof ColumnMapping
     */
    'entities'?: Array<EntityMapping>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ColumnMapping
     */
    'grouping_keys'?: Array<string>;
    /**
     * 
     * @type {Array<FeatureMapping>}
     * @memberof ColumnMapping
     */
    'features'?: Array<FeatureMapping>;
}
/**
 * 
 * @export
 * @interface EntityMapping
 */
export interface EntityMapping {
    /**
     * 
     * @type {string}
     * @memberof EntityMapping
     */
    'column_name': string;
    /**
     * 
     * @type {string}
     * @memberof EntityMapping
     */
    'entity_name': string;
}
/**
 * 
 * @export
 * @interface EventConfig
 */
export interface EventConfig {
    /**
     * 
     * @type {string}
     * @memberof EventConfig
     */
    'event_type': string;
    /**
     * 
     * @type {string}
     * @memberof EventConfig
     */
    'json_schema': string;
}
/**
 * 
 * @export
 * @interface FeatureCreate
 */
export interface FeatureCreate {
    /**
     * 
     * @type {string}
     * @memberof FeatureCreate
     */
    'etag'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureCreate
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureCreate
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureCreate
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof FeatureCreate
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FeatureCreate
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FeatureCreate
     */
    'feature_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureCreate
     */
    'query_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FeatureCreate
     */
    'query_column'?: string | null;
}
/**
 * 
 * @export
 * @interface FeatureMapping
 */
export interface FeatureMapping {
    /**
     * 
     * @type {string}
     * @memberof FeatureMapping
     */
    'column_name': string;
    /**
     * 
     * @type {string}
     * @memberof FeatureMapping
     */
    'feature_key': string;
    /**
     * 
     * @type {string}
     * @memberof FeatureMapping
     */
    'feature_type'?: string;
}
/**
 * 
 * @export
 * @interface FeaturePatch
 */
export interface FeaturePatch {
    /**
     * 
     * @type {string}
     * @memberof FeaturePatch
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FeaturePatch
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FeaturePatch
     */
    'query_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FeaturePatch
     */
    'query_column'?: string | null;
}
/**
 * 
 * @export
 * @interface FeatureQuery
 */
export interface FeatureQuery {
    /**
     * 
     * @type {string}
     * @memberof FeatureQuery
     */
    'etag'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureQuery
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureQuery
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureQuery
     */
    'query'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FeatureQuery
     */
    'event'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FeatureQuery
     */
    'backfilling_query'?: string | null;
    /**
     * 
     * @type {ColumnMapping}
     * @memberof FeatureQuery
     */
    'columns'?: ColumnMapping | null;
    /**
     * 
     * @type {number}
     * @memberof FeatureQuery
     */
    'window_hop'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FeatureQuery
     */
    'window_length'?: number | null;
    /**
     * 
     * @type {FeatureQueryStatus}
     * @memberof FeatureQuery
     */
    'status'?: FeatureQueryStatus | null;
    /**
     * 
     * @type {string}
     * @memberof FeatureQuery
     */
    'id'?: string;
}


/**
 * 
 * @export
 * @interface FeatureQueryCreate
 */
export interface FeatureQueryCreate {
    /**
     * 
     * @type {string}
     * @memberof FeatureQueryCreate
     */
    'query'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FeatureQueryCreate
     */
    'event'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FeatureQueryCreate
     */
    'backfilling_query'?: string | null;
    /**
     * 
     * @type {ColumnMapping}
     * @memberof FeatureQueryCreate
     */
    'columns'?: ColumnMapping | null;
    /**
     * 
     * @type {number}
     * @memberof FeatureQueryCreate
     */
    'window_hop'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FeatureQueryCreate
     */
    'window_length'?: number | null;
    /**
     * 
     * @type {FeatureQueryStatus}
     * @memberof FeatureQueryCreate
     */
    'status'?: FeatureQueryStatus | null;
}


/**
 * 
 * @export
 * @interface FeatureQueryPatch
 */
export interface FeatureQueryPatch {
    /**
     * 
     * @type {string}
     * @memberof FeatureQueryPatch
     */
    'query'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FeatureQueryPatch
     */
    'event'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FeatureQueryPatch
     */
    'backfilling_query'?: string | null;
    /**
     * 
     * @type {ColumnMapping}
     * @memberof FeatureQueryPatch
     */
    'columns'?: ColumnMapping | null;
    /**
     * 
     * @type {number}
     * @memberof FeatureQueryPatch
     */
    'window_hop'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FeatureQueryPatch
     */
    'window_length'?: number | null;
    /**
     * 
     * @type {FeatureQueryStatus}
     * @memberof FeatureQueryPatch
     */
    'status'?: FeatureQueryStatus | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export enum FeatureQueryStatus {
    ACTIVE = 'active',
    INACTIVE = 'inactive'
}


/**
 * 
 * @export
 * @interface FeatureResponse
 */
export interface FeatureResponse {
    /**
     * 
     * @type {string}
     * @memberof FeatureResponse
     */
    'etag'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureResponse
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureResponse
     */
    'updated_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureResponse
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof FeatureResponse
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FeatureResponse
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FeatureResponse
     */
    'feature_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof FeatureResponse
     */
    'query_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FeatureResponse
     */
    'query_column'?: string | null;
    /**
     * 
     * @type {FeatureQuery}
     * @memberof FeatureResponse
     */
    'query'?: FeatureQuery | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FeatureResponse
     */
    'entity_types'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface PreviewQueryRequest
 */
export interface PreviewQueryRequest {
    /**
     * 
     * @type {string}
     * @memberof PreviewQueryRequest
     */
    'query': string;
    /**
     * 
     * @type {number}
     * @memberof PreviewQueryRequest
     */
    'limit'?: number;
    /**
     * 
     * @type {number}
     * @memberof PreviewQueryRequest
     */
    'window_length': number;
}
/**
 * 
 * @export
 * @interface PreviewQueryResponse
 */
export interface PreviewQueryResponse {
    /**
     * 
     * @type {Array<object>}
     * @memberof PreviewQueryResponse
     */
    'data': Array<object>;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<ValidationErrorLocInner>}
     * @memberof ValidationError
     */
    'loc': Array<ValidationErrorLocInner>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface ValidationErrorLocInner
 */
export interface ValidationErrorLocInner {
}

/**
 * EventsApi - axios parameter creator
 * @export
 */
export const EventsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get All Event Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEventTypesEventsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/events/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Event Config
         * @param {string} eventType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventConfigEventsEventTypeGet: async (eventType: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventType' is not null or undefined
            assertParamExists('getEventConfigEventsEventTypeGet', 'eventType', eventType)
            const localVarPath = `/events/{event_type}`
                .replace(`{${"event_type"}}`, encodeURIComponent(String(eventType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save Event Config
         * @param {EventConfig} eventConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveEventConfigEventsPost: async (eventConfig: EventConfig, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventConfig' is not null or undefined
            assertParamExists('saveEventConfigEventsPost', 'eventConfig', eventConfig)
            const localVarPath = `/events/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eventConfig, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventsApi - functional programming interface
 * @export
 */
export const EventsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get All Event Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllEventTypesEventsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string | null>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllEventTypesEventsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EventsApi.getAllEventTypesEventsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Event Config
         * @param {string} eventType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventConfigEventsEventTypeGet(eventType: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EventConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventConfigEventsEventTypeGet(eventType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EventsApi.getEventConfigEventsEventTypeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Save Event Config
         * @param {EventConfig} eventConfig 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveEventConfigEventsPost(eventConfig: EventConfig, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveEventConfigEventsPost(eventConfig, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EventsApi.saveEventConfigEventsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EventsApi - factory interface
 * @export
 */
export const EventsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get All Event Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEventTypesEventsGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<string | null>> {
            return localVarFp.getAllEventTypesEventsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Event Config
         * @param {EventsApiGetEventConfigEventsEventTypeGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventConfigEventsEventTypeGet(requestParameters: EventsApiGetEventConfigEventsEventTypeGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<EventConfig> {
            return localVarFp.getEventConfigEventsEventTypeGet(requestParameters.eventType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save Event Config
         * @param {EventsApiSaveEventConfigEventsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveEventConfigEventsPost(requestParameters: EventsApiSaveEventConfigEventsPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.saveEventConfigEventsPost(requestParameters.eventConfig, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getEventConfigEventsEventTypeGet operation in EventsApi.
 * @export
 * @interface EventsApiGetEventConfigEventsEventTypeGetRequest
 */
export interface EventsApiGetEventConfigEventsEventTypeGetRequest {
    /**
     * 
     * @type {string}
     * @memberof EventsApiGetEventConfigEventsEventTypeGet
     */
    readonly eventType: string
}

/**
 * Request parameters for saveEventConfigEventsPost operation in EventsApi.
 * @export
 * @interface EventsApiSaveEventConfigEventsPostRequest
 */
export interface EventsApiSaveEventConfigEventsPostRequest {
    /**
     * 
     * @type {EventConfig}
     * @memberof EventsApiSaveEventConfigEventsPost
     */
    readonly eventConfig: EventConfig
}

/**
 * EventsApi - object-oriented interface
 * @export
 * @class EventsApi
 * @extends {BaseAPI}
 */
export class EventsApi extends BaseAPI {
    /**
     * 
     * @summary Get All Event Types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public getAllEventTypesEventsGet(options?: RawAxiosRequestConfig) {
        return EventsApiFp(this.configuration).getAllEventTypesEventsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Event Config
     * @param {EventsApiGetEventConfigEventsEventTypeGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public getEventConfigEventsEventTypeGet(requestParameters: EventsApiGetEventConfigEventsEventTypeGetRequest, options?: RawAxiosRequestConfig) {
        return EventsApiFp(this.configuration).getEventConfigEventsEventTypeGet(requestParameters.eventType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save Event Config
     * @param {EventsApiSaveEventConfigEventsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsApi
     */
    public saveEventConfigEventsPost(requestParameters: EventsApiSaveEventConfigEventsPostRequest, options?: RawAxiosRequestConfig) {
        return EventsApiFp(this.configuration).saveEventConfigEventsPost(requestParameters.eventConfig, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FeatureQueriesApi - axios parameter creator
 * @export
 */
export const FeatureQueriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Feature Query Handler
         * @param {FeatureQueryCreate} featureQueryCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFeatureQueryHandlerFeatureQueriesPost: async (featureQueryCreate: FeatureQueryCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'featureQueryCreate' is not null or undefined
            assertParamExists('createFeatureQueryHandlerFeatureQueriesPost', 'featureQueryCreate', featureQueryCreate)
            const localVarPath = `/feature_queries/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(featureQueryCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Feature Query Handler
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeatureQueryHandlerFeatureQueriesIdDelete: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteFeatureQueryHandlerFeatureQueriesIdDelete', 'id', id)
            const localVarPath = `/feature_queries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Execution Heartbeat
         * @param {number} heartbeatSeconds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executionHeartbeatFeatureQueriesHeartbeatPost: async (heartbeatSeconds: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'heartbeatSeconds' is not null or undefined
            assertParamExists('executionHeartbeatFeatureQueriesHeartbeatPost', 'heartbeatSeconds', heartbeatSeconds)
            const localVarPath = `/feature_queries/heartbeat`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (heartbeatSeconds !== undefined) {
                localVarQueryParameter['heartbeat_seconds'] = heartbeatSeconds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fetch Backfill Query
         * @param {string} id 
         * @param {number} numberOfWindows 
         * @param {string} timestampStart 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchBackfillQueryFeatureQueriesBackfillQueryPost: async (id: string, numberOfWindows: number, timestampStart: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchBackfillQueryFeatureQueriesBackfillQueryPost', 'id', id)
            // verify required parameter 'numberOfWindows' is not null or undefined
            assertParamExists('fetchBackfillQueryFeatureQueriesBackfillQueryPost', 'numberOfWindows', numberOfWindows)
            // verify required parameter 'timestampStart' is not null or undefined
            assertParamExists('fetchBackfillQueryFeatureQueriesBackfillQueryPost', 'timestampStart', timestampStart)
            const localVarPath = `/feature_queries/backfill_query`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (numberOfWindows !== undefined) {
                localVarQueryParameter['number_of_windows'] = numberOfWindows;
            }

            if (timestampStart !== undefined) {
                localVarQueryParameter['timestamp_start'] = timestampStart;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Feature Queries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureQueriesFeatureQueriesGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/feature_queries/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Feature Query
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureQueryFeatureQueriesIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFeatureQueryFeatureQueriesIdGet', 'id', id)
            const localVarPath = `/feature_queries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Preview Query
         * @param {PreviewQueryRequest} previewQueryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        previewQueryFeatureQueriesPreviewPost: async (previewQueryRequest: PreviewQueryRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'previewQueryRequest' is not null or undefined
            assertParamExists('previewQueryFeatureQueriesPreviewPost', 'previewQueryRequest', previewQueryRequest)
            const localVarPath = `/feature_queries/preview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(previewQueryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Feature Query Handler
         * @param {string} id 
         * @param {FeatureQueryPatch} featureQueryPatch 
         * @param {string | null} [ifMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFeatureQueryHandlerFeatureQueriesIdPatch: async (id: string, featureQueryPatch: FeatureQueryPatch, ifMatch?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateFeatureQueryHandlerFeatureQueriesIdPatch', 'id', id)
            // verify required parameter 'featureQueryPatch' is not null or undefined
            assertParamExists('updateFeatureQueryHandlerFeatureQueriesIdPatch', 'featureQueryPatch', featureQueryPatch)
            const localVarPath = `/feature_queries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['if-match'] = String(ifMatch);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(featureQueryPatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeatureQueriesApi - functional programming interface
 * @export
 */
export const FeatureQueriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeatureQueriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Feature Query Handler
         * @param {FeatureQueryCreate} featureQueryCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFeatureQueryHandlerFeatureQueriesPost(featureQueryCreate: FeatureQueryCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeatureQuery>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFeatureQueryHandlerFeatureQueriesPost(featureQueryCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureQueriesApi.createFeatureQueryHandlerFeatureQueriesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Feature Query Handler
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFeatureQueryHandlerFeatureQueriesIdDelete(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFeatureQueryHandlerFeatureQueriesIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureQueriesApi.deleteFeatureQueryHandlerFeatureQueriesIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Execution Heartbeat
         * @param {number} heartbeatSeconds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async executionHeartbeatFeatureQueriesHeartbeatPost(heartbeatSeconds: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.executionHeartbeatFeatureQueriesHeartbeatPost(heartbeatSeconds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureQueriesApi.executionHeartbeatFeatureQueriesHeartbeatPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Fetch Backfill Query
         * @param {string} id 
         * @param {number} numberOfWindows 
         * @param {string} timestampStart 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchBackfillQueryFeatureQueriesBackfillQueryPost(id: string, numberOfWindows: number, timestampStart: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchBackfillQueryFeatureQueriesBackfillQueryPost(id, numberOfWindows, timestampStart, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureQueriesApi.fetchBackfillQueryFeatureQueriesBackfillQueryPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Feature Queries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeatureQueriesFeatureQueriesGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FeatureQuery>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeatureQueriesFeatureQueriesGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureQueriesApi.getFeatureQueriesFeatureQueriesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Feature Query
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeatureQueryFeatureQueriesIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeatureQuery>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeatureQueryFeatureQueriesIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureQueriesApi.getFeatureQueryFeatureQueriesIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Preview Query
         * @param {PreviewQueryRequest} previewQueryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async previewQueryFeatureQueriesPreviewPost(previewQueryRequest: PreviewQueryRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PreviewQueryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.previewQueryFeatureQueriesPreviewPost(previewQueryRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureQueriesApi.previewQueryFeatureQueriesPreviewPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Feature Query Handler
         * @param {string} id 
         * @param {FeatureQueryPatch} featureQueryPatch 
         * @param {string | null} [ifMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFeatureQueryHandlerFeatureQueriesIdPatch(id: string, featureQueryPatch: FeatureQueryPatch, ifMatch?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeatureQuery>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFeatureQueryHandlerFeatureQueriesIdPatch(id, featureQueryPatch, ifMatch, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeatureQueriesApi.updateFeatureQueryHandlerFeatureQueriesIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FeatureQueriesApi - factory interface
 * @export
 */
export const FeatureQueriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeatureQueriesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Feature Query Handler
         * @param {FeatureQueriesApiCreateFeatureQueryHandlerFeatureQueriesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFeatureQueryHandlerFeatureQueriesPost(requestParameters: FeatureQueriesApiCreateFeatureQueryHandlerFeatureQueriesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<FeatureQuery> {
            return localVarFp.createFeatureQueryHandlerFeatureQueriesPost(requestParameters.featureQueryCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Feature Query Handler
         * @param {FeatureQueriesApiDeleteFeatureQueryHandlerFeatureQueriesIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeatureQueryHandlerFeatureQueriesIdDelete(requestParameters: FeatureQueriesApiDeleteFeatureQueryHandlerFeatureQueriesIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.deleteFeatureQueryHandlerFeatureQueriesIdDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Execution Heartbeat
         * @param {FeatureQueriesApiExecutionHeartbeatFeatureQueriesHeartbeatPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executionHeartbeatFeatureQueriesHeartbeatPost(requestParameters: FeatureQueriesApiExecutionHeartbeatFeatureQueriesHeartbeatPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.executionHeartbeatFeatureQueriesHeartbeatPost(requestParameters.heartbeatSeconds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fetch Backfill Query
         * @param {FeatureQueriesApiFetchBackfillQueryFeatureQueriesBackfillQueryPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchBackfillQueryFeatureQueriesBackfillQueryPost(requestParameters: FeatureQueriesApiFetchBackfillQueryFeatureQueriesBackfillQueryPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.fetchBackfillQueryFeatureQueriesBackfillQueryPost(requestParameters.id, requestParameters.numberOfWindows, requestParameters.timestampStart, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Feature Queries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureQueriesFeatureQueriesGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<FeatureQuery>> {
            return localVarFp.getFeatureQueriesFeatureQueriesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Feature Query
         * @param {FeatureQueriesApiGetFeatureQueryFeatureQueriesIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureQueryFeatureQueriesIdGet(requestParameters: FeatureQueriesApiGetFeatureQueryFeatureQueriesIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<FeatureQuery> {
            return localVarFp.getFeatureQueryFeatureQueriesIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Preview Query
         * @param {FeatureQueriesApiPreviewQueryFeatureQueriesPreviewPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        previewQueryFeatureQueriesPreviewPost(requestParameters: FeatureQueriesApiPreviewQueryFeatureQueriesPreviewPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PreviewQueryResponse> {
            return localVarFp.previewQueryFeatureQueriesPreviewPost(requestParameters.previewQueryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Feature Query Handler
         * @param {FeatureQueriesApiUpdateFeatureQueryHandlerFeatureQueriesIdPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFeatureQueryHandlerFeatureQueriesIdPatch(requestParameters: FeatureQueriesApiUpdateFeatureQueryHandlerFeatureQueriesIdPatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<FeatureQuery> {
            return localVarFp.updateFeatureQueryHandlerFeatureQueriesIdPatch(requestParameters.id, requestParameters.featureQueryPatch, requestParameters.ifMatch, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createFeatureQueryHandlerFeatureQueriesPost operation in FeatureQueriesApi.
 * @export
 * @interface FeatureQueriesApiCreateFeatureQueryHandlerFeatureQueriesPostRequest
 */
export interface FeatureQueriesApiCreateFeatureQueryHandlerFeatureQueriesPostRequest {
    /**
     * 
     * @type {FeatureQueryCreate}
     * @memberof FeatureQueriesApiCreateFeatureQueryHandlerFeatureQueriesPost
     */
    readonly featureQueryCreate: FeatureQueryCreate
}

/**
 * Request parameters for deleteFeatureQueryHandlerFeatureQueriesIdDelete operation in FeatureQueriesApi.
 * @export
 * @interface FeatureQueriesApiDeleteFeatureQueryHandlerFeatureQueriesIdDeleteRequest
 */
export interface FeatureQueriesApiDeleteFeatureQueryHandlerFeatureQueriesIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof FeatureQueriesApiDeleteFeatureQueryHandlerFeatureQueriesIdDelete
     */
    readonly id: string
}

/**
 * Request parameters for executionHeartbeatFeatureQueriesHeartbeatPost operation in FeatureQueriesApi.
 * @export
 * @interface FeatureQueriesApiExecutionHeartbeatFeatureQueriesHeartbeatPostRequest
 */
export interface FeatureQueriesApiExecutionHeartbeatFeatureQueriesHeartbeatPostRequest {
    /**
     * 
     * @type {number}
     * @memberof FeatureQueriesApiExecutionHeartbeatFeatureQueriesHeartbeatPost
     */
    readonly heartbeatSeconds: number
}

/**
 * Request parameters for fetchBackfillQueryFeatureQueriesBackfillQueryPost operation in FeatureQueriesApi.
 * @export
 * @interface FeatureQueriesApiFetchBackfillQueryFeatureQueriesBackfillQueryPostRequest
 */
export interface FeatureQueriesApiFetchBackfillQueryFeatureQueriesBackfillQueryPostRequest {
    /**
     * 
     * @type {string}
     * @memberof FeatureQueriesApiFetchBackfillQueryFeatureQueriesBackfillQueryPost
     */
    readonly id: string

    /**
     * 
     * @type {number}
     * @memberof FeatureQueriesApiFetchBackfillQueryFeatureQueriesBackfillQueryPost
     */
    readonly numberOfWindows: number

    /**
     * 
     * @type {string}
     * @memberof FeatureQueriesApiFetchBackfillQueryFeatureQueriesBackfillQueryPost
     */
    readonly timestampStart: string
}

/**
 * Request parameters for getFeatureQueryFeatureQueriesIdGet operation in FeatureQueriesApi.
 * @export
 * @interface FeatureQueriesApiGetFeatureQueryFeatureQueriesIdGetRequest
 */
export interface FeatureQueriesApiGetFeatureQueryFeatureQueriesIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof FeatureQueriesApiGetFeatureQueryFeatureQueriesIdGet
     */
    readonly id: string
}

/**
 * Request parameters for previewQueryFeatureQueriesPreviewPost operation in FeatureQueriesApi.
 * @export
 * @interface FeatureQueriesApiPreviewQueryFeatureQueriesPreviewPostRequest
 */
export interface FeatureQueriesApiPreviewQueryFeatureQueriesPreviewPostRequest {
    /**
     * 
     * @type {PreviewQueryRequest}
     * @memberof FeatureQueriesApiPreviewQueryFeatureQueriesPreviewPost
     */
    readonly previewQueryRequest: PreviewQueryRequest
}

/**
 * Request parameters for updateFeatureQueryHandlerFeatureQueriesIdPatch operation in FeatureQueriesApi.
 * @export
 * @interface FeatureQueriesApiUpdateFeatureQueryHandlerFeatureQueriesIdPatchRequest
 */
export interface FeatureQueriesApiUpdateFeatureQueryHandlerFeatureQueriesIdPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof FeatureQueriesApiUpdateFeatureQueryHandlerFeatureQueriesIdPatch
     */
    readonly id: string

    /**
     * 
     * @type {FeatureQueryPatch}
     * @memberof FeatureQueriesApiUpdateFeatureQueryHandlerFeatureQueriesIdPatch
     */
    readonly featureQueryPatch: FeatureQueryPatch

    /**
     * 
     * @type {string}
     * @memberof FeatureQueriesApiUpdateFeatureQueryHandlerFeatureQueriesIdPatch
     */
    readonly ifMatch?: string | null
}

/**
 * FeatureQueriesApi - object-oriented interface
 * @export
 * @class FeatureQueriesApi
 * @extends {BaseAPI}
 */
export class FeatureQueriesApi extends BaseAPI {
    /**
     * 
     * @summary Create Feature Query Handler
     * @param {FeatureQueriesApiCreateFeatureQueryHandlerFeatureQueriesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureQueriesApi
     */
    public createFeatureQueryHandlerFeatureQueriesPost(requestParameters: FeatureQueriesApiCreateFeatureQueryHandlerFeatureQueriesPostRequest, options?: RawAxiosRequestConfig) {
        return FeatureQueriesApiFp(this.configuration).createFeatureQueryHandlerFeatureQueriesPost(requestParameters.featureQueryCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Feature Query Handler
     * @param {FeatureQueriesApiDeleteFeatureQueryHandlerFeatureQueriesIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureQueriesApi
     */
    public deleteFeatureQueryHandlerFeatureQueriesIdDelete(requestParameters: FeatureQueriesApiDeleteFeatureQueryHandlerFeatureQueriesIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return FeatureQueriesApiFp(this.configuration).deleteFeatureQueryHandlerFeatureQueriesIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Execution Heartbeat
     * @param {FeatureQueriesApiExecutionHeartbeatFeatureQueriesHeartbeatPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureQueriesApi
     */
    public executionHeartbeatFeatureQueriesHeartbeatPost(requestParameters: FeatureQueriesApiExecutionHeartbeatFeatureQueriesHeartbeatPostRequest, options?: RawAxiosRequestConfig) {
        return FeatureQueriesApiFp(this.configuration).executionHeartbeatFeatureQueriesHeartbeatPost(requestParameters.heartbeatSeconds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fetch Backfill Query
     * @param {FeatureQueriesApiFetchBackfillQueryFeatureQueriesBackfillQueryPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureQueriesApi
     */
    public fetchBackfillQueryFeatureQueriesBackfillQueryPost(requestParameters: FeatureQueriesApiFetchBackfillQueryFeatureQueriesBackfillQueryPostRequest, options?: RawAxiosRequestConfig) {
        return FeatureQueriesApiFp(this.configuration).fetchBackfillQueryFeatureQueriesBackfillQueryPost(requestParameters.id, requestParameters.numberOfWindows, requestParameters.timestampStart, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Feature Queries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureQueriesApi
     */
    public getFeatureQueriesFeatureQueriesGet(options?: RawAxiosRequestConfig) {
        return FeatureQueriesApiFp(this.configuration).getFeatureQueriesFeatureQueriesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Feature Query
     * @param {FeatureQueriesApiGetFeatureQueryFeatureQueriesIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureQueriesApi
     */
    public getFeatureQueryFeatureQueriesIdGet(requestParameters: FeatureQueriesApiGetFeatureQueryFeatureQueriesIdGetRequest, options?: RawAxiosRequestConfig) {
        return FeatureQueriesApiFp(this.configuration).getFeatureQueryFeatureQueriesIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Preview Query
     * @param {FeatureQueriesApiPreviewQueryFeatureQueriesPreviewPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureQueriesApi
     */
    public previewQueryFeatureQueriesPreviewPost(requestParameters: FeatureQueriesApiPreviewQueryFeatureQueriesPreviewPostRequest, options?: RawAxiosRequestConfig) {
        return FeatureQueriesApiFp(this.configuration).previewQueryFeatureQueriesPreviewPost(requestParameters.previewQueryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Feature Query Handler
     * @param {FeatureQueriesApiUpdateFeatureQueryHandlerFeatureQueriesIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureQueriesApi
     */
    public updateFeatureQueryHandlerFeatureQueriesIdPatch(requestParameters: FeatureQueriesApiUpdateFeatureQueryHandlerFeatureQueriesIdPatchRequest, options?: RawAxiosRequestConfig) {
        return FeatureQueriesApiFp(this.configuration).updateFeatureQueryHandlerFeatureQueriesIdPatch(requestParameters.id, requestParameters.featureQueryPatch, requestParameters.ifMatch, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FeaturesApi - axios parameter creator
 * @export
 */
export const FeaturesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Feature Handler
         * @param {FeatureCreate} featureCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFeatureHandlerFeaturesPost: async (featureCreate: FeatureCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'featureCreate' is not null or undefined
            assertParamExists('createFeatureHandlerFeaturesPost', 'featureCreate', featureCreate)
            const localVarPath = `/features/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(featureCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Feature Handler
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeatureHandlerFeaturesKeyDelete: async (key: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('deleteFeatureHandlerFeaturesKeyDelete', 'key', key)
            const localVarPath = `/features/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Feature Handler
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureHandlerFeaturesKeyGet: async (key: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('getFeatureHandlerFeaturesKeyGet', 'key', key)
            const localVarPath = `/features/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Features
         * @param {string | null} [queryId] 
         * @param {string | null} [entityTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturesFeaturesGet: async (queryId?: string | null, entityTypes?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/features/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (queryId !== undefined) {
                localVarQueryParameter['query_id'] = queryId;
            }

            if (entityTypes !== undefined) {
                localVarQueryParameter['entity_types'] = entityTypes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch Feature Handler
         * @param {string} key 
         * @param {FeaturePatch} featurePatch 
         * @param {string | null} [ifMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchFeatureHandlerFeaturesKeyPatch: async (key: string, featurePatch: FeaturePatch, ifMatch?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('patchFeatureHandlerFeaturesKeyPatch', 'key', key)
            // verify required parameter 'featurePatch' is not null or undefined
            assertParamExists('patchFeatureHandlerFeaturesKeyPatch', 'featurePatch', featurePatch)
            const localVarPath = `/features/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            // authentication APIKeyHeader required
            await setApiKeyToObject(localVarHeaderParameter, "X-Api-Key", configuration)

            if (ifMatch != null) {
                localVarHeaderParameter['if-match'] = String(ifMatch);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(featurePatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeaturesApi - functional programming interface
 * @export
 */
export const FeaturesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeaturesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Feature Handler
         * @param {FeatureCreate} featureCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFeatureHandlerFeaturesPost(featureCreate: FeatureCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeatureResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFeatureHandlerFeaturesPost(featureCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeaturesApi.createFeatureHandlerFeaturesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Feature Handler
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFeatureHandlerFeaturesKeyDelete(key: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFeatureHandlerFeaturesKeyDelete(key, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeaturesApi.deleteFeatureHandlerFeaturesKeyDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Feature Handler
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeatureHandlerFeaturesKeyGet(key: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeatureResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeatureHandlerFeaturesKeyGet(key, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeaturesApi.getFeatureHandlerFeaturesKeyGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Features
         * @param {string | null} [queryId] 
         * @param {string | null} [entityTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeaturesFeaturesGet(queryId?: string | null, entityTypes?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FeatureResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeaturesFeaturesGet(queryId, entityTypes, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeaturesApi.getFeaturesFeaturesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Patch Feature Handler
         * @param {string} key 
         * @param {FeaturePatch} featurePatch 
         * @param {string | null} [ifMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchFeatureHandlerFeaturesKeyPatch(key: string, featurePatch: FeaturePatch, ifMatch?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeatureResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchFeatureHandlerFeaturesKeyPatch(key, featurePatch, ifMatch, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeaturesApi.patchFeatureHandlerFeaturesKeyPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FeaturesApi - factory interface
 * @export
 */
export const FeaturesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeaturesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Feature Handler
         * @param {FeaturesApiCreateFeatureHandlerFeaturesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFeatureHandlerFeaturesPost(requestParameters: FeaturesApiCreateFeatureHandlerFeaturesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<FeatureResponse> {
            return localVarFp.createFeatureHandlerFeaturesPost(requestParameters.featureCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Feature Handler
         * @param {FeaturesApiDeleteFeatureHandlerFeaturesKeyDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeatureHandlerFeaturesKeyDelete(requestParameters: FeaturesApiDeleteFeatureHandlerFeaturesKeyDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.deleteFeatureHandlerFeaturesKeyDelete(requestParameters.key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Feature Handler
         * @param {FeaturesApiGetFeatureHandlerFeaturesKeyGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeatureHandlerFeaturesKeyGet(requestParameters: FeaturesApiGetFeatureHandlerFeaturesKeyGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<FeatureResponse> {
            return localVarFp.getFeatureHandlerFeaturesKeyGet(requestParameters.key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Features
         * @param {FeaturesApiGetFeaturesFeaturesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturesFeaturesGet(requestParameters: FeaturesApiGetFeaturesFeaturesGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<FeatureResponse>> {
            return localVarFp.getFeaturesFeaturesGet(requestParameters.queryId, requestParameters.entityTypes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch Feature Handler
         * @param {FeaturesApiPatchFeatureHandlerFeaturesKeyPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchFeatureHandlerFeaturesKeyPatch(requestParameters: FeaturesApiPatchFeatureHandlerFeaturesKeyPatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<FeatureResponse> {
            return localVarFp.patchFeatureHandlerFeaturesKeyPatch(requestParameters.key, requestParameters.featurePatch, requestParameters.ifMatch, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createFeatureHandlerFeaturesPost operation in FeaturesApi.
 * @export
 * @interface FeaturesApiCreateFeatureHandlerFeaturesPostRequest
 */
export interface FeaturesApiCreateFeatureHandlerFeaturesPostRequest {
    /**
     * 
     * @type {FeatureCreate}
     * @memberof FeaturesApiCreateFeatureHandlerFeaturesPost
     */
    readonly featureCreate: FeatureCreate
}

/**
 * Request parameters for deleteFeatureHandlerFeaturesKeyDelete operation in FeaturesApi.
 * @export
 * @interface FeaturesApiDeleteFeatureHandlerFeaturesKeyDeleteRequest
 */
export interface FeaturesApiDeleteFeatureHandlerFeaturesKeyDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof FeaturesApiDeleteFeatureHandlerFeaturesKeyDelete
     */
    readonly key: string
}

/**
 * Request parameters for getFeatureHandlerFeaturesKeyGet operation in FeaturesApi.
 * @export
 * @interface FeaturesApiGetFeatureHandlerFeaturesKeyGetRequest
 */
export interface FeaturesApiGetFeatureHandlerFeaturesKeyGetRequest {
    /**
     * 
     * @type {string}
     * @memberof FeaturesApiGetFeatureHandlerFeaturesKeyGet
     */
    readonly key: string
}

/**
 * Request parameters for getFeaturesFeaturesGet operation in FeaturesApi.
 * @export
 * @interface FeaturesApiGetFeaturesFeaturesGetRequest
 */
export interface FeaturesApiGetFeaturesFeaturesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof FeaturesApiGetFeaturesFeaturesGet
     */
    readonly queryId?: string | null

    /**
     * 
     * @type {string}
     * @memberof FeaturesApiGetFeaturesFeaturesGet
     */
    readonly entityTypes?: string | null
}

/**
 * Request parameters for patchFeatureHandlerFeaturesKeyPatch operation in FeaturesApi.
 * @export
 * @interface FeaturesApiPatchFeatureHandlerFeaturesKeyPatchRequest
 */
export interface FeaturesApiPatchFeatureHandlerFeaturesKeyPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof FeaturesApiPatchFeatureHandlerFeaturesKeyPatch
     */
    readonly key: string

    /**
     * 
     * @type {FeaturePatch}
     * @memberof FeaturesApiPatchFeatureHandlerFeaturesKeyPatch
     */
    readonly featurePatch: FeaturePatch

    /**
     * 
     * @type {string}
     * @memberof FeaturesApiPatchFeatureHandlerFeaturesKeyPatch
     */
    readonly ifMatch?: string | null
}

/**
 * FeaturesApi - object-oriented interface
 * @export
 * @class FeaturesApi
 * @extends {BaseAPI}
 */
export class FeaturesApi extends BaseAPI {
    /**
     * 
     * @summary Create Feature Handler
     * @param {FeaturesApiCreateFeatureHandlerFeaturesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public createFeatureHandlerFeaturesPost(requestParameters: FeaturesApiCreateFeatureHandlerFeaturesPostRequest, options?: RawAxiosRequestConfig) {
        return FeaturesApiFp(this.configuration).createFeatureHandlerFeaturesPost(requestParameters.featureCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Feature Handler
     * @param {FeaturesApiDeleteFeatureHandlerFeaturesKeyDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public deleteFeatureHandlerFeaturesKeyDelete(requestParameters: FeaturesApiDeleteFeatureHandlerFeaturesKeyDeleteRequest, options?: RawAxiosRequestConfig) {
        return FeaturesApiFp(this.configuration).deleteFeatureHandlerFeaturesKeyDelete(requestParameters.key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Feature Handler
     * @param {FeaturesApiGetFeatureHandlerFeaturesKeyGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public getFeatureHandlerFeaturesKeyGet(requestParameters: FeaturesApiGetFeatureHandlerFeaturesKeyGetRequest, options?: RawAxiosRequestConfig) {
        return FeaturesApiFp(this.configuration).getFeatureHandlerFeaturesKeyGet(requestParameters.key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Features
     * @param {FeaturesApiGetFeaturesFeaturesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public getFeaturesFeaturesGet(requestParameters: FeaturesApiGetFeaturesFeaturesGetRequest = {}, options?: RawAxiosRequestConfig) {
        return FeaturesApiFp(this.configuration).getFeaturesFeaturesGet(requestParameters.queryId, requestParameters.entityTypes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch Feature Handler
     * @param {FeaturesApiPatchFeatureHandlerFeaturesKeyPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public patchFeatureHandlerFeaturesKeyPatch(requestParameters: FeaturesApiPatchFeatureHandlerFeaturesKeyPatchRequest, options?: RawAxiosRequestConfig) {
        return FeaturesApiFp(this.configuration).patchFeatureHandlerFeaturesKeyPatch(requestParameters.key, requestParameters.featurePatch, requestParameters.ifMatch, options).then((request) => request(this.axios, this.basePath));
    }
}



