import { useHistoryDecisionV2 } from "src/api/decisionHistoryV2/decisionHistoryQueries";
import { DecisionRequest } from "src/decisionsOverview/DecisionsSidepane";
import { Tree, TreeData } from "src/entities/entityView/Tree";
import { INTERNAL_DATA_KEYS } from "src/parentFlowNodes/loopNode/constants";
import { useWorkspaceContext } from "src/router/routerContextHooks";

type Props = {
  decisionId: string;
};

const transformToTreeData = (
  rowData: Record<string, unknown> | undefined,
): TreeData => {
  return {
    id: "root",
    key: "root",
    value: "Custom fields",
    children: Object.entries(rowData ?? {})
      .filter(([key]) => !INTERNAL_DATA_KEYS.includes(key))
      .map(([key, value]) => ({
        id: key,
        key,
        value,
      })),
  };
};

export const DecisionInputFieldsTree: React.FC<Props> = ({ decisionId }) => {
  const { workspace } = useWorkspaceContext();
  const { data, isLoading } = useHistoryDecisionV2({
    decisionId,
    baseUrl: workspace.base_url!,
  });

  const rowData = (() => {
    if (data?.isQueryError || !data?.decision) {
      return undefined;
    }

    const decision = data.decision;

    return (decision.request as DecisionRequest).data;
  })();

  const treeData = transformToTreeData(rowData);

  return <Tree data={treeData} loading={isLoading} />;
};
