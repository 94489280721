import { makeConnectionTemplate } from "src/connections/types";

const productModels: { key: string; value: string }[] = [
  {
    key: "BUSINESS_CREDIT_INDUSTRY_REPORT_PLUS_2",
    value: "Business Credit Industry Report Plus 2.0",
  },
  {
    key: "BUSINESS_CREDIT_INDUSTRY_REPORT_PLUS",
    value: "Business Credit Industry Report Plus",
  },
  {
    key: "BUSINESS_IDENTITY",
    value: "Business Identity Report",
  },
  { key: "BUSINESS_FAILURE_SCORE", value: "Business Failure Score" },
  {
    key: "COMMERCIAL_INSIGHT_DELINQUENCY_SCORE_SBFE",
    value: "Commercial Insight Delinquency Score, SBFE",
  },
  {
    key: "BUSINESS_DELINQUENCY_FINANCIAL_SCORE",
    value: "Business Delinquency Financial Score",
  },
  {
    key: "BUSINESS_DELINQUENCY_SCORE",
    value: "Business Delinquency Score",
  },
  {
    key: "BUSINESS_CREDIT_INDUSTRY_REPORT_SUMMARY",
    value: "Business Credit Industry Report Summary",
  },
  {
    key: "COMMERCIAL_INSIGHT_DELINQUENCY_SCORE_CFN",
    value: "Commercial Insight Delinquency Score, CFN",
  },
  {
    key: "PUBLIC_RECORDS",
    value: "Public Records",
  },
  {
    key: "FIRMOGRAPHICS",
    value: "Firmographics",
  },
];

export const EquifaxUsCommercialRiskTemplate = makeConnectionTemplate({
  provider: "equifax_us_commercial_risk",
  secrets: [
    { key: "customer_number", name: "Customer number", required: true },
    { key: "security_code", name: "Security code", required: true },
  ],
  resources: {
    equifax_us_commercial_risk_credit_report: {
      name: "Credit Report",
      configKeys: [
        {
          key: "products",
          name: "Commercial Products",
          hint: `The list of Equifax products that you want to request. The report response depends on the selected products. Refer to “Commercial XML System to System Manual” for more details on the available products.`,
          required: true,
          type: "multiple_selection",
          elements: productModels,
          minItems: 1,
          maxItems: 5,
        },
      ],
    },
  },
});
