import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";

import { FlowT, FlowVersionT } from "src/api/flowTypes";
import { NodeTypes } from "src/clients/flow-api";
import { useDatasets } from "src/datasets/api/queries";
import { Callout } from "src/design-system/Callout";
import { useAuthoringContext } from "src/router/routerContextHooks";

export const RunFlowWarning: React.FC<{
  flow: FlowT;
  selectedDatasetId: string | null;
  version: FlowVersionT;
}> = ({ flow, selectedDatasetId, version }) => {
  const { workspace } = useAuthoringContext();

  const datasetsQuery = useDatasets({
    flowId: flow.id,
    baseUrl: workspace.base_url,
  });
  const selectedDataset =
    selectedDatasetId !== null && datasetsQuery.data
      ? datasetsQuery.data.find((d) => d.id === selectedDatasetId)
      : null;
  if (!selectedDataset?.row_count || selectedDataset.row_count < 100) {
    return null;
  }

  const nodes_using_live_provider_environment = version.graph?.nodes
    ? Object.values(version.graph.nodes).filter((node) => {
        if (
          ![
            NodeTypes.MANIFEST_CONNECTION_NODE,
            NodeTypes.CUSTOM_CONNECTION_NODE,
          ].includes(node.type)
        )
          return false;
        const authoring_mode_data_source =
          node.meta?.config?.environments_config?.authoring_mode_data_source;

        return (
          authoring_mode_data_source &&
          authoring_mode_data_source !== "mock_data" &&
          authoring_mode_data_source !== "empty_sample"
        );
      })
    : [];

  if (!nodes_using_live_provider_environment.length) {
    return null;
  }

  return (
    <div className="mt-3">
      <Callout icon={faInfoCircle} type="warning">
        This test run will make 100+ API calls to a live provider environment
        for the following{" "}
        {nodes_using_live_provider_environment.length === 1 ? "node" : "nodes"}:
        <ul>
          {nodes_using_live_provider_environment.map((node) => (
            <li key={node.id}>• {node.name}</li>
          ))}
        </ul>
        <p>This may take longer to complete, or incur additional costs.</p>
      </Callout>
    </div>
  );
};
