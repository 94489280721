import {
  faCheck,
  faChevronDown,
  faTimes,
} from "@fortawesome/pro-regular-svg-icons";
import { Combobox as HeadlessCombobox } from "@headlessui/react";
import type { Placement } from "@popperjs/core";
import { AnimatePresence, m } from "framer-motion";
import { groupBy, isString, omit } from "lodash";
import { forwardRef, Fragment, useState } from "react";
import { usePopper } from "react-popper";
import { twJoin } from "tailwind-merge";

import { Icon } from "src/base-components/Icon";
import { Tooltip } from "src/design-system/Tooltip";
import { useFuseSearch } from "src/utils/useFuseSearch";

export type Section = { key: string; displayText: string };

type Option<Sections extends readonly Section[] | undefined> = {
  key: string;
  value: React.ReactNode;
  disabled?: boolean | string;
  searchText?: string;
} & (Sections extends readonly Section[]
  ? { sectionKey: Sections[number]["key"] }
  : {});

type sectionRenderer = (
  section: Section,
  index: number,
) => React.ReactNode | undefined;

type Props<Sections extends readonly Section[] | undefined> = {
  options: Option<Sections>[];
  value?: Nullable<string>;
  onChange?: (value: Nullable<string>) => void;
  placeholder?: string;
  disabled?: boolean;
  placement?: Placement;
  showResetButton?: boolean;
  monospaced?: boolean;
  dataLoc?: string;
  sections?: Sections;
  dropdownPlaceholder?: React.ReactNode;
  errored?: boolean;
  sectionRenderer?: sectionRenderer;
};

export const Combobox = <
  const Sections extends readonly Section[] | undefined = undefined,
>(
  props: Props<Sections>,
) => {
  const [referenceElement, setReferenceElement] =
    useState<HTMLInputElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );

  const { styles: popperStyles, attributes: popperAttributes } = usePopper(
    referenceElement,
    popperElement,
    {
      strategy: "fixed",
      modifiers: [{ name: "offset", options: { offset: [0, 8] } }],
      placement: props.placement ?? "bottom-start",
    },
  );

  const [query, setQuery] = useState("");

  const hasSearchText = props.options[0]?.searchText;

  const search = useFuseSearch(props.options, {
    threshold: 0.5,
    keys: hasSearchText ? ["searchText"] : ["key"],
  });

  const results = search(query);

  const resetButtonAvailable = props.showResetButton && props.value;

  const onReset = () => {
    props.onChange?.(null);
  };

  return (
    <HeadlessCombobox
      as="div"
      data-loc={props.dataLoc}
      disabled={props.disabled}
      value={props.value}
      onChange={props.onChange}
    >
      {({ open }) => (
        <>
          <div className="relative">
            <HeadlessCombobox.Input
              ref={setReferenceElement}
              className={twJoin(
                "h-8 w-full rounded-lg border border-gray-200 py-1 pl-3 placeholder:text-gray-500 focus:border-indigo-400 focus:outline-none focus:ring-2 focus:ring-indigo-500/25 disabled:cursor-not-allowed",
                props.monospaced
                  ? "font-mono text-xs text-indigo-600 placeholder:font-mono"
                  : "text-gray-800 font-inter-normal-12px placeholder:font-inter-normal-12px",
                resetButtonAvailable ? "pr-16" : "pr-7",
                props.errored && "border-red-600 ring-2 ring-red-400/25",
              )}
              placeholder={props.placeholder}
              onChange={(event) => setQuery(event.target.value)}
            />
            <div className="absolute right-0 top-1/2 flex -translate-y-1/2 items-center justify-start pr-2">
              <HeadlessCombobox.Button data-loc="expand-combobox-button">
                <Icon
                  color="text-gray-500"
                  disabled={props.disabled}
                  icon={faChevronDown}
                  size="xs"
                />
              </HeadlessCombobox.Button>
              {resetButtonAvailable && (
                <div
                  className="flex items-center justify-start"
                  data-loc={props.dataLoc && `${props.dataLoc}-reset`}
                >
                  <div className="mx-1.5 h-4 w-[1px] bg-gray-200" />
                  <Icon
                    color="text-gray-500"
                    disabled={props.disabled}
                    icon={faTimes}
                    size="xs"
                    onClick={onReset}
                  />
                </div>
              )}
            </div>
          </div>
          <AnimatePresence initial={false} onExitComplete={() => setQuery("")}>
            {open && (
              <div
                ref={setPopperElement}
                className="z-50"
                data-loc={`${props.dataLoc}-dropdown`}
                style={{
                  ...popperStyles.popper,
                  width: referenceElement?.scrollWidth,
                }}
                {...popperAttributes.popper}
              >
                <m.div
                  animate="visible"
                  className="decideScrollbar max-h-64 overflow-auto rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-200 ring-opacity-5 focus:outline-none"
                  exit="hidden"
                  initial="hidden"
                  transition={{
                    type: "tween",
                    ease: "easeOut",
                    duration: 0.15,
                  }}
                  variants={{
                    visible: {
                      opacity: 1,
                      scale: 1,
                    },
                    hidden: {
                      opacity: 0,
                      scale: 0.95,
                    },
                  }}
                >
                  {props.options.length === 0 ? (
                    props.dropdownPlaceholder
                  ) : (
                    <HeadlessCombobox.Options static>
                      {props.sections ? (
                        <ResultsWithSections
                          monospaced={props.monospaced}
                          results={results}
                          sectionRenderer={props.sectionRenderer}
                          sections={props.sections}
                        />
                      ) : (
                        <Results
                          monospaced={props.monospaced}
                          results={results}
                        />
                      )}
                    </HeadlessCombobox.Options>
                  )}
                </m.div>
              </div>
            )}
          </AnimatePresence>
        </>
      )}
    </HeadlessCombobox>
  );
};

const Results = <Sections extends readonly Section[] | undefined>({
  results,
  monospaced,
}: {
  results: Option<Sections>[];
  monospaced?: boolean;
}) => {
  return results.map((result) =>
    isString(result.disabled) ? (
      <Tooltip
        key={result.key}
        body={result.disabled}
        placement="right"
        asChild
      >
        <ResultOption monospaced={monospaced} result={result} />
      </Tooltip>
    ) : (
      <ResultOption key={result.key} monospaced={monospaced} result={result} />
    ),
  );
};

const ResultsWithSections = <Sections extends readonly Section[] | undefined>({
  results,
  sections,
  monospaced,
  sectionRenderer,
}: {
  results: Option<Sections>[];
  sections: Sections;
  monospaced?: boolean;
  sectionRenderer?: sectionRenderer;
}) => {
  if (!sections) return;

  const resultsGroupedBySections = groupBy(results, (r) =>
    "sectionKey" in r ? r.sectionKey : "",
  );

  const sectionsWithResults = sections
    .filter((section) => section.key in resultsGroupedBySections)
    .map((section) => ({
      ...section,
      results: resultsGroupedBySections[section.key],
    }));

  return (
    <>
      {sectionsWithResults.map((section, i) => (
        <Fragment key={section.key}>
          {sectionRenderer?.(omit(section, "results"), i) ?? (
            <p
              className={twJoin(
                "pb-1.5 pl-4 text-gray-500 font-inter-normal-12px",
                i === 0 ? "pt-1.5" : "pt-3.5",
              )}
            >
              {section.displayText}
            </p>
          )}
          <Results monospaced={monospaced} results={section.results} />
        </Fragment>
      ))}
    </>
  );
};

const ResultOption = forwardRef<
  HTMLLIElement,
  {
    monospaced?: boolean;
    result: Option<readonly Section[] | undefined>;
  }
>(({ monospaced, result }, ref) => (
  <HeadlessCombobox.Option
    ref={ref}
    className={twJoin(
      "flex items-center justify-between px-4 py-2.5 text-xs-sm",
      result.disabled
        ? "cursor-default text-gray-500"
        : "cursor-pointer text-gray-800 hover:bg-gray-50 ui-active:bg-gray-50",
      monospaced && "font-mono text-indigo-600",
    )}
    disabled={!!result.disabled}
    value={result.key}
  >
    {({ selected }) => (
      <>
        <div className="min-w-0 flex-1">{result.value}</div>
        {selected && (
          <span>
            <div>
              <Icon color="text-indigo-500" icon={faCheck} size="sm" />
            </div>
          </span>
        )}
      </>
    )}
  </HeadlessCombobox.Option>
));
