import {
  faBuilding,
  faCalculatorSimple,
  faUser,
} from "@fortawesome/pro-regular-svg-icons";
import { faCreditCard } from "@fortawesome/pro-regular-svg-icons";
import { capitalize } from "lodash";
import { useLocation } from "react-router-dom";
import { formatDialect, trino, hive } from "sql-formatter";

import { Pill, PillVariants } from "src/base-components/Pill";
import { Cell, Header } from "src/base-components/Table";
import {
  ColumnMapping,
  EntityMapping,
  FeatureQueryStatus,
  FeatureResponse,
} from "src/clients/features";
import { getWsUrl } from "src/router/urls";
import { formatDuration as formatDurationUtil } from "src/utils/numbers";

export const TAB_PATHS = {
  features: "",
  queries: "/queries",
};

export const useTabNavigation = (orgId: string, wsId: string) => {
  const location = useLocation();

  const isFeatureRoute = !location.pathname.includes("/queries");
  const isQueryRoute = location.pathname.includes("/queries");

  const getTabUrl = (tab: keyof typeof TAB_PATHS) => {
    const wsUrl = getWsUrl(orgId, wsId);
    return `${wsUrl}features${TAB_PATHS[tab]}${location.search}`;
  };

  return {
    isFeatureRoute,
    isQueryRoute,
    getTabUrl,
  };
};

export const STATUS_COLOR_MAP: Record<FeatureQueryStatus, PillVariants> = {
  active: "green",
  inactive: "gray",
} as const;

export type Entity = "card" | "account" | "company";
export const ENTITY_ICON_MAP = {
  card: faCreditCard,
  account: faUser,
  company: faBuilding,
} as const;

export type Query = {
  id: string;
  status: FeatureQueryStatus;
  event: "transaction";
  query: string;
  backfilling_query: string;
  columns: ColumnMapping;
  window_hop: number;
  window_length: number;
  created_at: string;
  updated_at: string;
  etag: string;
};

export type Common = {
  id: string;
  column: string;
  window_hop: number;
  window_length: number;
  status: FeatureQueryStatus;
  entities: Entity[];
  event: string;
  query: string;
};

export type Feature = FeatureResponse;

export type QueryRow = Common;
export type FeatureRow = Feature;

export const formatDuration = (seconds: number): string => {
  return formatDurationUtil(seconds * 1000, {
    locale: "long",
    format: ["years", "months", "weeks", "days", "hours", "minutes", "seconds"],
  });
};

export const commonColumns = [
  {
    accessorKey: "columns.entities",
    header: (info: any) => <Header info={info}>Entity</Header>,
    cell: (info: any) => (
      <Cell className="flex space-x-2" info={info}>
        {info.getValue()?.map((entity: EntityMapping) => {
          const icon =
            ENTITY_ICON_MAP[
              entity.entity_name as keyof typeof ENTITY_ICON_MAP
            ] ?? faBuilding;
          return (
            <Pill key={entity.entity_name} size="sm" variant="gray">
              <Pill.Icon icon={icon} />
              <Pill.Text>{capitalize(entity.entity_name)}</Pill.Text>
            </Pill>
          );
        })}
      </Cell>
    ),
  },
  {
    accessorKey: "event",
    header: (info: any) => <Header info={info}>Event</Header>,
    cell: (info: any) => (
      <Cell info={info}>
        <Pill size="sm" variant="gray">
          <Pill.Icon icon={faCalculatorSimple} />
          <Pill.Text>{info.getValue()}</Pill.Text>
        </Pill>
      </Cell>
    ),
  },
  {
    accessorKey: "window_hop",
    header: (info: any) => <Header info={info}>Time window</Header>,
    cell: (info: any) => (
      <Cell info={info}>{formatDuration(info.getValue())}</Cell>
    ),
  },
  {
    accessorKey: "window_length",
    header: (info: any) => <Header info={info}>Freshness</Header>,
    cell: (info: any) => (
      <Cell info={info}>{formatDuration(info.getValue())}</Cell>
    ),
  },
];

export const formatAthenaSqlQuery = (sql: string | undefined) => {
  if (!sql) return "";

  try {
    return formatDialect(sql, { dialect: trino });
  } catch (e) {
    try {
      return formatDialect(sql, { dialect: hive });
    } catch (e) {
      return sql;
    }
  }
};

export type OutletContextT = {
  type: "header" | "content";
  searchQuery?: string;
};

export const isActiveQuery = (query: Query) => {
  return query.status === "active";
};
