import { GenericObjectT } from "src/api/flowTypes";
import { DataRetention } from "src/connections/types";

export type CustomConnectionAuthMethod =
  | "api_key"
  | "oauth2"
  | "google_oauth2"
  | "no_auth"
  | "basic"
  | "aws_signature";

export type MySQLConnectionAuthMethod = "username_password";
export type PostgreSQLConnectionAuthMethod = "username_password";
export type BigQuerySQLConnectionAuthMethod = "service_account";
export type SnowflakeConnectionAuthMethod = "basic_auth" | "key_pair";
export type RedshiftConnectionAuthMethod = "username_password" | "aws_iam";

export const BigQuerySQLConnectionDefaultAuthScope =
  "https://www.googleapis.com/auth/bigquery";

export const BigQuerySQLConnectionAuthScopes = [
  BigQuerySQLConnectionDefaultAuthScope,
  "https://www.googleapis.com/auth/bigquery.insertdata",
  "https://www.googleapis.com/auth/cloud-platform",
  "https://www.googleapis.com/auth/cloud-platform.read-only",
  "https://www.googleapis.com/auth/devstorage.read_only",
  "https://www.googleapis.com/auth/devstorage.read_write",
  "https://www.googleapis.com/auth/devstorage.full_control",
] as const;

export type BigQuerySQLConnectionAuthScopeT =
  (typeof BigQuerySQLConnectionAuthScopes)[number];

type CustomConnectionConfigurationCreateT = {
  base_url?: string;
  probe_url?: string | null;
  media_key?: string | null;
  headers?: Record<string, string>;
  auth_settings?: ConnectionCreateAuthConfigT;
};

type T1ConnectionConfigurationCreateT = {};

type RetoolConnectionConfigurationCreateT = {
  base_url?: string;
};

type InboundWebhookConnectionConfigurationCreateT = {
  media_key?: string | null;
  webhook_auth_settings?: InboundWebhookConnectionCreateAuthConfigT;
  custom_success_status_code?: number | null;
};
type SSHTunnelConfig = {
  host: string;
  port: number;
  user: string;
};
export type TLSMode = "require" | "verify-ca" | "verify-full";
type MutualTLSConfig = {
  mode: TLSMode;
  client_certificate: string;
  ca_certificate: string;
};
type SQLConnectionConfigurationCreateT = {
  auth_method?:
    | PostgreSQLConnectionAuthMethod
    | BigQuerySQLConnectionAuthMethod
    | SnowflakeConnectionAuthMethod
    | RedshiftConnectionAuthMethod;

  // ssh tunnel
  has_ssh_tunnel_enabled?: boolean;
  ssh_tunnel?: SSHTunnelConfig;

  // postgres
  database_name?: string;
  host?: string;
  port?: number | null;
  has_mtls_enabled?: boolean;
  mutual_tls?: MutualTLSConfig;

  // bigquery
  scopes?: string[];

  // snowflake
  account_identifier?: string;
  warehouse_name?: string;
  database_schema?: string | null;
  user_role?: string | null;

  // redshift
  cluster_identifier?: string;
  aws_region?: string;
  role_arn?: string;
  ssl_mode?: RedshiftSSLMode;
  has_ssl_enabled?: boolean;
};

export type ConnectionConfigurationCreateT = {
  allow_test_invocations?: boolean;
} & CustomConnectionConfigurationCreateT &
  T1ConnectionConfigurationCreateT &
  InboundWebhookConnectionConfigurationCreateT &
  SQLConnectionConfigurationCreateT &
  RetoolConnectionConfigurationCreateT;

export type ConnectionCreateAuthConfigT =
  | ConnectionCreateAuthNoAuth
  | ConnectionCreateAuthAPIKey
  | ConnectionCreateAuthOAuth2
  | ConnectionCreateAuthAWSSignature
  | ConnectionCreateAuthBasicAuth;

export type ConnectionCreateAuthBase = {
  auth_method: CustomConnectionAuthMethod;
  ssl_enabled: boolean;
};
export type ConnectionCreateAuthNoAuth = ConnectionCreateAuthBase;
export type ConnectionCreateAuthBasicAuth = ConnectionCreateAuthBase;

export type ConnectionCreateAuthAPIKey = {
  header: string;
  prefix: string;
} & ConnectionCreateAuthBase;

export type ConnectionCreateT = {
  name: string;
  provider: ProviderT | string;
  secrets?: {
    key: string;
    value: string | null;
    environment?: string | null;
  }[];
  resource_configs?: ResourceConfigT[];
  is_sandbox: boolean;
  configuration: ConnectionConfigurationCreateT;
  enable_non_prod_configs: boolean;
  non_prod_env_configs: Record<string, ConnectionConfigurationCreateT>;
  data_retention?: DataRetention;
  has_raw_response_enabled?: boolean;
  has_raw_requests_enabled_in_resource?: boolean;
};

export type ConnectionCreateAuthOAuth2 = {
  auth_method: CustomConnectionAuthMethod;
  header?: string;
  prefix?: string;
  grant_type?: string;
  uses_basic_auth: boolean;
  basic_auth_header?: string;
  basic_auth_prefix?: string;
  authorize_url: string;
  authorize_query_params?: { [key: string]: string };
  authorize_headers?: { [key: string]: string };
  authorize_body?: object;
  refresh_url: string;
  refresh_query_params?: { [key: string]: string };
  refresh_headers?: { [key: string]: string };
  refresh_body?: object;
  scopes?: Array<string>;
  scope_delimiter?: string;
  token_refresh_interval?: number;
} & ConnectionCreateAuthBase;

export type ConnectionCreateAuthAWSSignature = {
  auth_method: CustomConnectionAuthMethod;
  aws_access_key: string;
  aws_host: string;
  aws_region: string;
  aws_service: string;
} & ConnectionCreateAuthBase;

export type InboundWebhookConnectionAuthMethod =
  | "no_auth"
  | "basic"
  | "api_key"
  | "hmac";
export type InboundWebhookHMACHashAlgorithm = "sha1" | "sha256" | "sha512";
export type InboundWebhookHMACSignatureEncoding = "hex" | "base64";

export type InboundWebhookConnectionCreateConfigT = {
  webhook_auth_settings: InboundWebhookConnectionCreateAuthConfigT;
};

export type InboundWebhookConnectionCreateAuthConfigT =
  | InboundWebhookConnectionCreateAuthNoAuth
  | InboundWebhookConnectionCreateAuthAPIKey
  | InboundWebhookConnectionCreateAuthBasicAuth
  | InboundWebhookConnectionCreateAuthHMAC;

export type InboundWebhookConnectionCreateAuthNoAuth = {
  auth_method: InboundWebhookConnectionAuthMethod;
};

export type InboundWebhookConnectionCreateAuthBasicAuth = {
  auth_method: InboundWebhookConnectionAuthMethod;
};

export type InboundWebhookConnectionCreateAuthAPIKey = {
  auth_method: InboundWebhookConnectionAuthMethod;
  header?: string;
  prefix?: string;
};

export type InboundWebhookConnectionCreateAuthHMAC = {
  auth_method: InboundWebhookConnectionAuthMethod;
  hash_algorithm: InboundWebhookHMACHashAlgorithm;
  signature_encoding: InboundWebhookHMACSignatureEncoding;
  header: string;
};

export const SQLDatabaseProviders = [
  "postgresql",
  "bigquery",
  "snowflake",
  "redshift",
  "mysql",
] as const;
export const GenericProviders = ["custom", "webhook", "retool"] as const;

export const IntegrationProviders = [
  "schufa",
  "bkr",
  "boniversum",
  "creditreform",
  "crs",
  "codat",
  "data_x",
  "dnb",
  "crif_b2b",
  "crif_b2c",
  "crif_high_mark",
  "equifax_canada",
  "equifax_uk",
  "equifax_us",
  "equifax_us_commercial_risk",
  "experian_colombia",
  "experian_germany",
  "experian_us_b2b",
  "experian_us",
  "experian_us_clarity_services",
  "socure",
  "plaid",
  "sardine",
  "sentilink",
  "thomson_reuters",
  "creditsafe",
  "trans_union",
] as const;

export const Providers = [
  ...IntegrationProviders,
  ...SQLDatabaseProviders,
  ...GenericProviders,
] as const;

export type SQLDatabaseProviderT = (typeof SQLDatabaseProviders)[number];
export type GenericProviderT = (typeof GenericProviders)[number];
export type LegacyIntegrationProviderT = (typeof IntegrationProviders)[number];
export type IntegrationProviderT = LegacyIntegrationProviderT | string;

export type ProviderT = (typeof Providers)[number];

export const NotTestableProviders: (ProviderT | string)[] = [
  "boniversum",
  "data_x",
  "sardine",
  "sentilink",
  "schufa",
  "thomson_reuters",
  "trans_union",
  "experian_germany",
  "experian_us_clarity_services",
  "equifax_us_commercial_risk",
] as const;

export const RawRequestsProviders: (ProviderT | string)[] = [
  "experian_us",
  "trans_union",
  "socure",
  "codat",
  "data_x",
  "experian_us_clarity_services",
  "creditsafe",
  "experian_colombia",
  "equifax_us",
] as const;

export const isSQLDatabaseProvider = (
  provider: ProviderT | string,
): provider is SQLDatabaseProviderT =>
  SQLDatabaseProviders.includes(provider as SQLDatabaseProviderT);

export const isGenericProvider = (
  provider: ProviderT | string,
): provider is GenericProviderT =>
  GenericProviders.includes(provider as GenericProviderT);

export const isIntegrationProvider = (
  provider: ProviderT | string,
): provider is LegacyIntegrationProviderT =>
  !isSQLDatabaseProvider(provider) &&
  !isGenericProvider(provider) &&
  Providers.includes(provider as ProviderT);

export const SchufaResources = [
  "b2c_report",
  "b2b_report_cascading",
  "schufa_fraud_pre_check",
] as const;
export type SchufaResourceT = (typeof SchufaResources)[number];

const CreditReformResources = [
  "compact_report",
  "traffic_light_report",
  "risk_check_report",
  "brief_report",
  "commercial_report",
  "identification_report",
  "ecrefo_report",
  "search_request",
  "creditreform_mailbox_directory",
  "creditreform_mailbox_entry",
] as const;

const BKRResources = ["bkr_cki"] as const;

const BoniversumResources = ["consumer_report"] as const;

const CustomResources = ["http", "xml"] as const;
export type CustomResourceT = (typeof CustomResources)[number];

const InboundWebhookResources = ["event"] as const;
export type InboundWebhookResourceT = (typeof InboundWebhookResources)[number];

const RetoolResources = ["retool_embed"] as const;
export type RetoolResourceT = (typeof RetoolResources)[number];

// The same single resource is used for all database connections
const DatabaseConnectionResources = ["database"] as const;
export type DatabaseConnectionResourceT =
  (typeof DatabaseConnectionResources)[number];

export const CRSResources = [
  "experian_credit_report",
  "transunion_credit_report",
  "transunion_imv_basic",
  "experian_business_profile_report",
  "experian_business_search",
  "experian_intelliscore_business_report",
  "lexisnexis_bridger_search",
  "crs_equifax_credit_report",
] as const;
export type CRSResourceT = (typeof CRSResources)[number];

export const CodatResources = [
  "company_info",
  "company_identification",
  "enhanced_profit_and_loss_accounts",
  "enhanced_balance_sheet_accounts",
  "aged_creditors_report",
  "aged_debtors_report",
] as const;

const DnbResources = [
  "identity_resolution",
  "credit_report",
  "custom_report",
] as const;

const CRIFB2BResources = [
  "full_report",
  "credit_report",
  "search_report",
] as const;

const CRIFB2CResources = ["identity_check", "credit_check"] as const;

const DataXResources = ["data_x_credit_report"] as const;

const EquifaxCanadaResources = ["equifax_canada_consumer_credit_file"] as const;

const EquifaxUKResources = ["gateway"] as const;

const EquifaxUSResources = [
  "one_view_report",
  "scores_and_attributes",
  "mla_report",
] as const;

const EquifaxUSCommercialRiskResources = [
  "equifax_us_commercial_risk_credit_report",
] as const;

const ExperianColombiaResources = [
  "experian_colombia_credit_history_for_legal_entities",
  "experian_colombia_chamber_of_commerce",
];

const ExperianGermanyResources = [
  "experian_germany_business_information_gateway",
];

const ExperianUSB2BResources = [
  "experian_us_b2b_business_search",
  "experian_us_b2b_small_business_credit_share_scores",
  "experian_us_b2b_small_business_credit_share_profiles_report",
] as const;

const ExperianUSClarityServicesResources = [
  "experian_us_clarity_services_report",
] as const;

const ExperianUSResources = ["experian_us_credit_report"] as const;

const SocureResources = [
  // New bundles
  "socure_fraud_bundle_1",
  "socure_fraud_bundle_2",
  "socure_fraud_bundle_3",
  "socure_fraud_bundle_4",
  "socure_fraud_bundle_5",
  "socure_doc_v_plus_bundle",
  "socure_digital_intelligence",
  // Deprecated bundles
  "compliance_bundle",
  "standard_bundle",
  "premium_bundle",
] as const;

export type SocureResourceT = (typeof SocureResources)[number];

const CRIFHighMarkResources = ["credit_report"] as const;

const PlaidResources = [
  "asset_report",
  "financial_insights_report",
  "plaid_cra_base_report",
  "plaid_cra_income_insights_report",
  "plaid_cra_partner_insights_report",
] as const;

const SardineResources = [
  "sardine_identity_verification",
  "sardine_feedback",
] as const;

const SentiLinkResources = [
  "sentilink_synthetic_fraud_score",
  "sentilink_identity_theft_score",
  "sentilink_custom_score_products",
  "sentilink_facets",
  "sentilink_insights_report",
  "sentilink_id_complete",
] as const;

const ThomsonReutersResources = [
  "thomson_reuters_clear_person_search",
  "thomson_reuters_clear_business_search",
  "thomson_reuters_risk_person_search",
  "thomson_reuters_risk_business_search",
  "thomson_reuters_person_report",
  "thomson_reuters_business_report",
] as const;

const CreditsafeResources = [
  "company_search",
  "company_credit_report",
] as const;

const TransUnionResources = ["tru_vision_model_report"] as const;

const ManualReviewResources = ["review_event"] as const;
export type ManualReviewResourceT = (typeof ManualReviewResources)[number];

export const IntegrationProviderResourceMap = {
  schufa: SchufaResources,
  bkr: BKRResources,
  boniversum: BoniversumResources,
  creditreform: CreditReformResources,
  crs: CRSResources,
  codat: CodatResources,
  dnb: DnbResources,
  crif_b2b: CRIFB2BResources,
  crif_b2c: CRIFB2CResources,
  crif_high_mark: CRIFHighMarkResources,
  data_x: DataXResources,
  equifax_canada: EquifaxCanadaResources,
  equifax_uk: EquifaxUKResources,
  equifax_us: EquifaxUSResources,
  equifax_us_commercial_risk: EquifaxUSCommercialRiskResources,
  experian_colombia: ExperianColombiaResources,
  experian_germany: ExperianGermanyResources,
  experian_us_b2b: ExperianUSB2BResources,
  experian_us: ExperianUSResources,
  experian_us_clarity_services: ExperianUSClarityServicesResources,
  socure: SocureResources,
  plaid: PlaidResources,
  sardine: SardineResources,
  sentilink: SentiLinkResources,
  thomson_reuters: ThomsonReutersResources,
  creditsafe: CreditsafeResources,
  trans_union: TransUnionResources,
  postgresql: DatabaseConnectionResources,
  bigquery: DatabaseConnectionResources,
  snowflake: DatabaseConnectionResources,
  redshift: DatabaseConnectionResources,
  retool: RetoolResources,
} as const;

export type IntegrationProviderResourceMapType =
  typeof IntegrationProviderResourceMap;

type IntegrationProviderResourceMapToTypeTransform<T> =
  T extends IntegrationProviderT
    ? { provider: T; resource: ResourceTypesForIntegrationProvider<T> }
    : never;

export type IntegrationProviderResourceT =
  IntegrationProviderResourceMapToTypeTransform<
    keyof IntegrationProviderResourceMapType
  >;

export type ManifestIntegrationProviderResourceT = {
  provider: string;
  resource: string;
  manifest_version: string;
};

export type ResourceTypesForIntegrationProvider<
  T extends IntegrationProviderT,
> = T extends keyof IntegrationProviderResourceMapType
  ? IntegrationProviderResourceMapType[T][number]
  : string;

export type CustomProviderResourceT = {
  provider: "custom";
  resource: CustomResourceT;
};

export type InboundWebhookProviderResourceT = {
  provider: "webhook";
  resource: InboundWebhookResourceT;
};

export type ManualReviewProviderResourceT = {
  provider: "webhook";
  resource: ManualReviewResourceT;
};

export type DatabaseProviderResourceT = {
  provider: SQLDatabaseProviderT;
  resource: DatabaseConnectionResourceT;
};

export type RetoolProviderResourceT = {
  provider: "retool";
  resource: RetoolResourceT;
};

export type ProviderResourceT =
  | IntegrationProviderResourceT
  | ManifestIntegrationProviderResourceT
  | CustomProviderResourceT
  | DatabaseProviderResourceT
  | InboundWebhookProviderResourceT
  | ManualReviewProviderResourceT
  | RetoolProviderResourceT;

export const Resources = [
  ...SchufaResources,
  ...BKRResources,
  ...BoniversumResources,
  ...CreditReformResources,
  ...CustomResources,
  ...CRSResources,
  ...CodatResources,
  ...DataXResources,
  ...DnbResources,
  ...CRIFB2BResources,
  ...CRIFB2CResources,
  ...CRIFHighMarkResources,
  ...EquifaxCanadaResources,
  ...EquifaxUKResources,
  ...EquifaxUSResources,
  ...EquifaxUSCommercialRiskResources,
  ...ExperianColombiaResources,
  ...ExperianGermanyResources,
  ...ExperianUSB2BResources,
  ...ExperianUSResources,
  ...ExperianUSClarityServicesResources,
  ...SocureResources,
  ...PlaidResources,
  ...SardineResources,
  ...SentiLinkResources,
  ...ThomsonReutersResources,
  ...CreditsafeResources,
  ...TransUnionResources,
  ...InboundWebhookResources,
  ...ManualReviewResources,
  ...RetoolResources,
  ...DatabaseConnectionResources,
  "probe",
] as const;

export type ResourceT = (typeof Resources)[number];

// We need to support an "arbitrary" nested configuration for
// resources, however we can't simply define recursive types
// b/c then the type becomes "infinite" and TypeScript complains about it.
// The solution was to use interfaces b/c of how TS type resolution works:
// https://github.com/microsoft/TypeScript/issues/3496#issuecomment-128553540
type PrimitiveConfigValueT = string | number | boolean | null;

interface ConfigRecordT {
  [key: string]: ConfigurationValueT;
}

interface ConfigArrayT extends Array<ConfigurationValueT> {}

export type ConfigurationValueT =
  | PrimitiveConfigValueT
  | ConfigRecordT
  | ConfigArrayT
  | undefined;

export type ConfigurationT = {
  [key: string]: ConfigurationValueT | Record<string, ConfigurationValueT>;
};

export type ResourceConfigT = {
  id: string;
  name: string;
  resource: ResourceT;
  configuration: ConfigurationT;
  has_raw_response_enabled: boolean;
  has_raw_requests_enabled_in_resource?: boolean;
  created_at: string;
  updated_at: string;
};

export type BaseAuthSettings = { ssl_enabled: boolean };
export type NoAuthSettings = BaseAuthSettings & { auth_method: "no_auth" };
export type BasicAuthSettings = BaseAuthSettings & { auth_method: "basic" };
export type APIKeyAuthSettings = BaseAuthSettings & {
  auth_method: "api_key";
  header?: string;
  prefix?: string;
  api_key_as_query_param?: boolean;
  query_param_name?: string;
};
export type OAuth2AuthSettings = BaseAuthSettings & {
  auth_method: "oauth2";
  header?: string;
  prefix?: string;
  grant_type?: string;
  uses_basic_auth: boolean;
  basic_auth_header?: string;
  basic_auth_prefix?: string;
  authorize_url: string;
  authorize_query_params?: { [key: string]: string };
  authorize_headers?: { [key: string]: string };
  authorize_body?: object;
  authorize_body_token_path?: string;
  authorize_body_expires_path?: string;
  refresh_url: string;
  refresh_query_params?: { [key: string]: string };
  refresh_headers?: { [key: string]: string };
  refresh_body?: object;
  scopes?: Array<string>;
  scope_delimiter?: string;
  token_refresh_interval?: number;
};
export type AWSSignatureAuthSettings = BaseAuthSettings & {
  auth_method: "aws_signature";
  aws_access_key: string;
  aws_host: string;
  aws_region: string;
  aws_service: string;
};

export type GoogleOAuth2AuthSettings = BaseAuthSettings & {
  auth_method: "google_oauth2";
  authorize_url: string;
  token_refresh_interval?: number;
  google_auth_filename?: string;
  scopes?: Array<string>;
};

export type AuthSettings =
  | NoAuthSettings
  | BasicAuthSettings
  | APIKeyAuthSettings
  | OAuth2AuthSettings
  | AWSSignatureAuthSettings
  | GoogleOAuth2AuthSettings;

export type InboundWebhookNoAuthSettings = { auth_method: "no_auth" };
export type InboundWebhookBasicAuthSettings = { auth_method: "basic" };
export type InboundWebhookAPIKeyAuthSettings = {
  auth_method: "api_key";
  header?: string;
  prefix?: string;
};
export type InboundWebhookHMACAuthSettings = {
  auth_method: "hmac";
  hash_algorithm: string;
  signature_encoding: string;
  header: string;
};

export type InboundWebhookAuthSettings =
  | InboundWebhookNoAuthSettings
  | InboundWebhookBasicAuthSettings
  | InboundWebhookAPIKeyAuthSettings
  | InboundWebhookHMACAuthSettings;

export type CustomConnectionConfiguration = {
  base_url?: string;
  probe_url?: string;
  media_key?: string;
  headers?: Record<string, string>;
  auth_settings?: AuthSettings;
  allow_test_invocations?: boolean;
};

export type T1ConnectionConfiguration = {
  multi_tenant?: boolean;
};

type InboundWebhookConnectionConfiguration = {
  media_key?: string;
  webhook_auth_settings: InboundWebhookAuthSettings;
  webhook_url?: string | null;
  custom_success_status_code?: number | null;
};

export type PostgreSQLConnectionConfigurationBE = {
  database_name: string;
  host: string;
  port?: number;
  auth_method: PostgreSQLConnectionAuthMethod;
  has_mtls_enabled: boolean;
  mutual_tls?: MutualTLSConfig;
  has_ssh_tunnel_enabled: boolean;
  ssh_tunnel?: SSHTunnelConfig;
};

export type MySQLConnectionConfigurationBE = {
  database_name: string;
  host: string;
  port?: number;
  auth_method: MySQLConnectionAuthMethod;
  has_mtls_enabled: boolean;
  mutual_tls: MutualTLSConfig;
};

export type BigQuerySQLConnectionConfigurationBE = {
  scopes: string[];
  auth_method: BigQuerySQLConnectionAuthMethod;
};

export type SnowflakeConnectionConfigurationBE = {
  account_identifier: string;
  warehouse_name: string;
  database_name: string;
  database_schema?: string;
  user_role?: string;
  auth_method: SnowflakeConnectionAuthMethod;
};

export type RedshiftSSLMode = "verify-ca" | "verify-full";

export type RedshiftConnectionConfigurationBE = {
  host?: string;
  port?: number;
  database_name?: string;
  cluster_identifier?: string;
  aws_region?: string;
  role_arn?: string;
  auth_method: RedshiftConnectionAuthMethod;
  has_ssl_enabled: boolean;
  ssl_mode?: RedshiftSSLMode | null;
  has_ssh_tunnel_enabled: boolean;
  ssh_tunnel?: SSHTunnelConfig;
};

export type ConnectionConfiguration = {
  allow_test_invocations?: boolean;
} & CustomConnectionConfiguration &
  T1ConnectionConfiguration &
  Partial<InboundWebhookConnectionConfiguration> &
  Partial<PostgreSQLConnectionConfigurationBE> &
  Partial<BigQuerySQLConnectionConfigurationBE> &
  Partial<SnowflakeConnectionConfigurationBE> &
  Partial<RedshiftConnectionConfigurationBE>;

export type ConnectionSecretT = {
  key: string;
  value: string;
  environment?: string | null;
  tenant_id?: string | null;
};

export type ConnectionT = {
  id: string;
  name: string;
  is_sandbox: boolean;
  provider: ProviderT | string;
  manifest_version?: string;
  environment_configurations?: Record<string, Record<string, unknown>>;
  created_at: string;
  updated_at: string;
  configuration: ConnectionConfiguration;
  resource_configs: ResourceConfigT[];
  secrets: ConnectionSecretT[];
  enable_non_prod_configs: boolean;
  has_raw_response_enabled: boolean;
  has_raw_requests_enabled_in_resource: boolean;
  non_prod_env_configs?: Record<string, ConnectionConfiguration>;
  data_retention?: DataRetention;
};

export type ResourceSample = {
  sample:
    | {
        data: Record<string, unknown>;
        insights: Record<string, unknown>;
      }
    | GenericObjectT;
  name: string;
  description?: string;
};

export type RetoolEmbedQueryParams = {
  provider_args: { max_age_seconds: number };
  query: { user_id: string | undefined; email: string | undefined };
  resource_config_id: string | undefined;
};

export type QueryPayload = RetoolEmbedQueryParams;

export type RetoolEmbedResponse = {
  data: {
    embed_url: string;
  };
};
