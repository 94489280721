import { faArrowDownToLine } from "@fortawesome/pro-regular-svg-icons";

import { loadVersion } from "src/api";
import { FlowVersionFlowChild } from "src/api/flowTypes";
import { OptionsDropdownElement } from "src/base-components/OptionsDropdown/OptionsDropdownItems";

export const getExportToJSONOption = (
  flowName: string,
  version: FlowVersionFlowChild,
): OptionsDropdownElement => {
  return {
    key: "Export to JSON",
    action: async () => {
      const versionWithGraph = await loadVersion(version.id);
      if (versionWithGraph) {
        const { locks: _locks, ...versionWithoutLocks } = versionWithGraph;
        const blob = new Blob([JSON.stringify(versionWithoutLocks)], {
          type: "application/json",
        });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `${flowName} - ${version.name}.json`;
        link.click();
        URL.revokeObjectURL(url);
      }
    },
    icon: faArrowDownToLine,
  };
};
