import { FieldErrorsBET, FieldErrorsT } from "src/api/types";
import { ConnectionCreationFieldError } from "src/connections/types";

export const convertFieldErrorsBeToFe = (
  beErrors: FieldErrorsBET,
  includeFieldNameSuffix: boolean = false,
): FieldErrorsT =>
  Object.fromEntries(
    beErrors.map((field_error) => [
      includeFieldNameSuffix
        ? mergeFieldIdAndNameUntyped(
            field_error.field_id,
            field_error.field_name,
          )
        : field_error.field_id,
      field_error.message,
    ]),
  );

/**
 * To be used internally in this file, does not enforce strict typing on the field name so that it
 * can be used in the BE to FE convert function
 */
const mergeFieldIdAndNameUntyped = (id?: string, name?: string) =>
  id && !name ? `${id}` : `${id}_${name}`;
export const mergeFieldIdAndName = (id?: string, name?: RunErrorFieldNames) =>
  mergeFieldIdAndNameUntyped(id, name);

/**
 * Gathering the field names of field errors prduced in the backend during test runs.
 * The main idea is to not have this knowledge burried deep inside node editors
 */
export enum RunErrorFieldNames {
  LEFT = "left",
  RIGHT = "right",
}

export const convertFieldErrorsConnectToFe = (
  beErrors: ConnectionCreationFieldError[],
): FieldErrorsT =>
  Object.fromEntries(
    beErrors
      .map((field_error) => [field_error.loc, field_error.msg])
      .filter((keyValuePair) => Boolean(keyValuePair[0])),
  );
