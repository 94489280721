import React from "react";

import { useWorkspace } from "src/api/queries";
import { Card } from "src/base-components/Card";
import { Spinner } from "src/base-components/Spinner";
import { DocsHeader } from "src/flowsOverview/DocsHeader";
import { Separator } from "src/flowsOverview/Separator";
import { FlowFolderList } from "src/flowsOverview/v2/FlowFolderList";
import { PageHeader } from "src/layout/PageHeader";
import { FEATURE_FLAGS, isFeatureFlagEnabled } from "src/router/featureFlags";

export const GRID_COLUMNS_CLASS =
  `grid grid-cols-[repeat(auto-fill,_280px)] justify-center gap-4.5` as const;

export const FlowOverview: React.FC<{
  organizationId: string;
  workspaceId: string;
}> = ({ organizationId, workspaceId }) => {
  const workspace = useWorkspace(workspaceId, { refetchOnPending: true });

  const isPending = workspace.data?.status === "PENDING";

  return (
    <>
      {isFeatureFlagEnabled(FEATURE_FLAGS.entitiesBase) && (
        <PageHeader title="Decision Flows" />
      )}
      <div className="flex h-full w-full flex-col px-8 pb-12 pt-8">
        {!isPending && (
          <>
            <DocsHeader />
            <Separator />
            <FlowFolderList
              organizationId={organizationId}
              workspaceId={workspaceId}
            />
          </>
        )}
        {isPending && (
          <div className="flex flex-col items-center">
            <Card className="flex h-80 w-2/3 flex-col items-center pt-2">
              <Spinner />
              <div className="mt-12 font-inter-semibold-16px">
                Workspace creation in progress...
              </div>
              <div className="mt-6 font-sans text-gray-800 font-inter-normal-13px">
                Please wait for a few minutes while we set up your new
                workspace.
              </div>
            </Card>
          </div>
        )}
      </div>
    </>
  );
};
