import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import React from "react";

import { ManageQueryModal } from "./ManageQueryModal";
import { Queries } from "./Queries";
import { useModal } from "src/design-system/Modal";
import { FeatureCatalogueWrapper } from "src/featureCatalogue/FeatureCatalogue";
import { SubHeader } from "src/flow/SubHeader";
import { useCapabilities } from "src/hooks/useCapabilities";

const QueriesHeader: React.FC = () => {
  const { openModal, closeModal, isOpen } = useModal();
  const { featureQueriesCatalogue } = useCapabilities();

  return (
    <>
      {featureQueriesCatalogue.canCreate && (
        <SubHeader.Button
          dataLoc="queries-create"
          icon={faPlus}
          tooltip="Create Query"
          onClick={openModal}
        />
      )}

      <ManageQueryModal open={isOpen} onClose={closeModal} />
    </>
  );
};

export const QueriesPage: React.FC = () => {
  return (
    <FeatureCatalogueWrapper header={<QueriesHeader />}>
      <Queries />
    </FeatureCatalogueWrapper>
  );
};
