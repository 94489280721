import {
  faCheck,
  faChevronDown,
  faInfoCircle,
  faTimes,
} from "@fortawesome/pro-regular-svg-icons";
import {
  Combobox as HeadlessCombobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
} from "@headlessui2/react";
import { groupBy } from "lodash";
import { useState } from "react";
import { twJoin } from "tailwind-merge";

import { SectionKey } from "src/analytics/utils";
import { Icon } from "src/base-components/Icon";
import { Pill } from "src/base-components/Pill";
import { Tooltip } from "src/design-system/Tooltip";
import { ExcludesFalse } from "src/flow/types";
import { assertUnreachable } from "src/utils/typeUtils";
import { useFuseSearch } from "src/utils/useFuseSearch";

const COMBOBOX_SECTIONS: {
  key: SectionKey;
  displayText: string;
}[] = [
  {
    key: SectionKey.node_logic,
    displayText: "Node Logic",
  },
  {
    key: SectionKey.data,
    displayText: "Data Fields",
  },
  {
    key: SectionKey.output_data,
    displayText: "Output Fields",
  },
  {
    key: SectionKey.aux_data,
    displayText: "Additional Fields",
  },
  {
    key: SectionKey.outcomes,
    displayText: "Outcomes",
  },
  {
    key: SectionKey.other,
    displayText: "Other",
  },
].filter(Boolean as unknown as ExcludesFalse);

const getSectionTooltip = (
  sectionKey: SectionKey,
  isFlowLevelAnalytics: boolean,
) => {
  switch (sectionKey) {
    case SectionKey.node_logic:
      return {
        title: "Node logic",
        body: "Rules and conditions applied at this node.",
      };
    case SectionKey.data:
      if (isFlowLevelAnalytics) {
        return {
          title: "Output fields",
          body: "Final output fields at the end of your flow.",
        };
      }
      return {
        title: "Data at current node",
        body: "Values of all data fields after this node was executed.",
      };
    case SectionKey.output_data:
      return {
        title: "Output fields",
        body: "Final output fields at the end of your flow.",
      };
    case SectionKey.aux_data:
      return {
        title: "Additional fields",
        body: "Additional fields which are not part of the input schema.",
      };
    case SectionKey.outcomes:
      return {
        title: "Outcomes",
        body: "Outcomes of the test run.",
      };
    case SectionKey.other:
      return {
        title: "Other fields",
        body: "System-generated fields, and metadata object.",
      };
    default:
      assertUnreachable(sectionKey);
      throw new Error("Unreachable");
  }
};

export type DimensionOption = {
  key: string;
  displayKey: string;
  value: React.ReactNode;
  disabled?: boolean;
  sectionKey: SectionKey;
};

type Props = {
  options: DimensionOption[];
  value?: Nullable<string>;
  onChange?: (value: Nullable<string>) => void;
  placeholder?: string;
  disabled?: boolean;
  dataLoc?: string;
  isFlowLevelAnalytics?: boolean;
};

const MAX_COLLAPSED_SECTION_RESULTS = 4;

export const DimensionsCombobox: React.FC<Props> = (props) => {
  const [query, setQuery] = useState("");

  const search = useFuseSearch(props.options, {
    threshold: 0.5,
    keys: ["displayKey"],
  });

  const results = search(query);

  const isResetButtonAvailable = props.value;

  const onReset = () => {
    props.onChange?.(null);
  };

  const value =
    props.options.find((option) => option.key === props.value) ?? null;

  const isOutputValue = value?.key.startsWith("output_data.");
  const inputRightPadding =
    isResetButtonAvailable && isOutputValue
      ? "pr-28"
      : isResetButtonAvailable
        ? "pr-16"
        : "pr-7";

  return (
    <HeadlessCombobox
      as="div"
      data-loc={props.dataLoc}
      disabled={props.disabled}
      value={value}
      immediate
      onChange={(value) => props.onChange?.(value?.key ?? null)}
      onClose={() => setQuery("")}
    >
      <div className="relative">
        <ComboboxInput
          className={twJoin(
            "h-8 w-full rounded-lg border border-gray-200 py-1 pl-3 placeholder:text-gray-500 focus:border-indigo-400 focus:outline-none focus:ring-2 focus:ring-indigo-500/25 disabled:cursor-not-allowed",
            "text-xs text-indigo-600 font-code-13 placeholder:font-code-13",
            inputRightPadding,
          )}
          displayValue={(value: Nullable<DimensionOption>) =>
            value?.displayKey ?? ""
          }
          placeholder={props.placeholder}
          onChange={(event) => setQuery(event.target.value)}
        />
        <div className="absolute right-0 top-1/2 flex -translate-y-1/2 items-center justify-start pr-2">
          {value?.key.startsWith("output_data.") && (
            <div className="mr-1.5 inline-flex">
              <Pill size="sm" variant="gray">
                <Pill.Text fontType="code">Output</Pill.Text>
              </Pill>
            </div>
          )}
          <ComboboxButton data-loc="expand-combobox-button">
            <Icon
              color="text-gray-500"
              disabled={props.disabled}
              icon={faChevronDown}
              size="xs"
            />
          </ComboboxButton>
          {isResetButtonAvailable && (
            <div
              className="flex items-center justify-start"
              data-loc={props.dataLoc && `${props.dataLoc}-reset`}
            >
              <div className="mx-1.5 h-4 w-[1px] bg-gray-200" />
              <Icon
                aria-label="Reset"
                color="text-gray-500"
                disabled={props.disabled}
                icon={faTimes}
                size="xs"
                onClick={onReset}
              />
            </div>
          )}
        </div>
      </div>
      <ComboboxOptions
        anchor={{
          to: "bottom",
          gap: 8,
        }}
        className={twJoin(
          "decideScrollbar h-96 overflow-auto rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-200 ring-opacity-5 focus:outline-none",
          "transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0",
          "z-50 w-[var(--input-width)] min-w-[280px]",
        )}
        data-loc={`${props.dataLoc}-dropdown`}
        transition
      >
        <ResultsWithSections
          isFiltering={query !== ""}
          isFlowLevelAnalytics={props.isFlowLevelAnalytics}
          results={results}
        />
      </ComboboxOptions>
    </HeadlessCombobox>
  );
};

const ResultsWithSections = ({
  results,
  isFiltering,
  isFlowLevelAnalytics = false,
}: {
  results: DimensionOption[];
  isFiltering: boolean;
  isFlowLevelAnalytics?: boolean;
}) => {
  const [visibleSections, setVisibleSections] = useState<
    Record<string, boolean>
  >({});
  const resultsGroupedBySections = groupBy(results, (r) =>
    "sectionKey" in r ? r.sectionKey : "",
  );

  return COMBOBOX_SECTIONS.filter(
    (section) => section.key in resultsGroupedBySections,
  ).map((section) => {
    const isSectionExpanded = visibleSections[section.key] || isFiltering;
    const results = resultsGroupedBySections[section.key];
    const visibleResults = isSectionExpanded
      ? results
      : results.slice(0, MAX_COLLAPSED_SECTION_RESULTS);

    const tooltip = getSectionTooltip(section.key, isFlowLevelAnalytics);

    return (
      <div key={section.key} className="mb-2">
        <div
          className={twJoin(
            "flex items-center justify-between px-4 py-2.5",
            "text-gray-800 font-inter-semibold-13px",
            "h-12",
          )}
        >
          {section.displayText}
          <div className="flex items-center gap-2">
            <Pill size="sm" variant="gray">
              <Pill.Text>{results.length}</Pill.Text>
            </Pill>
            <Tooltip
              body={tooltip.body}
              placement="right-start"
              title={tooltip.title}
            >
              <Icon color="text-gray-500" icon={faInfoCircle} size="sm" />
            </Tooltip>
          </div>
        </div>
        {visibleResults.map((result) => (
          <ComboboxOption
            key={result.key}
            className={twJoin(
              "group flex h-12 items-center justify-between py-2.5 pl-6 pr-4",
              result.disabled
                ? "cursor-default"
                : "cursor-pointer hover:bg-gray-50 ui-active:bg-gray-50",
              "text-indigo-600 font-code-13",
            )}
            disabled={result.disabled}
            value={result}
          >
            <div className="min-w-0 flex-1">{result.value}</div>
            <div className="ml-1 hidden group-data-[selected]:block">
              <Icon color="text-indigo-500" icon={faCheck} size="sm" />
            </div>
          </ComboboxOption>
        ))}
        {!isSectionExpanded &&
          results.length > MAX_COLLAPSED_SECTION_RESULTS && (
            <div
              className="flex h-12 cursor-pointer items-center py-2.5 pl-6 pr-4 font-inter-medium-13px"
              data-loc="see-more-button"
              onClick={() =>
                setVisibleSections((prev) => ({
                  ...prev,
                  [section.key]: true,
                }))
              }
            >
              See more
            </div>
          )}
      </div>
    );
  });
};
