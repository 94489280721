import { useQuery } from "@tanstack/react-query";

import { entitiesApi } from "src/api/endpoints";
import { SchemaTypesT } from "src/api/flowTypes";
import { DecisionEnvironment } from "src/api/types";
import { EntitiesBySchemaV1EnvSchemaGetEnvEnum } from "src/clients/entities/api";
import { entityIcons } from "src/entities/entityView/utils";
import { logger } from "src/utils/logger";

type UseEntitiesParams<S extends string> = {
  schema: S;
  env: DecisionEnvironment;
  baseUrl: string;
};

export type EntityType = SchemaTypesT | "tags" | "relation";

export type Cardinality = "1" | "n";

export type Property = {
  _type: EntityType;
  _display_name: string;
  _indexed: boolean;
  _rel_schema?: string;
  _cardinality?: Cardinality;
};

export type EntitySchemaResource = {
  _id: string;
  _schema: string;
  _etag: number;
  _related: Record<string, unknown>;
  _updatedAt: string;
  _singular_display_name: string;
  _plural_display_name: string;
  _display_symbol: keyof typeof entityIcons;
  _primary_property: string;
  _secondary_property: string;
} & {
  [key: string]: Property;
};

export type EntitySchemaResourcePage = {
  entities: EntitySchemaResource[] | EntityResource[];
  next_page_token: string;
};

export type EntityResource = {
  _id: string;
  _schema: string;
  _etag: number;
  _related: Record<string, unknown>;
  _updatedAt: string;
} & {
  [key: string]: string | string[];
};

export const useEntities = <S extends string>({
  schema,
  env,
  baseUrl,
}: UseEntitiesParams<S>) => {
  return useQuery({
    queryKey: ["entities", schema, env, baseUrl],
    queryFn: async () => {
      try {
        const response = await entitiesApi(
          baseUrl,
        ).entitiesBySchemaV1EnvSchemaGet({
          env: env as unknown as EntitiesBySchemaV1EnvSchemaGetEnvEnum,
          schema: schema,
        });
        if (response.data) {
          return response.data as {
            entities: S extends "schemas"
              ? EntitySchemaResource[]
              : EntityResource[];
            next_page_token: string;
          };
        } else {
          throw new Error(response.statusText);
        }
      } catch (error) {
        logger.error(error);
        throw error;
      }
    },
  });
};
