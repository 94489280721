import { autocompletion } from "@codemirror/autocomplete";
import {
  faArrowRight,
  faBuilding,
  faCalculatorSimple,
  faDatabase,
  faInfoCircle,
  faPlay,
  faPlus,
  faTrashAlt,
} from "@fortawesome/pro-regular-svg-icons";
import { faPlay as faPlaySolid } from "@fortawesome/pro-solid-svg-icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { UseMutationResult } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { capitalize, isString, noop, uniq } from "lodash";
import { Fragment, useEffect, useRef } from "react";
import React from "react";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import * as yup from "yup";

import { DecisionEnvironment } from "src/api/types";
import { Button } from "src/base-components/Button";
import { CodeEditor } from "src/base-components/CodeInput/CodeEditor";
import { Combobox } from "src/base-components/Combobox";
import {
  AccordionRoot,
  accordionRootClassName,
  EditorAccordionItem as AccordionItem,
} from "src/base-components/EditorAccordionItem";
import { ErrorHint } from "src/base-components/ErrorHint";
import { Icon } from "src/base-components/Icon";
import { Pill } from "src/base-components/Pill";
import { Select, type Option } from "src/base-components/Select";
import { Tabs } from "src/base-components/Tabs";
import {
  FeatureMapping as FeatureMappingT,
  EntityMapping,
  FeatureQueryStatus,
  PreviewQueryResponse,
  FeatureQueryCreate,
  FeatureQueryPatch,
} from "src/clients/features";
import { Callout } from "src/design-system/Callout";
import { EmptyState } from "src/design-system/EmptyState";
import { Modal } from "src/design-system/Modal";
import { TAKTILE_TEAM_NOTIFIED } from "src/design-system/Toast/constants";
import { toastActions } from "src/design-system/Toast/utils";
import { Tooltip } from "src/design-system/Tooltip";
import { useEntities } from "src/entities/queries";
import {
  inferDataTypes,
  SQLPreviewTable,
} from "src/featureCatalogue/Queries/SQLResonsePreviewTable";
import {
  useCreateFeatureQuery,
  useFeatureQueryPreview,
  useFeatures,
  useUpdateFeatureQuery,
} from "src/featureCatalogue/queries";
import { formatDuration, Query } from "src/featureCatalogue/utils";
import { queryClient } from "src/queryClient";
import { useWorkspaceContext } from "src/router/routerContextHooks";
import { TYPE_ICONS } from "src/utils/constants";
import { logger } from "src/utils/logger";
import { isPreconditionError } from "src/utils/predicates";

type ManageQueryModalProps = {
  open: boolean;
  onClose: () => void;
  query?: Query | Omit<Query, "key">;
  afterLeave?: () => void;
};

type ColumnExistsValidationContext = {
  availableColumns: string[];
};

const columnExistsValidation = {
  name: "column-exists",
  message: "Column name doesn't exist in query results. Update the value.",
  test: (
    value: string | undefined,
    context: yup.TestContext<yup.AnyObject>,
  ) => {
    if (!value) return true;
    return context.options.context?.availableColumns?.includes(value) ?? false;
  },
} as const;

const schema = yup.object({
  query: yup.string().required().min(1),
  columns: yup
    .object({
      entities: yup
        .array()
        .of(
          yup.object().shape(
            {
              column_name: yup.string().when("entity_name", {
                is: (entity_name: string) => Boolean(entity_name),
                then: (schema) =>
                  schema
                    .required("Column name is required when entity is selected")
                    .test(columnExistsValidation),
                otherwise: (schema) => schema.nullable(),
              }),
              entity_name: yup.string().when("column_name", {
                is: (column_name: string) => Boolean(column_name),
                then: (schema) =>
                  schema.required("Entity is required when column is selected"),
                otherwise: (schema) => schema.nullable(),
              }),
            },
            [["column_name", "entity_name"]],
          ),
        )
        .length(1),
      features: yup
        .array()
        .of(
          yup.object().shape(
            {
              column_name: yup.string().when("feature_key", {
                is: (feature_key: string) => Boolean(feature_key),
                then: (schema) =>
                  schema
                    .transform((value) => value?.trim())
                    .required(
                      "Column name is required when feature is selected",
                    )
                    .test(columnExistsValidation),
                otherwise: (schema) => schema.nullable(),
              }),
              feature_key: yup.string().when("column_name", {
                is: (column_name: string) => Boolean(column_name),
                then: (schema) =>
                  schema.required(
                    "Feature is required when column is selected",
                  ),
                otherwise: (schema) => schema.nullable(),
              }),
              feature_type: yup.string().optional(),
            },
            [["column_name", "feature_key"]],
          ),
        )
        .min(1)
        .test(
          "at-least-one-mapping",
          "At least one feature mapping must be complete",
          (features) => {
            return features?.some(
              (feature) => feature.column_name && feature.feature_key,
            );
          },
        ),
      grouping_keys: yup
        .array()
        .of(yup.string().test(columnExistsValidation))
        .max(1),
    })
    .required()
    .test(
      "use-of-all-available-columns",
      "Mapping must use all available columns",
      (value, { options }) => {
        // Access context values passed to the resolver
        const availableColumns = uniq(options.context?.availableColumns);

        const usedColumns = uniq(
          [
            ...(value.entities?.map((entity) => entity.column_name) ?? []),
            ...(value.features?.map((feature) => feature.column_name) ?? []),
            ...(value.grouping_keys ?? []),
          ].filter(Boolean),
        );

        return (
          usedColumns.length === availableColumns.length &&
          usedColumns.every((column) => availableColumns.includes(column))
        );
      },
    ),
  window_length: yup.number().required(),
  window_hop: yup.number().required(),
});

type QueryFormValues = yup.InferType<typeof schema>;

const defaultFormValues = {
  status: FeatureQueryStatus.INACTIVE,
  event: "transaction" as const,
  query: "",
  columns: {
    entities: [{ column_name: "", entity_name: "" }],
    grouping_keys: [],
    features: [{ column_name: "", feature_key: "", feature_type: "" }],
  },
};

type PreviewMutation = UseMutationResult<
  PreviewQueryResponse["data"],
  AxiosError<{ detail: string }>,
  { queryString: string; windowLength: number },
  unknown
>;

export const ManageQueryModal: React.FC<ManageQueryModalProps> = ({
  open,
  onClose,
  query,
  afterLeave,
}) => {
  const isEditing = query && "id" in query;
  const previewDataRef = useRef<PreviewQueryResponse["data"]>();

  const form = useForm<QueryFormValues>({
    reValidateMode: "onBlur",
    values: isEditing ? query : undefined,
    defaultValues: defaultFormValues,
    shouldFocusError: true,
    resolver: async (...args) => {
      // Ignore context, we pass it in manually
      const [data, _, options] = args;

      let response: PreviewQueryResponse["data"] | undefined =
        previewDataRef.current;

      // Make sure we have the latest preview data for submitting
      if (
        data.query !== previewMutation.variables?.queryString &&
        data.query.length > 0
      ) {
        response = await previewMutation.mutateAsync({
          queryString: data.query,
          windowLength: form.getValues("window_length"),
        });
      }

      return await yupResolver(schema)(
        data,
        {
          availableColumns: Object.keys(response?.at(0) ?? {}),
        } satisfies ColumnExistsValidationContext,
        options,
      );
    },
  });

  const previewMutation = useFeatureQueryPreview({
    onSuccess: (data) => {
      // Persisting the preview data to ref, to make sure
      // we have the previous data between mutations
      previewDataRef.current = data;
    },
  });

  // Make sure we have the latest preview data for displaying the preview table
  const fetchedPreviewData = useRef(false);
  useEffect(() => {
    if (query?.query && open && !fetchedPreviewData.current) {
      fetchedPreviewData.current = true;
      previewMutation.mutateAsync({
        queryString: query.query,
        windowLength: form.getValues("window_length"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, query?.query, previewMutation.mutateAsync]);

  const createFeatureQueryMutation = useCreateFeatureQuery();
  const updateFeatureQueryMutation = useUpdateFeatureQuery();

  const handleSubmit = async (data: QueryFormValues) => {
    try {
      // Make sure we have the latest preview data for submitting
      if (
        data.query !== previewMutation.variables?.queryString &&
        data.query.length > 0
      ) {
        await previewMutation.mutateAsync({
          queryString: data.query,
          windowLength: form.getValues("window_length"),
        });
      }

      const featureQuery = prepareFeatureQuery(
        data,
        previewDataRef.current ?? [],
      );

      if (isEditing) {
        await updateFeatureQueryMutation.mutateAsync({
          id: query.id,
          ...featureQuery,
          etag: query.etag,
        });
      } else {
        await createFeatureQueryMutation.mutateAsync(featureQuery);
      }

      onClose();
    } catch (error) {
      logger.error(error);

      if (isPreconditionError(error)) {
        await queryClient.invalidateQueries(["feature-queries"]);
        if (isEditing) {
          await queryClient.invalidateQueries(["feature-query", query.id]);
        }
        toastActions.failure({
          title: `Failed to ${isEditing ? "update" : "create"} Feature query`,
          description:
            "Somebody has changed the Feature query, check the updated configuration and try again",
        });
      } else {
        toastActions.failure({
          title: `Failed to ${isEditing ? "update" : "create"} Feature query`,
          description: TAKTILE_TEAM_NOTIFIED,
        });
      }
    }
  };

  return (
    <Modal
      afterLeave={() => {
        afterLeave?.();
        form.reset(defaultFormValues);
        previewMutation.reset();
        fetchedPreviewData.current = false;
        previewDataRef.current = undefined;
      }}
      autoFocus={false}
      open={open}
      size="lg"
      onClose={onClose}
    >
      <Modal.Header>
        {isEditing ? "Edit Feature query" : "Create Feature query"}
      </Modal.Header>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <FormProvider {...form}>
          <Modal.Content noScroll>
            <div className="flex h-full min-h-0 flex-1 items-stretch gap-x-5">
              <div className="decideScrollbar min-h-[38rem] w-6/12 max-w-164 flex-none py-5">
                <AccordionRoot
                  className={accordionRootClassName}
                  defaultValue={["sql-query", "mapping", "timing"]}
                  type="multiple"
                >
                  <AccordionItem
                    className="flex flex-col gap-y-3 pb-6"
                    title="SQL Query"
                    value="sql-query"
                  >
                    <div className="text-gray-500 font-inter-normal-12px">
                      <p>
                        Write a SQL query to calculate features. The SQL query
                        must
                      </p>
                      <ul className="marker:mr-1">
                        <li className="pl-2 marker:mr-1">
                          &bull; include exactly one time-based BETWEEN ? AND ?
                          clause have
                        </li>
                        <li className="pl-2">
                          &bull; no * wildcards or data modification statements
                          allowed
                        </li>
                        <li className="pl-2">
                          &bull; returns feature values by entity ID and/or
                          grouping columns
                        </li>
                      </ul>
                    </div>

                    <div className="h-70">
                      <Controller
                        name="query"
                        render={({ field, fieldState }) => {
                          return (
                            <CodeEditor
                              // Disable autocompletion
                              completionExtension={autocompletion({
                                override: [() => null],
                              })}
                              dataLoc="sql-query-editor"
                              errorHighlightedLine={
                                fieldState.error?.message ? 1 : undefined
                              }
                              keyBindings={[
                                {
                                  mac: "Cmd-Enter",
                                  win: "Ctrl-Enter",
                                  linux: "Ctrl-Enter",
                                  run: () => {
                                    return true;
                                  },
                                },
                              ]}
                              language="sql"
                              value={field.value}
                              onChange={field.onChange}
                            />
                          );
                        }}
                      />
                    </div>
                    <div className="flex items-start gap-x-2">
                      <RunQueryButton previewMutation={previewMutation} />
                    </div>
                    {previewMutation.isSuccess &&
                      previewMutation.variables?.queryString !==
                        form.watch("query") && (
                        <Callout type="warning">
                          The query has changed, please run the query again
                        </Callout>
                      )}
                  </AccordionItem>
                  <AccordionItem
                    className="flex flex-col gap-y-3 pb-6"
                    title="Mapping"
                    value="mapping"
                  >
                    <p className="text-gray-500 font-inter-normal-12px">
                      Map your SQL query results to entities and features for
                      use in decision flows
                    </p>
                    {form.formState.errors.columns?.root?.message && (
                      <Callout type="error">
                        {form.formState.errors.columns?.root?.message}
                      </Callout>
                    )}
                    <MappingConfiguration
                      previewData={previewDataRef.current ?? []}
                      previewMutation={previewMutation}
                    />
                  </AccordionItem>
                  <AccordionItem
                    className="flex flex-col gap-y-3 pb-6"
                    title="Timing configuration"
                    value="timing"
                  >
                    <div className="flex items-start gap-x-2">
                      <div>
                        <div className="text-gray-800 font-inter-semibold-13px">
                          Time window
                        </div>
                        <div className="text-gray-500 font-inter-normal-12px">
                          The time window for calculating your feature (e.g.,
                          last 30 days of transactions). Maximum 365 days.
                        </div>
                      </div>
                      <div className="w-60 shrink-0">
                        <Controller
                          name="window_length"
                          render={({ field }) => (
                            <Select
                              errored={!!form.formState.errors.window_length}
                              options={WINDOW_LENGTH_OPTIONS}
                              placeholder="Select time window"
                              value={
                                field.value ? field.value.toString() : null
                              }
                              searchable
                              onChange={(value) => {
                                const parsedWindowLengthValue = parseInt(value);
                                const currentWindowHop =
                                  form.getValues("window_hop") ?? 0;

                                if (
                                  currentWindowHop <
                                  getMinWindowHopFromWindowLength(
                                    parsedWindowLengthValue,
                                  )
                                ) {
                                  form.resetField("window_hop", {
                                    defaultValue: undefined,
                                  });
                                }

                                return field.onChange(parsedWindowLengthValue);
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="flex items-start gap-x-2">
                      <div>
                        <div className="text-gray-800 font-inter-semibold-13px">
                          Freshness
                        </div>
                        <div className="text-gray-500 font-inter-normal-12px">
                          How often your feature values are updated. Shorter
                          intervals mean more recent data. Match to your
                          decision-making needs (e.g., 1 min for real-time
                          fraud, 1 day for periodic reviews)
                        </div>
                      </div>
                      <div className="w-60 shrink-0">
                        <Controller
                          name="window_hop"
                          render={({ field }) => {
                            const windowLength = form.watch("window_length");
                            const options = WINDOW_HOP_OPTIONS.map(
                              (option) => ({
                                ...option,
                                disabled: isWindowHopOptionDisabled(
                                  parseInt(option.key),
                                  windowLength,
                                )
                                  ? "This option is not available for the selected time window"
                                  : false,
                              }),
                            );

                            return (
                              <Select
                                errored={!!form.formState.errors.window_hop}
                                options={options}
                                placeholder="Select freshness"
                                value={
                                  field.value ? field.value.toString() : null
                                }
                                searchable
                                onChange={(value) =>
                                  field.onChange(parseInt(value))
                                }
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                  </AccordionItem>
                </AccordionRoot>
              </div>
              <div className="flex min-w-0 flex-1 flex-col gap-y-3 py-5">
                <FeatureQueryPreview previewMutation={previewMutation} />
              </div>
            </div>
          </Modal.Content>
          <Modal.Footer
            primaryButton={
              <Button
                dataLoc="sql-modal-done"
                htmlType="submit"
                loading={form.formState.isSubmitting}
                variant="primary"
              >
                Done
              </Button>
            }
          >
            <RunQueryButton previewMutation={previewMutation} />
          </Modal.Footer>
        </FormProvider>
      </form>
    </Modal>
  );
};

const FeatureQueryPreview: React.FC<{
  previewMutation: PreviewMutation;
}> = ({ previewMutation }) => {
  return (
    <Tabs
      panelsClassName="pt-3"
      tabs={[
        {
          content: <SQLResponsePreview previewMutation={previewMutation} />,
          key: "sql-response",
          label: "SQL response",
        },
        {
          content: <div>Database schema</div>,
          key: "database-schema",
          label: "Database schema",
        },
      ]}
    />
  );
};

const RunQueryButton: React.FC<{
  previewMutation: PreviewMutation;
}> = ({ previewMutation }) => {
  const { mutateAsync: runQuery, isLoading } = previewMutation;

  const queryString = useWatch({ name: "query" });
  const windowLength = useWatch({ name: "window_length" });

  const handleRunQuery = () => {
    try {
      runQuery({
        queryString,
        windowLength,
      });
    } catch (error) {
      logger.error(error);
    }
  };

  return (
    <Button
      disabled={!queryString.length}
      iconLeft={faPlaySolid}
      loading={isLoading}
      variant="secondary"
      onClick={handleRunQuery}
    >
      Run Query
    </Button>
  );
};

const getMinWindowHopFromWindowLength = (windowLength: number) => {
  const ONE_HOUR = 3600;
  const ONE_DAY = 86400;
  const SIXTY_DAYS = 5184000;

  const FIFTEEN_SECONDS = 15;
  const ONE_MINUTE_IN_SECONDS = 60;
  const ONE_HOUR_IN_SECONDS = 3600;
  const ONE_DAY_IN_SECONDS = 86400;

  switch (true) {
    case windowLength < ONE_HOUR:
      return FIFTEEN_SECONDS;
    case windowLength < ONE_DAY:
      return ONE_MINUTE_IN_SECONDS;
    case windowLength < SIXTY_DAYS:
      return ONE_HOUR_IN_SECONDS;
    default:
      return ONE_DAY_IN_SECONDS;
  }
};

const WINDOW_HOP_OPTIONS = [
  15, 20, 30, 60, 120, 180, 240, 300, 360, 480, 540, 600, 720, 900, 960, 1080,
  1200, 1440, 1800, 1920, 2160, 2400, 2700, 2880, 3600, 4320, 4800, 5400, 5760,
  7200, 8640, 9600, 10800, 14400, 17280, 21600, 28800, 43200, 86400,
].map((duration) => ({
  key: duration.toString(),
  value: formatDuration(duration),
}));

const WINDOW_LENGTH_OPTIONS = [
  30, // 30s
  60, // 1m
  120, // 2m
  240, // 4m
  600, // 10m
  1200, // 20m
  1800, // 30m
  3600, // 60m
  5400, // 90m
  7200, // 2h
  14400, // 4h
  21600, // 6h
  43200, // 12h
  86400, // 1d
  172800, // 2d
  345600, // 4d
  604800, // 1w
  2592000, // 30d
  5184000, // 60d
  7776000, // 90d
  31536000, // 365d
].map((duration) => ({
  key: duration.toString(),
  value: formatDuration(duration),
}));

const SQLResponsePreview: React.FC<{
  previewMutation: PreviewMutation;
}> = ({ previewMutation }) => {
  const {
    isIdle,
    isLoading,
    isError,
    data: previewData,
    error,
  } = previewMutation;

  const isHandledError = error?.response?.status === 422;
  const errorDetail =
    isHandledError && error?.response?.data.detail
      ? error.response.data.detail
      : "Our team has been notified";

  return (
    <div className="overflow-hidden rounded-lg border border-gray-200">
      {isIdle && (
        <EmptyState
          description="SQL response will help you map the columns with the input schema"
          headline="Run Query to see SQL response"
          icon={faPlay}
        />
      )}
      {isError && (
        <EmptyState
          description={errorDetail}
          headline="We've got unexpected error"
          icon={faDatabase}
          variant="error"
        />
      )}
      {(previewData || isLoading) && (
        <SQLPreviewTable isLoading={isLoading} rows={previewData} />
      )}
    </div>
  );
};

const FeatureMapping: React.FC<{
  columnsOptions: { key: string; value: React.ReactNode }[];
  featureOptions: { key: string; value: React.ReactNode }[];
  featuresLoading: boolean;
  previewMutation: PreviewMutation;
}> = ({ columnsOptions, featureOptions, featuresLoading, previewMutation }) => {
  const { fields, append, remove } = useFieldArray({
    name: "columns.features",
  });

  const form = useFormContext<QueryFormValues>();

  return (
    <div className="flex flex-col gap-3 rounded-lg bg-gray-50 p-3">
      <div className="flex items-center text-gray-800 font-inter-medium-12px">
        Feature mapping
        <Tooltip
          body="Choose the column from your query results that contains the feature value(s)."
          footerAction={{
            // TODO: Add learn more link
            onClick: noop,

            text: "Learn more",
          }}
          placement="right-start"
          asChild
        >
          <Icon color="text-gray-500" icon={faInfoCircle} size="2xs" />
        </Tooltip>
      </div>
      <div className="grid grid-cols-[1fr_20px_1fr_20px] gap-2 text-gray-500 font-inter-normal-12px">
        <div>Select column</div>
        <div></div>
        <div>Associated feature</div>
        <div></div>
        {fields.map((field, index) => (
          <Fragment key={field.id}>
            <div>
              <Controller<
                QueryFormValues,
                `columns.features.${number}.column_name`
              >
                name={`columns.features.${index}.column_name`}
                render={({ field, fieldState, formState }) => (
                  <ColumnNameSelector
                    errored={
                      !!fieldState.error?.message ||
                      !!formState.errors.columns?.features?.root?.message
                    }
                    options={columnsOptions}
                    previewMutation={previewMutation}
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </div>
            <div className="flex items-center">
              <Icon color="text-gray-500" icon={faArrowRight} size="xs" />
            </div>
            <div>
              <Controller<
                QueryFormValues,
                `columns.features.${number}.feature_key`
              >
                name={`columns.features.${index}.feature_key`}
                render={({ field, fieldState, formState }) => (
                  <Combobox
                    disabled={featuresLoading}
                    dropdownPlaceholder={
                      <EmptyState
                        description={
                          <>
                            Create a new feature to map
                            <br /> your column to.
                          </>
                        }
                        headline="No features found"
                        icon={faDatabase}
                        size="sm"
                      />
                    }
                    errored={
                      !!fieldState.error?.message ||
                      !!formState.errors.columns?.features?.root?.message
                    }
                    options={featureOptions}
                    placeholder={
                      featuresLoading ? "Loading..." : "Select feature"
                    }
                    value={field.value}
                    showResetButton
                    onChange={field.onChange}
                  />
                )}
              />
            </div>
            <div className="flex items-center">
              <Icon
                color={fields.length === 1 ? "text-gray-300" : "text-gray-500"}
                disabled={fields.length === 1}
                icon={faTrashAlt}
                size="xs"
                onClick={() => remove(index)}
              />
            </div>
            {form.formState.errors.columns?.features?.[index] && (
              <div className="col-span-full">
                <ErrorHint>
                  {form.formState.errors.columns?.features?.[index]?.column_name
                    ?.message ||
                    form.formState.errors.columns?.features?.[index]
                      ?.feature_key?.message}
                </ErrorHint>
              </div>
            )}
          </Fragment>
        ))}
      </div>
      <div>
        {form.formState.errors.columns?.features?.root?.message && (
          <ErrorHint>
            {form.formState.errors.columns?.features?.root?.message}
          </ErrorHint>
        )}
      </div>
      <div>
        <Button
          iconLeft={faPlus}
          size="sm"
          variant="secondary"
          onClick={() => append({ column_name: "", feature_key: "" })}
        >
          Add Feature
        </Button>
      </div>
    </div>
  );
};

const MappingConfiguration: React.FC<{
  previewMutation: PreviewMutation;
  previewData: PreviewQueryResponse["data"];
}> = ({ previewMutation, previewData = [] }) => {
  const { data: featuresResponse, isLoading: featuresLoading } = useFeatures();
  const { workspace } = useWorkspaceContext();
  const { data: entitiesResponse, isLoading: entitiesLoading } = useEntities({
    schema: "schemas",
    env: DecisionEnvironment.LIVE,
    baseUrl: workspace.base_url!,
  });

  const columns = useWatch<QueryFormValues, "columns">({
    name: "columns",
  });

  const columnsDataTypes = inferDataTypes(previewData);
  const usedColumns = [
    ...(columns?.entities?.map((entity) => entity.column_name) ?? []),
    ...(columns?.features?.map((feature) => feature.column_name) ?? []),
    ...(columns?.grouping_keys ?? []),
  ].filter(Boolean);

  const columnsOptions = Object.keys(previewData?.at(0) ?? {}).map(
    (column) => ({
      key: column,
      value: (
        <div className="flex items-center gap-x-2">
          <Icon
            color="text-gray-500"
            icon={TYPE_ICONS[columnsDataTypes[column]]}
            size="2xs"
          />
          {column}
        </div>
      ),
      disabled: usedColumns.includes(column)
        ? "This column has already been mapped"
        : undefined,
    }),
  );

  const form = useFormContext<QueryFormValues>();

  return (
    <>
      <div className="flex flex-col gap-3 rounded-lg bg-gray-50 p-3">
        <div className="flex items-center text-gray-800 font-inter-medium-12px">
          Entity mapping
          <Tooltip
            body="Choose the primary entity (e.g., Account, Card) this feature will be associated with and map it to the corresponding ID column from your query results."
            footerAction={{
              // TODO: Add learn more link
              onClick: noop,
              text: "Learn more",
            }}
            placement="right-start"
            asChild
          >
            <Icon color="text-gray-500" icon={faInfoCircle} size="2xs" />
          </Tooltip>
        </div>
        <div className="grid grid-cols-[1fr_20px_1fr] gap-2 text-gray-500 font-inter-normal-12px">
          <div>Select column</div>
          <div></div>
          <div>Associated entity</div>
          <div>
            <Controller
              name="columns.entities.0.column_name"
              render={({ field, fieldState }) => (
                <ColumnNameSelector
                  errored={!!fieldState.error?.message}
                  options={columnsOptions}
                  previewMutation={previewMutation}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </div>
          <div className="flex items-center">
            <Icon color="text-gray-500" icon={faArrowRight} size="xs" />
          </div>
          <div>
            <Controller
              name="columns.entities.0.entity_name"
              render={({ field, fieldState }) => (
                <Combobox
                  disabled={entitiesLoading}
                  dropdownPlaceholder={
                    <EmptyState
                      description={
                        <>
                          Create a new entity to map
                          <br /> your column to.
                        </>
                      }
                      headline="No entities found"
                      icon={faBuilding}
                      size="sm"
                    />
                  }
                  errored={!!fieldState.error?.message}
                  options={
                    entitiesResponse?.entities?.map((entity) => ({
                      key: entity._id,
                      value: (
                        <div className="flex items-center gap-x-2 text-gray-800">
                          <Icon
                            color="text-gray-500"
                            icon={faBuilding}
                            size="xs"
                          />
                          {capitalize(entity._plural_display_name)}
                        </div>
                      ),
                    })) ?? []
                  }
                  placeholder={entitiesLoading ? "Loading..." : "Select entity"}
                  value={field.value}
                  showResetButton
                  onChange={field.onChange}
                />
              )}
            />
          </div>
          {form.formState.errors.columns?.entities?.length && (
            <div className="col-span-full">
              <ErrorHint>
                {form.formState.errors.columns?.entities?.[0]?.column_name
                  ?.message ??
                  form.formState.errors.columns?.entities?.[0]?.entity_name
                    ?.message}
              </ErrorHint>
            </div>
          )}
        </div>
      </div>
      <FeatureMapping
        columnsOptions={columnsOptions}
        featureOptions={
          featuresResponse?.data?.map((feature) => ({
            key: feature.key,
            value: (
              <div className="flex items-center gap-x-2">
                <Icon
                  color="text-gray-500"
                  icon={faCalculatorSimple}
                  size="xs"
                />
                {feature.key}
              </div>
            ),
          })) ?? []
        }
        featuresLoading={featuresLoading}
        previewMutation={previewMutation}
      />
      <div className="flex flex-col gap-3 rounded-lg bg-gray-50 p-3">
        <div className="flex items-center text-gray-800 font-inter-medium-12px">
          Additional Grouping
          <Tooltip
            body={
              <>
                <p className="mb-2">
                  Choose additional columns to group feature values (e.g., by
                  merchant category, country). Limited to 1000 unique values per
                  group.
                </p>

                <p>
                  Grouped features can be used like
                  <Pill size="sm" variant="dark-gray">
                    <Pill.Text>data.entity.feature.group_value</Pill.Text>
                  </Pill>
                  in the decision flow{" "}
                </p>
              </>
            }
            footerAction={{
              // TODO: Add learn more link
              onClick: noop,
              text: "Learn more",
            }}
            placement="right-start"
            asChild
          >
            <Icon color="text-gray-500" icon={faInfoCircle} size="2xs" />
          </Tooltip>
        </div>
        <div className="flex flex-col gap-2 text-gray-500 font-inter-normal-12px">
          <div>Grouping</div>
          <div>
            <Controller
              name="columns.grouping_keys.0"
              render={({ field, fieldState }) => (
                <ColumnNameSelector
                  errored={!!fieldState.error?.message}
                  options={columnsOptions}
                  previewMutation={previewMutation}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            {form.formState.errors.columns?.grouping_keys?.[0]?.message && (
              <ErrorHint>
                {form.formState.errors.columns?.grouping_keys?.[0]?.message}
              </ErrorHint>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const prepareFeatureQuery = (
  data: QueryFormValues,
  previewData: PreviewQueryResponse["data"],
): FeatureQueryCreate | FeatureQueryPatch => {
  const types = inferDataTypes(previewData);

  const entities = data.columns.entities?.filter<EntityMapping>(
    (entity): entity is EntityMapping =>
      Boolean(entity.column_name && entity.entity_name),
  );
  const features = data.columns.features?.filter<FeatureMappingT>(
    (feature): feature is FeatureMappingT =>
      Boolean(feature.column_name && feature.feature_key),
  );
  const groupingKeys = data.columns.grouping_keys?.filter(isString);

  return {
    ...data,
    backfilling_query: "",
    status: FeatureQueryStatus.INACTIVE,
    event: "transaction",
    columns: {
      entities,
      grouping_keys: groupingKeys,
      features: features?.map((feature) => ({
        ...feature,
        feature_type: types[feature.column_name],
      })),
    },
  };
};

const isWindowHopOptionDisabled = (
  value: number,
  windowLength: number | null,
) =>
  windowLength
    ? value > windowLength ||
      value < getMinWindowHopFromWindowLength(windowLength)
    : false;

const ColumnNameSelector: React.FC<{
  value: string | undefined;
  options: Option[];
  onChange: (value: Nullable<string>) => void;
  previewMutation: PreviewMutation;
  errored: boolean;
}> = ({ options, value, onChange, previewMutation, errored }) => {
  const optionValue = options.find((col) => col.key === value);

  // Populate the options with the value if it's not in the options
  // Validation handles error state for fake value
  const updatedOptions =
    !optionValue && value
      ? [
          {
            key: value,
            value: (
              <div className="flex items-center gap-x-2 text-gray-500 line-through">
                <Icon color="text-gray-500" icon={TYPE_ICONS.any} size="2xs" />
                {value}
              </div>
            ),
            disabled: true,
          },
          ...options,
        ]
      : options;

  const isLoading = !value && previewMutation.isLoading;
  return (
    <Combobox
      disabled={isLoading}
      dropdownPlaceholder={
        <EmptyState
          action={<RunQueryButton previewMutation={previewMutation} />}
          description="Run query to get column name suggestions."
          headline="No columns found"
          icon={faPlay}
          size="sm"
        />
      }
      errored={errored}
      options={updatedOptions}
      placeholder={isLoading ? "Loading..." : "Select column"}
      value={value ?? null}
      showResetButton
      onChange={onChange}
    />
  );
};
