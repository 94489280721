import {
  faCalculatorSimple,
  faHashtag,
  faMinus,
  faText,
  faSearch,
  faPlus,
} from "@fortawesome/pro-regular-svg-icons";
import {
  CellContext,
  createColumnHelper,
  RowData,
} from "@tanstack/react-table";
import React, { useState } from "react";

import { HighlightedText } from "src/base-components/HighlightedText";
import { Icon } from "src/base-components/Icon";
import { Pill } from "src/base-components/Pill";
import { CustomPopover } from "src/base-components/Popover";
import { Cell, Header, TableComp } from "src/base-components/Table";
import { mdService } from "src/base-components/TextEditor/MarkdownService";
import { EmptyState } from "src/design-system/EmptyState";
import { Tooltip } from "src/design-system/Tooltip";
import { FeatureActionsMenu } from "src/featureCatalogue/Features/FeatureActionsMenu";
import { StatusPill } from "src/featureCatalogue/StatusPill";
import { useFeatures } from "src/featureCatalogue/queries";
import { Feature, FeatureRow, commonColumns } from "src/featureCatalogue/utils";
import { WhitePane } from "src/jobs/common/WhitePane";

const columnHelper = createColumnHelper<FeatureRow>();

const HighlightedCellText = <D extends RowData>({
  info,
}: {
  info: CellContext<D, string | null | undefined>;
}) => (
  <HighlightedText
    highlight={info.table.getState().globalFilter}
    highlightClassName="font-inter-semibold-12px bg-yellow-200"
    text={info.getValue()}
  />
);

const COLUMNS = [
  columnHelper.accessor("name", {
    header: (info) => <Header info={info}>Feature name</Header>,
    cell: (info) => {
      const name = info.getValue();
      return (
        <Cell className="gap-x-0.5" info={info}>
          <Icon
            color="text-gray-500"
            icon={
              info.row.original.feature_type === "integer" ||
              info.row.original.feature_type === "number"
                ? faHashtag
                : info.row.original.feature_type === "string"
                  ? faText
                  : faMinus
            }
            size="xs"
          />
          <Tooltip
            disabled={!name || name.length <= 50}
            placement="top"
            title={name}
            asChild
          >
            <div className="truncate">
              <HighlightedCellText info={info} />
            </div>
          </Tooltip>
        </Cell>
      );
    },
    size: 300,
    maxSize: 300,
    filterFn: "includesString",
  }),
  columnHelper.accessor("description", {
    header: (info) => <Header info={info}>Description</Header>,
    cell: (info) => <DisplayDescriptionCell info={info} />,
    size: 300,
    maxSize: 300,
    filterFn: "includesString",
  }),
  columnHelper.accessor("key", {
    header: (info) => <Header info={info}>Key</Header>,
    cell: (info) => (
      <Cell info={info}>
        <Pill size="sm" variant="gray">
          <Pill.Text>
            <HighlightedCellText info={info} />
          </Pill.Text>
        </Pill>
      </Cell>
    ),
    size: 100,
    filterFn: "includesString",
  }),
  columnHelper.accessor("query.status", {
    header: (info) => <Header info={info}>Status</Header>,
    cell: (info) => {
      const status = info.getValue();
      return status ? (
        <Cell info={info}>
          <StatusPill status={status} />
        </Cell>
      ) : null;
    },
    enableGlobalFilter: false,
  }),
  ...commonColumns.map((column) =>
    columnHelper.accessor(`query.${column.accessorKey}` as keyof FeatureRow, {
      header: column.header,
      cell: (info) =>
        info.row.original.query ? (
          <Cell info={info}>{column.cell(info)}</Cell>
        ) : null,
      enableGlobalFilter: false,
    }),
  ),
  columnHelper.display({
    id: "actions-menu",
    header: () => null,
    cell: (info) => (
      <Cell className="justify-end" info={info}>
        <FeatureActionsMenu feature={info.row.original} />
      </Cell>
    ),
    enableGlobalFilter: false,
  }),
];

export const Features: React.FC<{
  openCreateModal: () => void;
  searchQuery: string;
}> = ({ openCreateModal, searchQuery }) => {
  const { data: features, isLoading } = useFeatures();

  return (
    <WhitePane>
      <div className="flex w-full flex-col gap-y-4">
        {!isLoading && features?.data?.length === 0 ? (
          <EmptyState
            actionButton={
              !searchQuery
                ? {
                    icon: faPlus,
                    text: "Create Feature",
                    onClick: () => {
                      openCreateModal();
                    },
                  }
                : undefined
            }
            dataLoc="features-empty-state"
            description={
              searchQuery
                ? "Try adjusting your search to find what you're looking for"
                : "Features help you detect patterns and analyze behavior across your entities and events."
            }
            headline={
              searchQuery
                ? "No matching features found"
                : "No features configured yet"
            }
            icon={searchQuery ? faSearch : faCalculatorSimple}
          />
        ) : (
          <TableComp
            columns={COLUMNS}
            data={features?.data ?? []}
            dataLoc="features-list"
            frameClassName="w-full p-4"
            globalFilter={searchQuery}
            isLoading={isLoading}
            variant="compact"
          />
        )}
      </div>
    </WhitePane>
  );
};

const DisplayDescriptionCell: React.FC<{
  info: CellContext<Feature, string | null | undefined>;
}> = ({ info }) => {
  const [isOpen, setIsOpen] = useState(false);
  const descriptionValue = info.getValue() ?? "";

  const needsPopover =
    descriptionValue.length > 50 || descriptionValue.includes("\n");

  const firstLineContent = descriptionValue.includes("\n")
    ? `${descriptionValue.split("\n")[0]}...`
    : descriptionValue.length > 50
      ? `${descriptionValue.slice(0, 50)}...`
      : descriptionValue;

  const content = (
    <div className="line-clamp-1 overflow-hidden">
      <div
        //skipcq: JS-0440
        dangerouslySetInnerHTML={{
          __html: mdService.toHtml(
            needsPopover ? firstLineContent : descriptionValue,
          ),
        }}
        className="review-case-description [&>p]:m-0"
      />
    </div>
  );

  return needsPopover ? (
    <CustomPopover
      button={<Cell info={info}>{content}</Cell>}
      className="w-96 overflow-auto border-0"
      isOpen={isOpen}
      offsetX={0}
      placement="top"
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      {isOpen && (
        <div
          //skipcq: JS-0440
          dangerouslySetInnerHTML={{
            __html: mdService.toHtml(descriptionValue),
          }}
          className="review-case-description p-3"
        />
      )}
    </CustomPopover>
  ) : (
    <Cell info={info}>{content}</Cell>
  );
};
