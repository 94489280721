import { capitalize } from "lodash";

import { Pill } from "src/base-components/Pill";
import { FeatureQueryStatus } from "src/clients/features";
import { STATUS_COLOR_MAP } from "src/featureCatalogue/utils";

type StatusPillProps = {
  status: FeatureQueryStatus;
};

export const StatusPill: React.FC<StatusPillProps> = ({ status }) => (
  <Pill size="sm" variant={STATUS_COLOR_MAP[status]}>
    <Pill.Text>{capitalize(status)}</Pill.Text>
  </Pill>
);
