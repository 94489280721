import { faCheckCircle, faCopy } from "@fortawesome/pro-regular-svg-icons";
import React, { useState } from "react";
import { twJoin } from "tailwind-merge";
import { v4 as uuid } from "uuid";

import { FlowT, FlowVersionFlowChild } from "src/api/flowTypes";
import { EllipsisOptionsDropdown } from "src/base-components/OptionsDropdown/EllipsisOptionsDropdown";
import { DescriptionRow } from "src/flow/DescriptionRow";
import { RelatedFlowsPill } from "src/flow/Pills";
import { VersionNameExpandable } from "src/flow/VersionName";
import { LegacyVersionMetaUser } from "src/flow/VersionsTable/LegacyVersionMetaUser";
import { Cell, Row, Table } from "src/flow/VersionsTable/VersionsTable";
import { VersionsTableColumn } from "src/flow/VersionsTable/types";
import { getExportToJSONOption } from "src/flow/getExportToJSON";
import { ExcludesFalse } from "src/flow/types";
import { useCapabilities } from "src/hooks/useCapabilities";
import { FlowPageParamsT, getUrlToAuthoringPage } from "src/router/urls";
import { formatDate } from "src/utils/datetime";
import { useParamsDecode } from "src/utils/useParamsDecode";

type ArchivedVersionRowProps = {
  flow: FlowT;
  version: FlowVersionFlowChild;
  onDuplicate: () => void;
  onRepublish: () => void;
};

export const ArchivedVersionRow: React.FC<ArchivedVersionRowProps> = ({
  flow,
  version,
  onDuplicate,
  onRepublish,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [isHover, setIsHover] = useState(false);

  const { orgId, wsId, flow_id } = useParamsDecode<FlowPageParamsT>();
  const { flowVersions } = useCapabilities();
  const canPublish = flowVersions.canPublish(flow);

  const options = [
    { key: "Duplicate", action: onDuplicate, icon: faCopy },
    getExportToJSONOption(flow.name, version),
    canPublish && {
      key: "Republish",
      action: onRepublish,
      icon: faCheckCircle,
    },
  ].filter(Boolean as any as ExcludesFalse);

  const onMouseEnter = () => setIsHover(true);
  const onMouseLeave = () => setIsHover(false);
  const onClickExpand = () => setExpanded(!expanded);

  return (
    <>
      <Row
        className={twJoin("cursor-pointer", isHover && "!bg-gray-50")}
        to={getUrlToAuthoringPage(orgId, wsId, flow_id, version.id)}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Cell data-loc="version-name" font="inter-medium-13px">
          <VersionNameExpandable
            expanded={expanded}
            version={version}
            onClickExpand={onClickExpand}
          />
        </Cell>
        <Cell>
          <LegacyVersionMetaUser
            status={version.status}
            versionMeta={version.meta}
          />
        </Cell>
        <Cell>
          <div className="whitespace-nowrap">
            {version.meta.archived_at && formatDate(version.meta.archived_at)}
          </div>
        </Cell>
        <Cell align="right">
          <div className="inline-flex items-center gap-2">
            {Boolean(version.child_flows?.length) && (
              <RelatedFlowsPill
                flows={version.child_flows!}
                includeVersions
                isParent
              />
            )}
            {Boolean(version.parent_flows?.length) && (
              <RelatedFlowsPill flows={version.parent_flows!} includeVersions />
            )}
          </div>
        </Cell>
        <Cell align="right">
          {flowVersions.canEdit && (
            <EllipsisOptionsDropdown
              buttonClassName="text-gray-300 w-5 rotate-90"
              buttonDataLoc={`${version.name}-options`}
              elements={options}
              menuClassName="inline-block"
              timeoutDuration={200}
            />
          )}
        </Cell>
      </Row>

      {expanded && (
        <tr
          className={twJoin(
            "group border-t-transparent transition-colors duration-150",
            isHover && "bg-gray-50",
          )}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <DescriptionRow description={version.meta.release_note} />
        </tr>
      )}
    </>
  );
};

type ArchivedVersionsTableProps = {
  children: React.ReactNode;
};

const ARCHIVED_TABLE_COLUMNS: VersionsTableColumn[] = [
  {
    id: uuid(),
    name: "Archived versions",
    width: "w-1/4",
  },
  { id: uuid(), name: "Archived by", width: "w-1/4" },
  { id: uuid(), name: "Archived on", width: "w-1/6" },
  { id: uuid(), name: "" },
  { id: uuid(), name: "", width: "w-16" },
];

export const ArchivedVersionsTable: React.FC<ArchivedVersionsTableProps> = ({
  children,
}) => (
  <Table columns={ARCHIVED_TABLE_COLUMNS} dataLoc="archived-versions-table">
    {children}
  </Table>
);
