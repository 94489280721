import {
  faChevronDown,
  faExclamationTriangle,
  faPlug,
  faTrashAlt,
} from "@fortawesome/pro-regular-svg-icons";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { capitalize, get } from "lodash";
import { useState, useEffect } from "react";
import { useFormContext, UseFormSetValue } from "react-hook-form";

import { ManifestIntegrationProvider } from "src/api/connectApi/manifestTypes";
import { ConnectionT } from "src/api/connectApi/types";
import { Button } from "src/base-components/Button";
import { Divider } from "src/base-components/Divider";
import { DropDown } from "src/base-components/DropDown";
import { Icon } from "src/base-components/Icon";
import { Tabs } from "src/base-components/Tabs";
import { Form } from "src/connections/config/manifest/common/Form";
import { ManifestFormType } from "src/connections/config/manifest/types";

type EmptyEnvironmentMessageProps = {
  availableEnvironments: string[];
  providerDisplayName: string;
  handleAddEnvironment: (environment: string) => void;
};

const NoAddedEnvironmentMessage = ({
  availableEnvironments,
  providerDisplayName,
  handleAddEnvironment,
}: EmptyEnvironmentMessageProps) => (
  <>
    <div className="flex w-full flex-col items-center justify-center rounded-lg bg-gray-50 pt-14 text-center">
      <div className="mb-4 flex h-12 w-12 items-center justify-center rounded-lg bg-indigo-100">
        <Icon color="text-indigo-500" icon={faPlug} size="sm" />
      </div>
      <span className="mb-2 text-gray-900 font-inter-medium-13px">
        No credentials added
      </span>
      <span className="mb-6 max-w-[240px] text-gray-500 font-inter-normal-12px">
        Add an environment and configure your {providerDisplayName} credentials
      </span>
      <div className="mb-10">
        <DropDown
          buttonClassName="border font-inter-semibold-13px text-gray-800 !ring-0 rounded-lg pl-3 py-1 mb-1"
          dataLoc="manifest-connection-add-environment"
          elements={availableEnvironments.map((env) => ({
            key: env,
            value: env,
          }))}
          placement="bottomLeft"
          renderButtonValue={() => (
            <div
              className="flex items-center justify-between"
              data-loc="add-environment-to-manifest-connection"
            >
              <span className="border-r pr-2">Add credentials</span>
              <Icon color="text-gray-500 px-2" icon={faChevronDown} size="xs" />
            </div>
          )}
          renderValue={({ value }) => (
            <div
              className="flex items-center justify-between whitespace-nowrap p-4 py-3"
              data-loc={`add-${value}-environment-to-manifest-connection`}
            >
              <span>Add {value} credentials</span>
            </div>
          )}
          onSelect={handleAddEnvironment}
        />
      </div>
    </div>
    <Divider spacing="my-4" />
  </>
);

type EnvironmentDropdownProps = {
  availableEnvironments: string[];
  addedEnvironments: string[];
  handleAddEnvironment: (environment: string) => void;
};

const EnvironmentDropDown = ({
  availableEnvironments,
  addedEnvironments,
  handleAddEnvironment,
}: EnvironmentDropdownProps) => {
  return (
    <DropDown
      buttonClassName="border-0 font-inter-semibold-13px text-gray-800 !ring-0"
      elements={availableEnvironments
        .filter((env) => !addedEnvironments.includes(env))
        .map((env) => ({
          key: env,
          value: env,
        }))}
      placement="bottomLeft"
      renderButtonValue={() => (
        <div
          className="flex items-center justify-between"
          data-loc="additional-manifest-connection-environment-button"
        >
          <Icon color="text-gray-500" icon={faPlus} size="xs" />
          <span>Add credentials</span>
        </div>
      )}
      renderValue={({ value }) => (
        <div
          className="flex items-center justify-between whitespace-nowrap p-4 py-3"
          data-loc={`add-${value}-environment-to-manifest-connection`}
        >
          <span>Add {value} credentials</span>
        </div>
      )}
      variant="transparent"
      onSelect={(value) => handleAddEnvironment(value)}
    />
  );
};

type ManifestEnvironmentsConfigProps = {
  manifest: ManifestIntegrationProvider;
  formValue: ManifestFormType;
  setFormValue: UseFormSetValue<ManifestFormType>;
  connectionToEdit?: ConnectionT | undefined;
};

export const ManifestEnvironmentsConfig = ({
  manifest,
  formValue,
  setFormValue,
  connectionToEdit,
}: ManifestEnvironmentsConfigProps) => {
  const preConfiguredEnvironments =
    connectionToEdit?.environment_configurations;
  const initialEnvironment = preConfiguredEnvironments
    ? Object.keys(preConfiguredEnvironments)[0]
    : undefined;

  const [activeEnvironment, setActiveEnvironment] = useState<
    string | undefined
  >(initialEnvironment);
  const availableEnvironments = Object.keys(manifest.environment_configs);
  const addedEnvironments = Object.keys(formValue.inputs || {});
  const form = useFormContext<ManifestFormType>();
  const environmentErrors = form.formState.errors;

  useEffect(() => {
    if (environmentErrors?.inputs) {
      const erroredEnvironments = Object.keys(environmentErrors.inputs);
      if (erroredEnvironments.length > 0) {
        setActiveEnvironment(erroredEnvironments[0]);
      }
    }
  }, [environmentErrors]);

  const hasEnvironmentError = (env: string) => {
    const envErrors = get(environmentErrors, `inputs.${env}`);
    return !!envErrors;
  };

  const handleAddEnvironment = (environment: string) => {
    form.resetField(`inputs.${environment}`);

    setFormValue(`inputs`, {
      ...formValue.inputs,
      [environment]: {},
    });
    setActiveEnvironment(environment);
  };

  const handleRemoveEnvironment = (environment: string) => {
    const newInputs = { ...formValue.inputs };
    delete newInputs[environment];
    setFormValue("inputs", newInputs);
    form.clearErrors(`inputs.${environment}`);
    form.unregister(`inputs.${environment}`);
    setActiveEnvironment(
      Object.keys(newInputs).length > 0 ? Object.keys(newInputs)[0] : undefined,
    );
  };

  const isEmptyManifestEnvironmentConfig = Object.values(
    manifest.environment_configs,
  ).every(
    (config) =>
      !config.inputs?.properties ||
      Object.keys(config.inputs.properties).length === 0,
  );

  if (isEmptyManifestEnvironmentConfig) return null;

  if (addedEnvironments.length === 0) {
    return (
      <>
        <NoAddedEnvironmentMessage
          availableEnvironments={availableEnvironments}
          handleAddEnvironment={handleAddEnvironment}
          providerDisplayName={manifest.display_name}
        />
      </>
    );
  }

  return (
    <>
      <Tabs
        containerClassName="flex flex-col"
        customButton={
          availableEnvironments.filter(
            (env) => !addedEnvironments.includes(env),
          ).length > 0 ? (
            <EnvironmentDropDown
              addedEnvironments={addedEnvironments}
              availableEnvironments={availableEnvironments}
              handleAddEnvironment={handleAddEnvironment}
            />
          ) : undefined
        }
        panelClassName="h-full flex flex-col rounded-lg bg-gray-50 px-4 pt-3 pb-6"
        panelsClassName="flex-1"
        selectedKey={activeEnvironment}
        tabClassName="px-0 mr-4 flex-shrink-0 cursor-pointer"
        tabListClassName="border-b border-gray-200 mb-4 sticky -top-5 bg-white z-10 flex"
        tabs={availableEnvironments
          .filter((env) => addedEnvironments.includes(env))
          .map((environment) => ({
            key: environment,
            label: (
              <div className="flex items-center gap-2">
                <span>{capitalize(environment)}</span>
                {hasEnvironmentError(environment) && (
                  <Icon
                    color="text-red-500"
                    icon={faExclamationTriangle}
                    size="sm"
                  />
                )}
              </div>
            ),
            content: (
              <Form
                formPath={`inputs.${environment}`}
                isConnectionUpdate={!!connectionToEdit}
                prefix={`manifest-connection-${environment}-config-form`}
                schema={manifest.environment_configs[environment].inputs}
              />
            ),
          }))}
        onChange={setActiveEnvironment}
      />
      <div className="mt-4 flex w-fit">
        <Button
          disabled={false}
          iconLeft={faTrashAlt}
          size="sm"
          variant="secondary"
          onClick={(e) => {
            e.currentTarget.blur();
            if (activeEnvironment !== undefined) {
              handleRemoveEnvironment(activeEnvironment);
            }
          }}
        >
          Remove {activeEnvironment} credentials
        </Button>
      </div>
      <Divider spacing="my-4" />
    </>
  );
};
