import { useIsTaktileOrgUser } from "src/hooks/useIsTaktileOrgUser";
import { FEATURE_FLAGS, isFeatureFlagEnabled } from "src/router/featureFlags";
import { useCurrentWorkspace } from "src/utils/useCurrentWorkspace";

export const usePowertools = () => {
  const isTaktileOrgUser = useIsTaktileOrgUser();

  return isTaktileOrgUser && isFeatureFlagEnabled(FEATURE_FLAGS.powertools);
};

export const useXrayTraceURL = (xrayTraceID: string | null) => {
  const workspaceData = useCurrentWorkspace();
  const AWSRegion = workspaceData.workspace?.aws_region;

  if (!AWSRegion || !xrayTraceID) {
    return null;
  }

  return `https://${AWSRegion}.console.aws.amazon.com/cloudwatch/home?region=${AWSRegion}#xray:traces/${xrayTraceID}`;
};
