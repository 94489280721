import {
  faSync,
  faThumbsDown,
  faThumbsUp,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faThumbsDown as faThumbsDownSolid,
  faThumbsUp as faThumbsUpSolid,
} from "@fortawesome/pro-solid-svg-icons";
import { useCallback, useMemo, useState } from "react";

import { Icon } from "src/base-components/Icon";
import {
  ConversationMessage,
  ConversationMessageRoleEnum,
} from "src/clients/copilot";
import { FlowVersionStatus } from "src/clients/flow-api/api";
import { BeMappedNode } from "src/constants/NodeDataTypes";
import { CopilotTextContent } from "src/copilot/CopilotTextContent";
import { NodeMention } from "src/copilot/NodeMention";
import { NodeSuggestion } from "src/copilot/NodeSuggestion";
import { NodeSuggestionType } from "src/copilot/NodeSuggestion";
import {
  normalizeAnimationSpeed,
  replaceNodeMentions,
  replaceNodeSuggestions,
} from "src/copilot/utils";
import { Tooltip } from "src/design-system/Tooltip";
import {
  tracker,
  trackingEvents,
} from "src/instrumentation/customTrackingEvents";
import { useAuthoringContext } from "src/router/routerContextHooks";
import { useGraphStore } from "src/store/StoreProvider";
import { logger } from "src/utils/logger";
import { errorMessage } from "src/utils/stringUtils";

type ReactionStatus = "liked" | "disliked";

export type RenderMentionFnArgs = {
  nodeName: string;
  key: number;
  delay: number;
};

const Actions: React.FC<{
  status: ReactionStatus | null;
  onLike: VoidFunction;
  onDislike: VoidFunction;
  onRegenerate: VoidFunction;
}> = ({ status, onLike, onDislike, onRegenerate }) => (
  <div className="flex items-center gap-x-1 opacity-0 transition-opacity group-hover:opacity-100">
    <Tooltip placement="top" title="Regenerate response" asChild>
      <Icon
        color="text-gray-500"
        icon={faSync}
        size="xs"
        onClick={onRegenerate}
      />
    </Tooltip>
    <div className="mx-0.5 h-2.5 border-l border-gray-200" />
    <Tooltip placement="top" title="Good response" asChild>
      <Icon
        color="text-gray-500"
        icon={status === "liked" ? faThumbsUpSolid : faThumbsUp}
        size="xs"
        onClick={onLike}
      />
    </Tooltip>
    <Tooltip placement="top" title="Not good response" asChild>
      <Icon
        color="text-gray-500"
        icon={status === "disliked" ? faThumbsDownSolid : faThumbsDown}
        size="xs"
        onClick={onDislike}
      />
    </Tooltip>
  </div>
);

type Props = {
  message: ConversationMessage;
  node: BeMappedNode;
  conversationId: string;
  completionId: string | null;
  onApply: (suggestion: NodeSuggestionType) => void;
  onRegenerate: VoidFunction;
  animate: boolean;
};

export type RenderTextContentFnArgs = {
  animate: boolean;
  content: string;
  delay: number;
  key?: number;
};

export type RenderSuggestionFnArgs = {
  suggestion: string;
  originalNode: BeMappedNode;
  key: number;
  onApply: (suggestion: NodeSuggestionType) => void;
  delay: number;
  isPublished: boolean;
};

export const renderTextContent = ({
  key,
  ...args
}: RenderTextContentFnArgs) => <CopilotTextContent {...args} key={key} />;

export const renderSuggestion = ({
  suggestion,
  originalNode,
  key,
  onApply,
  delay,
  isPublished,
}: RenderSuggestionFnArgs) => {
  try {
    const suggestionObj = JSON.parse(suggestion);
    if (
      "meta" in suggestionObj &&
      "name" in suggestionObj &&
      "type" in suggestionObj
    ) {
      const nodeSuggestion: NodeSuggestionType = {
        nodeId: originalNode.id,
        meta: suggestionObj.meta,
        name: suggestionObj.name,
        type: suggestionObj.type,
      };
      return (
        <NodeSuggestion
          key={key}
          delay={delay}
          isPublished={isPublished}
          nodeSuggestion={nodeSuggestion}
          originalNode={originalNode}
          onApply={onApply}
        />
      );
    } else {
      throw new Error(suggestionObj);
    }
  } catch (e) {
    logger.error("Invalid node suggestion format", e);
    return (
      <CopilotTextContent
        key={key}
        content={`Unable to load node suggestion: ${errorMessage(e)}`}
        delay={delay}
      />
    );
  }
};

export const ConversationMessageItem: React.FC<Props> = ({
  message,
  node,
  conversationId,
  completionId,
  onApply,
  onRegenerate,
  animate,
}) => {
  const { orgId, version } = useAuthoringContext();
  const [reactionStatus, setReaction] = useState<ReactionStatus | null>(null);
  const isCopilotMessage = message.role === ConversationMessageRoleEnum.COPILOT;
  const { nodesArray } = useGraphStore();

  const renderMention = useCallback(
    ({ nodeName, key, delay }: RenderMentionFnArgs) => {
      const node =
        nodesArray.find(
          (n) => n.data.label.toLowerCase() === nodeName.toLowerCase(),
        ) ?? null;
      return [<NodeMention key={key} delay={delay} node={node} />];
    },
    [nodesArray],
  );

  const renderedContent = useMemo(() => {
    const responseBlocks = replaceNodeMentions({
      renderMention,
      content: message.content,
    });
    let baseAnimationDelay = 0.1;
    let baseKey = responseBlocks.length;
    return responseBlocks.map((block) => {
      if (typeof block === "string") {
        const parsed = replaceNodeSuggestions({
          baseKey: baseKey,
          content: block,
          originalNode: node,
          onApply,
          animate: animate,
          baseAnimationDelay,
          isPublished: version.status === FlowVersionStatus.PUBLISHED,
        });
        baseKey += parsed.length;
        baseAnimationDelay += normalizeAnimationSpeed(block.length);
        return parsed;
      }
      return block;
    });
  }, [renderMention, message.content, node, onApply, animate, version.status]);

  const handleReaction = (status: ReactionStatus) => {
    if (!completionId) return;

    let recordedReaction;
    if (status === reactionStatus) {
      setReaction(null);
      recordedReaction = "unset" as const;
    } else {
      setReaction(status);
      recordedReaction = status;
    }

    tracker.emit(
      trackingEvents.reactCopilotResponse({
        organization_id: orgId,
        node_id: node.id,
        conversation_id: conversationId,
        completion_id: completionId,
        reaction: recordedReaction,
      }),
    );
  };

  return (
    <li className="group flex flex-col gap-y-2">
      <div className="flex items-center justify-between gap-x-4">
        <span className="text-gray-500 font-inter-normal-12px">
          {isCopilotMessage ? "Copilot" : "User"}
        </span>
        {isCopilotMessage && completionId && (
          <Actions
            status={reactionStatus}
            onDislike={() => handleReaction("disliked")}
            onLike={() => handleReaction("liked")}
            onRegenerate={onRegenerate}
          />
        )}
      </div>
      <div>{renderedContent}</div>
    </li>
  );
};
