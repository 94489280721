import { faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import { useFormContext } from "react-hook-form";

import { Button } from "src/base-components/Button";
import { ErrorHint } from "src/base-components/ErrorHint";
import { ExternalLink } from "src/base-components/ExternalLink";
import { FormItem } from "src/base-components/FormItem";
import { Input } from "src/base-components/Input";
import { MonospacedInput } from "src/base-components/MonospacedInput";
import { InboundWebhookActivationConditionForm } from "src/connections/config/inboundWebhook/InboundWebhookActivationConditionForm";
import { isFieldErrored } from "src/connections/config/isFieldErrored";
import { errorProps } from "src/connections/config/shared/errorProps";
import {
  ConnectionCreationFieldError,
  InboundWebhookConnectionConfigInputsT,
} from "src/connections/types";
import { Tooltip } from "src/design-system/Tooltip";

const IDENTIFIER_DOCUMENTATION_URL =
  "https://docs.taktile.com/third-party-connections/inbound-webhook-node/configuring-the-inbound-webhook-connection/resources#identifier-path";
const ACTIVATION_CONDITIONS_DOCUMENTATION_URL =
  "https://docs.taktile.com/third-party-connections/inbound-webhook-node/configuring-the-inbound-webhook-connection/resources#activation-conditions";

type InboundWebhookResourceConfigFieldsetPropsT = {
  id: string;
  index: number;
  canBeDeleted: boolean;
  handleResourceRemoval: (id: string) => void;
  activationFieldErrors: ConnectionCreationFieldError[];
};

const IdentifierHelpText: React.FC = () => {
  return (
    <>
      <div>
        Every webhook request contains a unique identifier for the processed
        entity (e.g., <code>business_id</code>, <code>person_id</code>,{" "}
        <code>report_id</code>) from the provider. This identifier allows the
        Taktile webhook handler to match the incoming webhook request to the
        correct paused Decision Flow execution. To specify the exact location of
        this identifier within the webhook request use <code>body</code>,{" "}
        <code>headers</code>, or <code>query_params</code> with dot-notation for
        nested keys.
      </div>
      <div>
        E.g. if the webhook's body has the following structure:
        <div>
          <code className="whitespace-pre">
            {`{
  "id": "8e9ea7e4",
  "business_id": "123456",
  "updated_at": "2021-11-09",
  "status": "In_Progress"
}`}
          </code>
        </div>{" "}
        Use <code>body.business_id</code> to define the identifier path.
      </div>
      <div>
        For further details refer to our{" "}
        <ExternalLink href={IDENTIFIER_DOCUMENTATION_URL}>
          documentation
        </ExternalLink>
        .
      </div>
    </>
  );
};

const ActivationConditionHelpText: React.FC = () => {
  return (
    <>
      <div>
        Activation conditions allow you to specify conditions that must be met
        by the webhook request to resume the Flow execution. This is especially
        useful when a data provider sends webhook requests for every step of the
        process while you only want to resume the Decision Flow execution when
        the entire process is finished.
      </div>
      <div>
        E.g. if the webhook's body has the following structure:
        <div>
          <code className="whitespace-pre">
            {`{
  "id": "8e9ea7e4",
  "business_id": "123456",
  "updated_at": "2021-11-09",
  "status": "Successful"
}`}
          </code>
        </div>{" "}
        Set the condition to <code>webhook.body.status = "Successful"</code> to
        resume the flow when the process is completed.
      </div>
      <div>
        For further details refer to our{" "}
        <ExternalLink href={ACTIVATION_CONDITIONS_DOCUMENTATION_URL}>
          documentation
        </ExternalLink>
        .
      </div>
    </>
  );
};

export const InboundWebhookResourceConfigFieldset: React.FC<
  InboundWebhookResourceConfigFieldsetPropsT
> = ({
  id,
  index,
  canBeDeleted,
  handleResourceRemoval,
  activationFieldErrors,
}) => {
  const {
    formState: { errors },
    register,
  } = useFormContext<InboundWebhookConnectionConfigInputsT>();
  const resourceErrors = errors.resourceConfigs?.[index];
  return (
    <div key={id} className="mb-4 rounded-lg bg-gray-50 px-4 pb-9 pt-4">
      <div className="flex items-center justify-between">
        <h3 className="text-xs uppercase text-gray-600">
          Resource {index + 1}
        </h3>
        <Tooltip
          dataLoc="resource-delete-button-tooltip"
          disabled={canBeDeleted}
          placement="top"
          title="At least one resource is required"
          asChild
        >
          <Button
            dataLoc="resource-delete-button"
            disabled={!canBeDeleted}
            iconLeft={faTrashAlt}
            size="sm"
            variant="secondary"
            onClick={() => handleResourceRemoval(id)}
          >
            Delete resource
          </Button>
        </Tooltip>
      </div>
      <p className="mb-5 mt-3 text-xs text-gray-500 font-inter-normal-12px">
        Use resources to configure multiple scenarios per Connection for
        different types of events that the provider might send
      </p>
      <FormItem className="mt-4" gap="sm" label="Name" isRequired>
        {isFieldErrored(resourceErrors, "name") && (
          <ErrorHint>{resourceErrors?.name?.message}</ErrorHint>
        )}
        <Input
          data-loc={`webhook-resource-name-${index}`}
          {...errorProps(isFieldErrored(resourceErrors, "name"))}
          {...register(`resourceConfigs.${index}.name`, {
            required: "Name is required",
          })}
          placeholder="Resource name"
          fullWidth
        />
      </FormItem>
      <FormItem
        gap="sm"
        helpClassName="mt-1"
        helpTooltipBody={<IdentifierHelpText />}
        label="Where in the webhook can we find an identifier?"
        isRequired
      >
        <p className="mb-5 text-xs text-gray-500 font-inter-normal-12px">
          Specify the unique identifier that the provider sends with the webhook
          response. This is required to connect a received webhook to a specific
          decision.
        </p>
        {isFieldErrored(resourceErrors, "correlationIDPath") && (
          <ErrorHint>{resourceErrors?.correlationIDPath?.message}</ErrorHint>
        )}
        <MonospacedInput
          containerClassName="flex-grow"
          errored={isFieldErrored(resourceErrors, "correlationIDPath")}
          formProps={register(`resourceConfigs.${index}.correlationIDPath`, {
            required: "Field is required",
          })}
          inputDataLoc={`webhook-resource-correlation-id-path-${index}`}
          placeholder="e.g. body.business_id"
          prefix="webhook."
          codeColors
          errorIconOnError
        />
      </FormItem>
      <FormItem
        gap="sm"
        helpClassName="mt-1"
        helpTooltipBody={<ActivationConditionHelpText />}
        label="Activation condition"
      >
        <p className="mb-3 text-xs text-gray-500 font-inter-normal-12px">
          Specify the conditions that the webhook must meet to resume the Flow
        </p>
        <InboundWebhookActivationConditionForm
          fieldErrors={activationFieldErrors || []}
          resourceIndex={index}
        />
      </FormItem>
    </div>
  );
};
