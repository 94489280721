import { useState } from "react";
import { useDebouncedCallback } from "use-debounce";

export const useShowOnHover = (delay: number) => {
  const [isHovered, setIsHovered] = useState(false);
  const setIsHoveredDebounced = useDebouncedCallback(setIsHovered, delay);

  return {
    isHovered,
    onMouseEnter: () => {
      setIsHoveredDebounced.cancel();
      return setIsHovered(true);
    },
    onMouseLeave: () => setIsHoveredDebounced(false),
  };
};
