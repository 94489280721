import {
  InboundWebhookProviderResourceT,
  IntegrationProviderResourceT,
  ManualReviewProviderResourceT,
} from "src/api/connectApi/types";
import {
  CONNECTIONS_MAP,
  providerInConfigMap,
} from "src/connections/connectionConfigs";
import { assertUnreachable } from "src/utils/typeUtils";

export const DOCS_FUNDAMENTALS_VERSIONS_AND_PUBLISHING =
  "https://docs.taktile.com/versioning-and-deployment/decision-flow-versions-and-publishing";
export const DOCS_FUNDAMENTALS_DATASET_TESTING =
  "https://docs.taktile.com/testing-and-mocking/datasets-and-testing-1";
export const DOCS_FUNDAMENTALS_SCHEMAS =
  "https://docs.taktile.com/decision-flow-fundamentals/input-output-schema-for-flows";
export const DOCS_FUNDAMENTALS_PARAMETERS =
  "https://docs.taktile.com/decision-flow-fundamentals/flow-parameters";
export const DOCS_FUNDAMENTALS_DECISION_HISTORY =
  "https://docs.taktile.com/monitoring/decision-history";
export const DOCS_RETENTION_POLICIES =
  "https://docs.taktile.com/settings-and-administration/data-retention-policies";
export const DOCS_API_KEYS = "https://docs.taktile.com/developer-docs/api-keys";
export const DOCS_JOBS = "https://docs.taktile.com/job-management/jobs";
export const DOCS_COMPARATIVE_ANALYTICS =
  "https://docs.taktile.com/testing-and-mocking/datasets-and-testing-1/analytics-and-metrics#comparative-analytics";

export const getConnectDocsLink = (
  providerResource:
    | IntegrationProviderResourceT
    | InboundWebhookProviderResourceT
    | ManualReviewProviderResourceT,
): string | null => {
  if (providerInConfigMap(providerResource.provider)) {
    const connectionConfig = CONNECTIONS_MAP[providerResource.provider];
    if (providerResource.resource in connectionConfig.resources) {
      const resourceConfig =
        // @ts-ignore
        connectionConfig.resources[providerResource.resource];
      return resourceConfig.externalLink;
    }
  }

  switch (providerResource.provider) {
    case "schufa": {
      switch (providerResource.resource) {
        case "b2c_report": {
          return "https://www.notion.so/taktile/B2C-Credit-Report-Bonit-tsauskunft-8d113d0ad0304f92b270ac0bee93e3c2?pvs=4";
        }
        case "schufa_fraud_pre_check": {
          return null;
        }
        case "b2b_report_cascading": {
          return "https://www.notion.so/taktile/B2B-Credit-Report-Cascading-Kurzauskunft-mit-Kaskade-b5f09b6057d14d8e9c5c835b62cf3e35?pvs=4";
        }
        default:
          assertUnreachable(providerResource);
          return null;
      }
    }
    case "boniversum": {
      if (providerResource.resource === "consumer_report") {
        return "https://www.notion.so/taktile/Consumer-Report-2e57afe5345440ff9a65d8dc836d154c?pvs=4";
      } else {
        assertUnreachable(providerResource);
        return null;
      }
    }
    case "dnb": {
      switch (providerResource.resource) {
        case "identity_resolution": {
          return "https://www.notion.so/taktile/How-to-Search-for-a-Company-ab7d4f78c9834874a29c66d8af4a143e?pvs=4";
        }
        case "credit_report": {
          return "https://www.notion.so/taktile/Credit-Report-6dff690fa1654b9790d1abdd33f2e87b?pvs=4";
        }
        case "custom_report": {
          return "https://www.notion.so/taktile/Custom-Report-b4a303b5db6e497686803db89b6cf4fc?pvs=4";
        }
        default:
          assertUnreachable(providerResource);
          return null;
      }
    }
    case "codat": {
      switch (providerResource.resource) {
        case "company_identification": {
          return "https://www.notion.so/taktile/How-to-Search-for-a-Company-76de161cddb8411cbdf53051b7fdeaac?pvs=4";
        }
        case "company_info": {
          return "https://www.notion.so/taktile/Company-Info-c7e2f6f226994364889e4b93077a7725?pvs=4";
        }
        case "enhanced_profit_and_loss_accounts": {
          return "https://www.notion.so/taktile/Enhanced-Profit-and-Loss-Accounts-41e04256e93d497bbce829cc5acaa81f?pvs=4";
        }
        case "enhanced_balance_sheet_accounts": {
          return "https://www.notion.so/taktile/Enhanced-Balance-Sheet-Accounts-4d6b58439ad4430c8ac8ea3216ccfe7c?pvs=4";
        }
        case "aged_creditors_report": {
          return "https://www.notion.so/taktile/Aged-Creditors-879a8c88ca144ed5a50de2a0cba9c0ee?pvs=4";
        }
        case "aged_debtors_report": {
          return "https://www.notion.so/taktile/Aged-Debtors-0c9cd2dbe2404049992b55070ce42b9f?pvs=4";
        }
        default:
          assertUnreachable(providerResource);
          return null;
      }
    }
    case "crif_b2c": {
      switch (providerResource.resource) {
        case "credit_check": {
          return "https://www.notion.so/taktile/Credit-Check-B2C-019dbb7cb2894b76bf1b6be6201436ea?pvs=4";
        }
        case "identity_check": {
          return "https://www.notion.so/taktile/Identity-Check-B2C-eccd1f9b7aa8446c913b1b8964ea662e?pvs=4";
        }
        default:
          assertUnreachable(providerResource);
          return null;
      }
    }
    case "crif_b2b": {
      switch (providerResource.resource) {
        case "credit_report": {
          return "https://www.notion.so/taktile/Credit-Report-B2B-65a15f0af14f46988145286ed81c86f8?pvs=4";
        }
        case "full_report": {
          return "https://www.notion.so/taktile/Full-Report-B2B-5eea3f1f91684987b14f571e20e55fea?pvs=4";
        }
        case "search_report": {
          return "https://www.notion.so/taktile/How-to-search-for-a-Company-B2B-f0616320ec53447db6acfdfd48d9b47a?pvs=4";
        }
        default:
          assertUnreachable(providerResource);
          return null;
      }
    }
    case "crif_high_mark": {
      return null;
    }
    case "equifax_uk": {
      if (providerResource.resource === "gateway") {
        return "https://www.notion.so/taktile/Equifax-Gateway-fd1565c93e414ebfb05c2b8011f506ae?pvs=4";
      } else {
        assertUnreachable(providerResource);
        return null;
      }
    }
    case "data_x": {
      if (providerResource.resource === "data_x_credit_report") {
        return "https://www.notion.so/taktile/DataX-Credit-Report-256c90551a554563929016447cef39fe?pvs=4";
      } else {
        assertUnreachable(providerResource);
        return null;
      }
    }
    case "equifax_us": {
      switch (providerResource.resource) {
        case "scores_and_attributes": {
          return "https://www.notion.so/taktile/Scores-and-Attributes-f0ef10ef275b40e89120e8d7b937cf10";
        }
        case "mla_report": {
          // TODO: adjust (INT-979)
          return null;
        }
        case "one_view_report": {
          return "https://www.notion.so/taktile/OneView-Consumer-Credit-Report-01dcaf48169f4529856289920af94500?pvs=4";
        }
        default:
          assertUnreachable(providerResource);
          return null;
      }
    }
    case "equifax_us_commercial_risk": {
      if (
        providerResource.resource === "equifax_us_commercial_risk_credit_report"
      ) {
        return "https://www.notion.so/taktile/Equifax-Commercial-Risk-Credit-Report-7777d88531474bc49fd6800f7b12ec98?pvs=4";
      } else {
        assertUnreachable(providerResource);
        return null;
      }
    }
    case "experian_us_b2b": {
      switch (providerResource.resource) {
        case "experian_us_b2b_business_search": {
          return "https://www.notion.so/taktile/How-to-search-for-a-Company-76a9a919bf59412497c1867e4176cc4a?pvs=4";
        }
        case "experian_us_b2b_small_business_credit_share_scores": {
          return "https://www.notion.so/taktile/Small-Business-Credit-Share-Scores-f6ac71b423de46b9ba38643fd25ab0c7?pvs=4";
        }
        case "experian_us_b2b_small_business_credit_share_profiles_report": {
          return "https://www.notion.so/taktile/Small-Business-Credit-Share-Profiles-Report-62058d2175114a28802c2a304a184a81?pvs=4";
        }
        default:
          assertUnreachable(providerResource);
          return null;
      }
    }
    case "experian_us_clarity_services": {
      if (providerResource.resource === "experian_us_clarity_services_report") {
        return "https://www.notion.so/taktile/Clarity-Services-Report-cf301d2cba5e44ae855a1fcc70230a7d?pvs=4";
      } else {
        assertUnreachable(providerResource);
        return null;
      }
    }
    case "creditsafe": {
      switch (providerResource.resource) {
        case "company_credit_report": {
          return "https://www.notion.so/taktile/Company-Credit-Report-49b817c1c3d247d5aa4c3bdb5864e87c?pvs=4";
        }
        case "company_search": {
          return "https://www.notion.so/taktile/Company-Search-c645083af119465abf7108ea5209ffaa?pvs=4";
        }
        default:
          assertUnreachable(providerResource);
          return null;
      }
    }
    case "experian_us": {
      if (providerResource.resource === "experian_us_credit_report") {
        return "https://www.notion.so/taktile/Consumer-Credit-Report-eaf6d31be1424801a1d06407785d6d9e?pvs=4";
      } else {
        assertUnreachable(providerResource);
        return null;
      }
    }
    case "webhook":
      switch (providerResource.resource) {
        case "event": {
          return "";
        }
        case "review_event": {
          return "https://docs.taktile.com/decision-design/manual-review";
        }
        default:
          assertUnreachable(providerResource);
          return null;
      }
    default:
      // @ts-ignore
      assertUnreachable(providerResource);
      return null;
  }
};
