import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import React, { useState } from "react";
import { useDebounceCallback } from "usehooks-ts";

import { ManageFeatureModal } from "./ManageFeatureModal";
import { useModal } from "src/design-system/Modal";
import { FeatureCatalogueWrapper } from "src/featureCatalogue/FeatureCatalogue";
import { Features } from "src/featureCatalogue/Features/Features";
import { SubHeader } from "src/flow/SubHeader";
import { useCapabilities } from "src/hooks/useCapabilities";

const FeaturesHeader: React.FC<{
  onSearch: (query: string) => void;
  openCreateModal: () => void;
}> = ({ onSearch, openCreateModal }) => {
  const { featureCatalogue } = useCapabilities();

  const debouncedSearch = useDebounceCallback((value: string) => {
    onSearch(value);
  }, 300);

  return (
    <>
      <div className="ml-auto flex h-full items-center">
        <SubHeader.SearchBox
          dataLoc="features-search"
          placeholder="Search features..."
          onChange={(value) => debouncedSearch(value)}
        />
        {featureCatalogue.canCreate && (
          <SubHeader.Button
            dataLoc="features-create"
            icon={faPlus}
            tooltip="Create Feature"
            onClick={openCreateModal}
          />
        )}
      </div>
    </>
  );
};

export const FeaturesPage: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const { openModal, closeModal, isOpen, afterLeave } = useModal();

  return (
    <FeatureCatalogueWrapper
      header={
        <FeaturesHeader openCreateModal={openModal} onSearch={setSearchQuery} />
      }
    >
      <Features openCreateModal={openModal} searchQuery={searchQuery} />
      <ManageFeatureModal
        afterLeave={afterLeave}
        isOpen={isOpen}
        mode="create"
        onClose={closeModal}
      />
    </FeatureCatalogueWrapper>
  );
};
