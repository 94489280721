import { TableValue } from "src/entities/entityView/EntityDetailsSidePane";
import { TreeData } from "src/entities/entityView/Tree";
import { Property } from "src/entities/queries";

export const mockTreeData: TreeData = {
  id: "1",
  key: "trx",
  value: "Transaction",
  children: [
    { id: "1.1", key: "id", value: "...1640c31" },
    { id: "1.2", key: "timestamp", value: "2022-10-10" },
    { id: "1.3", key: "amount", value: "16,500" },
    { id: "1.4", key: "currency", value: "EUR" },
    { id: "1.5", key: "status", value: '"Pending"' },
    { id: "1.6", key: "type", value: "Business" },
    { id: "1.7", key: "ip_address", value: "31.414.31.13" },
    {
      id: "1.8",
      key: "related_card",
      value: "Related Card",
      type: "cards",
      updatedAt: "2017-10-07T17:11:00Z",
      children: [
        { id: "1.8.1", key: "id", value: "<uuid>" },
        { id: "1.8.2", key: "issuer", value: "MC" },
        { id: "1.8.3", key: "number", value: "...345" },
      ],
    },
    {
      id: "1.9",
      key: "merchant",
      value: "Merchant",
      updatedAt: "2017-10-07T17:11:00Z",
      children: [
        { id: "1.9.1", key: "id", value: "...ab1603" },
        { id: "1.9.2", key: "name", value: "ACME Inc." },
        { id: "1.9.3", key: "email", value: "x@y.com" },
        { id: "1.9.4", key: "phone", value: "1234567" },
        {
          id: "1.9.5",
          key: "email",
          value: "Merchant Address",
          children: [
            { id: "1.9.5.1", key: "street", value: "Blue avenue" },
            { id: "1.9.5.2", key: "house number", value: "1" },
            { id: "1.9.5.3", key: "post code", value: "10178" },
            { id: "1.9.5.4", key: "city", value: "Berlin" },
            { id: "1.9.5.5", key: "country", value: "Germany" },
          ],
        },
      ],
    },
  ],
};

export const mockedSchemaDefinition: Record<string, Property> = {
  id: {
    _display_name: "id",
    _indexed: true,
    _type: "string",
  },
  first_name_last_name: {
    _display_name: "name",
    _indexed: true,
    _type: "string",
  },
  email: {
    _display_name: "e-mail",
    _indexed: true,
    _type: "string",
  },
  phone: {
    _display_name: "phone number",
    _indexed: true,
    _type: "string",
  },
  date_of_birth: {
    _display_name: "date of birth",
    _indexed: true,
    _type: "date",
  },
  user_role: {
    _display_name: "user role",
    _indexed: true,
    _type: "enum",
  },
  team: {
    _display_name: "team",
    _indexed: true,
    _type: "enum",
  },
  employer: {
    _display_name: "employer",
    _indexed: true,
    _type: "relation",
    _rel_schema: "companies",
    _cardinality: "1",
  },
  user_category: {
    _display_name: "user category",
    _indexed: true,
    _type: "tags",
  },
  risk_group: {
    _display_name: "risk group",
    _indexed: true,
    _type: "enum",
  },
  cards: {
    _display_name: "cards",
    _indexed: true,
    _type: "relation",
    _rel_schema: "cards",
    _cardinality: "n",
  },
};

export const mockedEntity: Record<string, TableValue> = {
  id: "6a4e6d79-3ebd-4b94-93f1-4433e6efbe4a",
  first_name_last_name: "Charles Darwin",
  email: "charles.darwin@gmail.com",
  phone: "987-654-3210",
  date_of_birth: "1970-05-14",
  user_role: "Viewer",
  team: "Sales",
  employer: "Global Solutions Corp.",
  user_category: ["Recently Added", "High Usage"],
  risk_group: "Low",
  cards: ["3147", "4721"],
};
